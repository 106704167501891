import axios from 'axios';
import React, { Component } from 'react';
import ReactDom from 'react-dom';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import './App.css';
import { Redirect } from 'react-router';
import loadingGif from './images/loading.gif';
import loadingGif2 from './images/loading2.gif';
import swal from 'sweetalert';
import PasswordStrength from "./passwordStrength";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'
import img from './ImgFactory.js';
import MetaTags from 'react-meta-tags';
import ReactTooltip from 'react-tooltip'
import { isMobile } from "react-device-detect";

const firebase = vars.firebase;


function getParameterByName(name) {
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  var regexS = "[\\?&]"+name+"=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if( results == null )
    return "";
  else
    return decodeURIComponent(results[1].replace(/\+/g, " "));
}

class FirebaseAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: false,
      userProfile: null,
      changePassword: false,
      actionCode: "",
      password: "",
      confirmPassword: "",
      goHome: false,
      goSignIn: false,
      recaptchaVerified: false,
      deleteSignIn: false,
      goDelete: false,
      deleteURL: '',
    };

    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleVerifyEmail = this.handleVerifyEmail.bind(this);
    this.handleRecoverEmail = this.handleRecoverEmail.bind(this);
    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleConfirmPassChange = this.handleConfirmPassChange.bind(this);
    this.submitNewPassword = this.submitNewPassword.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.captchaLoaded = this.captchaLoaded.bind(this);

    this.doCancel = this.doCancel.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.deleteAccountRender = this.deleteAccountRender.bind(this);
    this.handleDeleteSignIn = this.handleDeleteSignIn.bind(this);
    this.validateUserForDelete = this.validateUserForDelete.bind(this);

    var self = this;

    document.addEventListener('DOMContentLoaded', function() {
      // Get the action to complete.
      var mode = getParameterByName('mode');
      // Get the one-time code from the query parameter.
      var actionCode = getParameterByName('oobCode');

      var auth = firebase.auth();

      // Handle the user management action.
      switch (mode) {
        case 'resetPassword':
          self.handleResetPassword(auth, actionCode);
          break;
        case 'recoverEmail':
          self.handleRecoverEmail(auth, actionCode);
          break;
        case 'verifyEmail':
          self.handleVerifyEmail(auth, actionCode);
          break;
        case 'signIn':
          self.handleDeleteSignIn();
          break;
        default:
          self.setState({ goHome: true });
          break;
      }
    }, false);
  }

  async handleDeleteSignIn() {
    let self = this;
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = await swal("Please provide your email for confirmation", {
          content: "input",
          icon: "info"
        });
      }
      // The client SDK will parse the code from the link for you.
      firebase.auth().signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          
          if (result.additionalUserInfo.isNewUser) {
            //If they are a new user, delete the account now
            //and just redirect them to the homepage
            //Say we couldn't find the account
            swal("Account not Found", "No account with this email address was found", "error");
            result.user.delete();
            self.setState({
              goHome: true
            });
          } else {
            self.validateUserForDelete(result);
          }
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          swal("Error", "An error occured when verifying email link\n" + error, "error");

          console.log(error);

          self.setState({
            goHome: true
          })
        });
    }
  }

  async validateUserForDelete(result) {
    let self = this;
    let userId = result.user.uid;
    const snapshot = await firebase.database().ref("users/" + userId).once("value");
    var data = snapshot.val() || null;

    console.log(data);

    if (data === null || !data.eth || data.eth.trim() == "") {
      self.setState({
        deleteSignIn: true,
        userProfile: result.user,
        deleteURL: getParameterByName('continueUrl')
      });
    } else {
      self.setState({
        goDelete: true
      });
    }
  }

  handleResetPassword(auth, actionCode) {
    var self = this;

    var accountEmail;

    this.setState({ actionCode: actionCode });

    auth.verifyPasswordResetCode(actionCode).then(function(email) {
      var accountEmail = email;

      self.setState({ changePassword: true });

    }).catch(function(error) {
      swal({
        title: "Error",
        text: "Invalid or expired code, please try resetting your password again",
        icon: "error"
      }).then(function() {
        self.setState({
          goHome: true
        });
      })
    });
  }

  handleVerifyEmail(auth, actionCode) {

    var self = this;

    var email = '';

    var verified = false;

    this.setState({ actionCode: actionCode });

    auth.checkActionCode(actionCode).then(function(resp) {
      email = resp.data.email;

      return auth.applyActionCode(actionCode);
    }).then(function(resp) {
      verified = true;

      return fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/confirmlink', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email
        })
      });
    }).then(function(r) {
      return r.json();
    }).then(function(obj) {
      if (!obj.error) {
        swal("Success", "Your email has successfully been verified and your wallet address has been linked", "success");
        self.setState({
          goSignIn: true
        });
      } else {
        if (obj.message == "No Pending Link") {
          swal({
            title: 'Link Wallet Address',
            text: 'Your email has been verified, you may now link your external wallet address to your game account. Would you like to do this now? You can always link your wallet at a later time by signing in\nYou may be required to sign in to link your wallet',
            icon: 'info',
            buttons: true
          }).then((willLink) => {
            if (willLink) {
              vars.askForWeb3().then(function() {
                self.setState({
                  goSignIn: true
                });
              }).catch(function(e) {
                swal({
                  title: 'Wallet Connection Failed',
                  text: 'Wallet connection failed. To link an external wallet address to your game account, go to your account and choose Link',
                  icon: 'error'
                });
                console.log(e);
              });
            } else {
              self.setState({
                goHome: true
              });
            }
          });
        } else {
          swal({
            title: "Error",
            text: "Your email has been verified, but there was a problem linking your external wallet. You can try again by signing in\nError: " + obj.message,
            icon: "error"
          });
        }
      }
    }).catch(function(error) {
      if (verified) {
        swal("Success", "Your email has successfully been verified and you now need to link your wallet", "info");
        return;
      }

      swal({
        title: "Error",
        text: "Invalid or expired code, please try verifying your email again. If you have already verified your email, then no futher action is needed.",
        icon: "error"
      }).then(function() {
        self.setState({
          goHome: true
        });
      })
    });
  }

  handleRecoverEmail(auth, actionCode) {
    var self = this;

    this.setState({ actionCode: actionCode });

    var restoredEmail = null;
    auth.checkActionCode(actionCode).then(function(info) {
      restoredEmail = info['data']['email'];
      return auth.applyActionCode(actionCode);
    }).then(function() {
      swal({
        title: "Success",
        text: "Email has been reverted to " + restoredEmail + ". Would you like to reset your password?",
        icon: "success",
        buttons: ["No", "Yes"]
      }).then(function(shouldReset) {
        if (shouldReset) {
          auth.sendPasswordResetEmail(restoredEmail).then(function() {
              swal("Instructions Sent", "An email has been sent with instructions to reset your password, please follow the instructions in the email to reset your password", "warning");
          }).catch(function(error) {
            swal({
              title: "Error",
              text: "An error has occured sending the password reset email:\n" + error.message ,
              icon: "error"
            }).then(function() {
              self.setState({
                goHome: true
              });
            })
          });
        }
      });
    }).catch(function(error) {
      swal({
        title: "Error",
        text: "Invalid or expired code, please try resetting your password again",
        icon: "error"
      }).then(function() {
        self.setState({
          goHome: true
        });
      });
    });
  }

  componentDidMount() {
    let self = this;
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      this.setState({ isSignedIn: !!user, userProfile: user });
      self.setState({
        userProfile: user
      });
      vars.username().then(function(cu) {
        self.setState({
          currentUsername: cu
        });
      }).catch(function(e) {
        console.log("Could not lookup username");
        console.log(e);
      });
    });

    let userCheck = firebase.auth().currentUser;
    if (userCheck != null) {
      self.setState({
        userProfile: userCheck
      });
      vars.username().then(function(cu) {
        self.setState({
          currentUsername: cu
        });
      }).catch(function(e) {
        console.log("Could not lookup username");
        console.log(e);
      });
    }
    
    loadReCaptcha();
  }

  componentWillUnmount() {
    // Un-registers the auth state observer.
    this.unregisterAuthObserver();
  }

  submitNewPassword() {
    var self = this;

    const forbiddenCharacters = ['"', "'"];

    if (this.state.password != this.state.confirmPassword) {
      swal("Mismatch Password", "The passwords entered do not match.", "warning");
      return;
    }

    if (!(/\d/.test(this.state.password) && /[a-zA-Z]/.test(this.state.password))) {
      swal("Password Requirement", "Your password must include at least one letter and one number", "error");
      return;
    }

    if (this.state.password.length < 6) {
      swal("Password Requirement", "Your password must be at least 6 characters long", "error");
      return;
    }

    if (!this.state.recaptchaVerified) {
      swal("ReCaptcha", "Please complete the ReCaptcha before you create your game account", "error");
      return;
    }

    if (forbiddenCharacters.some((c) => this.state.password.includes(c))) {
      swal("Password Requirement", "Your password cannot contain any quotes", "error");
      return;
    }

    var auth = firebase.auth();

    auth.confirmPasswordReset(this.state.actionCode, this.state.password).then(function(resp) {
      swal({
        title: "Success",
        text: "Your account password has successfully been changed. You will now be redirected to the homepage",
        icon: "success"
      }).then(function() {
        self.setState({
          goHome: true
        });
      })
    }).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode == 'auth/weak-password') {
        swal("Password Weak", "Your password is to weak, please try using a stronger password", "error");
      } else {
        swal("Error", "An error has occured: \"" + errorMessage + "\"", "error");
      }
    });
  }

  handlePassChange(event) {
    this.setState({password: event.target.value});
  }

  handleConfirmPassChange(event) {
    this.setState({confirmPassword: event.target.value});
  }

  verifyCallback(response) {
    if (response) {
      this.setState({ recaptchaVerified: true });
    } else {
      this.setState({ recaptchaVerified: false });
    }
  }

  captchaLoaded() {
    this.setState({ recaptchaVerified: false });
  }

  confirmDelete() {
    if (!this.state.recaptchaVerified) {
      swal("ReCaptcha", "Please complete the ReCaptcha before you delete your game account", "error");
      return;
    }

    let self = this;
    if (self.state.userProfile == null) {
      swal("Login Failed", "No account logged in!", "error");
      self.setState({
        goHome: true
      });
      return;
    }

    swal({
        title: "Are You Sure?",
        text: "Please confirm that you would like to delete the account " + this.state.currentUsername + " permanently. This action cannot be undone.",
        icon: 'warning',
        buttons: true
    }).then((willdelete) => {
        if (willdelete) {
          self.doDelete();
        }
    });
  }

  async doDelete() {
    try {
      swal({
        title: "Deleting...",
        text: "Please wait while your game account is deleted. Do not close this window",
        icon: loadingGif,
        className: 'wr-loader',
        button: false,
        closeOnClickOutside: false
      });

      try {
        const userToken = await this.state.userProfile.getIdToken();
        
        let response = await fetch(this.state.deleteURL, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + userToken
          },
        });

        let result = await response.json();

        if (result.error) {
          swal("Delete Failed", result.message, "error");
          return;
        }
      } catch (e) {
        swal("Delete Failed", e, "error");
        return;
      }
      
      firebase.auth().signOut();

      swal("Delete Successful", "Your account has been deleted successfully", "success");
      this.setState({
        goHome: true
      });
    } catch (error) {
      swal("Deletion Failed", "Could not delete your account. Sorry about that :(\n Please give this error to the devs.\nError: " + error, "error");
    }
  }

  doCancel() {
    this.setState({
      goHome: true
    });
  }

  deleteAccountRender() {
    return (
      <div className="loginPage" style={{paddingLeft: '40px', paddingRight: '40px'}}>
        <MetaTags>
          <title>War Riders Delete Game Account</title>
          <meta property="og:title" content="War Riders Delete Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <br></br>
        <br></br>
        <br></br>
        <h2 style={{textAlign: 'center'}}>Final Step: Delete Game Account?</h2>
        <h4 style={{textAlign: 'center', marginTop: '3%'}}>Are you sure you want to delete your game account and all associated data? This action cannot be undone.</h4>
        <Grid>
          <Row>
            <Col md={4} sm={12}>
            </Col>
            <Col md={2} sm={12}>
              <a onClick={this.confirmDelete} className="btn-bar" style={{ color: '#fff', width: '140px', margin: "0 auto", marginTop: '45px'}}>
                <span>Delete Account</span>
              </a>
            </Col>
            <Col md={2} sm={12}>
              <a onClick={this.doCancel} className="btn-bar" style={{ color: '#fff', width: '120px', margin: "0 auto", marginTop: '45px'}}>
                <span>Cancel</span>
              </a>
            </Col>
            <Col md={4} sm={12}>
            </Col>
          </Row>
        </Grid>
        <div style={{  display: "flex", justifyContent: "center", alignItems: "center", margin: '25px auto 0px auto', width: 'fit-content' }}>
          <ReCaptcha
              ref={(el) => {this.createCaptcha = el;}}
              size="normal"
              data-theme="dark"
              render="explicit"
              sitekey="6LfRHosUAAAAAN4nwL9re2SXahZXVPm0LuSDYHou"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.captchaLoaded}
          />
        </div>
      </div>
    )
  }

  changePasswordRender() {
    return (
      <div className="loginPage">
        <h3>Please enter a new password:</h3>
        <PasswordStrength
            name="password-strength"
            value={this.state.password}
            minLength={6}
            errorBorder={true}
            divStyle={{
              marginLeft: '20%'
            }}
            colors={{
              poor: {
                color: "red",
                label: "Very weak"
              },
              weak: {
                color: "red",
                label: "Very weak"
              },
              good: {
                color: "orange",
                label: "Good"
              },
              strong: {
                color: "lightgreen",
                label: "Strong"
              },
              veryStrong: {
                color: "#4cbac0",
                label: "Awesome Very strong"
              }
            }}
          >
            <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePassChange} />
        </PasswordStrength>
        <p style={{ textAlign: 'center' }}>Password must be at least 6 characters with one letter and one number</p>
        <input type="password" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={this.handleConfirmPassChange} />
        <div style={{  display: "flex", justifyContent: "center", alignItems: "center", margin: '25px auto 0px auto', width: 'fit-content' }}>
          <ReCaptcha
              ref={(el) => {this.createCaptcha = el;}}
              size="normal"
              data-theme="dark"
              render="explicit"
              sitekey="6LfRHosUAAAAAN4nwL9re2SXahZXVPm0LuSDYHou"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.captchaLoaded}
          />
        </div>
        <a onClick={this.submitNewPassword} className="btn-bar" style={{ color: '#fff', width: '141px', marginTop: '15px'}}>
          <span>Change Password</span>
        </a>
      </div>
    )
  }

  render() {
    if (this.state.goHome) {
      return <Redirect to='/' />
    }

    if (this.state.goSignIn) {
      return <Redirect to='/link' />
    }

    if (this.state.goDelete) {
      return <Redirect to='/delete' />
    }

    if (this.state.changePassword) {
      return this.changePasswordRender();
    }
    else if (this.state.deleteSignIn) {
      return this.deleteAccountRender();
    } 
    else {
      return (
        <div className="loginPage" style={{paddingLeft: '40px', paddingRight: '40px'}}>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div id="loader" />
          <br></br>
        </div>
      )
    }
  }
}

export default FirebaseAuth;
