import axios from 'axios';
import React, { Component } from 'react';
import ReactDom from 'react-dom';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import './App.css';
import { Redirect } from 'react-router';
import bznTrunk from './images/BZN-truck.png';
import loadingGif from './images/loading.gif';
import swal from 'sweetalert';
import { isMobile } from "react-device-detect";
import img from './ImgFactory.js';
import ReactTooltip from 'react-tooltip';

//const web3 = vars.web3;


var hashesConfirmed = [];
let old_bzn_address = "0x1BD223e638aEb3A943b8F617335E04f3e6B6fFfa";
let old2_bzn_address = "0x85171d9cd1cfd8b10072096763674392176f039b";
let bzn_address = '0x6524b87960c2d573ae514fd4181777e7842435d4';

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

async function getBznBalance(address) {
  let totalBZN = await vars.contracts.oldBzn.methods.balanceOf(address).call();
  return totalBZN;
}

async function getBzn2Balance(address) {
  let totalBZN = await vars.contracts.old2Bzn.methods.balanceOf(address).call();
  return totalBZN;
}

class BZNMigrate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      goHome: false,
      oldBalance: 0,
      oldBalance2: 0,
      oldBalanceWei: 0,
      oldBalance2Wei: 0,
      noLink: false
    };

    this.getBalance = this.getBalance.bind(this);
    this.getBalance2 = this.getBalance2.bind(this);
    this.handleMigrate = this.handleMigrate.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    var self = this;

    vars.getAccounts().then(function(accounts) {
      if (accounts != null) {
        if (accounts.length == 0) {
          var elem = document.createElement("div");
          elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
          swal({
            title: "No External Wallet",
            content: elem,
            icon: "error"
          }).then(function() {
            self.setState({
              noLink: true
            })
          });
        }
        else {
          console.log('We found external wallets from metamask');
          self.setState ({
            address: accounts[0]
          });

          self.getBalance();
          self.getBalance2();
        }
      }
      else {
        var elem = document.createElement("div");
        elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
        swal({
          title: "No External Wallet",
          content: elem,
          icon: "error"
        }).then(function() {
          self.setState({
            noLink: true
          })
        });
      }
    }).catch(function(error) {
      var elem = document.createElement("div");
      elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a><br><br>Error: " + error + "</p>";
      swal({
        title: "No External Wallet",
        content: elem,
        icon: "error"
      }).then(function() {
        self.setState({
          noLink: true
        })
      });
    });
  }

  getBalance() {
    var self = this;
    getBznBalance(self.state.address).then( function(balance) {
      console.log("DDD " + balance);
      let totalBZN = round(vars.web3.utils.fromWei(balance.toString(), 'ether'), 2);
      console.log("DDDA " + totalBZN);
      self.setState({
        oldBalance: totalBZN,
        oldBalanceWei: balance
      })
    }).catch(function(err) {
      var elem = document.createElement("div");
      elem.innerHTML = "<p style=\"color: black\">Could not get old BZN Balance. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a><br><br>Error: " + err.toString() + "</p>";
      swal({
        title: "Cant Get Balance",
        content: elem,
        icon: "error"
      }).then(function() {
        self.setState({
          noLink: true
        })
      });
    });
  }

  getBalance2() {
    var self = this;
    getBzn2Balance(self.state.address).then( function(balance) {
      console.log("DDD " + balance);
      let totalBZN = round(vars.web3.utils.fromWei(balance.toString(), 'ether'), 2);
      console.log("DDDA " + totalBZN);
      self.setState({
        oldBalance2: totalBZN,
        oldBalance2Wei: balance
      })
    }).catch(function(err) {
      var elem = document.createElement("div");
      elem.innerHTML = "<p style=\"color: black\">Could not get old BZN Balance. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a><br><br>Error: " + err.toString() + "</p>"
      swal({
        title: "Cant Get Balance",
        content: elem,
        icon: "error"
      }).then(function() {
        self.setState({
          noLink: true
        })
      });
    });
  }

  handleBZN2Migrate() {
    var elem = document.createElement("div");
    elem.innerHTML = "<p style=\"color: black\" id=\"txtext\">Please submit 1 transactions to migrate all BZN 2.0 tokens.</p>";

    var self = this;

    async function migrateAll() {
      try {
        const approveAndCall = await vars.econtracts.old2Bzn.approveAndCall(bzn_address, self.state.oldBalance2Wei.toString(), '0x00');
        document.getElementById("txtext").innerHTML = "Please wait while your transactions are confirmed..";
        swal({
          title: "Migrating BZN 2.0 -> 2.1",
          content: elem,
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });
        const approveAndCallr = await approveAndCall.wait(1);
        let tx = approveAndCallr.transactionHash;
        if (approveAndCallr.status) {
          setTimeout(function() {
            let iconType = "success";
            self.getBalance2();
            swal({
              title: "Migration Complete",
              text: "BZN 2.0 Migration completed",
              icon: iconType
            })
          }, 3000)
        }
      } catch (e) {
        setTimeout(function() {
          let errText = "BZN 2.0 Migration transaction has failed";
          self.getBalance();
          swal({
            title: "Migration Failed",
            text: errText,
            icon: "error"
          })
        }, 3000)
      }
    }

    swal({
      title: "Migrating BZN 2.0 -> 2.1",
      content: elem,
      icon: bznTrunk,
      buttons: true
    }).then(function(willSubmit) {
      if (willSubmit) {
        migrateAll();
      }
    });
  }

  handleBZN1Migrate() {
    var submitCount = 0;
    var confirmCount = 0;
    var errorCount = 0;
    var requiredAmount = 2;

    var self = this;
    var elem = document.createElement("div");

    async function migrateAll() {
      try {
        const migratetx = await vars.econtracts.bzn["migrateAll(address,address)"](old_bzn_address, self.state.address);
        submitCount++;
        document.getElementById("txtext").innerHTML = "Please wait while your transactions are confirmed..";
        document.getElementById("ts").innerHTML = "Transactions Submitted: " + submitCount + " / " + requiredAmount;
        swal({
          title: "Migrating BZN 1.0 -> 2.1",
          content: elem,
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });
        const migrater = await migratetx.wait(1);
        let tx = migrater.transactionHash;
        if (migrater.status) {
          confirmCount++;
          document.getElementById("tc").innerHTML = "Transactions Confirmed: " + confirmCount + " / " + requiredAmount;
          hashesConfirmed.push(tx);

          if (confirmCount + errorCount >= requiredAmount) {
            setTimeout(function() {
              let errText = "";
              let iconType = "success";
              if (errorCount > 0) {
                errText = ", " + errorCount + " transactions have failed"
                iconType = "warning";
              }
              self.getBalance();
              swal({
                title: "Migration Complete",
                text: "BZN 1.0 Migration completed, " + confirmCount + " transactions have been confirmed" + errText,
                icon: iconType
              }).then(function() {
                if (self.state.oldBalance2 > 0) {
                  self.handleBZN2Migrate();
                }
              });
            }, 3000);
          }
        }
      } catch (e) {
        console.log(e);
        errorCount++;
        document.getElementById("te").innerHTML = "Transactions Failed: " + errorCount + " / " + requiredAmount;
        if (confirmCount + errorCount >= requiredAmount) {
          setTimeout(function() {
            let errText = "";
            if (errorCount > 0) {
              errText = ", " + errorCount + " transactions have failed"
            }
            self.getBalance();
            swal({
              title: "Migration Complete",
              text: "BZN 1.0 Migration completed, " + confirmCount + " transactions have been confirmed" + errText,
              icon: "error"
            }).then(function() {
              if (self.state.oldBalance2 > 0) {
                self.handleBZN2Migrate();
              }
            });
          }, 3000);
        }
      }
    }

    async function approveAll() {
      try {
        let approveResponse = await vars.econtracts.oldBzn.approve(bzn_address, self.state.oldBalanceWei);
        submitCount++;
        document.getElementById("txtext").innerHTML = "Please wait while your transactions are confirmed..";
        document.getElementById("ts").innerHTML = "Transactions Submitted: " + submitCount + " / " + requiredAmount;
        swal({
          title: "Migrating",
          content: elem,
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });
        const approver = await approveResponse.wait(1);
        let tx = approver.transactionHash;
        if (approver.status) {
          confirmCount++;
          document.getElementById("tc").innerHTML = "Transactions Confirmed: " + confirmCount + " / " + requiredAmount;
          migrateAll();
        }
      } catch (e) {
        if (e.message.includes("User denied transaction signature.")) {
          return;
        }
        errorCount++;
        document.getElementById("te").innerHTML = "Transactions Failed: " + errorCount + " / " + requiredAmount;
        setTimeout(function() {
          let errText = "";
          if (errorCount > 0) {
            errText = ", " + errorCount + " transactions have failed"
          }
          self.getBalance();
          swal({
            title: "Migration Failed",
            text: "BZN Migration failed\n" + e.message,
            icon: "error"
          })
        }, 3000);
      }
    }

    async function choose() {
      const allowedAmount = await vars.econtracts.oldBzn.allowance(self.state.address, bzn_address);
      const amount = Number(allowedAmount);
      if (amount == 0 || amount < self.state.oldBalanceWei) {
        approveAll();
      } else {
        requiredAmount = 1;
        migrateAll();
      }
    }

    vars.econtracts.oldBzn.allowance(self.state.address, bzn_address).then(function(allowedAmount) {
      const amount = Number(allowedAmount);
      if (amount == 0 || amount < self.state.oldBalanceWei) {
        requiredAmount = 2;
      } else {
        requiredAmount = 1;
      }

      elem.innerHTML = "<p style=\"color: black\" id=\"txtext\">Please submit " + requiredAmount + " transactions to migrate all BZN 1.0 -> 2.1.<br><p id='ts'>Transactions Submitted: " + submitCount + " / " + requiredAmount + "</p><br><p id='tc'>Transactions Confirmed: " + confirmCount + " / " + requiredAmount + "</p><br><p id='te'>Transactions Failed: " + errorCount + " / " + requiredAmount + "</p></p>";
      swal({
        title: "Migrating BZN 1.0 -> 2.1",
        content: elem,
        icon: bznTrunk,
        buttons: true
      }).then(function(willSubmit) {
        if (willSubmit) {
          choose();
        }
      });
    })
  }

  handleMigrate() {
    if (this.state.oldBalance > 0) {
      this.handleBZN1Migrate();
    } else if (this.state.oldBalance2 > 0) {
      this.handleBZN2Migrate();
    }
  }

  render() {
    if (this.state.goHome) {
      return <Redirect to='/' />
    }

    let should_display = this.state.oldBalance > 0 || this.state.oldBalance2 > 0 ? 'inherit' : 'none';

    return (
      <div className="loginPage">
        <ReactTooltip id='maintenance-info-migrate' aria-haspopup='true' role='example'>
          <p>BZN Migration is currently closed for maintenance</p>
        </ReactTooltip>
        <img src={img.account} />
        <h3>{this.state.oldBalance > 0 || this.state.oldBalance2 > 0 ? "You Should Migrate Your Tokens" : "You Do Not Need to Migrate"}</h3>
        <p style={{ textAlign: 'center' }}>You have {this.state.oldBalance + this.state.oldBalance2} old BZN tokens.</p>
        <a onClick={this.handleMigrate} className="btn-bar" style={{ color: '#fff', width: '141px', margin: "0 auto", marginTop: '45px', display: should_display}}>
          <span>Migrate Tokens</span>
        </a>
        <div style={{textAlign: 'center', width: '100%', marginTop: '25px'}} >
          <a style={{cursor: 'pointer', color: '#8D4AF3'}} onClick={() => window.open(vars.urls.bznmigratelearn, "_blank")}>Learn More</a>
        </div>
      </div>
    )
  }
}

export default BZNMigrate;
