import React from 'react';
import { Col, Row, Grid } from 'react-bootstrap';
import './App.css';
import "react-sweet-progress/lib/style.css";
import _ from 'underscore';
import swal from 'sweetalert';
import loadingGif from './images/loading.gif';

function roundToFour(num) {
    return +(Math.round(num + "e+4")  + "e-4");
}

async function getFromURL(url) {
    return new Promise(function(resolve, reject) {
      return fetch(url)
       .then((response) => response.json())
       .then((responseJson) => {
         return resolve(responseJson);
       })
       .catch((error) => {
         reject(error);
       });
    })
}

class LeaderboardDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        leaderboard: null,
        failed: false
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    var self = this;
    swal({
        title: "Loading...",
        text: "Please wait while the leaderboard is loaded",
        icon: loadingGif,
        className: 'wr-loader',
        button: false,
        closeOnClickOutside: false
    });

    try {
        let data = {}

        try {
            //let newLeaderboards = testData;
            let newLeaderboards = await getFromURL(this.props.leaderboardURL);

            if (newLeaderboards != null && newLeaderboards.leaderboards != null) {
                data.leaderboards = newLeaderboards.leaderboards;
            }
        } catch (e) {
            console.log("Got error response " + e);
        }


        self.setState({
            leaderboard: data.leaderboards
        });

        swal.close();
    } catch(e) {
        console.log(e);

        let data = [
            {
                username: <center>Failed to fetch leaderboard data, sorry about that</center>,
                bzn: ''
            }
        ]

        self.setState({
            leaderboard: data,
            failed: true
        })

        swal('Error', "There was a problem fetching leaderboard data, if the problem persists please contact us\n" + e, 'error')
    }
  }

  render() {
    let renderedData = [];
    if (this.state.leaderboard != null) {
        for (let i = 0; i < this.state.leaderboard.length; i++) {
            if (i + 1 < this.state.leaderboard.length) {
                renderedData.push(
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                            <Leaderboard leaderboard={this.state.leaderboard[i]} isFull={false} />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={2}>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                            <Leaderboard leaderboard={this.state.leaderboard[i + 1]} isFull={false} />
                        </Col>
                    </Row>
                )
                i++;
            } else {
                renderedData.push(
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <Leaderboard leaderboard={this.state.leaderboard[i]} isFull={true} />
                        </Col>
                    </Row>
                )
            }
        }
    }

    console.log(renderedData.length);

    return (
      <div id='VehicleSelectType' style={{textAlign: 'center'}}>
        <Grid className="leaderboardContainer">
            <Row style={{height: 100}}></Row>
            <Row>
                <br></br><br></br><br></br>
            </Row>
            {renderedData}
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </Grid>
      </div>
    );
  }
}

class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leaderboard: props.leaderboard,
            isFull: props.isFull,
            width: 0,
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      }

      componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
      }
    
      render() {
        let renderedData = [];
        if (this.state.leaderboard != null) {
            for (let i = 0; i < this.state.leaderboard.data.length; i++) {
                let usernameStyle = (this.state.leaderboard.data[i].username.length > 12 && this.state.width < 768 ? {fontSize: '12px'} : {})

                renderedData.push(
                    <Row className={"leaderboardColumn" + (i % 2 == 0 ? "Even" : "Odd") + (this.state.isFull ? 'Full': '')}>
                        <Col xs={4} sm={4} md={4}>
                            <h4>
                                {i + 1}
                            </h4>
                        </Col>
                        <Col xs={4} sm={4} md={4}>
                            <h4 style={usernameStyle}>
                                {this.state.leaderboard.data[i].username}
                            </h4>
                        </Col>
                        <Col xs={4} sm={4} md={4}>
                            <h4>
                                {roundToFour(this.state.leaderboard.data[i].value)}
                            </h4>
                        </Col>
                    </Row>
                )
            }
        }
    
        return (
          <div id='VehicleSelectType' style={{textAlign: 'center'}}>
              <Row>
                <br></br>
                <Col xs={12} sm={12} md={12}>
                  <h3 className='centered'>{this.state.leaderboard.name}</h3>
                </Col>
                <br></br><br></br><br></br>
              </Row>
              <Row className="leaderboardHeader">
                <Col xs={4} sm={4} md={4}>
                    <h3>
                        Rank
                    </h3>
                </Col>
                <Col xs={4} sm={4} md={4}>
                    <h3>
                        {this.state.leaderboard.columns[0]}
                    </h3>
                </Col>
                <Col xs={4} sm={4} md={4}>
                    <h3>
                        {this.state.leaderboard.columns[1]}
                    </h3>
                </Col>
              </Row>
              {renderedData}
    
              <br></br>
          </div>
        );
      }
}

export default LeaderboardDisplay;
