import React from 'react';
import ReactDom from 'react-dom';
import { Form, FormControl, FormGroup, Checkbox, ProgressBar, ControlLabel, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well } from 'react-bootstrap';
import imgs from './ImgFactory.js';
import ViewCar from './ViewCar.js';
import './App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import SelectVariant from './SelectVariant.js';
import {LineChart, ComposedChart, Sector, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, AreaChart, Area, ReferenceLine, Cell, BarChart, Bar, LabelList} from 'recharts';
import { Link } from 'react-router-dom';
import variables from './variables.js';
import { Progress } from 'react-sweet-progress';
import { Line, Circle } from 'rc-progress';
import "react-sweet-progress/lib/style.css";
import {Motion, spring, presets} from 'react-motion';
import Popup from 'react-popup';
import Referral from './Referral.js';
import MetaTags from 'react-meta-tags';
import _ from 'underscore';
import STLViewer from 'stl-viewer';
let maxes = variables.maxes;
//let web3 = variables.web3;
let gradients = variables.gradients;

const dataaa = [
  {quarter: 1, earnings: 13000},
  {quarter: 2, earnings: 16500},
  {quarter: 3, earnings: 14250},
  {quarter: 4, earnings: 19000}
];

let urls = variables.urls;
let ranged_data = variables.cars_table_data_minDiff;
var carSampleData = [{name: 'Speed', value: 95},
      {name: 'Armor Units', value: 93},
      {name: 'Benzene Tank', value: 93},
      {name: 'Main Gun Slots', value: 94}]
var carSampleData02 = [{name: 'Speed', value: 80},
      {name: 'Armor Units', value: 76},
      {name: 'Benzene Tank', value: 73},
      {name: 'Main Gun Slots', value: 76}]
var carSampleData03 = [{name: 'Speed', value: 55},
      {name: 'Armor Units', value: 64},
      {name: 'Benzene Tank', value: 65},
      {name: 'Main Gun Slots', value: 60}]


      var carDatta = [{name: 'Speed', premiumMin: getRndInteger(170,200), midGradeMin: getRndInteger(120,180), regularMin: getRndInteger(100,120)},
            {name: 'Armor Units', premiumMin: getRndInteger(170,200), midGradeMin: getRndInteger(120,180), regularMin: getRndInteger(100,120)},
            {name: 'Benzene Tank', premiumMin: getRndInteger(170,200), midGradeMin: getRndInteger(120,180), regularMin: getRndInteger(100,120)},
            {name: 'Main Gun Slots', premiumMin: getRndInteger(170,200), midGradeMin: getRndInteger(120,180), regularMin: getRndInteger(100,120)}]

const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

const carPopDistributionData = [{name: 'Regular', value: 1000000}, {name: 'Mid-Grade', value: 150000},
    {name: 'Premium', value: 30000}];

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }

    const renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';

      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Total supply: ${value}`}</text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };

    class AdvPieChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        activeIndex: 0,
        isDesktop: false
      };
      this.updatePredicate = this.updatePredicate.bind(this);
      this.onPieEnter = this.onPieEnter.bind(this);
     }
     componentDidMount() {
       this.updatePredicate();
       window.addEventListener("resize", this.updatePredicate);
     }

     componentWillUnmount() {
       window.removeEventListener("resize", this.updatePredicate);
     }

     updatePredicate() {
       this.setState({ isDesktop: window.innerWidth > 1000 });
     }

    onPieEnter(data, index) {
      this.setState({
        activeIndex: index,
      });
    }
    render () {
      if (this.state.isDesktop) {
        return (
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                stroke="none"
                fill="red"
                data={carPopDistributionData}
                className='pie2'
                innerRadius='50%'
                outerRadius='80%'
                fill="#8884d8"
                paddingAngle={0}
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                onMouseEnter={this.onPieEnter}
              >
                {
                  carPopDistributionData.map((entry, index) => <Cell fill={COLORS[COLORS.length-2-index]}/>)
                }
              </Pie>
             </PieChart>
         </ResponsiveContainer>
        );
      } else {

        return (
          <img style={{width: '100%', height: '100%'}} src={imgs.supplyOverview}></img>
        );
      }

    }
  }

      class CustomizedLabel extends React.Component {
        constructor(props) {
          super(props);
          console.log('x: ' + props.x);
          console.log('value: ' + props.value);
          let perc = '' + props.value*0.9 + '%';
          this.state = {
            x: props.x,
            y: props.y,
            fill: props.fill,
            value: props.value,
            perc: perc
          };
        }
        render () {
         	return (
            <text
                     x={this.state.x}
                     y={this.state.y}
                     dx={-4}
                     fontSize='16'
                     fontFamily='sans-serif'
                     fill={this.state.fill}
                     textAnchor="middle">{this.state.value}%</text>
            );

        }
      }
      // class CustomizedLabel extends React.Component {
      //   constructor(props) {
      //     super(props);
      //     this.state = {
      //       x: this.props.x,
      //       y: this.props.y,
      //       fill: this.props.fill,
      //       value: this.props.value
      //     };
      //   }
      //
      //   render () {
      //    	return (
      //       <text
      //          x={this.state.x}
      //          y={this.state.y}
      //          dy={-4}
      //          fontSize='16'
      //          fontFamily='sans-serif'
      //          fill={this.state.fill}
      //          textAnchor="middle">{this.state.value}%</text>
      //     )
      //   }
      // }

class VertChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      realData: props.realData,
      visualData: props.visualData
    };
    var data = JSON.stringify(props.data);
    console.log('the Vert data is: ' + data)
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart layout="vertical" data={this.state.realData}
            margin={{top: 20, right: 20, bottom: 20, left: 20}}>

          <XAxis type="number" hide={true} domain={[0, 100]} />
          <YAxis dataKey="name" type="category" />
          <Bar dataKey="value" stackId="a" barSize={20} fill="#8884d8" >
            {
              this.state.visualData.map((entry, index) => {
                const color = COLORS[COLORS.length-1-index];
                return <Cell fill={color}/>;
              })
            }
            <LabelList dataKey="value" position="right" />
         </Bar>
         <Bar dataKey="" barSize={20} stackId="a" fill="#8884d8" >
           {
             this.state.visualData.map((entry, index) => {
               const color = COLORS[COLORS.length-1-index];
               return <Cell fill={color}/>;
             })
           }
           <LabelList dataKey="value" position="right" />
        </Bar>
       </ComposedChart>
     </ResponsiveContainer>
    );
  }
}

class VertChart2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
    var data = JSON.stringify(props.data);
    console.log('the Vert data is: ' + data)
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart layout="vertical" data={this.state.data}
            margin={{top: 20, right: 20, bottom: 20, left: 20}}>

          <XAxis type="number" hide={true} />
          <YAxis dataKey="name" type="category" />
          <Bar dataKey="min" stackId="a" barSize={20} >
            {
              this.state.data.map((entry, index) => {
                const color = COLORS[COLORS.length-1-index];
                return <Cell fill={color}/>;
              })
            }
            <LabelList dataKey="min" position="top" />
         </Bar>
         <Bar dataKey="max" barSize={20} stackId="a">
           {
             this.state.data.map((entry, index) => {
               const color = COLORS[index+1];
               return <Cell fill={color}/>;
             })
           }
           <LabelList dataKey="title" position="right" />
        </Bar>
       </ComposedChart>
     </ResponsiveContainer>
    );
  }
}

var hComp = (text, color) => {
  if (color) {
    return (
      <h5 style={{color: {color}}}>{text}</h5>
    );
  } else {
    return (
      <h5>{text}</h5>
    );
  }
}

var mmComponent = (type, cat, max, color) => {
  let cmin = variables.getRanges(type)[cat].min
  let cmax = variables.getRanges(type)[cat].max
  return (
      <div>{hComp(cmin, color)} - {hComp(cmax, color)} out of {hComp(max)}</div>
  );
}


var ProgressComponent = (type, cat, max, title, titleColor, dimension) => {
  let cmin = variables.getRanges(type)[cat].min;
  let cmax = variables.getRanges(type)[cat].max;


  return (
    <div style={{width: '99%'}}>
      <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
      <Row>
        <Col xs={12} sm={4}></Col>
        <Col xs={12} sm={8}>
          <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span> <span dangerouslySetInnerHTML={{ __html: dimension }} /></h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <h4 style={{marginTop: '0%', color: titleColor}}>{title}</h4>
        </Col>
        <Col xs={12} sm={8}>

        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={1} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>

        </Col>
      </Row>
      </ScrollAnimation>
    </div>
  );
}




class VehicleSelectType extends React.Component {
  constructor(props) {
    super(props);
  //  this.animate = this.animate.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div id='VehicleSelectType' style={{textAlign: 'center'}}>
        <MetaTags>
          <title>Vehicle Supply Overview</title>
          <meta property="og:title" content="Warriders Vehicle Supply Overview" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={imgs.lambo}/>
          <meta property="og:url" content="https://app.warriders.com/selectType"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <Grid>
          <Row style={{height: 100}}></Row>
          <Row>
            <br></br><br></br><br></br>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3 className='centered'>VEHICLE SUPPLY OVERVIEW</h3>
            </Col>
            <br></br><br></br><br></br>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Premium</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <div style={{height: '60%'}}><img src={imgs.lambo} style={{height: '90%'}}></img></div>
            </ScrollAnimation>
              <br></br>
              <h5>SALE <Link to={urls.selectCar(0)}><span style={{color: COLORS[3], fontSize: '16px'}}><strong>FINISHED</strong></span></Link></h5>
              <br></br>
              <a className="btn" href="https://opensea.io/assets/war-riders?refinementList%5Basset_contract.address%5D%5B0%5D=0x5caebd3b32e210e85ce3e9d51638b9c445481567">
                <span>VIEW MARKETPLACE</span>
              </a>
              <br></br><br></br>
              <div>{ProgressComponent(0,3,maxes.speed, 'Speed', COLORS[1], ' MPH')}</div>
              <div>{ProgressComponent(0,4,maxes.acc, 'Accel.', COLORS[2], ' MPH<sup>2</sup>')}</div>
              <div>{ProgressComponent(0,2,maxes.bznTank, 'BZN Tank', COLORS[3], ' BZN')}</div>
              <div>{ProgressComponent(0,1,maxes.armor, 'Armor', COLORS[4], ' UNITS')}</div>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Mid-Grade</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <div style={{height: '60%'}}><img src={imgs.buggy} style={{height: '90%'}}></img></div>
            </ScrollAnimation>
              <br></br>
              <h5>SALE STARTS UPON WASTELAND BETA </h5>
              <br></br>
              <Link to={urls.selectCar(1)}>
                <a className="btn">
                  <span>PREVIEW</span>
                </a>
              </Link>
              <br></br><br></br>
              <div>{ProgressComponent(1,3,maxes.speed, 'Speed', COLORS[1], ' MPH')}</div>
              <div>{ProgressComponent(1,4,maxes.acc, 'Accel.', COLORS[2], ' MPH<sup>2</sup>')}</div>
              <div>{ProgressComponent(1,2,maxes.bznTank, 'BZN Tank', COLORS[3], ' BZN')}</div>
              <div>{ProgressComponent(1,1,maxes.armor, 'Armor', COLORS[4], ' UNITS')}</div>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Regular</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <div style={{height: '60%'}}><img src={imgs.prius} style={{height: '90%'}}></img></div>
            </ScrollAnimation>
              <br></br>
              <h5>SALE STARTS UPON GLOBAL RELEASE</h5>
              <br></br>
              <Link to={urls.selectCar(2)}>
                <a className="btn">
                  <span>PREVIEW</span>
                </a>
              </Link>
              <br></br><br></br>
              <div>{ProgressComponent(2,3,maxes.speed, 'Speed', COLORS[1], ' MPH')}</div>
              <div>{ProgressComponent(2,4,maxes.acc, 'Accel.', COLORS[2], ' MPH<sup>2</sup>')}</div>
              <div>{ProgressComponent(2,2,maxes.bznTank, 'BZN Tank', COLORS[3], ' BZN')}</div>
              <div>{ProgressComponent(2,1,maxes.armor, 'Armor', COLORS[4], ' UNITS')}</div>
            </ScrollAnimation>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <br></br><br></br>
              <h3>TOTAL SUPPLY OF VEHICLES:</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <AdvPieChart />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3>PRICING:</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h4 style={{lineHeight: '30px'}}>The prices for premium vehicles start low and increase with every vehicle sold. All in-game items including BZN will be tradable in a wide range of marketplaces, including OPSkins, WAX, OpenSea and more.</h4>
            </Col>
          </Row>

          <Referral />
        </Grid>
      </div>
    );
  }
}

export default VehicleSelectType;
