import React, { Component } from 'react';
import ReactDom from 'react-dom';
import {  ProgressBar, Col, Row, Grid, } from 'react-bootstrap';
import imgs from './ImgFactory.js';
import "react-sweet-progress/lib/style.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { ComposedChart, ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, Legend, ResponsiveContainer, Cell, Bar, LabelList} from 'recharts';
import {  isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NumericInput from 'react-numeric-input';
import ReactTooltip from 'react-tooltip'
import Referral from './Referral.js';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './App.css';
import vars from './variables.js';
import GradientSVG from './GradientSVG.js';
import Popup from 'react-popup';
import posed from 'react-pose';
import { CSSTransitionGroup } from 'react-transition-group' // ES6
import ReactPlayer from 'react-player'
import swal from 'sweetalert';
import MetaTags from 'react-meta-tags';
import premiumGarageImg from './images/garage-premium.jpg'
import midgradeGarageImg from './images/garage-mid-grade.jpg'
import regularGarageImg from './images/garage-regular.jpg'
import premiumGarageWebm from './images/garage-premium.webm'
import midgradeGarageWebm from './images/garage-mid-grade.webm'
import regularGarageWebm from './images/garage-regular.webm'
import ReactGA from 'react-ga';
import loadingGif from './images/loading.gif';
import { Redirect } from 'react-router'
import Countdown from 'react-countdown-now';
String.prototype.hashCode = function() {
    var hash = 0;
    if (this.length == 0) {
        return hash;
    }
    for (var i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

var cars = vars.cars_table_data_minMax;
//let web3 = vars.web3;
let gradients = vars.gradients;

async function getBzn1Balance(address) {
  if (address == "DNE")
    return 0;
  
  let totalBZN = await vars.contracts.oldBzn.methods.balanceOf(address).call();
  return totalBZN;
}

async function getBzn2Balance(address) {
  if (address == "DNE")
    return 0;

  let totalBZN = await vars.contracts.old2Bzn.methods.balanceOf(address).call();
  return totalBZN;
}

async function getBznBalance(address) {
  if (address == "DNE")
    return 0;

  let totalBZN = await vars.contracts.bzn.methods.balanceOf(address).call();
  return totalBZN;
}

let caseVars = [
  {
    name: 'PREMIUM',
    image: premiumGarageImg,
    webm: premiumGarageWebm,
    type: 'Main',
    base_price: 100,
    unique_skins: 117,
    contentLink: "https://warriders.com/premium-case-garage/",
    contentAlt: "Main weapons",
  },
  {
    name: 'MID-GRADE',
    image: midgradeGarageImg,
    webm: midgradeGarageWebm,
    type: 'Main and alt',
    base_price: 50,
    unique_skins: 98,
    contentLink: "https://warriders.com/mid-grade-case-garage/",
    contentAlt: "Main and Alt weapons",
  },
  {
    name: 'REGULAR',
    image: regularGarageImg,
    webm: regularGarageWebm,
    type: 'Main and alt',
    base_price: 20,
    unique_skins: 77,
    contentLink: "https://warriders.com/regular-case-garage/",
    contentAlt: "Main and Alt weapons",
  }
]
let referralText = 'Earn BZN by becoming a Virtual Garage Dealer. Copy the URL below, share it with your friends on social media and earn a 5% commission on every case opened using your referral link.';

var URLs = vars.urls;
var maxes = vars.maxes;

function log(x) {
  //console.log('(Z) ' + x);
}

const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

// Random component
const conditionalRenderer = (value) => {
  if (value == 'DNE') { return <span style={{color: 'black'}}><strong>{value}</strong></span>; }
   else { return <span style={{color: '#a922ed'}}><strong>{value}</strong></span>; }
};


function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function riseData(type, base_price, current_price, current_quantity, totalSupply) {
  let dots = 100;
  let PRICE_INCREASE = getMultipleFor(type);
  var data = [];
  let temp = 0;
  let currentprice = base_price;

  for (var i = 0; i < dots; i++) {
    let x = totalSupply / dots * i;
    //log('base: ' + base_price + ' | pwr: ' + x);
    let y = base_price * Math.pow(PRICE_INCREASE, x);
    //log('riseData pow result: ' + y)
    let obj = {quantity: Math.round(x), Price: round(y, 2), z: 80};
    //log('riseData appending: ' + toStr(obj));
    data.push(obj);
  }
  let current_obj = {quantity: current_quantity, Price: round(current_price, 2), z: 90};
  data.push(current_obj);
  return data;
}


// END USELESS

var ProgressComponent = (cmin, cmax, max, title, unit, hintTextId = null, addPadding = false) =>
(
  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row style={{marginTop: '-2px', marginBotton: '-2px'}}>
      <Col xs={12} sm={2}></Col>
      <Col xs={12} sm={10} style={(addPadding && !isMobile ? {paddingLeft: '90px'} : {})}>
        <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span> {unit}</h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={3}>
        <h5 style={{marginTop: '0%'}}>{title} {hintTextId ? <span data-tip data-for={hintTextId}><FontAwesomeIcon icon='question-circle' /></span> : <></>}</h5>
      </Col>
      <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={2} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
      </Col>
      <Col xs={12} sm={1}></Col>
    </Row>
  </ScrollAnimation>
  </div>
)

function randomTokenId() {
  let POSSIBLES = '0123456789';
  let ID_LENGTH = 8;
  var id = '';
  for (var i = 0; i < ID_LENGTH; i++) {
    id += POSSIBLES.charAt(Math.floor(Math.random() * POSSIBLES.length));
  }
  log('-randTKNid() - : id=' + id)
  log('-randTKNid() - : Number(id)=' + Number(id))
  return Number(id);
}

function getMinMax(type) {
  if (type == 0) {
    return [
      {
        min: roundToTwo(70),
        max: roundToTwo(100)
      },
      {
        min: roundToTwo(70),
        max: roundToTwo(100)
      },
      {
        min: roundToTwo(10),
        max: roundToTwo(30)
      },
      {
        min: roundToTwo(4),
        max: roundToTwo(7)
      },
      {
        min: roundToTwo(7),
        max: roundToTwo(10)
      },
      {
        min: roundToTwo(7),
        max: roundToTwo(10)
      },
      {
        min: roundToTwo(1),
        max: roundToTwo(10)
      },
    ]
  } else if (type == 1) {
    return [{
      min: roundToTwo(40),
      max: roundToTwo(70)
    },
    {
      min: roundToTwo(40),
      max: roundToTwo(70)
    },
    {
      min: roundToTwo(10),
      max: roundToTwo(30)
    },
    {
      min: roundToTwo(4),
      max: roundToTwo(7)
    },
    {
      min: roundToTwo(4),
      max: roundToTwo(7)
    },
    {
      min: roundToTwo(4),
      max: roundToTwo(7)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(7)
    }]
  } else {
    return [{
      min: roundToTwo(5),
      max: roundToTwo(40)
    },
    {
      min: roundToTwo(5),
      max: roundToTwo(40)
    },
    {
      min: roundToTwo(10),
      max: roundToTwo(30)
    },
    {
      min: roundToTwo(4),
      max: roundToTwo(7)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(4)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(4)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(4)
    }]
  }
}

class CustomChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      currentQuantity: props.currentQuantity
    };
    var data = JSON.stringify(props.data);
  }
  render() {
    if (this.props.data.length > 0) {
      return (
          <ResponsiveContainer width="95%" height={300}>
            <ScatterChart margin={{top: 30, right: 0, left: 0, bottom: 5}}>
              <XAxis type="number" dataKey={'quantity'} name='Quantity' unit=' Cases' />
              <YAxis width={100} type="number" dataKey={'Price'} name='Price' unit=' BZN' domain={[this.props.data[0].Price, this.props.data[this.props.data.length-1].Price]}/>
              <ZAxis dataKey={'z'} range={[10, 100]} name='Reference Size' unit=' units'/>
             <Tooltip/>
             <Legend />
             <Scatter name='Price Increase' data={this.props.data} >
               {
                 this.props.data.map((entry, index) => {
                   if (entry.quantity === this.props.data[this.props.data.length-1].quantity) {
                     return <Cell key={`cell-${index}-${new Date().getTime()}`} fill='#3399FF' />
                   } else {
                     return <Cell key={`cell-${index}-${new Date().getTime()}`} fill={COLORS[1]} />
                   }

                 })
               }
             </Scatter>

            </ScatterChart>
          </ResponsiveContainer>
        );
    } else {
      return <div></div>
    }

  };
}

function getTrueMultipleFor(type) {
  if (type == 0) {
    return 0.0350;
  } else if (type == 1) {
    return 0.0250;
  } else if (type == 2) {
    return 0.0150;
  }
}

function getMultipleFor(type) {
  if (type == 0) {
    return 1.00035;
  } else if (type == 1) {
    return 1.00025;
  } else if (type == 2) {
    return 1.00015;
  }
}

function getDescription(type) {
  if (type == 0) {
    return 'Each premium case contains one NFT Property Title with the highest amount of random garage tokens per NFT. The NFTs grant non-expiring spawn/entry privileges with an expiration term for passive earning (entrance fee distribution among active holders). After expiration, the NFTs will have to be renewed in-game using BZN to continue earning. The higher discount and luck index will reduce the cost of renewal.'
  } else if (type == 1) {
    return 'Each mid-grade case contains one NFT Property Title with the average amount of random garage tokens per NFT. The NFTs grant non-expiring spawn/entry privileges with an expiration term for passive earning (entrance fee distribution among active holders). After expiration, the NFTs will have to be renewed in-game using BZN to continue earning. The higher discount and luck index will reduce the cost of renewal.'
  } else {
    return 'Each regular case contains one NFT Property Title with the smallest amount of random garage tokens per NFT. The NFTs grant non-expiring spawn/entry privileges with an expiration term for passive earning (entrance fee distribution among active holders). After expiration, the NFTs will have to be renewed in-game using BZN to continue earning. The higher discount and luck index will reduce the cost of renewal.'
  }
}


let increasingDataChanged = true;
class ViewGarage extends Component {

  constructor(props) {
    super(props);
      //    let id = props.carId; // Passed to specify for HTTP Req so we can differentiate between different car types
      let typeIndex = props.typeIndex;

      let limit;
      if (typeIndex == 1) {
        limit = 100;
      } else if (typeIndex == 2) {
        limit = 200;
      } else {
        limit = 20;
      }

      this.state = {
        currentId: 'DNE',
        min: 0,

        limit: limit,
        limitDate: 'Dec 17',
        caseName: caseVars[typeIndex].name,
        image: caseVars[typeIndex].image,
        adCaseTypes: [0, 1, 2],
        skinCount: caseVars[typeIndex].unique_skins,
        currency: 'BZN',
        requiredGasFee: 0,
        loaded: false,

        bznBalance: 0,
        lastTotalSupply: 0,
        hasOldBZN: false,
        migrateRedirect: false,
        mintingFee: 0,
        isOpen: true,
        quantityBuying: 1,
        carInfo: {},
        increasing: [],
        speedMax: '',
        accMax: '',
        armorMax: '',
        bznTankMax: '',
        mg: '',
        ag: '',
        speedMin: '',
        accMin: '',
        armorMin: '',
        img: '',
        bznTankMin: '',
        nos: '',
        description: getDescription(typeIndex),
        price: 0,
        ethPrice: 0,
        bznPrice: 0,
        totalSupply: 0,
        amtLeft: true,
        casesLeft: '',

        min: 1,
        max: 10,

        lastSold: 'DNE',
        videoSupported: true,
        shouldUpdate: true,
        typeIndex: typeIndex,
        adCarsNames: [cars.premium[0].name, cars.premium[1].name, cars.premium[2].name],
        adCarsImgs: [cars.premium[0].img, cars.premium[1].img, cars.premium[2].img],
        adCarIndex: [0, 1, 2],
        currentDayEnd: 0,
      };
      this.handleOnChange = this.handleOnChange.bind(this);
      this.getData = this.getData.bind(this);
      this.smallGetDataForQuantity = this.smallGetDataForQuantity.bind(this);
      this.smallGetData = this.smallGetData.bind(this);
      this.currencySelected = this.currencySelected.bind(this);
  }

getReferrer() {
 let address = window.localStorage.getItem("refaddress");
 this.setState({referrer: address});

}

handleOnChange = (valueAsNumber) => {
  this.setState({
    quantityBuying: valueAsNumber
  })
  try {
    if (!valueAsNumber)
      return;
    this.smallGetDataForQuantity(valueAsNumber);
  } catch (e) { }
}

componentWillUnmount() {
  this.setState({
    shouldUpdate: false
  });
  this.state.shouldUpdate = false;
}

componentDidMount() {
  window.scrollTo(0, 0);
  swal({
    title: "Loading...",
    text: "Please wait while this case is loaded",
    icon: loadingGif,
    className: 'wr-loader',
    button: false,
    closeOnClickOutside: false
  });
  setTimeout(this.getData, 500);
  //updateContracts();
}

linkAccount(self) {
  vars.getAccounts((error, accounts) => {
    if (accounts != null) {
      if (accounts.length == 0) {
        self.setState ({
          currentId: "NA"
        });
      } else {
        self.setState ({
          currentId: accounts[0]
        });
      }
    }
    setTimeout(function() {
      self.linkAccount(self);
    }, 5000);
  })
}

componentWillMount() {
  this.getReferrer()
  this.linkAccount(this);
}

popup = async (askBZN = true) => {
     var self = this;
     let addrToUse = null;
     if (!vars.isWeb3Known()) {
        await vars.askForWeb3();
        let allAccounts = await vars.getAccounts();
        if (allAccounts.length > 0) {
          addrToUse = allAccounts[0];
        }
    }
    if (addrToUse == null) {
      if (this.state.currentId == "NA") {
        swal("External Wallet Locked", "No external wallet could be used to complete this transaction. Please unlock your external wallet and try again.\nIf you just unlocked your external wallet, try refreshing the page.", "error");
        this.linkAccount(this);
        return;
      }
      if (this.state.currentId == "DNE") {
        if (vars.isWeb3Known()) {
          swal("External Wallet Locked", "No external wallet could be used to complete this transaction. Please unlock your external wallet and try again.\nIf you just unlocked your external wallet, try refreshing the page.", "error");
          this.linkAccount(this);
        } else {
          var elem = document.createElement("div");
          elem.innerHTML = "<p style=\"color: black\">In order to purchase garage property title cases, you must use a Web3 compatible browser!<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3</a></p>";
          swal({
            title: "Incompatible Browser",
            content: elem,
            icon: "error"
          })
        }
        return;
      }

      addrToUse = this.state.currentId;
    }

     if (!this.state.amtLeft) {
       swal("Sold Out!", "This garage property title case has reached the daily limit. Please check back tomorrow.", "warning");
       return
     }

     //console.log("FFFFE " + this.state.currency);

     let numberOfGuns = this.state.quantityBuying;
     let isBZN = this.state.currency == 'BZN';

     let extraMsg = ""
     if (isBZN) {
       if (Number(self.state.bznPrice) > self.state.bznBalance) {
         swal("Insufficient BZN Funds", "It appears you don't have enough BZN for this purchase", "error");
         return;
       }

       if (self.state.hasOldBZN && askBZN) {
        swal({
          title: 'You have some old BZN',
          text: "It appears you haven't migrated all of your BZN to BZN 2.1, would you like to migrate now?",
          icon: 'warning',
          buttons: true
        }).then((willMigrate) => {
          if (willMigrate) {
            self.setState({
              migrateRedirect: true
            })
          } else {
            self.popup(false);
          }
        });
        return;
       }

       extraMsg = "\n\n\n" + round(vars.web3.utils.fromWei(self.state.bznPrice, 'ether'), 4).toFixed(4) + " BZN will be used to open " + numberOfGuns + " cases. ETH minting fee will be automatically calculated."
     }

     swal({
       title: 'Open ' + this.state.quantityBuying + ((this.state.quantityBuying > 1) ? ' cases' : ' case'),
       text: 'Please confirm that you would like to proceed with the purchase with the ETH wallet ' + addrToUse + extraMsg,
       icon: 'info',
       buttons: true
     }).then((willBuy) => {
       if (willBuy) {
         self.buyGun(addrToUse);
       }
     });
}

buyGun = async (overrideAddress = "") => {
    log('Present gun popup...');
    var self = this;
    let numberOfGuns = this.state.quantityBuying;
    let userAccount = overrideAddress != "" ? overrideAddress : this.state.currentId;
    let type = this.state.typeIndex;
    let gasPrice = vars.web3.utils.toBN(vars.web3.utils.toWei('0', 'gwei'));
    let isBZN = true;
    //let bufferAmount = (isBZN ? 0 : 100000000000000);
    let bufferAmount = 0;
    let gasPriceToCalc = (isBZN ? 0 : gasPrice.toNumber());
    let price = (isBZN ? vars.web3.utils.toBN(self.state.bznPrice) : vars.web3.utils.toBN(self.state.ethPrice))

    let etherRequired = this.state.mintingFee;
    //let etherRequired = (isBZN ? (Number(this.state.ethPrice) * this.state.requiredEtherPercent) + (Number(this.state.gasFeeRequired) * numberOfGuns / 10) : price + (this.state.requiredGasFee * numberOfGuns / 10));

    //if (!self.refs.buffer.checked) {
      //console.log("FFDDA: " + etherRequired + " " + price);
    price = price.add(price.divRound(vars.web3.utils.toBN(20)))
      //console.log("FFDDA: " + etherRequired + " " + price);
    //} else {
    //  price = price + (price * 0.1);
    //}

    //price = Math.ceil(price);

    //log('Gas should be ' + (gasPrice * price))
    let gas = 500000;
    log("# CARS: " + numberOfGuns);
    log("UID: " + userAccount);
    log('Price: ' + price);
    log('T INDEX: ' + (type + 1));

    var refId = (self.state.referrer != null && self.state.referrer != '' && self.state.referrer != 'DNE' && self.state.referrer != userAccount) ? self.state.referrer : null;

    //FIX Default a null referral to the 0x0 address
    if (!refId) {
      refId = "0x0000000000000000000000000000000000000000";
    }

    try {
      if (isBZN) {
        let abiEncoded = vars.web3.eth.abi.encodeParameters(['address', 'uint8', 'uint16'], [refId, type + 1, numberOfGuns]);
        
        console.log("DA DATA: " + abiEncoded);

        swal({
          title: "Processing...",
          text: "Please wait while your transaction is confirmed",
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });
        let bznResult = await vars.econtracts.bzn.approveAndCall(vars.contracts.garagePreOrder._address, price.toString(), abiEncoded, {value: etherRequired.toString()});
        let bznr = await bznResult.wait(1);
        let tx = bznr.transactionHash;
        if (bznr.status) {
          var elem = document.createElement("div");
          elem.innerHTML = "<p style=\"color: black\">Your purchase was successfully processed!<br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
          swal({
            title: "Order Successful",
            content: elem,
            icon: "success"
          })
          setTimeout(this.smallGetData, 5000);
          //swal("Order Successful", "Your purchase was successfully processed!\nTransaction Hash: " + tx, "success");
          self.submitPurchase(type + 1, numberOfGuns, price, tx);
          ReactGA.event({
            category: 'Purchase',
            action: 'Bought a garage',
            value: numberOfGuns
          });
        } else {
          swal("Order Failed", "Your purchase could not be processed! We're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nYour ether has been refunded.\nTransaction Hash: " + tx, "error")
          ReactGA.event({
            category: 'Failed Purchase',
            action: 'Failed to purchase a garage case',
            value: numberOfGuns
          });
        }
      } else {
        swal({
          title: "Processing...",
          text: "Please wait while your transaction is confirmed",
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });
        const buyResult = await vars.econtracts.garagePreOrder.buyWithEther(refId, type + 1, userAccount, numberOfGuns, {value: etherRequired.toString()});
        let buyr = await buyResult.wait(1);
        let tx = buyr.transactionHash;
        if (buyr.status) {
          var elem = document.createElement("div");
          elem.innerHTML = "<p style=\"color: black\">Your purchase was successfully processed!<a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
          swal({
            title: "Order Successful",
            content: elem,
            icon: "success"
          })
          setTimeout(this.smallGetData, 5000);
          //swal("Order Successful", "Your purchase was successfully processed!\nTransaction Hash: " + tx, "success");
          self.submitPurchase(type + 1, numberOfGuns, price, tx);
          ReactGA.event({
            category: 'Purchase',
            action: 'Bought a garage',
            value: numberOfGuns
          });
        } else {
          swal("Order Failed", "Your purchase could not be processed! We're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nYour ether has been refunded.\nTransaction Hash: " + tx, "error")
          ReactGA.event({
            category: 'Failed Purchase',
            action: 'Failed to purchase a garage case',
            value: numberOfGuns
          });
        }
      }
    } catch (e) {
      console.log(e)
      if (e.message.includes('insufficient funds')) {
        swal('Could not submit Transaction', "You don't have enough ether to complete the order. Please fund your account before trying again", "error")
      } else {
        swal.close();
      }
    }
}

submitPurchase(type, numberOfGuns, weiPrice, txHash) {
  //console.log("Submitting purchase " + " " + type + " " + numberOfGuns + " " + weiPrice + " " + txHash);

  try {
    function intToName(type) {
      switch (type) {
          case 0:
              return "Premium";
          case 1:
              return "Midgrade";
          case 2:
              return "Regular";
          default:
              return "";
        }
    }

    let ether = vars.web3.utils.fromWei(weiPrice.toString(), 'ether');

    ReactGA.plugin.execute(
      'ecommerce',
      'addItem',
      {
        id: txHash,
        name: intToName(type),
        sku: '0001' + type.toString(),
        price: ether,
        quantity: numberOfGuns.toString(),
      }
    );
    ReactGA.plugin.execute(
      'ecommerce',
      'addTransaction',
      {
        id: txHash,
        revenue: ether,
      }
    );
    ReactGA.plugin.execute('ecommerce', 'send');
    ReactGA.plugin.execute('ecommerce', 'clear');

    //console.log("FFF Sent the following: " + {
    //  id: txHash,
    //  name: intToName(type),
    //  sku: '0001' + type.toString(),
    //  price: ether,
    //  quantity: numberOfGuns.toString(),
   // });

    //console.log("FFF Also sent " + {
     // id: txHash,
     // revenue: ether,
    //})
  } catch (e) {
    console.log("FFF GOT ERROR " + e);
  }
}

smallGetDataForQuantity = (q) => {
  let type = this.state.typeIndex; // Start at 1 in Eth

  //console.log('carRes: ' + JSON.stringify(carRes))
  var self = this;

  let bznPrice = 0;
  let ethPrice = 0;
  let bznBal = 0;
  if (q == 0 || isNaN(q)) {
    q = 1;
  }

  vars.infoContracts.garagePreOrder.methods.priceFor(type + 1, q).call().then(function(pdata) {
    let p = pdata[1];
    if (p == 0) {
      //TODO Shop isn't open yet
      return;
    }
    bznPrice = p;
    //console.log("FFFDD " + usdPrice);
    return getBznBalance(self.state.currentId);
  }).then(function(bzn) {
    bznBal = bzn

    self.setState ({
      price: bznPrice,
      bznPrice: bznPrice,
      ethPrice: ethPrice,
      bznBalance: bznBal,
    });

  }).catch(function(err) {
    console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
    //ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
  })
}

smallGetData = () => {
  let type = this.state.typeIndex; // Start at 1 in Eth

  //console.log('carRes: ' + JSON.stringify(carRes))
  var self = this;

  let bznPrice = 0;
  let ethPrice = 0;
  let bznBal = 0;
  let q = Number(self.state.quantityBuying);
  let currentDayEnd = 0;
  let categoryOffset = 0;
  if (q == 0 || isNaN(q)) {
    q = 1;
  }

  vars.infoContracts.garagePreOrder.methods.priceFor(type + 1, q).call().then(function(pdata) {
    let p = pdata[1];
    if (p == 0) {
      //TODO Shop isn't open yet
      return;
    }
    bznPrice = p;
    //console.log("FFFDD " + usdPrice);
    return getBznBalance(self.state.currentId);
  }).then(function(bzn) {
    bznBal = bzn

    return vars.infoContracts.garageFactory.methods.categoryData(type + 1).call();
  }).then(function(catData) {
    currentDayEnd = catData.currentDayEnd;

    return vars.infoContracts.garageToken.methods.categoryOffset(type + 1).call();
  }).then(function (co) {
    categoryOffset = co

    return vars.infoContracts.garageFactory.methods.hasReachedDailyLimit(type + 1).call();
  }).then(function(hasReachedLimit) {

    const currentDayEndDate = new Date(Number(currentDayEnd) * 1000)
    const pastDue = Date.now() >= currentDayEndDate
    let amountLeft = Number(hasReachedLimit[2]) - (Number(hasReachedLimit[1]) + Number(categoryOffset));
    if (pastDue) {
      amountLeft = Number(hasReachedLimit[2])
    }

    self.setState ({
      price: bznPrice,
      bznPrice: bznPrice,
      ethPrice: ethPrice,
      bznBalance: bznBal,
      amtLeft: amountLeft > 0 || pastDue,
      casesLeft: amountLeft,
      limit: Number(hasReachedLimit[2]),
      currentDayEnd: currentDayEndDate,
    });

    setTimeout(self.smallGetData, 5000);
  }).catch(function(err) {
    console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
    //ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
  })
}


getData = () => {
    let type = this.state.typeIndex; // Start at 1 in Eth

    let minMax = getMinMax(type);

    //console.log('carRes: ' + JSON.stringify(carRes))
    var self = this;
    // Real

    let tokensPerNFTMin = roundToTwo(minMax[0].min);
    let tokensPerNFTMax = roundToTwo(minMax[0].max);

    let renewTermMin = roundToTwo(minMax[1].min);
    let renewTermMax = roundToTwo(minMax[1].max);

    let entranceFeeMin = roundToTwo(minMax[2].min);
    let entranceFeeMax = roundToTwo(minMax[2].max);

    let entrancesMin = roundToTwo(minMax[3].min);
    let entrancesMax = roundToTwo(minMax[3].max);

    let damageMin = roundToTwo(minMax[4].min);
    let discountMax = roundToTwo(minMax[4].max);

    let stabilityMin = roundToTwo(minMax[5].min);
    let luckMax = roundToTwo(minMax[5].max);

    let rarityMin = roundToTwo(minMax[6].min);
    let rarityMax = roundToTwo(minMax[6].max);
    //log('new description is: ' + description)
    var svg = ( <GradientSVG
           idCSS='sxxx'
           rotation={90}
         />);

    var posedProps = {
      draggable: 'x',
      open: { x: '0%' },
      closed: { x: '-100%' }
    }


    function gunInfoProgressComponents(){
      return (
              <div>
                <div>{ProgressComponent(tokensPerNFTMin,tokensPerNFTMax,maxes.tokensPerNFT, 'Tokens', 'tokens')}</div>
                <div>{ProgressComponent(renewTermMin,renewTermMax,maxes.renewTerm, 'Renew Term', 'days', 'renew-term-hint')}</div>
                <div>{ProgressComponent(entranceFeeMin,entranceFeeMax,maxes.entranceFee, 'Entrance Fee', '%', 'entrance-fee-hint')}</div>
                <div>{ProgressComponent(entrancesMin,entrancesMax,maxes.entrances, 'Entrances', 'doors', null)}</div>
                  <Row >
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                            <h5 className='centered'>Max Discount <span data-tip data-for='other-hints'><FontAwesomeIcon icon='question-circle' /></span></h5>
                            <br></br>
                            <CircularProgressbar percentage={(discountMax)/10*100} text={`${discountMax}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                        <h5 className='centered'>Max Luck Index <span data-tip data-for='other-hints'><FontAwesomeIcon icon='question-circle' /></span></h5>
                        <br></br>
                            <CircularProgressbar percentage={(luckMax)/10*100} text={`${luckMax}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                        <h5 className='centered'>Max Rarity Index <span data-tip data-for='other-hints'><FontAwesomeIcon icon='question-circle' /></span></h5>
                        <br></br>
                            <CircularProgressbar percentage={(rarityMax)/10*100} text={`${rarityMax}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>

                  </Row>
                  <Row><div style={{width: '100%', height: '30px'}}></div></Row>
              </div>
            );

    }
    //console.log('IMG to be:' + carRes.img);
    let img = premiumGarageImg;
    if (type == 1) {
      img = midgradeGarageImg;
    } else if (type == 2) {
      img = regularGarageImg;
    }
    this.setState({img: img})

    let onVideoFailed = function() {
      self.setState({videoSupported: false});
    };

    let vurl = caseVars[type].webm;
    let vid = <ReactPlayer  width='100%' height='100%' url={vurl} playing={true} loop={true} onError={onVideoFailed}/>
    if (vurl != "" && this.state.videoSupported && isBrowser) {
      //  alert('baggaeg claim')
    //  ReactDom.render(<div>{vid}</div>, document.getElementById('cImg'));
      this.setState({imgComp: vid});

    } else {
    //  ReactDom.render(<img src={img}></img>, document.getElementById('cImg'));
      this.setState({imgComp: <img src={img}></img>});
    }

    var target = document.getElementById('progressi');

    if (target == null)
      return;

    ReactDom.render(<div>{gunInfoProgressComponents()}</div>, target);
    ReactTooltip.rebuild()

    //ReactDom.render(<h4 style={{lineHeight: '30px'}}>{description}</h4>, document.getElementById('car_description'));
    let bznPrice = 0;
    let ethPrice = 0;
    let totalSupply = 0;
    let mintingFee = 0;
    let hold = 7000;
    let bznBal = 0;
    let oldBZN = false;
    let currentDayEnd = 0;
    let categoryOffset = 0;
    let q = Number(self.state.quantityBuying);
    if (q == 0 || isNaN(q)) {
      q = 1;
    }

    ///onsole.log("Using indexxxxx " + index + "(type: " + type + ", car: " + car + ")");
    vars.infoContracts.garagePreOrder.methods.priceFor(type + 1, q).call().then(function(pdata) {
      let p = pdata[1];
      if (p == 0) {
        //TODO Shop isn't open yet
        return;
      }
      bznPrice = p;
      //console.log("FFFDD " + usdPrice);
      return vars.infoContracts.garageToken.methods.categoryCount(type + 1).call();
    }).then(function(supply) {
      totalSupply = Number(supply);

      return getBzn1Balance(self.state.currentId);
    }).then(function(bzn) {
      oldBZN = bzn > 0;

      return getBzn2Balance(self.state.currentId);
    }).then(function(bzn) {
      oldBZN = oldBZN || bzn > 0;

      return getBznBalance(self.state.currentId);
    }).then(function(bzn) {
      bznBal = bzn

      return vars.infoContracts.garagePreOrder.methods.categoryData(type + 1).call();
    }).then(function(ep) {
      mintingFee = ep.mintingFee;

      return vars.infoContracts.garageFactory.methods.categoryData(type + 1).call();
    }).then(function(cd) {
      currentDayEnd = cd.currentDayEnd;

      return vars.infoContracts.garageToken.methods.categoryOffset(type + 1).call();
    }).then(function (co) {
      categoryOffset = co

      return vars.infoContracts.garageFactory.methods.hasReachedDailyLimit(type + 1).call();
    }).then(function(hasReachedLimit) {
      let wasNotLoaded = !self.state.loaded;

      const currentDayEndDate = new Date(Number(currentDayEnd) * 1000)
      const pastDue = Date.now() >= currentDayEndDate
      let amountLeft = Number(hasReachedLimit[2]) - (Number(hasReachedLimit[1]) + Number(categoryOffset));
      if (pastDue) {
        amountLeft = Number(hasReachedLimit[2])
      }
      
      self.setState ({
        price: bznPrice,
        bznPrice: bznPrice,
        ethPrice: ethPrice,
        mintingFee: mintingFee,
        totalSupply: totalSupply,
        hold: hold,
        amtLeft: amountLeft > 0 || Date.now() >= currentDayEndDate,
        casesLeft: amountLeft,
        limit: Number(hasReachedLimit[2]),
        bznBalance: bznBal,
        hasOldBZN: oldBZN,
        gasFeeRequired: 0,
        loaded: true,
        currentDayEnd: currentDayEndDate,
      });

      let base_price = caseVars[type].base_price;
      //log('Calling Rise Data with params: ' + '\nBase Price: ' + base_price + '\nCurrent Price: ' + price + '\nCurrent Q: ' + hold + '\nTotal supply: ' + totalSupply)
      let increasing = riseData(type, base_price, vars.web3.utils.fromWei(bznPrice, 'ether'), totalSupply, hold);

      //let increasing = getIncrFXdata(price, (totalSupply-hold));
      if (increasingDataChanged) {
        self.setState({
          increasing: increasing
        });
        increasingDataChanged = false;
      }

      if (totalSupply != self.state.lastTotalSupply) {
        increasingDataChanged = true;
        self.setState({
          lastTotalSupply: totalSupply
        })
      }
      
      if (wasNotLoaded)
        swal.close();

    }.bind(self)).catch(function(err) {
      console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
      //ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
    });

    if (this.state.shouldUpdate) {
      setTimeout(this.smallGetData, 5000);
    }
  }

outFunc() {
  var tooltip = document.getElementById("myTooltip");
  tooltip.innerHTML = "Copy to clipboard";
}

anim() {
  document.getElementById('anim').className ='classname';
}

currencySelected(val) {
  this.setState({
    currency: val.toUpperCase()
  });
}

render() {
  if (this.state.migrateRedirect) {
    return <Redirect to='/migrate' />
  }

  //log('rerender triggered, description is ' + this.state.description)
  const MyComponent = ({ hostRef }) => <div ref={hostRef} ><img style={{width: '50%'}} src={imgs.car}></img></div>

  function customizationText(typeIndex, carType) {
    return "Customization is live";
  }

  function getSpecialTrait(carType) {
    carType = carType + 1;
    switch (carType) {
      case 5:
        return ["Bonus Trait | NOS".toUpperCase(), "Instantly gains speed | NOS equip. installed"];
      case 4:
        return ["Bonus Trait | NOS".toUpperCase(), "No critical damage | NOS equip. installed"];
      case 3:
        return ["Bonus Trait | NOS".toUpperCase(), "Perfect maneuverability | NOS equip. installed"];
      default:
        return ["Bonus Trait | NOS", "NOS equip. installed"];
    }
  }


  const SpecialTrait = ({ title, description }) => <div><h4 style={{color: '#a922ed'}}>{title}</h4><p>{description}</p></div>

  let config = {
    visible: {
      opacity: 1,
      x: 100,
      transition: { duration: 300000 }
    }, invisible: {
        opacity: 0.5,
        x: -100,
        transition: { duration: 300000 }
      }
  };

  const PosedComponent = posed(MyComponent)(config)

  const Box = posed.circle(config);

  var isOpen = true
  let type = this.state.typeIndex;
  let btnText = "OPEN NOW"
  if (!this.state.amtLeft) btnText = "LIMIT REACHED"
      return (
        <div className="ViewCar">
          <MetaTags>
            <title>About {this.state.caseName}</title>
            <meta property="og:title" content="Buy game items now!" />
            <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            
            <meta property="og:image" content={this.state.img}/>
            <meta property="og:url" content={"https://app.warriders.com/viewCar/" + this.state.typeIndex + '/' + this.state.carIndex}/>
            <meta name="twitter:card" content="summary_large_image"/>

            <meta property="og:site_name" content="War Riders"/>
            <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
          </MetaTags>
          <ReactTooltip id='global' aria-haspopup='true' role='example'>
            <p>Enabling buffer will add 5% more ETH/BZN in the case of a race condition</p>
            <p>Any unused ETH/BZN will be refunded immediately</p>
          </ReactTooltip>
          <ReactTooltip id='renew-term-hint' aria-haspopup='true' role='example'>
            <p>The Renew Term defines the earning</p>
            <p>ability of the tokens. The players will</p>
            <p>have to periodically renew the titles to</p>
            <p>continue earning passive income.</p>
          </ReactTooltip>
          <ReactTooltip id='entrance-fee-hint' aria-haspopup='true' role='example'>
            <p>The percentage of BZN that is taken out</p>
            <p>of your vehicle's tank when you enter a</p>
            <p>garage if you don't have a privillege to</p>
            <p>enter for free. This BZN is distributed</p>
            <p>proportionally amoung the Property Title</p>
            <p>holders.</p>
          </ReactTooltip>
          <ReactTooltip id='other-hints' aria-haspopup='true' role='example'>
            <p>Max discount, rarity index and a little</p>
            <p>luck will define the ability to earn a</p>
            <p>significant renewal discount.</p>
          </ReactTooltip>
          <ReactTooltip id='maintenance-info' aria-haspopup='true' role='example'>
            <p>Case Opening is currently closed for maintenances</p>
          </ReactTooltip>
          <Grid>
            <Row style={{height: 100}}></Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h3>ABOUT {this.state.caseName} PROPERTY TITLE GARAGE CASE</h3>
                <br></br>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} md={6}>
                <div id='cImg'>
                  {this.state.imgComp}
                </div>
                <h1> {this.state.quantityBuying > 1 ? "TOTAL PRICE" : "PRICE NOW"}: {conditionalRenderer((this.state.price != 'DNE') ? round(vars.web3.utils.fromWei(this.state.price.toString(), 'ether'), 2).toFixed(2) : 'DNE' )} BZN </h1>
                <br></br>
                <p> {getTrueMultipleFor(this.state.typeIndex)}% price increase with each case opened </p>
              </Col>
              <Col xs={12} sm={6} md={6}>

                <div style={{ 'margin-bottom': '15px'}}>The stats will generate randomly within these ranges:</div>
                  <Col xs={12} sm={12} md={12}>
                    <div id='progressi'>

                    </div>
                   <Row>
                     <Col sm={6} md={6}>
                       <p className='concealed'>.</p>
                       <div className='left-to-right'>
                         <a className="btn-one-that-doesnt-resize" onClick={() => this.popup()} >
                           <span className="hide-selection">{btnText}</span>
                         </a>
                       </div>
                     </Col>
                     <Col sm={6} md={6}>
                     <p>Quantity</p>
                       <NumericInput id='reqFunds' className="form-control" min={this.state.min} max={this.state.max} value={this.state.quantityBuying} step={1} onChange={this.handleOnChange} style={{
                         input: {
                           backgroundColor: 'black',
                           color: '#a922ed',
                           fontSize: 16,
                           marginBottom: (isMobile ? '40px' : 'initial')
                         },
                         btn: {
                           color: 'white',
                           background: 'white'
                         },
                         'btn:hover': {
                           background: 'lightGrey'
                         }
                       }}/>
                     </Col>
                   </Row>
                 </Col>

              </Col>

            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <div id='CustomChart'>
                  <CustomChart currentQuantity={this.state.hold} data={this.state.increasing} className='CustomChart'/>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <div style={{marginTop: '10%', textAlign: 'left' }}>
                  <br></br><br></br>
                  <h3>This case contains: <span style={{color: '#a922ed'}}><strong><a href={caseVars[this.state.typeIndex].contentLink} style={{color: 'inherit'}}>VIEW</a></strong></span></h3>
                  <h3>Supply Cap: {conditionalRenderer(this.state.limit + '/day')}</h3>
                  <h3>Amount left today: {conditionalRenderer(this.state.casesLeft + ' cases')}</h3>
                  {!this.state.amtLeft ? <h3>Supply Restock In: <span style={{color: '#a922ed'}}><strong><Countdown date={this.state.currentDayEnd} /></strong></span></h3> : <></>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>ABOUT THIS GARAGE OWNERSHIP CASE</h3>
                <br></br><br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <h4 style={{color: '#a922ed'}}>LOCATION</h4>
                <p>Genesis Map</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>ERC-721 STRUCTURE</h4>
                <p>Standard, tradable non-fungible tokens</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>EXCLUSIVE MEMBERSHIP</h4>
                <p>Spawn Privileges and Passive Earning</p>
                <br></br>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <h4 id='car_description' style={{lineHeight: '50px'}}>{(this.state.description) ? (this.state.description) : 'Sorry, there is no available description for this vehicle!'}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>OPEN MORE CASES</h3>
                <br></br>
                <h4 style={{lineHeight: '30px'}}>Opening more cases will increase your chances of securing more garage tokens with favorable renewal terms. Only the active holders are eligible for passive in-game earning.</h4>
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[0]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[0]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGarage(this.state.adCaseTypes[0])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[1]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[1]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGarage(this.state.adCaseTypes[1])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[2]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[2]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGarage(this.state.adCaseTypes[2])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
            </Row>
          </Grid>

          <Referral text={referralText} />

        </div>
      );

  }

  animate = () => {
    document.getElementById('ex').classList.add('horizTranslate');
  }

}

function ImageCarousel(img) {
  return (
    <div>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}>
        <img src={img} key={img} />
      </CSSTransitionGroup>
    </div>
  );
}


export default ViewGarage;
