import axios from 'axios';
import React, { Component } from 'react';
import ReactDom from 'react-dom';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import './App.css';
import { Redirect } from 'react-router';
import loadingGif from './images/loading.gif';
import swal from 'sweetalert';
import { isMobile } from "react-device-detect";
import img from './ImgFactory.js';
import PasswordStrength from "./passwordStrength";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'
import MetaTags from 'react-meta-tags';

const firebase = vars.firebase;
const NEW_FLOW_ENABLED = false;
//const web3 = vars.web3;

async function uuidToUsername(uuid) {
  const snapshot = await firebase.database().ref("users/" + uuid + "/username").once("value");
  let username = snapshot.val() || null;

  return username
}

function getRedirectURL() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (urlParams.has("redirect"))
    return urlParams.get("redirect");
  else
    return "/";
}

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: false,
      userProfile: null,
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      isLogIn: true,
      address: "",
      alreadyLinked: false,
      linkedAddress: "",
      claimUsername: false,
      recaptchaVerified: false,
      noLink: false,
      sendEmail: true,
      emailUpdateCheck: true,
      currentUsername: "",
      goRedirect: null,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleConfirmPassChange = this.handleConfirmPassChange.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.loginAccount = this.loginAccount.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClaimUsername = this.handleClaimUsername.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.captchaLoaded = this.captchaLoaded.bind(this);
    this._link = this._link.bind(this);
    this._unlink = this._unlink.bind(this);
    this.handleUnlink = this.handleUnlink.bind(this);
    this.handleGoHome = this.handleGoHome.bind(this);
    this.handleLinkCancel = this.handleLinkCancel.bind(this);
    this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    loadReCaptcha();

    var self = this;

    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      let temp = !!user;

      if (temp) {
        uuidToUsername(user.uid).then(function(u) {
          if (u) {
            self.setState({ currentUsername: u })
          } else {
            self.setState({ currentUsername: " " });
          }
        }).catch(function(e) {
          self.setState({ currentUsername: " " });
        })

        //User is now signed in, lets see if they're linked
        vars.accountLinked().then(function(address) {
          self.setState({ alreadyLinked: true, linkedAddress: address, isSignedIn: temp, userProfile: user });
        }).catch(function() {
          self.setState({ alreadyLinked: false, linkedAddress: "", isSignedIn: temp, userProfile: user });
        })
      } else {
        self.setState({ alreadyLinked: false, linkedAddress: "", isSignedIn: temp, userProfile: user });
      }
    });

    let userCheck = firebase.auth().currentUser;
    if (userCheck != null) {
      //User is already signed in, lets see if they're linked

      uuidToUsername(userCheck.uid).then(function(u) {
        if (u) {
          self.setState({ currentUsername: u })
        } else {
          self.setState({ currentUsername: " " });
        }
      }).catch(function(e) {
        self.setState({ currentUsername: " " });
      })

      vars.accountLinked().then(function(address) {
        self.setState({ alreadyLinked: true, linkedAddress: address, isSignedIn: true, userProfile: userCheck });
      }).catch(function() {
        self.setState({ alreadyLinked: false, linkedAddress: "", isSignedIn: true, userProfile: userCheck });
      })
    }

    vars.getAccounts().then(function(accounts) {
      if (accounts != null) {
        if (accounts.length == 0) {
          self.setState({
            noLink: true
          })
          /* var elem = document.createElement("div");
          elem.innerHTML = "<p style=\"color: black\">No account is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
          swal({
            title: "No Account",
            content: elem,
            icon: "error"
          }).then(function() {
            
          }); */
        }
        else {
          console.log('We found accounts from metamask');
          self.setState ({
            address: accounts[0]
          });

        }
      }
      else {
        self.setState({
          noLink: true
        })
        /* var elem = document.createElement("div");
        elem.innerHTML = "<p style=\"color: black\">No account is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
        swal({
          title: "No Account",
          content: elem,
          icon: "error"
        }).then(function() {
          self.setState({
            noLink: true
          })
        }); */
      }
    }).catch(function(error) {
      self.setState({
        noLink: true
      })
      /* var elem = document.createElement("div");
      elem.innerHTML = "<p style=\"color: black\">No account is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
      swal({
        title: "No Account",
        content: elem,
        icon: "error"
      }).then(function() {
        self.setState({
          noLink: true
        })
      }); */
    });
  }

  createAccount() {
    this.setState({ isLogIn: false });
  }

  loginAccount() {
    this.setState({ isLogIn: true });
  }

  handleCreateAccount() {
    var self = this;

    let email = this.state.email;
    let password = this.state.password;
    let confirmPassword = this.state.confirmPassword;
    const forbiddenCharacters = ['"', "'"];

    if (email == "" || password == "" || confirmPassword == "") {
      swal("Incomplete", "Please fill in all fields", "error");
      return;
    }

    if (password != confirmPassword) {
      swal("Mismatch Password", "The passwords entered do not match.", "warning");
      return;
    }

    if (!(/\d/.test(password) && /[a-zA-Z]/.test(password))) {
      swal("Password Requirement", "Your password must include at least one letter and one number", "error");
      return;
    }

    if (forbiddenCharacters.some((c) => password.includes(c))) {
      swal("Password Requirement", "Your password cannot contain any quotes", "error");
      return;
    }

    if (password.length < 6) {
      swal("Password Requirement", "Your password must be at least 6 characters long", "error");
      return;
    }

    if (!this.state.recaptchaVerified) {
      swal("ReCaptcha", "Please complete the ReCaptcha before you create your game account", "error");
      return;
    }

    swal({
      title: "Processing...",
      text: "Please wait while your account is created",
      icon: loadingGif,
      className: 'wr-loader',
      button: false,
      closeOnClickOutside: false
    });

    if (this.state.emailUpdateCheck == true){
      axios.post(vars.urls.emailURL, {email: email});
    }

    firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode == 'auth/weak-password') {
        swal("Password Weak", "Your password is to weak, please try using a stronger password", "error");
      } else {
        swal("Error", "An error has occured: \"" + errorMessage + "\"", "error");
      }
    }).then(function(data) {
      if (data == null)
        return;

      axios.post(vars.urls.lip, {email: email});

      swal.close();

      self.setState({
        claimUsername: true,
        userProfile: data.user
      });
    });
  }

  handleClaimUsername() {
    let userId = this.state.userProfile.uid;

    var self = this;

    let username = this.state.username.trim().toLowerCase();

    if (username.length < 3) {
      swal("Name too short", "Your nickname must be at least 3 characters long!", "error");
      return;
    }

    if (username.length > 10) {
      swal("Name too long", "Your nickname cannot exceed 10 characters!", "error");
      return;
    }

    if (/[^A-Za-z0-9]+/.test(username)) {
      swal("Illegal Name", "Your nickname can only contain letters and numbers!", "error");
      return;
    }

    swal({
      title: "Processing...",
      text: "Please wait while your nickname is claimed",
      icon: loadingGif,
      className: 'wr-loader',
      button: false,
      closeOnClickOutside: false
    });

    firebase.database().ref("usernames/" + username).set(userId).then(function() {
      firebase.database().ref("users/" + userId).update({
        username: username
      }).then(function() {
        return self.state.userProfile.updateProfile({
          displayName: username
        })
      }).then(function() {
        if (NEW_FLOW_ENABLED) {
          if (!vars.isWeb3Known()) {
            self.state.userProfile.sendEmailVerification();
            self.setState({
              sendEmail: false
            })
            swal({
              title: 'Link Wallet Address',
              text: 'Your nickname has been set to ' + self.state.username + '. To link your wallet address, you must connect your wallet. Would you like to do this now? You can always link your wallet at a later time by signing in',
              icon: 'info',
              buttons: true
            }).then((willLink) => {
              if (willLink) {
                vars.askForWeb3().then(function() {
                  self.setState({
                    claimUsername: false,
                    currentUsername: self.state.username
                  });
                }).catch(function(e) {
                  swal({
                    title: 'Wallet Connection Failed',
                    text: 'Wallet connection failed. To link a wallet address to your game account, go to your account and choose Link',
                    icon: 'error'
                  });
                  console.log(e);
                });
              } else {
                self.setState({
                  goHome: false
                });
              }
            });
          } else {
            swal("Success", "Your nickname has been set to " + self.state.username + ". Please link your wallet address to complete account creation.", "success");

            self.setState({
              claimUsername: false,
              currentUsername: self.state.username
            });
          }
        } else {
          if (self.state.userProfile.emailVerified) {
            swal("Success", "Your nickname has been set to " + self.state.username + ". Please link your wallet address to complete account creation.", "success");

            self.setState({
              claimUsername: false,
              currentUsername: self.state.username
            })
          } else {
            swal("Success", "Your account has been created, please check your email for instructions to verify your email and link your account.", "success");

            self.state.userProfile.sendEmailVerification();

            self.setState({
              goHome: true
            });
          }
        }
      }).catch(function() {
        swal("Nickname Taken", "Looks like this nickname has already been taken, please choose a different nickname", "error");
      });
    }).catch(function(error) {
      swal("Nickname Taken", "Looks like this nickname has already been taken, please choose a different nickname", "error");
    });
  }

  handleLogin() {
    var self = this;

    let email = this.state.email;
    let password = this.state.password;

    if (email == "" || password == "") {
      swal("Incomplete", "Please fill in all fields", "error");
      return;
    }

    firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;

      swal("Error", "An error has occured: \"" + errorMessage + "\"", "error");
    }).then(function(data) {
      if (data == null) {
        swal("Login Failed", "Invalid email or password!", "error");
        return;
      }

      if (!data.user.emailVerified) {
        swal("Not Verified", "Your account has not been verified. Please check your email for instructions to verify your email and link your external wallet.\nIf you just verified your account, try logging out and logging back in.\nIf you lost the email, you can resend it using the button below", "error", {
          buttons: {
            resend: "Resend Verification Email", 
            close: "Try Again"
          }
        }).then(function(val) {
          if (val == "resend") {
            data.user.sendEmailVerification();
          }
        });
        firebase.auth().signOut();
        return;
      }

      let userId = data.user.uid;

      firebase.database().ref("users/" + userId).once('value').then(function(snapshot) {
        var address = (snapshot.val() && snapshot.val().eth) || "";

        if (address != "") {
          /* if (address.toLowerCase() == self.state.address.toLowerCase()) {
            self.setState({
              goHome: true
            });
          } else {
            self.setState({
              alreadyLinked: true,
              linkedAddress: address
            });
          } */
          self.setState({
            goRedirect: getRedirectURL()
          });
        } else if (snapshot.val()) {
          self.setState({
            claimUsername: false,
            currentUsername: snapshot.val().username,
            userProfile: data.user
          });
        } else {
          self.setState({
            claimUsername: true,
            userProfile: data.user
          });
        }
      }).catch(function(e) {
        console.log(e);
        //User needs setup for some reason
        self.setState({
          claimUsername: true
        });
      });
    });
  }

  _unlink() {
    if (this.state.currentUsername == " ") {
      return;
    }

    var self = this;

    const msg = new Buffer('I confirm I am the owner of this account');
    var hash = '0x' + msg.toString('hex')

    vars.web3.eth.personal.sign(hash, self.state.address).then(function(signature) {
      self.state.userProfile.getIdToken().then(function(token) {
        fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/unlink', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            message: signature
          })
        }).then(function(response) {
          return response.json();
        }).then(function(obj) {
          if (!obj.error) {
            swal({
              title: "Success",
              text: "Your external wallet has successfully been unlinked. You will now be redirected to the homepage",
              icon: "success"
            }).then(function() {
              self.setState({
                goHome: true
              });
            });
          } else {
            swal("Got Error", "Error: " + obj.message, "error");
          }
        }).catch(function(error) {
          swal("Got Error", "Error: " + error, "error");
        })
      });
    });
  }

  _link() {
    if (this.state.userProfile == null) {
      return;
    }
    if (this.state.currentUsername == " ") {
      return;
    }

    var self = this;

    const msg = new Buffer('I confirm I am the owner of this account');
    var hash = '0x' + msg.toString('hex')

    var pendingLink = !self.state.userProfile.emailVerified;

    var fetchURL = 'https://us-central1-war-riders-account-system.cloudfunctions.net/app/link';
    var messageTitle = "Success";
    var messageIcon = 'success';
    var successMessage = "Your external wallet has successfully been linked. You will now be redirected to the homepage";

    if (pendingLink) {
      fetchURL = 'https://us-central1-war-riders-account-system.cloudfunctions.net/app/pendinglink';
      messageIcon = 'info';
      messageTitle = "Email Confirmation Required";
      successMessage = "Your game account has been created and your external wallet link is pending. Please check your email for instructions to verify your email and finish the signup process.";
    }

    vars.web3.eth.personal.sign(hash, self.state.address).then(function(signature) {
      self.state.userProfile.getIdToken().then(function(token) {
        fetch(fetchURL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            message: signature
          })
        }).then(function(response) {
          return response.json();
        }).then(function(obj) {
          if (!obj.error || obj.message == "Account Already Pending Link") {
            if (pendingLink && self.state.sendEmail) {
              self.state.userProfile.sendEmailVerification();
            }
            swal({
              title: "Success",
              text: successMessage,
              icon: messageIcon
            }).then(function() {
              self.setState({
                goHome: true
              });
            })
          } else if (obj.message == "Address linked elsewhere") {
            swal("Wallet Already Linked", "This wallet address has already been linked to another account. Please unlink this wallet first before linking it to this account.", "error");
          } else {
            swal("Got Error", "Error: " + obj.message, "error");
          }
        }).catch(function(error) {
          console.log(error);
          swal("Got Error", "Error: " + error, "error");
        })
      });
    }).catch(function(error) {
      swal("Error", "You must sign the message in order to link your account", "error");
    });
  }

  handleUnlink() {
    let self = this;

    swal({
      title: "Processing...",
      text: "Please wait while your external wallet is unlinked. You may need to sign a message to verify you are the owner of this wallet. Check your wallet if you don’t see a Signature Request pop-up.",
      icon: loadingGif,
      className: 'wr-loader',
      button: false,
      closeOnClickOutside: false
    });

    if (self.state.userProfile == null) {
      swal("Login Failed", "Invalid email or password!", "error");
      return;
    }

    self.state.userProfile.reload().then(function() {
      if (!self.state.userProfile.emailVerified) {
        swal("Not Verified", "Your account has not been verified. Please check your email for instructions to verify your email and link your external wallet.", "error")
        return;
      }

      self._unlink();

    });
  }
 
  handleLink() {
    let self = this;

    swal({
      title: "Processing...",
      text: "Please wait while your external wallet is linked. You may need to sign a message to verify you are the owner of this wallet. Check your wallet if you don’t see a Signature Request pop-up.",
      icon: loadingGif,
      className: 'wr-loader',
      button: false,
      closeOnClickOutside: false
    });

    if (self.state.userProfile == null) {
      swal("Login Failed", "Invalid email or password!", "error");
      return;
    }

    self.state.userProfile.reload().then(function() {
     self._link();
    });
  }

  handleLinkCancel() {
    var pendingLink = !this.state.userProfile.emailVerified;

    if (pendingLink) {
      this.state.userProfile.sendEmailVerification();
    }

    this.setState({
      goHome: true
    })
  }

  handleCancel() {
    this.setState({
      goHome: true
    })
  }

  handleForgotPassword() {
    let email = this.state.email;

    if (email != "") {
      firebase.auth().sendPasswordResetEmail(email).then(function() {
        swal("Instructions Sent", "An email has been sent with instructions to reset your password, please follow the instructions in the email to reset your password", "warning");
      }).catch(function(err) {
        swal("Error", "An error has occured: \"" + err.message + "\"", "error");
      })
    } else {
      swal("No Email", "Please provide the email you'd like to reset", "error");
    }
  }

  resendVerifyEmail() {
    let email = this.state.email;

    if (email != "") {
      firebase.auth().sendPasswordResetEmail(email).then(function() {
        swal("Instructions Sent", "An email has been sent with instructions to reset your password, please follow the instructions in the email to reset your password", "warning");
      }).catch(function(err) {
        swal("Error", "An error has occured: \"" + err.message + "\"", "error");
      })
    } else {
      swal("No Email", "Please provide the email you'd like to reset", "error");
    }
  }

  componentWillUnmount() {
    // Un-registers the auth state observer.
    this.unregisterAuthObserver();
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleUserChange(event) {
    this.setState({username: event.target.value});
  }

  handlePassChange(event) {
    this.setState({password: event.target.value});
  }

  handleConfirmPassChange(event) {
    this.setState({confirmPassword: event.target.value});
  }

  handleGoHome(event) {
    this.setState({goHome: true});
  }

  verifyCallback(response) {
    console.log(response);
    const self = this;
    fetch('https://api.warriders.com/verifycaptcha', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        recaptchaResponse: response
      })
    }).then(function(response) {
      return response.text();
    }).then(function(data) {
      if (data == 'passed') {
        self.setState({ recaptchaVerified: true });
      } else {
        self.setState({ recaptchaVerified: false });
      }
    }).catch(function(e) {
      console.log(e);
      self.setState({ recaptchaVerified: false });
    });
  }

  captchaLoaded() {
    this.setState({ recaptchaVerified: false });
  }

  linkRender() {
    if (this.state.currentUsername == " ") {
      this.setState({
        claimUsername: true
      });
      return this.claimUsernameRender();
    }

    vars.askForWeb3();

    return (
      <div className="loginPage">
        <MetaTags>
          <title>War Riders Game Account</title>
          <meta property="og:title" content="War Riders Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <img src={img.link} />
        <h3>Would you like to link <a target="_blank" href={"https://etherscan.io/address/" + this.state.address}>{this.state.address.substring(0, 7) + "..." + this.state.address.substring(this.state.address.length - 7)}</a></h3>
        <h3>using the account {this.state.userProfile.email} ?</h3>
        <div>
          <a onClick={this.handleLink} className="btn-bar" style={{ color: '#fff', width: '222px', margin: '0 auto', marginTop: '45px'}}>
            <span>Yes Link Account</span>
          </a>
          <a onClick={this.handleLinkCancel} className="btn-bar" style={{ color: '#fff', width: '150px', margin: '0 auto', marginTop: '45px'}}>
            <span>No Cancel</span>
          </a>
        </div>
      </div>
    )
  }

  noLinkRender() {
    if (this.state.currentUsername == " ") {
      this.setState({
        claimUsername: true
      });
      return this.claimUsernameRender();
    }

    return (
      <div className="loginPage">
        <MetaTags>
          <title>War Riders Game Account</title>
          <meta property="og:title" content="War Riders Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <img src={img.link} />
        <h2 style={{ textAlign: 'center' }}>This account is already linked to <a target="_blank" href={"https://etherscan.io/address/" + this.state.linkedAddress}>{this.state.linkedAddress.substring(0, 7) + "..." + this.state.linkedAddress.substring(this.state.linkedAddress.length - 7)}</a></h2>
        <h3>You can only unlink this account using that wallet address</h3>
        <div>
          <a onClick={this.handleGoHome} className="btn-bar relink-btn" style={{ color: '#fff', width: '222px'}}>
            <span>Go Home</span>
          </a>
        </div>
      </div>
    )
  }

  unLinkRender() {
    if (this.state.currentUsername == " ") {
      this.setState({
        claimUsername: true
      });
      return this.claimUsernameRender();
    }



    return (
      <div className="loginPage">
        <MetaTags>
          <title>War Riders Game Account</title>
          <meta property="og:title" content="War Riders Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <img src={img.link} />
        <h2 style={{ textAlign: 'center' }}>This account is currently linked to <a target="_blank" href={"https://etherscan.io/address/" + this.state.linkedAddress}>{this.state.linkedAddress.substring(0, 7) + "..." + this.state.linkedAddress.substring(this.state.linkedAddress.length - 7)}</a></h2>
        <p style={{ textAlign: 'center' }}>You can unlink this wallet.</p>
        <h3>Would you like to unlink <a target="_blank" href={"https://etherscan.io/address/" + this.state.address}>{this.state.address.substring(0, 7) + "..." + this.state.address.substring(this.state.address.length - 7)}</a></h3>
        <h3>from the account {this.state.userProfile.email} ?</h3>
        <div>
          <a onClick={this.handleUnlink} className="btn-bar relink-btn" style={{ color: '#fff', width: '222px'}}>
            <span>Yes Unlink Wallet</span>
          </a>
          <a onClick={this.handleCancel} className="btn-bar relink-btn" style={{ color: '#fff', width: '150px'}}>
            <span>No Cancel</span>
          </a>
        </div>
      </div>
    )
  }

  mobileLoginRender() {
    return (
      <div className="loginPage">
        <MetaTags>
          <title>War Riders Game Account</title>
          <meta property="og:title" content="War Riders Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <img src={img.account} />
        <h3>Please log in to an existing game account or create a new one:</h3>
        <input placeholder="Email Address" value={this.state.email} onChange={this.handleEmailChange} />
        <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePassChange} />
        <a onClick={this.handleLogin} className="btn-bar loginBtn">
          <span>Log In</span>
        </a>
        <div className="loginButtons">
          <a onClick={this.createAccount} className="btn-bar createButton" style={{ color: '#fff', width: '222px', marginTop: '45px'}}>
            <span>Create a new game account</span>
          </a>
          <a onClick={this.handleForgotPassword} className="btn-bar" style={{ color: '#fff', width: '150px', marginTop: '45px'}}>
            <span>Forgot password</span>
          </a>
        </div>
      </div>
    )
  }

  loginRender() {
    return (
      <div className="loginPage">
        <MetaTags>
          <title>War Riders Game Account</title>
          <meta property="og:title" content="War Riders Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <img src={img.account} />
        <h3>Please log in to an existing game account or create a new one:</h3>
        <input placeholder="Email Address" value={this.state.email} onChange={this.handleEmailChange} />
        <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePassChange} />
        <Grid>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <a onClick={this.createAccount} className="btn-bar createButton" style={{ color: '#fff', width: '222px', marginTop: '45px', marginLeft: 'auto'}}>
                <span>Create a new game account</span>
              </a>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <a onClick={this.handleForgotPassword} className="btn-bar" style={{ color: '#fff', width: '150px', marginTop: '45px', marginLeft: 'auto'}}>
                <span>Forgot password</span>
              </a>
            </Col>
          </Row>
          <Row style={{marginTop: '30px'}}>
            <Col>
              <a onClick={this.handleLogin} className="btn-bar loginBtn">
                <span>Log In</span>
              </a>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

  claimUsernameRender() {
    return (
      <div className="loginPage">
        <MetaTags>
          <title>War Riders Game Account</title>
          <meta property="og:title" content="War Riders Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <img src={img.account} />
        <h3>Please choose a nickname for your account:</h3>
        <input placeholder="Nickname" value={this.state.username} onChange={this.handleUserChange} />
        <a onClick={this.handleClaimUsername} className="btn-bar" style={{ color: '#fff', width: '141px', margin: "0 auto", marginTop: '45px'}}>
          <span>Claim Nickname</span>
        </a>
      </div>
    )
  }

  handleEmailUpdate(event) {
    this.setState({emailUpdateCheck: event.target.checked});
  }

  createAccountRender() {
    return (
      <div className="loginPage">
        <MetaTags>
          <title>War Riders Game Account</title>
          <meta property="og:title" content="War Riders Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <img src={img.account} />
        <h3>Please create a new game account to link your Ethereum wallet:</h3>
        <input placeholder="Email Address" value={this.state.email} onChange={this.handleEmailChange} />
        <PasswordStrength
            name="password-strength"
            value={this.state.password}
            minLength={6}
            errorBorder={true}
            divStyle={{
              marginLeft: '20%'
            }}
            colors={{
              poor: {
                color: "red",
                label: "Very weak"
              },
              weak: {
                color: "red",
                label: "Very weak"
              },
              good: {
                color: "orange",
                label: "Good"
              },
              strong: {
                color: "lightgreen",
                label: "Strong"
              },
              veryStrong: {
                color: "#4cbac0",
                label: "Awesome Very strong"
              }
            }}
          >
            <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePassChange} />
        </PasswordStrength>
        <p style={{ textAlign: 'center' }}>Password must be at least 6 characters with one letter and one number</p>
        <input type="password" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={this.handleConfirmPassChange} />
        <div style={{  display: "flex", justifyContent: "center", alignItems: "center", margin: '25px auto 0px auto', width: 'fit-content' }}>
          <ReCaptcha
              ref={(el) => {this.createCaptcha = el;}}
              size="normal"
              data-theme="dark"
              render="explicit"
              sitekey="6LfRHosUAAAAAN4nwL9re2SXahZXVPm0LuSDYHou"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.captchaLoaded}
          />
        </div>
        <div className="flexemailsub">
        <input className="emailsubcheckbox" type="checkbox" id="emailsub" name="emailsub"  checked={this.state.emailUpdateCheck} onChange={this.handleEmailUpdate}/>
        <p> Signup for Updates</p>
        </div>
        <a onClick={this.handleCreateAccount} className="btn-bar" style={{ color: '#fff', width: '141px', margin: "0 auto", marginTop: '45px'}}>
          <span>Create Account</span>
        </a>
        <a onClick={this.loginAccount} className="btn-bar" style={{ color: '#fff', width: '222px', margin: "0 auto", marginTop: '45px'}}>
          <span>Login to existing account</span>
        </a>
      </div>
    )
  }

  render() {
    if (this.state.goRedirect) {
      return <Redirect to={this.state.goRedirect} />
    }

    if (this.state.goHome) {
      return <Redirect to='/' />
    }

    if (this.state.claimUsername) {
      return this.claimUsernameRender();
    }

    if (this.state.userProfile != null) {
      if (this.state.noLink) {
        //If there's nothing to do, then redirect to homepage
        return <Redirect to='/' />
      }

      if (this.state.alreadyLinked) {
        if (this.state.address.toLowerCase() != this.state.linkedAddress.toLowerCase()) {
          return this.noLinkRender();
        } else {
          //If there's nothing to do, then redirect to homepage
          return this.unLinkRender();
        }
      } else {
        return this.linkRender();
      }
    }

    if (this.state.isLogIn) {
      if (isMobile) {
        return this.mobileLoginRender();
      }
      return this.loginRender();
    } else {
      return this.createAccountRender();
    }
  }
}

export default SignIn;
