import React, { Component } from 'react';
import ReactDom from 'react-dom';
import { ToggleButtonGroup, ToggleButton, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import imgs from './ImgFactory.js';
import { Progress } from 'react-sweet-progress';
import { Line, Circle } from 'rc-progress';
import "react-sweet-progress/lib/style.css";
import ScrollAnimation from 'react-animate-on-scroll';
import {LineChart, ComposedChart, ScatterChart, Scatter, Dot, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, AreaChart, Area, ReferenceLine, Cell, BarChart, Bar, LabelList} from 'recharts';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import {Helmet} from "react-helmet";
import NumericInput from 'react-numeric-input';
import axios from 'axios';
import ReactTooltip from 'react-tooltip'
import Countdown from 'react-countdown-now';
import logo from './logo.svg';
import Referral from './Referral.js';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './App.css';
import moment from 'moment';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import GradientSVG from './GradientSVG.js';
import Popup from 'react-popup';
import posed from 'react-pose';
import styled from "styled-components";
import { tween, transform } from "popmotion";
import { CSSTransitionGroup } from 'react-transition-group' // ES6
import ReactPlayer from 'react-player'
import { timeline } from 'popmotion';
import swal from 'sweetalert';
import premiumGunImg from './images/premium-case.jpg';
import MetaTags from 'react-meta-tags';
import midgradeGunImg from './images/mid-grade-case.jpg';
import regularGunImg from './images/regular-case.jpg';
import ReactGA from 'react-ga';
import loadingGif from './images/loading.gif';
import { Redirect } from 'react-router'
import returnValue from './TokensInfo.js';
String.prototype.hashCode = function() {
    var hash = 0;
    if (this.length == 0) {
        return hash;
    }
    for (var i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

const { pipe, clamp } = transform;
var cars = vars.cars_table_data_minMax;
//let web3 = vars.web3;
let gradients = vars.gradients;
const Box = posed.div({});

async function getBzn1Balance(address) {
  if (address == "DNE")
    return 0;
  
  let totalBZN = await vars.contracts.oldBzn.methods.balanceOf(address).call();
  return totalBZN;
}

async function getBzn2Balance(address) {
  if (address == "DNE")
    return 0;

  let totalBZN = await vars.contracts.old2Bzn.methods.balanceOf(address).call();
  return totalBZN;
}

async function getBznBalance(address) {
  if (address == "DNE")
    return 0;

  let totalBZN = await vars.contracts.bzn.methods.balanceOf(address).call();
  return totalBZN;
}

let caseVars = [
  {
    name: 'PREMIUM',
    image: premiumGunImg,
    webm: 'https://warriders.com/wr_uploads/2019/09/Premium-case.webm',
    type: 'Main',
    base_price: 50,
    unique_skins: 117,
    contentLink: "https://warriders.com/premium-case",
    contentAlt: "Main weapons",
  },
  {
    name: 'MID-GRADE',
    image: midgradeGunImg,
    webm: 'https://warriders.com/wr_uploads/2019/09/mid-grade-case.webm',
    type: 'Main and alt',
    base_price: 20,
    unique_skins: 98,
    contentLink: "https://warriders.com/midgrade-case",
    contentAlt: "Main and Alt weapons",
  },
  {
    name: 'REGULAR',
    image: regularGunImg,
    webm: 'https://warriders.com/wr_uploads/2019/09/Regular-case.webm',
    type: 'Main and alt',
    base_price: 10,
    unique_skins: 77,
    contentLink: "https://warriders.com/regular-case",
    contentAlt: "Main and Alt weapons",
  }
]
let referralText = 'Earn ETH and BZN by becoming a Virtual Gun Dealer. Copy the URL below, share it with your friends on social media and earna 5% commission on every case opened using your referral link. The top 3 gun dealers will receive unique weapons!';


/* function updateContracts() {
  preorder_contract = vars.contracts.gunPreOrder;
  info_preorder_contract = vars.infoContracts.gunPreOrder;
  gun_token_contract = vars.infoContracts.gunToken;
  preorder_address = preorder_contract.address;
} */
//var localhost = 'http://127.0.0.1:1234';
var localhost = 'https://api.warriders.com/';
var URLs = vars.urls;
var maxes = vars.maxes;

function log(x) {
  //console.log('(Z) ' + x);
}

let app_local_server = 'https://app.warriders.com';
let homeDir = app_local_server + '/selectType/';

const increasingFXdata = [
  { ourXvalue: 1, Price: 2, extraData: 100 },
  { ourXvalue: 2, Price: 4, extraData: 120 },
  { ourXvalue: 3, Price: 6, extraData: 150 },
  { ourXvalue: 4, Price: 10, extraData: 180 },
  { ourXvalue: 5, Price: 15, extraData: 200 },
  { ourXvalue: 6, Price: 21, extraData: 499 },
  { ourXvalue: 7, Price: 28, extraData: 50 },
  { ourXvalue: 8, Price: 36, extraData: 100 },
  { ourXvalue: 9, Price: 45, extraData: 200 },
  { ourXvalue: 10, Price: 55, extraData: 222},
  { ourXvalue: 11, Price: 66, extraData: 210 }
];

const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}

function getColorForValue(value) {

  if (value <= 25) {  return COLORS[3] }
  else if (value <= 50) {return COLORS[2]}
  else if (value <= 75) { return COLORS[1]}
  else {  return COLORS[0]}
}
// Random component
const Completionist = () => <span style={{color: '#a922ed'}}><strong>SALE HAS ENDED</strong></span>;
const conditionalRenderer = (value) => {
  if (value == 'DNE') { return <span style={{color: 'black'}}><strong>{value}</strong></span>; }
   else { return <span style={{color: '#a922ed'}}><strong>{value}</strong></span>; }
};
// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span style={{color: '#a922ed'}}>{days} : {hours} : {minutes} : {seconds}</span>;
  }
};

async function getFromURL(url) {
  return new Promise(function(resolve, reject) {
    return fetch(url)
     .then((response) => response.json())
     .then((responseJson) => {
       return resolve(responseJson);
     })
     .catch((error) => {
       reject(error);
     });
  })
}


function roundToThree(num) {
    return +(Math.round(num + "e+3")  + "e-3");
}
function roundToFour(num) {
    return +(Math.round(num + "e+4")  + "e-4");
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

function roundToEight(num) {
    return +(Math.round(num + "e+8")  + "e-8");
}

function getXvalues(CURRENT) {
  //console.log('getXVals input: ' + CURRENT)
  let curr = Number(CURRENT);
    var xValues = [];
    for (var i = curr; i < curr + 1000; i+=1) {
      //console.log('getXvals appending ' + i)
      xValues.push(i);
    }
    return xValues;
}



class ImgComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
      img: props.img
    }
  }
  render() {
    const MyComponent = ({ hostRef }) => <div ref={hostRef} ><img style={{width: '50%'}} src={this.state.img}></img></div>

    const View = posed.div({
      idle: { scale: 1, opacity: 1,
        transition: { duration: 30000 }
       },
      hovered: { scale: 1.5, opacity: 0.3,
        transition: { duration: 30000 }
       }
    });

    const StyledView = styled(View)`
      width: 200px;`;

    return (

        <StyledView
          pose={this.state.hovering ? "hovered" : "idle"}
          onMouseEnter={() => this.setState({ hovering: true })}
          onMouseLeave={() => this.setState({ hovering: false })}
        >
          <h1>hi</h1>
          <MyComponent />
      </StyledView>
    );
  }
}

/*

Domain = [ 0 , max_x_value ]
20 points + nowPoint

for loop:
x value = max_x / 20 x index
y value = price => base_price ^ x_value

append ( currentQuantity, currentPrice)

*/

const DraggableCircle = posed.circle({
  draggable: 'x',
  dragBounds: { left: 0, right: 100 }
})

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function riseData(type, base_price, current_price, current_quantity, totalSupply) {
  let dots = 100;
  let PRICE_INCREASE = getMultipleFor(type);
  var data = [];
  let temp = 0;
  let currentprice = base_price;

  for (var i = 0; i < dots; i++) {
    let x = totalSupply / dots * i;
    //log('base: ' + base_price + ' | pwr: ' + x);
    let y = base_price * Math.pow(PRICE_INCREASE, x);
    //log('riseData pow result: ' + y)
    let obj = {quantity: Math.round(x), Price: round(y, 2), z: 80};
    //log('riseData appending: ' + toStr(obj));
    data.push(obj);
  }
  let current_obj = {quantity: current_quantity, Price: round(current_price, 2), z: 90};
  data.push(current_obj);
  return data;
}


// END USELESS

// Travel speed = (Engine power - Armor)/1.6
function calculateSpeed(engineSize, armor) {
return ((engineSize - armor)/1.6);
}
// Acceleration Ratio = Armor / Engine Power
// Acceleration = (Travel speed/10) / (Acceleration Ratio + 1)
function calculateAcceleration(engineSize, armor) {
var ratio = armor / engineSize;
var speed = calculateSpeed(engineSize, armor);
var acc = (speed/10) / (ratio + 1);
return acc;
}


var hashesConfirmed = [];

var ProgressComponent = (cmin, cmax, max, title, unit, addPadding = false) =>
(
  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row style={{marginTop: '-2px', marginBotton: '-2px'}}>
      <Col xs={12} sm={4}></Col>
      <Col xs={12} sm={8} style={(addPadding && !isMobile ? {paddingLeft: '90px'} : {})}>
        <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span> {unit}</h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={3}>
        <h4 style={{marginTop: '0%'}}>{title}</h4>
      </Col>
      <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={2} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
      </Col>
      <Col xs={12} sm={1}></Col>
    </Row>
  </ScrollAnimation>
  </div>
)

function randomTokenId() {
  let POSSIBLES = '0123456789';
  let ID_LENGTH = 8;
  var id = '';
  for (var i = 0; i < ID_LENGTH; i++) {
    id += POSSIBLES.charAt(Math.floor(Math.random() * POSSIBLES.length));
  }
  log('-randTKNid() - : id=' + id)
  log('-randTKNid() - : Number(id)=' + Number(id))
  return Number(id);
}

function getMinMax(type) {
  if (type == 0) {
    return [
      {
        min: roundToTwo(60),
        max: roundToTwo(600)
      },
      {
        min: roundToTwo(350),
        max: roundToTwo(985)
      },
      {
        min: roundToTwo(276),
        max: roundToTwo(776)
      },
      {
        min: roundToTwo(1102),
        max: roundToTwo(3089)
      },
      {
        min: roundToTwo(1),
        max: roundToTwo(10)
      },
      {
        min: roundToTwo(1),
        max: roundToTwo(10)
      },
      {
        min: roundToTwo(1),
        max: roundToTwo(10)
      }
    ]
  } else if (type == 1) {
    return [{
      min: roundToTwo(50),
      max: roundToTwo(570)
    },
    {
      min: roundToTwo(300),
      max: roundToTwo(930)
    },
    {
      min: roundToTwo(236),
      max: roundToTwo(732)
    },
    {
      min: roundToTwo(945),
      max: roundToTwo(2918)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(9)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(8)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(7)
    }]
  } else {
    return [{
      min: roundToTwo(40),
      max: roundToTwo(550)
    },
    {
      min: roundToTwo(230),
      max: roundToTwo(900)
    },
    {
      min: roundToTwo(181),
      max: roundToTwo(709)
    },
    {
      min: roundToTwo(724),
      max: roundToTwo(2824)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(8)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(7)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(5)
    }]
  }
}

function getFinalIndex(_type, _index) {
    let type = Number(_type);
    let index = Number(_index);
    //console.log('Error1 on getFinalIndex input!! Recieved type ' + type + ' and index ' + index)
  let premCars = 5;
  let mgCars = 3;
  let regCars = 3;
  if (type == 0 ) {
    return index+1;
  } else if (type == 1) {
    return premCars + index+1;
  } else if (type == 2) {
    return premCars + mgCars + index+1;
  } else {
    console.log('Error1 on getFinalIndex input!! Recieved type ' + type + ' and index ' + index)
  }
}

class Popupp extends React.Component {
    render() {
      return (
        <Popup trigger={<button> Trigger</button>} position="right center">
          <div>Popup content here !!</div>
        </Popup>
      );
    }
};


class CustomizedDot extends React.Component {
    render() {
        const { cx, cy } = this.props;

        return (
            <circle cx={cx - 10} cy={cy - 10} r={25} stroke="red" strokeWidth={9} fill="red" />
        );
    }
  };

  class CDot extends React.Component {
      render() {
          const { cx, cy } = this.props;

          return (
              <circle cx={cx - 10} cy={cy - 10} r={25} stroke="black" strokeWidth={3} fill="red" />
          );
      }
    };

class CustomChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      currentQuantity: props.currentQuantity
    };
    var data = JSON.stringify(props.data);
  }
  render() {
    if (this.props.data.length > 0) {
      return (
          <ResponsiveContainer width="95%" height={300}>
            <ScatterChart margin={{top: 30, right: 0, left: 0, bottom: 5}}>
              <XAxis type="number" dataKey={'quantity'} name='Quantity' unit=' Cases' />
              <YAxis width={100} type="number" dataKey={'Price'} name='Price' unit=' USD' domain={[this.props.data[0].Price, this.props.data[this.props.data.length-1].Price]}/>
              <ZAxis dataKey={'z'} range={[10, 100]} name='Reference Size' unit=' units'/>
             <Tooltip/>
             <Legend />
             <Scatter name='Price Increase' data={this.props.data} >
               {
                 this.props.data.map((entry, index) => {
                   if (entry.quantity === this.props.data[this.props.data.length-1].quantity) {
                     return <Cell key={`cell-${index}-${new Date().getTime()}`} fill='#3399FF' />
                   } else {
                     return <Cell key={`cell-${index}-${new Date().getTime()}`} fill={COLORS[1]} />
                   }

                 })
               }
             </Scatter>

            </ScatterChart>
          </ResponsiveContainer>
        );
    } else {
      return <div></div>
    }

  };
}

class VertChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
    var data = JSON.stringify(props.data);
    //console.log('the Vert data is: ' + data)
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart layout="vertical" data={this.state.data}
            margin={{top: 20, right: 20, bottom: 20, left: 20}}>
          <XAxis type="number" hide={true}/>
          <YAxis dataKey="name" type="category"/>
          <Bar dataKey="value" barSize={20} fill="#8884d8">
            {
              this.state.data.map((entry, index) => {
                const color = (index < COLORS.length) ? COLORS[index] : COLORS[COLORS.length-1];
                return <Cell fill={color} />;
              })
            }
            <LabelList dataKey="value" position="right" />
         </Bar>
       </ComposedChart>
     </ResponsiveContainer>
    );
  }
}

function toStr(x) {
  return (JSON.stringify(x))
}

class CircularProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // Size of the enclosing square
    const sqSize = '80%';
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * this.props.percentage / 100;

    return (
      <svg
          width={this.props.sqSize}
          height={this.props.sqSize}
          viewBox={viewBox}>
          <circle
            className="circle-background"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`} />
          <circle
            className="circle-progress"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset
            }} />
          <text
            className="circle-text"
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle">
            {`${this.props.displayText}`}
          </text>
      </svg>
    );
  }
}

// function getFinalPrice(currentPrice, amountCars, multiplier) {
//   var total = 0;
//   var lastPrice = Number(currentPrice);
//   for (var i = 0; i < amountCars; i++) {
//     total += lastPrice
//     lastPrice = lastPrice * Math.pow(multiplier, (i+1));
//     total += 300000; lastPrice += 300000;
//   }
//   let BUFFER = 100000;
//   total += BUFFER;
//   return total;
// }

function getFinalPrice(base, numCars, mult, gasPrice, bufferAmount) {
    //log('Get final price input: ' + base + ' ' + mult + ' ' + numCars)
    let curPrice = Number(base);
    let total = 0;
    for (var i = 0; i < numCars; i++) {
      let nextPrice = curPrice * mult;

      total += curPrice;

      curPrice = nextPrice;
    }

    let txfee = numCars * gasPrice * 400000;
    let buffer = numCars * 100000000000000;

    /*var total = 0;
    for (var i = 0; i < numCars; i++) {
        //log(Math.pow(mult, i))
        let add = (base * (Math.pow(mult, i)) + (4500000 * gasPrice) + 100000000000000)
        //log('adding ' + add +  ' to final price')
        total +=  add;
    }
    return total + 4500000 + 100000000000000;*/

    return total + txfee + buffer;
};

function getTrueMultipleFor(type) {
  if (type == 0) {
    return 0.0350;
  } else if (type == 1) {
    return 0.0250;
  } else if (type == 2) {
    return 0.0150;
  }
}

function getMultipleFor(type) {
  if (type == 0) {
    return 1.00035;
  } else if (type == 1) {
    return 1.00025;
  } else if (type == 2) {
    return 1.00015;
  }
}

function getDescription(type) {
  if (type == 0) {
    return 'The premium weapon case contains only main top-of-the-line weapons with the highest chance of receiving Exclusive skins. This case contains: Common skins - 10%; Rare skins - 20%; Epic skins - 35%; Legendary skins - 25%; and Exclusive skins - 10%.'
  } else if (type == 1) {
    return 'The mid-grade weapon case contains main and alt weapons with a high chance of receiving Legendary skins. This case contains: No skin - 15%; Common skins - 25%; Rare skins - 30%; Epic skins - 15%; Legendary skins - 13%; and Exclusive skins - 2%.'
  } else {
    return 'The regular weapon case contains main and alt weapons with a high chance of receiving Rare skins. This case contains: No skin - 30%; Common skins 40%; Rare skins - 25%; Epic skins - 4%; and Legendary skins - 2%.'
  }
}


let increasingDataChanged = true;
let oldIncreasingData = [];
class ViewGun extends Component {

  constructor(props) {
    super(props);
      //    let id = props.carId; // Passed to specify for HTTP Req so we can differentiate between different car types
      let typeIndex = props.typeIndex;

      console.log(URLs.selectGun(0));

      let limit;
      if (typeIndex == 1) {
        limit = 100;
      } else if (typeIndex == 2) {
        limit = 200;
      } else {
        limit = 20;
      }

      this.state = {
        currentId: 'DNE',
        min: 0,

        limit: limit,
        limitDate: 'Dec 17',
        caseName: caseVars[typeIndex].name,
        image: caseVars[typeIndex].image,
        adCaseTypes: [0, 1, 2],
        skinCount: caseVars[typeIndex].unique_skins,
        currency: 'ETH',
        requiredEtherPercent: 0.0,
        requiredGasFee: 0,
        loaded: false,

        bznBalance: 0,
        lastTotalSupply: 0,
        hasOldBZN: false,
        migrateRedirect: false,

        isOpen: true,
        quantityBuying: 1,
        carInfo: {},
        increasing: [],
        speedMax: '',
        accMax: '',
        armorMax: '',
        bznTankMax: '',
        mg: '',
        ag: '',
        speedMin: '',
        accMin: '',
        armorMin: '',
        img: '',
        bznTankMin: '',
        nos: '',
        description: getDescription(typeIndex),
        price: 0,
        ethPrice: 0,
        bznPrice: 0,
        totalSupply: 0,
        amtLeft: true,

        min: 1,
        max: 3000,

        lastSold: 'DNE',
        videoSupported: true,
        shouldUpdate: true,
        typeIndex: typeIndex,
        adCarsNames: [cars.premium[0].name, cars.premium[1].name, cars.premium[2].name],
        adCarsImgs: [cars.premium[0].img, cars.premium[1].img, cars.premium[2].img],
        adCarIndex: [0, 1, 2],
      };
      this.handleOnChange = this.handleOnChange.bind(this);
      this.getData = this.getData.bind(this);
      this.smallGetDataForQuantity = this.smallGetDataForQuantity.bind(this);
      this.smallGetData = this.smallGetData.bind(this);
      this.currencySelected = this.currencySelected.bind(this);
  }

getReferrer() {
/*   var self = this;
  //console.logconsole.log("XXX Request " + URLs.getReferrer + "/" + uid);
  axios.get(URLs.getReferrer + "/" + uid).then( function(response) {
    let referrer = response.data;
    self.setState({referrer: referrer});
    self.state.referrer = referrer;
    //console.log("XXX Got " + referrer);
  }) */
 let address = window.localStorage.getItem("refaddress");
 this.setState({referrer: address});

}

handleOnChange = (valueAsNumber) => {
  this.setState({
    quantityBuying: valueAsNumber
  })
  try {
    if (!valueAsNumber)
      return;
    this.smallGetDataForQuantity(valueAsNumber);
  } catch (e) { }
}

componentWillUnmount() {
  this.setState({
    shouldUpdate: false
  });
  this.state.shouldUpdate = false;
}

componentDidMount() {
  window.scrollTo(0, 0);
  swal({
    title: "Loading...",
    text: "Please wait while this case is loaded",
    icon: loadingGif,
    className: 'wr-loader',
    button: false,
    closeOnClickOutside: false
  });
  setTimeout(this.getData, 500);
  //updateContracts();
}

linkAccount(self) {
  vars.getAccounts((error, accounts) => {
    if (accounts != null) {
      if (accounts.length == 0) {
        //console.log('Error: couldn\'t find accounts');
        self.setState ({
          currentId: "NA"
        });
      } else {
        //console.log("We found accounts!");
        self.setState ({
          currentId: accounts[0]
        });
/*         self.getReferrer(accounts[0]); */
      }
    } else {
      //console.log("Got error looking up accounts " + error);
    }
    setTimeout(function() {
      self.linkAccount(self);
    }, 500);
  })
}

componentWillMount() {
  this.getReferrer()
  this.linkAccount(this);
}

popup = async (askBZN = true) => {
     var self = this;
     let addrToUse = null;
     if (!vars.isWeb3Known()) {
        await vars.askForWeb3();
        let allAccounts = await vars.getAccounts();
        if (allAccounts.length > 0) {
          addrToUse = allAccounts[0];
        }
    }
    if (addrToUse == null) {
      if (this.state.currentId == "NA") {
        swal("External Wallet Locked", "No external wallet could be used to complete this transaction. Please unlock your external wallet and try again.\nIf you just unlocked your external wallet, try refreshing the page.", "error");
        this.linkAccount(this);
        return;
      }
      if (this.state.currentId == "DNE") {
        if (vars.isWeb3Known()) {
          swal("External Wallet Locked", "No external wallet could be used to complete this transaction. Please unlock your external wallet and try again.\nIf you just unlocked your external wallet, try refreshing the page.", "error");
          this.linkAccount(this);
        } else {
          var elem = document.createElement("div");
          elem.innerHTML = "<p style=\"color: black\">In order to purchase gun cases, you must use a Web3 compatible browser!<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3</a></p>";
          swal({
            title: "Incompatible Browser",
            content: elem,
            icon: "error"
          })
        }
        return;
      }

      addrToUse = this.state.currentId;
    }

     if (!this.state.amtLeft) {
       swal("Sold Out!", "This gun case has reached the monthly limit. Please check back next month.", "warning");
       return
     }

     //console.log("FFFFE " + this.state.currency);

     let numberOfGuns = this.state.quantityBuying;
     let isBZN = this.state.currency == 'BZN';

     let extraMsg = ""
     if (isBZN) {
       if (Number(self.state.bznPrice) > self.state.bznBalance) {
         swal("Insufficient BZN Funds", "It appears you don't have enough BZN for this purchase", "error");
         return;
       }

       if (self.state.hasOldBZN && askBZN) {
        swal({
          title: 'You have some old BZN',
          text: "It appears you haven't migrated all of your BZN to BZN 2.1, would you like to migrate now?",
          icon: 'warning',
          buttons: true
        }).then((willMigrate) => {
          if (willMigrate) {
            self.setState({
              migrateRedirect: true
            })
          } else {
            self.popup(false);
          }
        });
        return;
       }

       extraMsg = "\n\n\n" + round(vars.web3.utils.fromWei(self.state.bznPrice, 'ether'), 4).toFixed(4) + " BZN will be used to open " + numberOfGuns + " cases. ETH minting fee will be automatically calculated."
     }

     swal({
       title: 'Open ' + this.state.quantityBuying + ((this.state.quantityBuying > 1) ? ' cases' : ' case'),
       text: 'Please confirm that you would like to proceed with the purchase with the ETH wallet ' + addrToUse + extraMsg,
       icon: 'info',
       buttons: true
     }).then((willBuy) => {
       if (willBuy) {
         self.buyGun(addrToUse);
       }
     });
}

buyGun = async (overrideAddress = "") => {
    log('Present gun popup...');
    var self = this;
    let numberOfGuns = this.state.quantityBuying;
    let userAccount = overrideAddress != "" ? overrideAddress : this.state.currentId;
    let type = this.state.typeIndex;
    let isBZN = this.state.currency == 'BZN';
    //let bufferAmount = (isBZN ? 0 : 100000000000000);
    let price = (isBZN ? vars.web3.utils.toBN(self.state.bznPrice) : vars.web3.utils.toBN(self.state.ethPrice))

    let etherRequired = (isBZN ? vars.web3.utils.toBN(Number(this.state.ethPrice) * this.state.requiredEtherPercent) : vars.web3.utils.toBN(price));
    //let etherRequired = (isBZN ? (Number(this.state.ethPrice) * this.state.requiredEtherPercent) + (Number(this.state.gasFeeRequired) * numberOfGuns / 10) : price + (this.state.requiredGasFee * numberOfGuns / 10));

    if (!self.refs.buffer.checked) {
      //console.log("FFDDA: " + etherRequired + " " + price);
      etherRequired = etherRequired.add(etherRequired.divRound(vars.web3.utils.toBN(200)));
      price = price.add(price.divRound(vars.web3.utils.toBN(20)));
      //console.log("FFDDA: " + etherRequired + " " + price);
    } else {
      etherRequired = etherRequired.add(etherRequired.divRound(vars.web3.utils.toBN(20)));
      price = price.add(price.divRound(vars.web3.utils.toBN(10)));
    }

    //log('Gas should be ' + (gasPrice * price))
    let gas = 500000;
    log("# CARS: " + numberOfGuns);
    log("UID: " + userAccount);
    log('Price: ' + price);
    log('T INDEX: ' + (type + 1));

    var refId = (self.state.referrer != null && self.state.referrer != '' && self.state.referrer != 'DNE' && self.state.referrer != userAccount) ? self.state.referrer : null;

    //FIX Default a null referral to the 0x0 address
    if (refId == null) {
      refId = "0x0000000000000000000000000000000000000000";
    }

    console.log("DA DATA: " + vars.web3.eth.abi.encodeParameters(['string'], ['edkek']));

    try {
      if (isBZN) {
        let abiEncoded = vars.web3.eth.abi.encodeParameters(['address', 'uint8', 'uint16'], [refId, type + 1, numberOfGuns]);
        swal({
          title: "Processing...",
          text: "Please wait while your transaction is confirmed",
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });
        let bznResult = await vars.econtracts.bzn.approveAndCall(vars.contracts.gunPreOrder._address, price.toString(), abiEncoded, {value: etherRequired.toString()});
        let bznr = await bznResult.wait(1);
        let tx = bznr.transactionHash;
        if (bznr.status) {
          vars.infoContracts.gunToken.methods.getBatchCount(userAccount).call().then(function(batchCount) {
            var elem = document.createElement("div");
            elem.innerHTML = "<p style=\"color: black\">Your purchase was successfully processed!<br><br><a href=\"/garage/guns/" + (batchCount - 1) + "\">View Opened Case</a><br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
            swal({
              title: "Order Successful",
              content: elem,
              icon: "success"
            })
            //swal("Order Successful", "Your purchase was successfully processed!\nTransaction Hash: " + tx, "success");
            self.submitPurchase(type + 1, numberOfGuns, price, tx);
            ReactGA.event({
              category: 'Purchase',
              action: 'Bought a gun',
              value: numberOfGuns
            });
          });
        } else {
          swal("Order Failed", "Your purchase could not be processed! We're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nYour ether has been refunded.\nTransaction Hash: " + tx, "error")
          ReactGA.event({
            category: 'Failed Purchase',
            action: 'Failed to purchase a gun case',
            value: numberOfGuns
          });
        }
      } else {
        swal({
          title: "Processing...",
          text: "Please wait while your transaction is confirmed",
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });
        const buyResult = await vars.econtracts.gunPreOrder.buyWithEther(refId, type + 1, userAccount, numberOfGuns, {value: etherRequired.toString()});
        let buyr = await buyResult.wait(1);
        let tx = buyr.transactionHash;
        if (buyr.status) {
          vars.infoContracts.gunToken.methods.getBatchCount(userAccount).call().then(function(batchCount) {
            var elem = document.createElement("div");
            elem.innerHTML = "<p style=\"color: black\">Your purchase was successfully processed!<br><br><a href=\"/garage/guns/" + (batchCount - 1) + "\">View Opened Case</a><br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
            swal({
              title: "Order Successful",
              content: elem,
              icon: "success"
            })
            //swal("Order Successful", "Your purchase was successfully processed!\nTransaction Hash: " + tx, "success");
            self.submitPurchase(type + 1, numberOfGuns, price, tx);
            ReactGA.event({
              category: 'Purchase',
              action: 'Bought a gun',
              value: numberOfGuns
            });
          });
        } else {
          swal("Order Failed", "Your purchase could not be processed! We're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nYour ether has been refunded.\nTransaction Hash: " + tx, "error")
          ReactGA.event({
            category: 'Failed Purchase',
            action: 'Failed to purchase a gun case',
            value: numberOfGuns
          });
        }
      }
    } catch (e) {
      if (e.message.includes('insufficient funds')) {
        swal('Could not submit Transaction', "You don't have enough ether to complete the order. Please fund your account before trying again", "error")
      } else {
        swal.close();
      }
    }
}

submitPurchase(type, numberOfGuns, weiPrice, txHash) {
  //console.log("Submitting purchase " + " " + type + " " + numberOfGuns + " " + weiPrice + " " + txHash);

  try {
    function intToName(type) {
      switch (type) {
          case 0:
              return "Premium";
          case 1:
              return "Midgrade";
          case 2:
              return "Regular";
          default:
              return "";
        }
    }

    let ether = vars.web3.utils.fromWei(weiPrice.toString(), 'ether');

    ReactGA.plugin.execute(
      'ecommerce',
      'addItem',
      {
        id: txHash,
        name: intToName(type),
        sku: '0001' + type.toString(),
        price: ether,
        quantity: numberOfGuns.toString(),
      }
    );
    ReactGA.plugin.execute(
      'ecommerce',
      'addTransaction',
      {
        id: txHash,
        revenue: ether,
      }
    );
    ReactGA.plugin.execute('ecommerce', 'send');
    ReactGA.plugin.execute('ecommerce', 'clear');

    //console.log("FFF Sent the following: " + {
    //  id: txHash,
    //  name: intToName(type),
    //  sku: '0001' + type.toString(),
    //  price: ether,
    //  quantity: numberOfGuns.toString(),
   // });

    //console.log("FFF Also sent " + {
     // id: txHash,
     // revenue: ether,
    //})
  } catch (e) {
    console.log("FFF GOT ERROR " + e);
  }
}

smallGetDataForQuantity = (q) => {
  let type = this.state.typeIndex; // Start at 1 in Eth

  //console.log('carRes: ' + JSON.stringify(carRes))
  var self = this;

  let usdPrice = 0;
  let bznPrice = 0;
  let ethPrice = 0;
  let bznBal = 0;
  if (q == 0 || isNaN(q)) {
    q = 1;
  }

  vars.infoContracts.gunPreOrder.methods.priceFor(type + 1, q).call().then(function(pdata) {
    let p = pdata[1];
    if (p == 0) {
      //TODO Shop isn't open yet
      return;
    }
    usdPrice = p;
    //console.log("FFFDD " + usdPrice);
    return vars.infoContracts.gunPreOrder.methods.convert(usdPrice, true).call();
  }).then(function(s) {
    bznPrice = s;
    //console.log("FFFDD " + bznPrice);
    return vars.infoContracts.gunPreOrder.methods.convert(usdPrice, false).call();
  }).then(function(t) {
    ethPrice = t;

    return getBznBalance(self.state.currentId);
  }).then(function(bzn) {
    bznBal = bzn

    self.setState ({
      price: usdPrice,
      bznPrice: bznPrice,
      ethPrice: ethPrice,
      bznBalance: bznBal,
    });

  }).catch(function(err) {
    console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
    //ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
  })
}

smallGetData = () => {
  let type = this.state.typeIndex; // Start at 1 in Eth

  //console.log('carRes: ' + JSON.stringify(carRes))
  var self = this;

  let usdPrice = 0;
  let bznPrice = 0;
  let ethPrice = 0;
  let bznBal = 0;
  let q = Number(self.state.quantityBuying);
  if (q == 0 || isNaN(q)) {
    q = 1;
  }

  vars.infoContracts.gunPreOrder.methods.priceFor(type + 1, q).call().then(function(pdata) {
    let p = pdata[1];
    if (p == 0) {
      //TODO Shop isn't open yet
      return;
    }
    usdPrice = p;
    //console.log("FFFDD " + usdPrice);
    return vars.infoContracts.gunPreOrder.methods.convert(usdPrice, true).call();
  }).then(function(s) {
    bznPrice = s;
    //console.log("FFFDD " + bznPrice);
    return vars.infoContracts.gunPreOrder.methods.convert(usdPrice, false).call();
  }).then(function(t) {
    ethPrice = t;

    return getBznBalance(self.state.currentId);
  }).then(function(bzn) {
    bznBal = bzn

    self.setState ({
      price: usdPrice,
      bznPrice: bznPrice,
      ethPrice: ethPrice,
      bznBalance: bznBal,
    });

    setTimeout(self.smallGetData, 5000);
  }).catch(function(err) {
    console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
    //ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
  })
}


getData = () => {
    let type = this.state.typeIndex; // Start at 1 in Eth

    let minMax = getMinMax(type);

    //console.log('carRes: ' + JSON.stringify(carRes))
    var self = this;
    // Real

    let rateOfFireMin = roundToTwo(minMax[0].min);
    let rateOfFireMax = roundToTwo(minMax[0].max);

    let velocityMin = roundToTwo(minMax[1].min);
    let velocityMax = roundToTwo(minMax[1].max);

    let effectiveRangeMin = roundToTwo(minMax[2].min);
    let effectiveRangeMax = roundToTwo(minMax[2].max);

    let maxRangeMin = roundToTwo(minMax[3].min);
    let maxRangeMax = roundToTwo(minMax[3].max);

    let damageMin = roundToTwo(minMax[4].min);
    let damageMax = roundToTwo(minMax[4].max);

    let stabilityMin = roundToTwo(minMax[5].min);
    let stabilityMax = roundToTwo(minMax[5].max);

    let rarityMin = roundToTwo(minMax[6].min);
    let rarityMax = roundToTwo(minMax[6].max);
    //log('new description is: ' + description)
    var svg = ( <GradientSVG
           idCSS='sxxx'
           rotation={90}
         />);

    var posedProps = {
      draggable: 'x',
      open: { x: '0%' },
      closed: { x: '-100%' }
    }


    function gunInfoProgressComponents(){
      return (
              <div>
                <div>{ProgressComponent(rateOfFireMin,rateOfFireMax,maxes.rateOfFire, 'Rate of Fire', 'rpm')}</div>
                <div>{ProgressComponent(velocityMin,velocityMax,maxes.velocity, 'Velocity', 'ft/s')}</div>
                <div>{ProgressComponent(effectiveRangeMin,effectiveRangeMax,maxes.effectiveRange, 'Effective Range', 'ft')}</div>
                <div>{ProgressComponent(maxRangeMin,maxRangeMax,maxes.maxRange, 'Max Range', 'ft', true)}</div>
                  <Row >
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                            <h5 className='centered'>Max Damage LVL</h5>
                            <br></br>
                            <CircularProgressbar percentage={(damageMax)/10*100} text={`${damageMax}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                        <h5 className='centered'>Max Stability LVL</h5>
                        <br></br>
                            <CircularProgressbar percentage={(stabilityMax)/10*100} text={`${stabilityMax}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                        <h5 className='centered'>Max Rarity Index</h5>
                        <br></br>
                            <CircularProgressbar percentage={(rarityMax)/10*100} text={`${rarityMax}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>

                  </Row>
                  <Row><div style={{width: '100%', height: '30px'}}></div></Row>
              </div>
            );

    }
    //console.log('IMG to be:' + carRes.img);
    let img = premiumGunImg;
    if (type == 1) {
      img = midgradeGunImg;
    } else if (type == 2) {
      img = regularGunImg;
    }
    this.setState({img: img})

    let onVideoFailed = function() {
      self.setState({videoSupported: false});
    };

    let vurl = caseVars[type].webm;
    let vid = <ReactPlayer  width='100%' height='100%' url={vurl} playing={true} loop={true} onError={onVideoFailed}/>
    if (vurl != "" && this.state.videoSupported && isBrowser) {
      //  alert('baggaeg claim')
    //  ReactDom.render(<div>{vid}</div>, document.getElementById('cImg'));
      this.setState({imgComp: vid});

    } else {
    //  ReactDom.render(<img src={img}></img>, document.getElementById('cImg'));
      this.setState({imgComp: <img src={img}></img>});
    }

    var target = document.getElementById('progressi');

    if (target == null)
      return;

    ReactDom.render(<div>{gunInfoProgressComponents()}</div>, target);

    //ReactDom.render(<h4 style={{lineHeight: '30px'}}>{description}</h4>, document.getElementById('car_description'));
    let usdPrice = 0;
    let bznPrice = 0;
    let ethPrice = 0;
    let totalSupply = 0;
    let etherPercent = 0;
    let etherPercentBase = 0;
    let hold = 7000;
    let bznBal = 0;
    let oldBZN = false;
    let q = Number(self.state.quantityBuying);
    if (q == 0 || isNaN(q)) {
      q = 1;
    }

    ///onsole.log("Using indexxxxx " + index + "(type: " + type + ", car: " + car + ")");
    vars.infoContracts.gunPreOrder.methods.priceFor(type + 1, q).call().then(function(pdata) {
      let p = pdata[1];
      if (p == 0) {
        //TODO Shop isn't open yet
        return;
      }
      usdPrice = p;
      //console.log("FFFDD " + usdPrice);
      return vars.infoContracts.gunPreOrder.methods.convert(usdPrice, true).call();
    }).then(function(s) {
      bznPrice = s;
      //console.log("FFFDD " + bznPrice);
      return vars.infoContracts.gunPreOrder.methods.convert(usdPrice, false).call();
    }).then(function(t) {
      ethPrice = t;
      //console.log("FFFDD " + ethPrice);
      return vars.infoContracts.gunToken.methods.gunCountForCategory(type + 1).call();
    }).then(function(supply) {
      totalSupply = Number(supply);

      return getBzn1Balance(self.state.currentId);
    }).then(function(bzn) {
      oldBZN = bzn > 0;

      return getBzn2Balance(self.state.currentId);
    }).then(function(bzn) {
      oldBZN = oldBZN || bzn > 0;

      return getBznBalance(self.state.currentId);
    }).then(function(bzn) {
      bznBal = bzn

      return vars.infoContracts.gunPreOrder.methods.requiredEtherPercent(type + 1).call();
    }).then(function(ep) {
      etherPercent = ep;

      return vars.infoContracts.gunPreOrder.methods.requiredEtherPercentBase(type + 1).call();
    }).then(function (epb) {
      etherPercentBase = epb;

      return vars.infoContracts.gunFactory.methods.reachedLimit(type + 1).call();
    }).then(function(hasReachedLimit) {
      let wasNotLoaded = !self.state.loaded;
      
      self.setState ({
        price: usdPrice,
        bznPrice: bznPrice,
        ethPrice: ethPrice,
        totalSupply: totalSupply,
        hold: hold,
        amtLeft: !hasReachedLimit,
        bznBalance: bznBal,
        hasOldBZN: oldBZN,
        requiredEtherPercent: etherPercent / etherPercentBase,
        gasFeeRequired: 0,
        loaded: true
      });

      let base_price = caseVars[type].base_price;
      //log('Calling Rise Data with params: ' + '\nBase Price: ' + base_price + '\nCurrent Price: ' + price + '\nCurrent Q: ' + hold + '\nTotal supply: ' + totalSupply)
      let increasing = riseData(type, base_price, vars.web3.utils.fromWei(usdPrice, 'ether'), totalSupply, hold);

      //let increasing = getIncrFXdata(price, (totalSupply-hold));
      if (increasingDataChanged) {
        self.setState({
          increasing: increasing
        });
        increasingDataChanged = false;
        oldIncreasingData = increasing;
      }

      if (totalSupply != self.state.lastTotalSupply) {
        increasingDataChanged = true;
        self.setState({
          lastTotalSupply: totalSupply
        })
      }
      
      if (wasNotLoaded)
        swal.close();

    }.bind(self)).catch(function(err) {
      console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
      //ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
    });

    if (this.state.shouldUpdate) {
      setTimeout(this.smallGetData, 5000);
    }
  }

outFunc() {
  var tooltip = document.getElementById("myTooltip");
  tooltip.innerHTML = "Copy to clipboard";
}

anim() {
  document.getElementById('anim').className ='classname';
}

currencySelected(val) {
  this.setState({
    currency: val.toUpperCase()
  });
}

render() {
  if (this.state.migrateRedirect) {
    return <Redirect to='/migrate' />
  }

  //log('rerender triggered, description is ' + this.state.description)
  const MyComponent = ({ hostRef }) => <div ref={hostRef} ><img style={{width: '50%'}} src={imgs.car}></img></div>

  function customizationText(typeIndex, carType) {
    return "Customization is live";
  }

  function getSpecialTrait(carType) {
    carType = carType + 1;
    switch (carType) {
      case 5:
        return ["Bonus Trait | NOS".toUpperCase(), "Instantly gains speed | NOS equip. installed"];
      case 4:
        return ["Bonus Trait | NOS".toUpperCase(), "No critical damage | NOS equip. installed"];
      case 3:
        return ["Bonus Trait | NOS".toUpperCase(), "Perfect maneuverability | NOS equip. installed"];
      default:
        return ["Bonus Trait | NOS", "NOS equip. installed"];
    }
  }


  const SpecialTrait = ({ title, description }) => <div><h4 style={{color: '#a922ed'}}>{title}</h4><p>{description}</p></div>

  let config = {
    visible: {
      opacity: 1,
      x: 100,
      transition: { duration: 300000 }
    }, invisible: {
        opacity: 0.5,
        x: -100,
        transition: { duration: 300000 }
      }
  };

  const PosedComponent = posed(MyComponent)(config)

  const Box = posed.circle(config);

  var isOpen = true
  let type = this.state.typeIndex;
  let qa = <p>Quantity <br></br><a href="https://warriders.com/bulk-buy/">(learn more)</a>:</p>
  let qq = <p>Quantity</p>
  let btnText = "OPEN NOW"
  if (!this.state.amtLeft) btnText = "LIMIT REACHED"
      return (
        <div className="ViewCar">
          <MetaTags>
            <title>About {this.state.caseName}</title>
            <meta property="og:title" content="Buy game items now!" />
            <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            
            <meta property="og:image" content={this.state.img}/>
            <meta property="og:url" content={"https://app.warriders.com/viewCar/" + this.state.typeIndex + '/' + this.state.carIndex}/>
            <meta name="twitter:card" content="summary_large_image"/>

            <meta property="og:site_name" content="War Riders"/>
            <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
          </MetaTags>
          <ReactTooltip id='global' aria-haspopup='true' role='example'>
            <p>Enabling buffer will add 5% more ETH/BZN in the case of a race condition</p>
            <p>Any unused ETH/BZN will be refunded immediately</p>
          </ReactTooltip>
          <ReactTooltip id='buffer-info' aria-haspopup='true' role='example'>
            <p>ETH minting fee is requried for BZN purchases. ETH minting fee covers different</p>
            <p>aspects of the Gun Factory operations and is different from the network fee.</p>
          </ReactTooltip>
          <ReactTooltip id='maintenance-info' aria-haspopup='true' role='example'>
            <p>Case Opening is currently closed for maintenances</p>
          </ReactTooltip>
          <Grid>
            <Row style={{height: 100}}></Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h3>ABOUT {this.state.caseName} WEAPON CASE</h3>
                <br></br>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} md={6}>
                <div id='cImg'>
                  {this.state.imgComp}
                </div>
                <h1> {this.state.quantityBuying > 1 ? "TOTAL PRICE" : "PRICE NOW"}: {conditionalRenderer((this.state.price != 'DNE') ? "$" + round(vars.web3.utils.fromWei(this.state.price.toString(), 'ether'), 2).toFixed(2) : 'DNE' )} USD </h1>
                <h1> {conditionalRenderer((this.state.price != 'DNE') ? round(vars.web3.utils.fromWei(this.state.bznPrice.toString(), 'ether'), 2).toFixed(2) : 'DNE' )} BZN | {conditionalRenderer((this.state.price != 'DNE') ? round(vars.web3.utils.fromWei(this.state.ethPrice.toString(), 'ether'), 4) : 'DNE' )} ETH</h1>
                <p> {getTrueMultipleFor(this.state.typeIndex)}% price increase with each case opened </p>
              </Col>
              <Col xs={12} sm={6} md={6}>

                <div style={{ 'margin-bottom': '15px'}}>Gun stats will generate randomly within these ranges:</div>
                  <Col xs={12} sm={12} md={12}>
                    <div id='progressi'>

                    </div>
                   <Row>
                     <Col sm={4} md={4}>
                       <p className='concealed'>.</p>
                       <div className='left-to-right'>
                         <a className="btn-one-that-doesnt-resize" onClick={() => this.popup()} >
                           <span className="hide-selection">{btnText}</span>
                         </a>
                       </div>
                     </Col>
                     <Col sm={5} md={5}>
                       <p className='concealed'>.</p>
                       <ToggleButtonGroup name='payment-type' style={{width: '100%'}} onChange={this.currencySelected}>
                          <ToggleButton style={(isMobile ? {marginLeft: '30px', marginBottom: '40px'} : {})} id="toggle-btn" type="radio" defaultChecked value="eth">ETH</ToggleButton>
                          <ToggleButton id="toggle-btn" type="radio" value="bzn"><p data-tip data-for='buffer-info'>BZN</p></ToggleButton>
                       </ToggleButtonGroup>
                     </Col>
                     <Col sm={3} md={3}>
                     {this.state.quantityBuying > 10 ? qa : qq}
                       <NumericInput id='reqFunds' className="form-control" min={this.state.min} max={this.state.max} value={this.state.quantityBuying} step={1} onChange={this.handleOnChange} style={{
                         input: {
                           backgroundColor: 'black',
                           color: '#a922ed',
                           fontSize: 16,
                           marginBottom: (isMobile ? '40px' : 'initial')
                         },
                         btn: {
                           color: 'white',
                           background: 'white'
                         },
                         'btn:hover': {
                           background: 'lightGrey'
                         }
                       }}/>
                     </Col>
                   </Row>
                   <Row>
                     <Col xs={12} sm={12} md={12}>
                       <h5 data-tip data-for='global'>Add buffer to transaction: <input type="checkbox" ref="buffer" name="buffer" value="addBuffer" style={{height: '13px', marginLeft: '10px', marginTop: '20px'}}></input></h5>
                     </Col>
                   </Row>
                 </Col>

              </Col>

            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <div id='CustomChart'>
                  <CustomChart currentQuantity={this.state.hold} data={this.state.increasing} className='CustomChart'/>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <div style={{marginTop: '10%', textAlign: 'left' }}>
                  <br></br><br></br>
                  <h3>This case contains: <span style={{color: '#a922ed'}}><strong><a href={caseVars[this.state.typeIndex].contentLink} style={{color: 'inherit'}}>VIEW</a></strong></span></h3>
                  <h3>Unique skins in this case: {conditionalRenderer(this.state.skinCount)}</h3>
                  <h3>Type of case: {conditionalRenderer(caseVars[this.state.typeIndex].contentAlt)}</h3>
                  <h3>Supply: {conditionalRenderer(this.state.limit + '/day')}</h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>ABOUT {this.state.caseName} WEAPON CASE</h3>
                <br></br><br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <h4 style={{color: '#a922ed'}}>ERC-721 STRUCTURE</h4>
                <p>Standard, tradable non-fungible tokens</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>PREMIUM BARRELS</h4>
                <p>Gold, Platinum, Carbon or Regular barrels</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>BULK OPENING</h4>
                <p>Open weapon cases in bulk with a single transaction</p>
                <br></br>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <h4 id='car_description' style={{lineHeight: '50px'}}>{(this.state.description) ? (this.state.description) : 'Sorry, there is no available description for this vehicle!'}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>OPEN MORE CASES</h3>
                <br></br>
                <h4 style={{lineHeight: '30px'}}>Opening more cases will increase your chances of receiving a rare weapon with unique skins. Weapons with top of the line stats will perform better in combat.</h4>
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[0]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[0]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGun(this.state.adCaseTypes[0])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[1]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[1]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGun(this.state.adCaseTypes[1])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[2]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[2]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGun(this.state.adCaseTypes[2])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
            </Row>
          </Grid>

          <Referral text={referralText} />

        </div>
      );

  }

  animate = () => {
    document.getElementById('ex').classList.add('horizTranslate');
  }

}

function ImageCarousel(img) {
  return (
    <div>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}>
        <img src={img} key={img} />
      </CSSTransitionGroup>
    </div>
  );
}


export default ViewGun;
