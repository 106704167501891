import React from 'react';
import { Col, Row, Grid } from 'react-bootstrap';
import './App.css';
import vars from './variables.js';
import "react-sweet-progress/lib/style.css";
import _ from 'underscore';
import swal from 'sweetalert';
import loadingGif from './images/loading.gif';
import { Redirect } from 'react-router';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'

const firebase = vars.firebase;

const recaptchaRef = React.createRef();

function roundToFour(num) {
    return +(Math.round(num + "e+4")  + "e-4");
}

async function getFromURL(url) {
    return new Promise(function(resolve, reject) {
      return fetch(url)
       .then((response) => response.json())
       .then((responseJson) => {
         return resolve(responseJson);
       })
       .catch((error) => {
         reject(error);
       });
    })
}

async function timeout(time) {
    return new Promise(function(resolve, reject) {
        setTimeout(function() {
            resolve(null);
        }, time);
    });
}

async function getCurrentUser() {
    return new Promise(function(resolve, reject) {
        try {
            let user = firebase.auth().currentUser;
            if (user) {
                resolve(user);
            } else {
                let timer = timeout(30 * 1000);
                let userPromise = new Promise(function(__resolve, __reject) {
                    let unregisterAuthObserver = null;
                    let callback = function(user) {
                        let temp = !!user;
                        if (temp) {
                            __resolve(user);
                            if (unregisterAuthObserver) {
                                unregisterAuthObserver();
                            }
                        } else {
                            __resolve(null);
                        }
                    }

                    unregisterAuthObserver = firebase.auth().onAuthStateChanged(callback);
                });

                Promise.race([timer, userPromise]).then(function(val) {
                    if (val) {
                        resolve(val);
                    } else {
                        resolve(null);
                    }
                }).catch(function(e) {
                    reject(e);
                });
            }
        } catch (e) {
            reject(e);
        }
    });
}

async function uuidToUsername(uuid) {
    const snapshot = await firebase.database().ref("users/" + uuid + "/username").once("value");
    let username = snapshot.val() || null;

    return {
        uuid,
        username
    }
}

class AccountSharing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        accessList: [],
        grantedList: [],
        inviteList: [],
        pendingInviteList: [],
        goHome: false,
        goLogin: false,
        user: null,
    }

    this.removeAccountCallback = this.removeAccountCallback.bind(this);
    this.loadData = this.loadData.bind(this);
    this.inviteCallback = this.inviteCallback.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
    this.leaveAccountCallback = this.leaveAccountCallback.bind(this);
    this.cancelInviteCallback = this.cancelInviteCallback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.captchaLoaded = this.captchaLoaded.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.loadData();
  }

  async loadData() {
    var self = this;
    swal({
        title: "Loading...",
        text: "Please wait while the data is loaded",
        icon: loadingGif,
        className: 'wr-loader',
        button: false,
        closeOnClickOutside: false
    });
    loadReCaptcha();
    try {
        let user = await getCurrentUser();

        if (!user) {
            self.setState({
                goLogin: true
            });
            return;
        }

        firebase.auth().onAuthStateChanged(function(user) {
            let temp = !!user;
            if (!temp) {
                self.setState({
                    goLogin: true
                });
            }
        });

        const userToken = await user.getIdToken();

        let response1 = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/access', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            }
        });

        let response2 = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/grants', {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + userToken
            }
        });

        let response3 = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/invites', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            }
        });
        
        let response4 = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/invites/pending', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            }
        });

        let access = await response1.json();
        let grants = await response2.json();
        let invites = await response3.json();
        let pending = await response4.json();

        if (access.error) {
            swal({
                title: 'Error',
                text: access.message,
                icon: 'error'
            });
            self.setState({
                goHome: true
            });
            return;
        }

        if (grants.error) {
            swal({
                title: 'Error',
                text: grants.message,
                icon: 'error'
            });
            self.setState({
                goHome: true
            });
            return;
        }

        if (invites.error) {
            swal({
                title: 'Error',
                text: invites.message,
                icon: 'error'
            });
            self.setState({
                goHome: true
            });
            return;
        }

        if (pending.error) {
            swal({
                title: 'Error',
                text: pending.message,
                icon: 'error'
            });
            self.setState({
                goHome: true
            });
            return;
        }

        access = access.data;
        grants = grants.data;
        invites = invites.data;
        pending = pending.data;

        access = await Promise.all(access.map((u) => uuidToUsername(u)));
        grants = await Promise.all(grants.map((u) => uuidToUsername(u))) ;
        invites = await Promise.all(invites.map((u) => uuidToUsername(u)));
        pending = await Promise.all(pending.map((u) => uuidToUsername(u)));

        self.setState({
            accessList: access,
            grantedList: grants,
            inviteList: invites,
            pendingInviteList: pending,
            user: user
        })
    } catch(e) {
        console.log(e);
        self.setState({
            goHome: true
        });
    } finally {
        swal.close();
    }
  }

  verifyCallback(response) {
    this.setState({
        recaptchaVerification: response
    });
  }

  captchaLoaded() {
    this.setState({ recaptchaVerified: false });
  }

  removeAccountCallback(user) {
    var self = this;
    return async function(event) {
        try {
            let confirm = await swal({
                title: 'Are you Sure?',
                text: 'Are you sure you want to remove ' + user.username + '? This will prevent them from using your account.',
                icon: 'warning',
                buttons: true
            });

            if (!confirm) {
                return;
            }
            swal({
                title: "Loading...",
                text: "Please wait while the user is removed",
                icon: loadingGif,
                className: 'wr-loader',
                button: false,
                closeOnClickOutside: false
            });

            const userToken = await self.state.user.getIdToken();

            let response = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/kick', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify({username: user.username})
            });

            let result = await response.json();

            if (result.error) {
                swal({
                    title: 'Error',
                    text: result.message,
                    icon: 'error'
                });
            } else {
                await self.loadData();
                swal({
                    title: 'Success',
                    text: result.message,
                    icon: 'success'
                });
            }
        } catch (e) {
            swal({
                title: 'Error',
                text: e.message,
                icon: 'error'
            });
            console.log(e);
        }
    }
  }

  leaveAccountCallback(user) {
    var self = this;
    return async function(event) {
        try {
            let confirm = await swal({
                title: 'Are you Sure?',
                text: 'Are you sure you want to remove ' + user.username + '? This will prevent you from using their account.',
                icon: 'warning',
                buttons: true
            });

            if (!confirm) {
                return;
            }
            swal({
                title: "Loading...",
                text: "Please wait while you are removed",
                icon: loadingGif,
                className: 'wr-loader',
                button: false,
                closeOnClickOutside: false
            });

            const userToken = await self.state.user.getIdToken();

            let response = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/leave', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify({username: user.username})
            });

            let result = await response.json();

            if (result.error) {
                swal({
                    title: 'Error',
                    text: result.message,
                    icon: 'error'
                });
            } else {
                await self.loadData();
                swal({
                    title: 'Success',
                    text: result.message,
                    icon: 'success'
                });
            }
        } catch (e) {
            swal({
                title: 'Error',
                text: e.message,
                icon: 'error'
            });
            console.log(e);
        }
    }
  }
  
    async inviteUser() {
        var self = this;
        try {
            if (!this.state.recaptchaVerification) {
                swal('Recaptcha Required', "You must first complete the ReCaptcha challenge", "error");
                return;
            }

            let user = await swal({
                title: 'Enter Username to Invite',
                text: 'Please enter the username of the user you would like to share your account with. This will give them access to your account in the game client. This will not give them access to your wallet or any other secure information on your account',
                content: "input",
                icon: 'info'
            });

            if (!user) {
                return;
            }

            swal({
                title: "Loading...",
                text: "Please wait while the invite is sent",
                icon: loadingGif,
                className: 'wr-loader',
                button: false,
                closeOnClickOutside: false
            });

            const userToken = await self.state.user.getIdToken();

            let response = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/invite', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify({username: user, recaptchaResponse: self.state.recaptchaVerification})
            });

            let result = await response.json();

            if (result.error) {
                swal({
                    title: 'Error',
                    text: result.message,
                    icon: 'error'
                });
            } else {
                await self.loadData();
                swal({
                    title: 'Success',
                    text: result.message,
                    icon: 'success'
                });
            }
        } catch (e) {
            swal({
                title: 'Error',
                text: e.message,
                icon: 'error'
            });
            console.log(e);
        } finally {
            self.setState({
                recaptchaVerification: null
            });
            recaptchaRef.current.reset();
        }
    }

    inviteCallback(inviteAccept, user) {
        var self = this;
        return async function(event) {
            try {
                const verb = inviteAccept ? 'accept' : 'decline';
                const verbed = inviteAccept ? 'accepted' : 'declined';
                const msg2 = inviteAccept ? 'This will grant you access to their account.' : 'This will delete the invite.'

                let confirm = await swal({
                    title: 'Are you Sure?',
                    text: 'Are you sure you want to ' + verb + ' the invite from ' + user.username + '? ' + msg2,
                    icon: 'warning',
                    buttons: true
                });
    
                if (!confirm) {
                    return;
                }
                swal({
                    title: "Loading...",
                    text: "Please wait while the invite is " + verbed,
                    icon: loadingGif,
                    className: 'wr-loader',
                    button: false,
                    closeOnClickOutside: false
                });
    
                const userToken = await self.state.user.getIdToken();
    
                let response = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/' + user.uuid + '/' + verb, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + userToken
                    },
                });
    
                let result = await response.json();
    
                if (result.error) {
                    swal({
                        title: 'Error',
                        text: result.message,
                        icon: 'error'
                    });
                } else {
                    await self.loadData();
                    swal({
                        title: 'Success',
                        text: result.message,
                        icon: 'success'
                    });
                }
            } catch (e) {
                swal({
                    title: 'Error',
                    text: e.message,
                    icon: 'error'
                });
                console.log(e);
            }
        }
    }

    cancelInviteCallback(user) {
        var self = this;
        return async function(event) {
            try {
                let confirm = await swal({
                    title: 'Are you Sure?',
                    text: 'Are you sure you want to cancel the invite for ' + user.username + '? This will delete the invite sent',
                    icon: 'warning',
                    buttons: true
                });
    
                if (!confirm) {
                    return;
                }
                swal({
                    title: "Loading...",
                    text: "Please wait while the invite is cancelled",
                    icon: loadingGif,
                    className: 'wr-loader',
                    button: false,
                    closeOnClickOutside: false
                });
    
                const userToken = await self.state.user.getIdToken();
    
                let response = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/' + user.uuid + '/cancel', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + userToken
                    },
                });
    
                let result = await response.json();
    
                if (result.error) {
                    swal({
                        title: 'Error',
                        text: result.message,
                        icon: 'error'
                    });
                } else {
                    await self.loadData();
                    swal({
                        title: 'Success',
                        text: result.message,
                        icon: 'success'
                    });
                }
            } catch (e) {
                swal({
                    title: 'Error',
                    text: e.message,
                    icon: 'error'
                });
                console.log(e);
            }
        }
    }

    expiredCallback() {
        this.setState({ recaptchaVerification: null });
        swal({
            title: 'ReCaptcha Expired',
            text: "The ReCaptcha challenge has expired. Please try again",
            icon: 'error'
        });
    }


  render() {
    let self = this;
    if (this.state.goLogin) {
        return <Redirect to="/link?redirect=/share" />
    }

    if (this.state.goHome) {
        return <Redirect to='/' />
    }

    return (
      <div id='VehicleSelectType' style={{textAlign: 'center'}}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Grid>
            <Row>
                <Col xs={12} sm={6} style={{marginTop: '7.5rem'}}>
                    <h3 className='centered underline-header'>Accounts with Access</h3>

                    {this.state.grantedList.map((user, index) =>
                        <Row className={"share-row-" + (index % 2 == 0 ? "even" : "odd")}>
                            <Col xs={6} className="share-column">
                                {user.username}
                            </Col>
                            <Col xs={6} className="share-column">
                                <a className="btn full-btn" onClick={self.removeAccountCallback(user)}>
                                    <span>REMOVE</span>
                                </a>
                            </Col>
                        </Row>
                    )}
                    {this.state.grantedList.length == 0 ?
                        <div>
                            <br></br>
                            You are not sharing your account
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                        :
                        <></>
                    }
                    <Row style={{paddingLeft: '20%', paddingRight: '20%', marginTop: '45px'}}>
                        <a className="btn full-btn" onClick={this.inviteUser}>
                            <span>INVITE</span>
                        </a>
                        <div style={{  display: "flex", justifyContent: "center", alignItems: "center", margin: 'auto', marginTop: '20px' }}>
                            <ReCaptcha
                                ref={recaptchaRef}
                                size="normal"
                                data-theme="dark"
                                render="explicit"
                                sitekey="6Lc06kogAAAAACgOMfI1o9-Oxt3EVJrB9fi_UUbJ"
                                verifyCallback={this.verifyCallback}
                                onloadCallback={this.captchaLoaded}
                                expiredCallback={this.expiredCallback}
                            />
                        </div>
                    </Row>
                </Col>
                <Col xs={12} sm={6} style={{marginTop: '7.5rem'}}>
                    <h3 className='centered underline-header'>Accounts Shared with You</h3>

                    {this.state.accessList.map((user, index) =>
                        <Row className={"share-row-" + (index % 2 == 0 ? "even" : "odd")}>
                            <Col xs={6} className="share-column">
                                {user.username}
                            </Col>
                            <Col xs={6} className="share-column">
                                <a className="btn full-btn" onClick={self.leaveAccountCallback(user)}>
                                    <span>REMOVE</span>
                                </a>
                            </Col>
                        </Row>
                    )}
                    {this.state.accessList.length == 0 ?
                        <div>
                            <br></br>
                            No accounts have been shared with you
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                        :
                        <></>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} style={{marginTop: '7.5rem'}}>
                    <h3 className='centered underline-header'>Pending Invites</h3>

                    {this.state.pendingInviteList.map((user, index) =>
                        <Row className={"share-row-" + (index % 2 == 0 ? "even" : "odd")}>
                            <Col xs={6} className="share-column">
                                {user.username}
                            </Col>
                            <Col xs={6} className="share-column" onClick={self.cancelInviteCallback(user)}>
                                <a className="btn full-btn">
                                    <span>CANCEL</span>
                                </a>
                            </Col>
                        </Row>
                    )}
                    {this.state.pendingInviteList.length == 0 ?
                        <div>
                            <br></br>
                            No pending invites
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                        :
                        <></>
                    }
                </Col>
                <Col xs={12} sm={6} style={{marginTop: '7.5rem'}}>
                    <h3 className='centered underline-header'>Incoming Invites</h3>

                    {this.state.inviteList.map((user, index) =>
                        <Row className={"share-row-" + (index % 2 == 0 ? "even" : "odd")}>
                            <Col xs={6} className="share-column">
                                {user.username}
                            </Col>
                            <Col xs={3} className="share-column" onClick={self.inviteCallback(true, user)}>
                                <a className="btn full-btn invite-btn">
                                    <span>ACCEPT</span>
                                </a>
                            </Col>
                            <Col xs={3} className="share-column">
                                <a className="btn full-btn invite-btn" onClick={self.inviteCallback(false, user)}>
                                    <span>DECLINE</span>
                                </a>
                            </Col>
                        </Row>
                    )}
                    {this.state.inviteList.length == 0 ?
                        <div>
                            <br></br>
                            No new invites
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                        :
                        <></>
                    }
                </Col>
            </Row>

        </Grid>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    );
  }
}

export default AccountSharing;
