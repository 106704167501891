import React, { Component } from 'react';
import ReactDom from 'react-dom';
import vars from './variables.js';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import './App.css';
import skull from './images/skull-popup.png';
import coinbase from './images/coinbase-wallet.png';
import metamask from './images/metamask.png';


class NoWeb3 extends React.Component {

  render() {
    return (
      <Grid className="nwGrid">
        <Row>
        <Col xs={18} md={12} className="center-logo" >
          <img src={skull} />
        </Col>
        </Row>
        <Row>
          <Col xs={18} md={12} className="centeredText nwTitle">
            <h1>Welcome to War Riders!</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={18} md={12} className="centeredText nwSubtitle">
            <h3>The first MMO game of earning cryptocurrency and blowing up cars.</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={18} md={12} className="centeredText nwDescription">
            <p>Before you can purchase vehicles, you need to enable your <a href="https://metamask.io/">MetaMask</a> extension for Chrome or Firefox. This will make your browser Web3 compatible!</p>
            <br></br>
            <p>We also recommend using <a href="https://warriders.com/which-device/">Coinbase Wallet</a> for the best experience on mobile.</p>
          </Col>
        </Row>
        <Row className="nwButtons">
          <Col md={4} smHidden xsHidden></Col>
          <Col xs={6} md={2} sm={6}>
            <a href="https://metamask.io/">
              <img src={metamask} />
            </a>
          </Col>
          <Col xs={6} md={2} sm={6}>
            <a href="https://warriders.com/which-device/">
              <img src={coinbase} />
            </a>
          </Col>
          <Col md={4} smHidden xsHidden></Col>
        </Row>
        <Row>
        <Col xs={18} md={12} className="centeredText nwDescription">
          <p>Dont know what MetaMask is? Want to learn more about the buying process?</p>
        </Col>
        </Row>
        <Row className="nwButtons">
          <Col xs={18} md={12} className="centeredText nwLearnMore">
            <a className="btn-bar" onClick={() => window.open(vars.urls.howto, "_blank")}>
              <span style={{color: '#fff'}}>Learn More</span>
            </a>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default NoWeb3;
