import React from 'react';
import { Suspense, lazy } from 'react';
import swal from 'sweetalert';
import loadingGif from './images/loading.gif';
import variables from './variables.js';
import { Redirect } from 'react-router';
import ReactDom from 'react-dom';
import GunRow from './GunRow.js';
import gunLarge from './images/gun-largel.jpg';
import MetaTags from 'react-meta-tags';

let gun_token_contract = variables.infoContracts.gunToken;
let gun_token_contract_real = variables.contracts.gunToken;
//let web3 = variables.web3;
var hashesConfirmed = [];

async function getAllTokens(uid) {
    let tokens = await gun_token_contract_real.methods.tokenOfOwner(uid).call();

    return tokens;
}

async function getAllBatches(uid) {
    let batchCount = await gun_token_contract_real.methods.getBatchCount(uid).call();

    let batches = []
    for (let i = 0; i < batchCount; i++) {
        let batch = await gun_token_contract_real.methods.batchesOwned(uid, i).call();

        //Query how many tokens the user owns
        let allTokens = await gun_token_contract_real.methods.getTokensInBatch(uid, i).call();

        batch.size = allTokens.length;
        batches.push(batch);
    }

    return batches;
}

async function getAllTokensInBatch(uid, batchNumber) {
    let batch = await gun_token_contract_real.methods.getTokensInBatch(uid, batchNumber).call();

    return batch;
}

const DEFAULT_INPUT_TEXT = "";
class MyInput extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        text: DEFAULT_INPUT_TEXT,
      };
    }

    changeText(e) {
      let text = e.target.value;

      this.setState({
        text,
      });

      /*
       * This will update the value that the confirm
       * button resolves to:
       */
      swal.setActionValue(text);
    }

    render() {
      return (
        <input
            style={{color: 'black', padding: '2px', width: '80%'}}
            value={this.state.text}
            onChange={this.changeText.bind(this)}
        />
      )
    }
}

class GunGarage extends React.Component {

    constructor(props) {
      super(props);
      let batchNumber = props.batchNumber; //-1 is all tokens, -2 is all batches, n>=0 is batch n
      this.state = {
          batchNumber: batchNumber,
          currentId: 'NA',
          tokens: [],
          metas: [],
          loaded: false,
          goHome: false,
          lastShowMore: 0,
          showMoreTimeout: 300
      };

      this.fetchData = this.fetchData.bind(this);
      this.transferPrompt = this.transferPrompt.bind(this);

    }

    async componentDidMount() {
        window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
        await this.fetchData(this.state.batchNumber);
    }

    async componentWillReceiveProps(nextProps) {
        let batchNumber = nextProps.batchNumber; //-1 is all tokens, -2 is all batches, n>=0 is batch n

        this.setState({
            batchNumber: batchNumber,
            currentId: 'NA',
            tokens: [],
            length: 0,
            loaded: false,
            goHome: false
        });

        await this.fetchData(batchNumber);
    }

    async transferPrompt(metadata, id) {
        let self = this;
        let wrapper = document.createElement('div');
        ReactDom.render(<MyInput />, wrapper);
        let el = wrapper.firstChild;

        let isBatch = this.state.batchNumber == -2;

        let name;
        if (isBatch) {
            name = "Batch " + id
        } else {
            name = metadata.name;
        }

        let value = await swal({
            title: "Transfer " + name,
            text: "Who would you like to transfer " + name + " to:",
            icon: metadata.image,
            content: el,
            buttons: {
                confirm: {
                    /*
                    * We need to initialize the value of the button to
                    * an empty string instead of "true":
                    */
                    value: DEFAULT_INPUT_TEXT,
                }
            },
        });

        if (value == null || value == "")
                return;
        
        if (value.endsWith('.eth')) {
            value = await variables.web3.eth.ens.getAddress(value);
        }
        if (!variables.web3.utils.isAddress(value)) {
            swal('Invalid Address', 'Please enter a valid address', 'error').then(function() {
                self.transferPrompt(metadata);
            })
        } else {
            let result = await swal({
                title: 'Please Confirm',
                text: 'Would you like to transfer ' + name + ' to ' + value + ' ?',
                icon: 'warning',
                buttons: true,
                confirmButtonText: 'Yes, transfer it'
            });

            if (result) {
                swal({
                    title: "Loading...",
                    text: "Please wait while " + name + " is transferred to " + value,
                    icon: loadingGif,
                    className: 'wr-loader',
                    button: false,
                    closeOnClickOutside: false
                });

                if (!isBatch) {
                    try {
                        let gunTransfer = await variables.econtracts.gunToken.transferFrom(self.state.currentId, value, metadata.tokenId);
                        let gunr = await gunTransfer.wait(1);
                        let tx = gunr.transactionHash;
                        if (gunr.status) {
                            var elem = document.createElement("div");
                            elem.innerHTML = "<p style=\"color: black\">" +  name + " has been successfully been transferred to " + value + "<br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
                            swal({
                                title: "Transfer Successful",
                                content: elem,
                                icon: "success"
                            }).then(function() {
                                self.fetchData(self.state.batchNumber);
                            });
                        } else {
                            swal("Transfer Failed", "Your transfer could not be processed, we're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nTransaction Hash: " + tx, "error");
                        }
                    } catch (e) {
                        if (e.message.includes('User denied')) {
                            swal.close();
                        } else {
                            swal("Transfer Failed", "Your transfer could not be processed, we're sorry :( Please provide the error to the devs to help figure out what went wrong.\nError: " + e.message, "error");
                        }
                    }
                } else {
                    try {
                        let batchTransfer = await variables.econtracts.gunToken.batchTransfer(metadata.id, value);
                        let batchr = await batchTransfer.wait(1);
                        let tx = batchr.transactionHash;
                        if (batchr.status) {
                            var elem = document.createElement("div");
                            elem.innerHTML = "<p style=\"color: black\">" +  name + " has been successfully been transferred to " + value + "<br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
                            swal({
                                title: "Transfer Successful",
                                content: elem,
                                icon: "success"
                            }).then(function() {
                                self.fetchData(self.state.batchNumber);
                            });
                        } else {
                            swal("Transfer Failed", "Your transfer could not be processed, we're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nTransaction Hash: " + tx, "error");
                        }
                    } catch (e) {
                        if (e.message.includes('User denied')) {
                            swal.close();
                        } else {
                            swal("Transfer Failed", "Your transfer could not be processed, we're sorry :( Please provide the error to the devs to help figure out what went wrong.\nError: " + e.message, "error");
                        }
                    }
                }
            }
        }
    }

    async fetchData(batchNumber) {
        try {
            window.scrollTo(0, 0)
            var self = this;
            swal({
                title: "Loading...",
                text: "Please wait while the inventory is loaded",
                icon: loadingGif,
                className: 'wr-loader',
                button: false,
                closeOnClickOutside: false
            });

            if (!variables.isWeb3Known()) {
                await variables.askForWeb3();
            }

            let accounts = await variables.getAccounts();
            console.log('Found accounts: ' + accounts)
            let address = accounts[0];

            this.setState({currentId: address});

            let tokens;
            if (batchNumber == -2) {
                tokens = await getAllBatches(address);
            } else if (batchNumber == -1) {
                tokens = await getAllTokens(address);
            } else if (batchNumber >= 0) {
                tokens = await getAllTokensInBatch(address, batchNumber);
            } else {
                throw "Invalid URL";
            }

            self.setState({
                tokens: tokens,
                length: Math.min(6, tokens.length)
            })

            swal.close();
            self.setState({
                loaded: true
            })
        } catch (e) {
            console.log("ERROR: " + e);

            swal.close();
            var elem = document.createElement("div");
            elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
            swal({
                title: "No External Wallet",
                content: elem,
                icon: "error"
            }).then(function() {
                self.setState({
                  goHome: true
                })
            });
        }
    }

    handleScroll = () => {
        var lastLi = document.querySelector("#grow:last-child");
        if (lastLi == null) {
            this.showMore();
            return;
        }
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset * 2 > lastLiOffset) {
             this.showMore();
        }
    };

    showMore() {
        if (this.state.length >= this.state.tokens.length)
            return;

        var d = new Date();
        var n = d.getTime();

        if (n - this.state.lastShowMore >= this.state.showMoreTimeout) {
            let toAdd = Math.min(this.state.tokens.length - this.state.length, 6);
            this.setState({
                length: this.state.length + toAdd,
                lastShowMore: n
            })
        }
    }

    render() {
        if (this.state.goHome) {
            return <Redirect to='/' />
        }

        let isBatch = this.state.batchNumber == -2;

        let name = "All Guns";

        if (this.state.batchNumber == -2) {
            name = "All Batches";
        } else if (this.state.batchNumber >= 0) {
            name = "Batch #" + this.state.batchNumber;
        }

        let items = <div><h1>Inventory Empty</h1></div>
        if (this.state.loaded) {
            items = [];

            let i = 0;
            while (i < this.state.length) {
                const one = i;
                const two = i + 1;
                const three = i + 2;

                if (three < this.state.tokens.length) {
                    items.push(<GunRow isBatch={isBatch} callback={this.transferPrompt} tokens={[this.state.tokens[one], this.state.tokens[two], this.state.tokens[three]]} startIndex={i} />)
                    i += 3;
                } else if (two < this.state.tokens.length) {
                    items.push(<GunRow isBatch={isBatch} callback={this.transferPrompt} tokens={[this.state.tokens[one], this.state.tokens[two]]} startIndex={i} />)
                    i += 2;
                } else {
                    items.push(<GunRow isBatch={isBatch} callback={this.transferPrompt} tokens={[this.state.tokens[one]]} startIndex={i} />)
                    i += 1;
                }
            }
        }

        return (
            <div id='VehicleSelectBuy' style={{textAlign: 'center'}}>
                <MetaTags>
                    <title>Gun Garage</title>
                    <meta property="og:title" content="Gun Garage" />
                    <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
                    <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
                    <meta property="og:image" content={gunLarge}/>
                    <meta property="og:url" content={"https://app.warriders.com/garage/guns/" + this.state.batchNumber}/>
                    <meta name="twitter:card" content="summary_large_image"/>

                    <meta property="og:site_name" content="War Riders"/>
                    <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
                </MetaTags>
                <div>
                    <div><br></br><br></br><br></br><br></br><br></br><br></br></div>
                    <h2 style={{marginBottom: '20px'}}>View {name}</h2>
                    <div style={{overflow: 'hidden', display: 'block'}}>{items}</div>
                    <div><br></br><br></br><br></br></div>
                </div>
            </div>
        );
    }
}

export default GunGarage;
