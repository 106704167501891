import React, { Component } from 'react';
import ReactDom from 'react-dom';
import { ToggleButtonGroup, ToggleButton, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import imgs from './ImgFactory.js';
import { Progress } from 'react-sweet-progress';
import { Line, Circle } from 'rc-progress';
import "react-sweet-progress/lib/style.css";
import ScrollAnimation from 'react-animate-on-scroll';
import {LineChart, ComposedChart, ScatterChart, Scatter, Dot, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, AreaChart, Area, ReferenceLine, Cell, BarChart, Bar, LabelList} from 'recharts';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import {Helmet} from "react-helmet";
import NumericInput from 'react-numeric-input';
import axios from 'axios';
import Countdown from 'react-countdown-now';
import logo from './logo.svg';
import Referral from './Referral.js';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './App.css';
import moment from 'moment';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import GradientSVG from './GradientSVG.js';
import Popup from 'react-popup';
import posed from 'react-pose';
import styled from "styled-components";
import { tween, transform } from "popmotion";
import MetaTags from 'react-meta-tags';
import { CSSTransitionGroup } from 'react-transition-group' // ES6
import ReactPlayer from 'react-player'
import { timeline } from 'popmotion';
import swal from 'sweetalert';
import premiumGunImg from './images/premium-case.jpg';
import midgradeGunImg from './images/mid-grade-case.jpg';
import regularGunImg from './images/regular-case.jpg';
import ReactGA from 'react-ga';
import loadingGif from './images/loading.gif';
String.prototype.hashCode = function() {
    var hash = 0;
    if (this.length == 0) {
        return hash;
    }
    for (var i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

const { pipe, clamp } = transform;
var cars = vars.cars_table_data_minMax;
//let web3 = vars.web3;
let gradients = vars.gradients;
const Box = posed.div({});

let preorder_contract = vars.contracts.gunPreOrder;
let gunfactory_contract = vars.infoContracts.gunFactory;
let guntoken_contract = vars.infoContracts.gunToken;

let bzn_contract = vars.contracts.bzn;
let info_preorder_contract = vars.infoContracts.gunPreOrder;
let info_factory_contract = vars.infoContracts.gunFactory;
let gun_token_contract = vars.infoContracts.gunToken;
let gun_factory_contract = vars.infoContracts.gunToken;
let preorder_address = preorder_contract.address;

let caseVars = [
  {
    name: 'PREMIUM',
    image: premiumGunImg,
    webm: 'https://warriders.com/wr_uploads/2019/09/Premium-case.webm',
    type: 'Main',
    base_price: 50,
  },
  {
    name: 'MID-GRADE',
    image: midgradeGunImg,
    webm: 'https://warriders.com/wr_uploads/2019/09/mid-grade-case.webm',
    type: 'Main and alt',
    base_price: 20,
  },
  {
    name: 'REGULAR',
    image: regularGunImg,
    webm: 'https://warriders.com/wr_uploads/2019/09/Regular-case.webm',
    type: 'Main and alt',
    base_price: 10,
  }
]
let referralText = 'Earn ETH and BZN by becoming a Virtual Gun Dealer. Copy the URL below, share it with your friends on social media and earna 5% commission on every case opened using your referral link. The top 3 gun dealers will receive unique weapons!';


function updateContracts() {
  preorder_contract = vars.contracts.gunPreOrder;
  info_preorder_contract = vars.infoContracts.gunPreOrder;
  gun_token_contract = vars.infoContracts.gunToken;
  preorder_address = preorder_contract.address;
}
//var localhost = 'http://127.0.0.1:1234';
var localhost = 'https://api.warriders.com/';
var URLs = vars.urls;
var maxes = vars.maxes;

function log(x) {
  console.log('(Z) ' + x);
}

function categoryIdToToken(category, categoryId) {
  return new Promise(function(resolve, reject) {
    console.log("We doing it")
    gun_token_contract.methods.categoryTypeToId(category, categoryId).call().then(function(result) {
      console.log("WOOO " + result);
      resolve(result);
    }).catch(function(err) {
      console.log(":( " + err);
      reject(err);
    });
  });
}

const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

function getMinMax(type) {
  if (type == 0) {
    return [
      {
        min: roundToTwo(60),
        max: roundToTwo(600)
      },
      {
        min: roundToTwo(350),
        max: roundToTwo(985)
      },
      {
        min: roundToTwo(276),
        max: roundToTwo(776)
      },
      {
        min: roundToTwo(1102),
        max: roundToTwo(3089)
      },
      {
        min: roundToTwo(1),
        max: roundToTwo(10)
      },
      {
        min: roundToTwo(1),
        max: roundToTwo(10)
      },
      {
        min: roundToTwo(1),
        max: roundToTwo(10)
      }
    ]
  } else if (type == 1) {
    return [{
      min: roundToTwo(50),
      max: roundToTwo(570)
    },
    {
      min: roundToTwo(300),
      max: roundToTwo(930)
    },
    {
      min: roundToTwo(236),
      max: roundToTwo(732)
    },
    {
      min: roundToTwo(945),
      max: roundToTwo(2918)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(8)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(8)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(7)
    }]
  } else {
    return [{
      min: roundToTwo(40),
      max: roundToTwo(550)
    },
    {
      min: roundToTwo(230),
      max: roundToTwo(900)
    },
    {
      min: roundToTwo(181),
      max: roundToTwo(709)
    },
    {
      min: roundToTwo(724),
      max: roundToTwo(2824)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(7)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(7)
    },
    {
      min: roundToTwo(1),
      max: roundToTwo(5)
    }]
  }
}


// END USELESS

// Travel speed = (Engine power - Armor)/1.6
function calculateSpeed(engineSize, armor) {
  return ((engineSize - armor)/1.6);
}
// Acceleration Ratio = Armor / Engine Power
// Acceleration = (Travel speed/10) / (Acceleration Ratio + 1)
function calculateAcceleration(engineSize, armor) {
  var ratio = armor / engineSize;
  var speed = calculateSpeed(engineSize, armor);
  var acc = (speed/10) / (ratio + 1);
  return acc;
}

var ProgressComponent = (num, max, title, unit, dimension) => {
  return (
    <div style={{width: '100%'}}>
      <Row>
        <Col xs={12} sm={4}></Col>
        <Col xs={12} sm={8}>
          <h5><span style={{color: COLORS[2]}}>{num}</span> out of <span style={{color: COLORS[3]}}>{max}</span> {unit}</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <h4 style={{marginTop: '0%'}}>{title}</h4>
        </Col>
        <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={(num/max)*100} key={1} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
        </Col>
      </Row>
    </div>
  );
}

async function getFromURL(url) {
  return new Promise(function(resolve, reject) {
    return fetch(url)
     .then((response) => response.json())
     .then((responseJson) => {
       return resolve(responseJson);
     })
     .catch((error) => {
       reject(error);
     });
  })
}

async function getMetaURLFromToken(token) {
  try {
    let metaURL = await guntoken_contract.methods.tokenURI(token).call();

    return metaURL;
  } catch (err) {
    console.log("Got error fetching URL: " + err);
    return null;
  }
}

async function getMetasForTokens(token) {
  return new Promise( async function(resolve, reject) {
      let metaURLs = [];
      for (let i = 0; i < token.length; i++) {
        let url = await getMetaURLFromToken(token[i]);
        metaURLs.push(getFromURL(url));
      }
      Promise.all(metaURLs).then(function(allGuns) {
        resolve(allGuns);
      }).catch(function(err) {
        reject(err);
      });
  })
}

class ViewMyGun extends Component {

  constructor(props) {
    super(props);
      //    let id = props.carId; // Passed to specify for HTTP Req so we can differentiate between different car types
      let token = props.token;
      let _category = props.category;
      let categoryId = props.categoryId;

      let category = null;
      if (_category != null) {
        category = 1;
        if (_category.toLowerCase() == 'premium') {
          category = 1;
        } else if (_category.toLowerCase() == 'midgrade') {
          category = 2;
        } else if (_category.toLowerCase() == 'regular') {
          category = 3;
        }
      }

      console.log("DDDD " + token);
      console.log("DDDD " + categoryId);
      console.log("DDDD " + category);

      this.state = {
        currentId: 'DNE',
        min: 0,

        limit: 20,
        limitDate: 'Dec 17',
        adCaseTypes: [0, 1, 2],
        currency: 'ETH',
        requiredEtherPercent: 0.0,
        requiredGasFee: 0,

        token: token,
        category: category,
        categoryId: categoryId,
        weaponSlot: '',
        barrel: '',
        skin: '',
        skinRarity: '',
        caseName: '',

        isOpen: true,
        quantityBuying: 1,
        carInfo: {},
        speedMax: '',
        accMax: '',
        armorMax: '',
        bznTankMax: '',
        mg: '',
        ag: '',
        speedMin: '',
        accMin: '',
        armorMin: '',
        bznTankMin: '',
        nos: '',
        description: '',
        price: 0,
        ethPrice: 0,
        bznPrice: 0,
        totalSupply: 0,
        amtLeft: true,

        lastSold: 'DNE',
        videoSupported: true,
        shouldUpdate: true,
        adCarsNames: [cars.premium[0].name, cars.premium[1].name, cars.premium[2].name],
        adCarsImgs: [cars.premium[0].img, cars.premium[1].img, cars.premium[2].img],
        adCarIndex: [0, 1, 2],
      };
      this.getData = this.getData.bind(this);
  }

getReferrer(uid) {
  var self = this;
  console.log("XXX Request " + URLs.getReferrer + "/" + uid);
  axios.get(URLs.getReferrer + "/" + uid).then( function(response) {
    let referrer = response.data;
    self.setState({referrer: referrer});
    self.state.referrer = referrer;
    console.log("XXX Got " + referrer);
  })
}

componentWillUnmount() {
  this.setState({
    shouldUpdate: false
  });
  this.state.shouldUpdate = false;
}

componentDidMount() {
  window.scrollTo(0, 0)
  swal({
    title: "Loading...",
    text: "Please wait while this weapon is loaded",
    icon: loadingGif,
    className: 'wr-loader',
    button: false,
    closeOnClickOutside: false
  });
  setTimeout(this.getData, 5000);
  updateContracts();
}

linkAccount(self) {
  vars.getAccounts((error, accounts) => {
    if (accounts != null) {
      if (accounts.length == 0) {
        console.log('Error: couldn\'t find accounts');
        self.setState ({
          currentId: "NA"
        });
      } else {
        console.log("We found accounts!");
        self.setState ({
          currentId: accounts[0]
        });
        self.getReferrer(accounts[0]);
      }
    } else {
      console.log("Got error looking up accounts " + error);
    }
    setTimeout(function() {
      self.linkAccount(self);
    }, 500);
  })
}

componentWillMount() {
  this.linkAccount(this);
}


getData = () => {
    let token = this.state.token;
    let category = this.state.category;
    let categoryId = this.state.categoryId;
    let self = this;

    if (token == null || token == '') {
      console.log("DDD GOTTA CONVERT IT");
      
      console.log("DDD " + category);
      console.log("DDD " + categoryId);
      categoryIdToToken(category, categoryId).then(function(t) {
        self.setState({
          token: t
        });

        self.getData();
      }).catch(function(err) {
        console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
      })

      return;
    }

    let minMax = getMinMax(category);
    let damageMax = roundToTwo(minMax[4].max);
    let stabilityMax = roundToTwo(minMax[5].max);
    let rarityMax = roundToTwo(minMax[6].max);

    getMetasForTokens([token]).then(function(response) {
      let meta = response[0].meta;
      let image = meta.image;
      let rateOfFire = meta.rateOfFire;
      let velocity = meta.muzzleVelocity;
      let effective = meta.effectiveRange;
      let maxRange = meta.maxRange;
      let damageLvl = meta.damageLevel;
      let stabilityLvl = meta.stabilityLevel;
      let rarityIndex = meta.gunRarityIndex;
      let weaponSlot = meta.weaponSlot;
      let barrel = meta.barrel;
      let skin = meta.skin == "" ? "No Skin" : meta.skin;
      let rarity = meta.skinRarity == "" ? "No Skin" : meta.skinRarity;

      self.setState({
        image: image,
        weaponSlot: weaponSlot,
        barrel: barrel,
        skin: skin,
        skinRarity: rarity
      });

      let name = response[0].name;
      self.setState ({
        caseName: name
      });
      
    console.log("XXXX " + name);
    this.setState({description: response[0].description, image: response[0].image});
    //this.state.description = description;
    var svg = ( <GradientSVG
           idCSS='sxxx'
           rotation={90}
         />);


         function gunInfoProgressComponents(){
          return (
                  <div>
                    <div>{ProgressComponent(rateOfFire,maxes.rateOfFire, 'Rate of Fire', 'rpm')}</div>
                    <div>{ProgressComponent(velocity,maxes.velocity, 'Velocity', 'ft/s')}</div>
                    <div>{ProgressComponent(effective,maxes.effectiveRange, 'Effective Range', 'ft')}</div>
                    <div>{ProgressComponent(maxRange,maxes.maxRange, 'Max Range', 'ft', true)}</div>
                      <Row >
                        <Col xs={4} sm={4} md={4}>
                          <div>
                            {svg}
                                <h5 className='centered'>Damage LVL</h5>
                                <br></br>
                                <CircularProgressbar percentage={(damageLvl)/10*100} text={`${damageLvl}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                          </div>
                        </Col>
                        <Col xs={4} sm={4} md={4}>
                          <div>
                            {svg}
                            <h5 className='centered'>Stability LVL</h5>
                            <br></br>
                                <CircularProgressbar percentage={(stabilityLvl)/10*100} text={`${stabilityLvl}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                          </div>
                        </Col>
                        <Col xs={4} sm={4} md={4}>
                          <div>
                            {svg}
                            <h5 className='centered'>Rarity Index</h5>
                            <br></br>
                                <CircularProgressbar percentage={(rarityIndex)/10*100} text={`${rarityIndex}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                          </div>
                        </Col>
    
                      </Row>
                      <Row><div style={{width: '100%', height: '30px'}}></div></Row>
                  </div>
                );
    
        }
    let onVideoFailed = function() {
      self.setState({videoSupported: false});
      ReactDom.render(<img src={self.state.image}></img>, document.getElementById('gun-img'));
    };

    let vurl = response[0].webm;
    if (vurl != null && vurl != "" && this.state.videoSupported && isBrowser) {
      ReactDom.render(<ReactPlayer  width='100%' height='100%' url={vurl} playing={true} loop={true} onError={onVideoFailed}/>, document.getElementById('gun-img'));
    } else {
      ReactDom.render(<img src={this.state.image}></img>, document.getElementById('gun-img'));
    }

    ReactDom.render(<div>{gunInfoProgressComponents()}</div>, document.getElementById('progressi'));

    swal.close();
  }.bind(this)).catch(function(err) { console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description) })
}

outFunc() {
  var tooltip = document.getElementById("myTooltip");
  tooltip.innerHTML = "Copy to clipboard";
}

anim() {
  document.getElementById('anim').className ='classname';
}

render() {
  //log('rerender triggered, description is ' + this.state.description)
  const MyComponent = ({ hostRef }) => <div ref={hostRef} ><img style={{width: '50%'}} src={imgs.car}></img></div>

  let config = {
    visible: {
      opacity: 1,
      x: 100,
      transition: { duration: 300000 }
    }, invisible: {
        opacity: 0.5,
        x: -100,
        transition: { duration: 300000 }
      }
  };
  
  let btnText = "OPEN NOW"
  if (!this.state.amtLeft) btnText = "LIMIT REACHED"
      return (
        <div className="ViewCar">
          <MetaTags>
            <title>{this.state.caseName}</title>
            <meta name="description" content={this.state.description} />
            <meta property="og:title" content={this.state.caseName + ' - War Riders'} />
            <meta property="og:image" content={this.state.image} />

            <meta property="og:description" content={this.state.description} />
            <meta property="og:url" content={"https://app.warriders.com/guns/" + (this.state.token != null ? this.state.token : this.state.category + '/' + this.state.categoryId)}/>
            <meta name="twitter:card" content="summary_large_image"/>

            <meta property="og:site_name" content="War Riders"/>
            <meta name="twitter:image:alt" content={this.state.description}/>
          </MetaTags>
          <Grid>
            <Row style={{height: 100}}></Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h3>{this.state.caseName}</h3>
                <br></br>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} md={6}>
              <div id='gun-img'>
                  <img src={this.state.image}></img>

                </div>
              </Col>
              <Col xs={12} sm={6} md={6}>
                  <div id='progressi'>

                  </div>
              </Col>

            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>ABOUT THIS WEAPON</h3>
                <br></br><br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <h4 style={{color: '#a922ed'}}>WEAPON SLOT</h4>
                <p>{this.state.weaponSlot}</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>BARREL TYPE</h4>
                <p>{this.state.barrel}</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>SKIN</h4>
                <p>{this.state.skin}</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>SKIN RARITY</h4>
                <p>{this.state.skinRarity}</p>
                <br></br>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <h4 id='gun_description' style={{lineHeight: '50px'}}>{(this.state.description) ? (this.state.description) : 'Sorry, there is no available description for this weapon!'}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>OPEN MORE CASES</h3>
                <br></br>
                <h4 style={{lineHeight: '30px'}}>Opening more cases will increase your chances of receiving a rare weapon with unique skins. Weapons with top of the line stats will perform better in combat.</h4>
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[0]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[0]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGun(this.state.adCaseTypes[0])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[1]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[1]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGun(this.state.adCaseTypes[1])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5>{caseVars[this.state.adCaseTypes[2]].name} Case</h5>
                <img src={caseVars[this.state.adCaseTypes[2]].image}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.selectGun(this.state.adCaseTypes[2])}>
                  <span>OPEN NOW</span>
                </a>
              </Col>
            </Row>
          </Grid>

          <Referral text={referralText} />

        </div>
      );

  }

  animate = () => {
    document.getElementById('ex').classList.add('horizTranslate');
  }

}

function ImageCarousel(img) {
  return (
    <div>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}>
        <img src={img} key={img} />
      </CSSTransitionGroup>
    </div>
  );
}


export default ViewMyGun;
