import React, { Component } from 'react';
import vars from './variables.js';
import { Col, Row, Grid } from 'react-bootstrap';
import './App.css';
import loadingGif from './images/loading.gif';
import swal from 'sweetalert';
import img from './ImgFactory.js';
import MetaTags from 'react-meta-tags';
import ReactTooltip from 'react-tooltip'
import { Redirect } from 'react-router';

var hashesConfirmed = [];
//const web3 = vars.web3;
const firebase = vars.firebase;

class Deposits extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        bznAmount: 0.0,
        ethAmount: 0.0,
        depositAnother: false,
        username: "",
        goHome: false,
        address: null,
        userProfile: null,
        currentUsername: ""
      };

      this.handleUserChange = this.handleUserChange.bind(this);
      this.handleBZNAmount = this.handleBZNAmount.bind(this);
      this.handleETHAmount = this.handleETHAmount.bind(this);
      this.handleDepositAnother = this.handleDepositAnother.bind(this);
      this.confirmDeposit = this.confirmDeposit.bind(this);
      this.handleDeposit = this.handleDeposit.bind(this);
    }

    componentDidMount() {
        let self = this;

        window.scrollTo(0, 0);

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
          self.setState({
            userProfile: user
          });
          vars.username().then(function(cu) {
            self.setState({
              currentUsername: cu
            });
          }).catch(function(e) {
            console.log("Could not lookup username");
            console.log(e);
          });
        });

        vars.username().then(function(cu) {
          self.setState({
            currentUsername: cu
          });
        }).catch(function(e) {
          console.log("Could not lookup username");
          console.log(e);
        });
        
        vars.getAccounts().then(function(accounts) {
            if (accounts != null) {
              if (accounts.length == 0) {
                var elem = document.createElement("div");
                elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
                swal({
                  title: "No External Wallet",
                  content: elem,
                  icon: "error"
                }).then(function() {
                  self.setState({
                    goHome: true
                  })
                });
              }
              else {
                console.log('We found external wallets from metamask');
                self.setState ({
                  address: accounts[0]
                });
      
              }
            }
            else {
              var elem = document.createElement("div");
              elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
              swal({
                title: "No External Wallet",
                content: elem,
                icon: "error"
              }).then(function() {
                self.setState({
                    goHome: true
                })
              });
            }
        }).catch(function(error) {
            var elem = document.createElement("div");
            elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
            swal({
              title: "No External Wallet",
              content: elem,
              icon: "error"
            }).then(function() {
              self.setState({
                goHome: true
              })
            });
        });
    }

    async handleDeposit() {
        let userAccount = this.state.address;
        let username;
        if (this.state.depositAnother || this.state.userProfile == null) {
          username = this.state.username.toLowerCase();
        } else {
          username = (await vars.username()).toLowerCase();
          //username = this.state.userProfile.displayName.toLowerCase();
        }

        if (username.length == 0) {
          swal("Invalid Username", "You must enter a username");
          return;
        }

        if (/[^A-Za-z0-9]+/.test(username)) {
          swal("Invalid Username", "The username has invalid characters, in order to deposit BZN or ETH to that game account, it must have a valid username.", "warn");
          return
        }
    
        let bznAmount = vars.web3.utils.toWei(this.state.bznAmount.toString(), 'ether');
        let ethAmount = vars.web3.utils.toWei(this.state.ethAmount.toString(), 'ether');
    
        let depositBZNLimit = await vars.contracts.gameBalance.methods.depositBZNLimit().call();
        let depositETHLimit = await vars.contracts.gameBalance.methods.depositETHLimit().call();
    
        let actualBZN = await vars.contracts.bzn.methods.balanceOf(userAccount).call();
        let actualEther = await vars.web3.eth.getBalance(userAccount);
    
        if (vars.web3.utils.toBN(actualBZN).lt(vars.web3.utils.toBN(bznAmount))) {
          swal('Insufficent Funds', "You don't have enough BZN", 'error');
          return;
        }
    
        if (vars.web3.utils.toBN(actualEther).lt(vars.web3.utils.toBN(ethAmount))) {
          swal('Insufficent Funds', "You don't have enough ETH", 'error');
          return;
        }
    
        if (vars.web3.utils.toBN(bznAmount).gt(vars.web3.utils.toBN(depositBZNLimit))) {
          swal('Deposit Limit Reached', 'You may only deposit ' + vars.web3.utils.fromWei(depositBZNLimit, 'ether') + ' BZN at a time', 'error');
          return;
        }
    
        if (vars.web3.utils.toBN(ethAmount).gt(vars.web3.utils.toBN(depositETHLimit))) {
          swal('Deposit Limit Reached', 'You may only deposit ' + vars.web3.utils.fromWei(depositETHLimit, 'ether') + ' ETH at a time', 'error');
          return;
        }
    
        let abiEncoded = vars.web3.eth.abi.encodeParameters(['string'], [username]);
        try {
          swal({
            title: "Processing...",
            text: "Please wait while your transaction is confirmed",
            icon: loadingGif,
            className: 'wr-loader',
            button: false,
            closeOnClickOutside: false
          });
          //let gas = await vars.contracts.bzn.methods.approveAndCall(vars.contracts.gameBalance._address, vars.web3.utils.toHex(bznAmount.toLocaleString('fullwide', {useGrouping:false})), abiEncoded).estimateGas({from: userAccount, value: ethAmount});
          let depositResult = await vars.econtracts.bzn.approveAndCall(vars.contracts.gameBalance._address, vars.web3.utils.toHex(bznAmount.toLocaleString('fullwide', {useGrouping:false})), abiEncoded, {value: ethAmount});
          let depositr = await depositResult.wait(1);
          let tx = depositr.transactionHash;
          if (depositr.status) {
            var elem = document.createElement("div");
            elem.innerHTML = "<p style=\"color: black\">Your deposit was successfully processed and will usually appear in your game after 10 confirmations. In rare cases, it may take up to a few hours to complete.<br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
            swal({
              title: "Deposit Successful",
              content: elem,
              icon: "success"
            });
          } else {
            swal("Deposit Failed", "Your deposit could not be processed! We're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nYour Ether and BZN has been refunded.\nTransaction Hash: " + tx, "error")
          }
        } catch (e) {
          if (e.message.includes('User denied')) {
            swal.close();
          } else {
            swal("Deposit Failed", "Your deposit could not be processed, we're sorry :( Please provide the error to the devs to help figure out what went wrong.\nYour Ether and BZN has been refunded.\nError: " + e.message, "error");
          }
        }
    }
    
    async confirmDeposit() {
        if (this.state.bznAmount == 0 && this.state.ethAmount == 0) {
          swal('Deposit Something', 'You must deposit some amount of BZN and/or ETH', 'error');
          return;
        }

        if (this.state.bznAmount < 0 || this.state.ethAmount < 0) {
          swal('Invalid Amount', "You can't deposit a negative amount of BZN or ETH", 'error');
          return;
        }

        swal({
          title: "Loading...",
          text: "Please wait while things are configured..",
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });
    
        if (this.state.depositAnother || this.state.userProfile == null) {
          if (this.state.username.trim() == '') {
            swal('Empty Username', "You must enter the username of the game account you'd like to deposit to", 'error');
            return;
          } else {
            let result = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/usertoaddress/' + this.state.username);
    
            if (result.status == 500) {
              swal("User doesn't exist", "That username doesn't exist", "error");
              return;
            }
          }
        }

        let username = this.state.depositAnother || this.state.userProfile == null ? this.state.username : (await vars.username()).toLowerCase();
        let response = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/usertoaddress/' + username);
        let data = await response.json();

        let addressLinked;
        if (data.error) {
          swal("An Error Occured", data.message.split(':')[1].trim(), "error");
          return;
        } else {
          addressLinked = data.message;
          addressLinked = addressLinked.substring(0, 7) + "...." + addressLinked.substring(addressLinked.length - 7, addressLinked.length);
        }
    
        let self = this;
    
        let titleText = '';
        let descText = 'Please confirm that you would like to deposit ';
        
        if (this.state.depositAnother) {
          titleText += 'Send ';
        } else {
          titleText += 'Deposit ';
        }
    
        if (this.state.bznAmount > 0) {
          titleText += 'BZN';
          descText += this.state.bznAmount + ' BZN';
        }
        if (this.state.bznAmount > 0 && this.state.ethAmount > 0) {
          titleText += ' and ';
          descText += ' and ';
        }
    
        if (this.state.ethAmount > 0) {
          titleText += 'ETH';
          descText += this.state.ethAmount + ' ETH';
        }
    
        descText += ' to ';
    
        if (this.state.depositAnother || this.state.userProfile == null) {
          descText += this.state.username + "'s game account";
        } else {
          descText += 'your game account';
        }

        
        descText += " that is linked to the ETH address " + addressLinked;
    
        titleText += '?';
        descText += '?'
    
        swal({
          title: titleText,
          text: descText,
          icon: 'info',
          buttons: true
        }).then((willDeposit) => {
          if (willDeposit) {
            self.handleDeposit();
          }
        });
    }

    handleUserChange(event) {
        this.setState({username: event.target.value.toLowerCase()});
    }
    
    handleBZNAmount(event) {
        this.setState({bznAmount: event.target.value});
    }
    
    handleETHAmount(event) {
        this.setState({ethAmount: event.target.value});
    }
    
    handleDepositAnother(event) {
        this.setState({depositAnother: event.target.checked});
    }

    render() {
        if (this.state.goHome) {
            return <Redirect to='/' />
        }
        
        let userInput;
        let extraOption;
        if (this.state.userProfile == null) {
          userInput = <input className="username-field" placeholder="username" value={this.state.username} onChange={this.handleUserChange} />
          extraOption = <p style={{textAlign: 'center', width: '100%', marginTop: '30px', userSelect: 'none'}}>To auto-fill your username, you must <a href={vars.urls.linkAccount}>Login</a></p>
        } else {
          userInput = <input className="username-field" disabled={!this.state.depositAnother} placeholder={this.state.depositAnother || this.state.userProfile == null ? "username" : this.state.currentUsername.toLowerCase()} value={this.state.depositAnother || this.state.userProfile == null ? this.state.username : this.state.currentUsername.toLowerCase()} onChange={this.handleUserChange} />
          extraOption = <label data-tip data-for='buffer-info' for="bznAnotherBox" style={{textAlign: 'center', width: '100%', marginTop: '30px', userSelect: 'none'}}>
            Deposit to another game account 
            <input id="bznAnotherBox" type="checkbox" className="user-checkbox" value={this.state.depositAnother} onChange={this.handleDepositAnother} style={{marginTop: '0'}}></input>
          </label>
        }
    
        return (
          <div className="loginPage">
            <MetaTags>
              <title>War Riders Game Account</title>
              <meta property="og:title" content="War Riders Game Account" />
              <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
              <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
              <meta property="og:image" content={img.account}/>
              <meta property="og:url" content="https://app.warriders.com/link"/>
              <meta name="twitter:card" content="summary_large_image"/>
    
              <meta property="og:site_name" content="War Riders"/>
              <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
            </MetaTags>
            <ReactTooltip id='buffer-info' aria-haspopup='true' role='example'>
              <p>You must provide the correct username of the game account you'd like to deposit to</p>
            </ReactTooltip>
            <img src={img.account} />
            <h3>Deposit ETH or BZN to:</h3>
            {userInput}
            {extraOption}
            <p style={{textAlign: 'center'}}><a href="https://warriders.com/deposit-withdraw">Read this before depositing</a></p>
            <Grid>
              <Row>
                <Col xs={6}>
                  <p className='currency-text'>BZN Amount:</p>
                  <input style={{'backgroundColor': '#171717', color: 'white', borderRadius: '5px'}} className="deposit-input" type="number" placeholder="0.0" step="0.00001" min="0" value={this.state.bznAmount} onChange={this.handleBZNAmount} />
                </Col>
                <Col xs={6}>
                  <p className='currency-text'>ETH Amount:</p>
                  <input style={{'backgroundColor': '#171717', color: 'white', borderRadius: '5px'}} className="deposit-input" type="number" placeholder="0.0" step="0.00001" min="0" value={this.state.ethAmount} onChange={this.handleETHAmount} />
                </Col>
              </Row>
            </Grid>
            <a onClick={this.confirmDeposit} className="btn-bar" style={{ color: '#fff', width: '141px', margin: "0 auto", marginTop: '45px'}}>
              <span>Deposit</span>
            </a>
          </div>
        )
    }
}

export default Deposits;