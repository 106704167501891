import React from 'react';
import { ProgressBar, Col, Row, Grid } from 'react-bootstrap';
import './App.css';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'
import variables from './variables.js';
import "react-sweet-progress/lib/style.css";
import GradientSVG from './GradientSVG.js';
import { Contract, Provider } from 'ethers-multicall';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import swal from 'sweetalert';
import loadingGif from './images/loading.gif';
import MetaTags from 'react-meta-tags';
import ReactDom from 'react-dom';

let maxes = variables.maxes;
let urls = variables.urls;
let cars = variables.cars_table_data_minMax;
let gradients = variables.gradients;
const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];
//let web3 = variables.web3;
var hashesConfirmed = [];
let car_token_contract = variables.infoContracts.carToken;
let car_token_contract_real = variables.contracts.carToken;

function updateContracts() {
  car_token_contract = variables.infoContracts.carToken;
  car_token_contract_real = variables.contracts.carToken;
}

function toStr(x) {
  return (JSON.stringify(x))
}

var token_ids = [];

async function getMyTokens(uid, typeFilter) {
  const ethcallProvider = new Provider(variables.econtracts.carToken.provider);

  await ethcallProvider.init(); // Only required when `chainId` is not provided in the `Provider` constructor

  const carContract = new Contract(variables.econtracts.carToken.address, variables.abis.car_tkn);

  var tokenCount = await variables.contracts.carToken.methods.balanceOf(uid).call();

  tokenCount = Number(tokenCount);
  console.log("RRRR " + uid + " has " + tokenCount + " tokens");

  let calls = [];
  let type_calls = [];
  for (let i = 0; i < tokenCount; i++) {
    calls.push(carContract.tokenOfOwnerByIndex(uid, i));
  }

  let token_ids_temp = await ethcallProvider.all(calls);

  for (let i = 0; i < token_ids_temp.length; i++) { 
    type_calls.push(carContract.getCarType(token_ids_temp[i]));
  }

  let types = await ethcallProvider.all(type_calls);
  token_ids = []
  for (let i = 0; i < types.length; i++) {
    if (Number(types[i].toString()) == typeFilter)
      token_ids.push(token_ids_temp[i]);
  }

  return token_ids;
}

async function getFromURL(url) {
  return new Promise(function(resolve, reject) {
    return fetch(url)
     .then((response) => response.json())
     .then((responseJson) => {
       return resolve(responseJson);
     })
     .catch((error) => {
       reject(error);
     });
  })
}

async function getMetasForTokens(tokens) {
  return new Promise( async function(resolve, reject) {
      let metaURLs = [];
      for (let i = 0; i < tokens.length; i++) {
        let url = "https://vault.warriders.com/" + tokens[i] + ".json";

        let tokenId = tokens[i];
        metaURLs.push(getFromURL(url).then(function(new_meta) {
          if (new_meta.meta.tokenId == null) {
            new_meta.meta.tokenId = tokenId.toString();
          }
          return new_meta;
        }));
      }
      Promise.all(metaURLs).then(function(allCars) {
        resolve(allCars);
      }).catch(function(err) {
        reject(err);
      });
  })
}

function getCarType(type, car) {
  var img; // Name of correct car
  if (type == 0) {
    img = cars.premium[car].type;
  } else if (type == 1) {
    img = cars.midGrade[car].type;
  } else if (type == 2) {
    img = cars.regular[car].type;
  } else { return 'ERRORR!!!' }
  console.log('ZQ: Returning car: ' + img);
  return img;
}

function filterMetas(type, car, metas) {
  console.log('filter metas called with params: \ntype: ' + type + '\ncar: ' + car + '\nmetas: ' + metas)
  var name = getCarType(type, car);
  console.log('name of this car is ' + name)
  let children = Object.keys(metas).length;
  console.log('Metas children: ' + children)
  if (children > 1) {
    var returnValue = []
    var filteredMetas = metas.map(function(meta, index) {
      console.log('searching a piece of metas.. name: ' + meta.meta.type)
      if (meta.meta.type.includes(name)) {
        console.log('found match for ' + name + ' => ' + meta.meta.type)

        returnValue.push(meta);
      } else {
        console.log(meta.meta.type + ' did not match ' + name)
      }
    })
    console.log('Returning filtered metas: ' + returnValue)

    return returnValue;
  } else {
    if (metas[0].meta.type.includes(name)) {
      console.log('found match for ' + name + ' => ' + metas[0].meta.type)
      return metas;
    } else {
      console.log(metas[0].meta.type + ' did not match ' + name)
    }
  }


}

function getCarName(type, car) {
  var img; // Name of correct car
  if (type == 0) {
    img = cars.premium[car].name;
  } else if (type == 1) {
    img = cars.midGrade[car].name;
  } else if (type == 2) {
    img = cars.regular[car].name;
  } else { return 'ERRORR!!!' }
  console.log('ZQ: Returning car: ' + img);
  return img;
}

function filterTokens(type, car, metas, tokens) {
  console.log('filter metas called with params: \ntype: ' + type + '\ncar: ' + car + '\nmetas: ' + metas)
  var name = getCarType(type, car);
  console.log('name of this car is ' + name)
  let children = Object.keys(metas).length;
  console.log('Metas children: ' + children)
  if (children > 1) {
    var returnValue = []
    var filteredMetas = metas.map(function(meta, index) {
      console.log('searching a piece of metas.. name: ' + meta.meta.type)
      if (meta.meta.type.includes(name)) {
        console.log('found match for ' + name + ' => ' + meta.meta.type)

        returnValue.push(tokens[index]);
      } else {
        console.log(meta.meta.type + ' did not match ' + name)
      }
    })
    console.log('Returning filtered metas: ' + returnValue)

    return returnValue;
  } else {
    if (metas[0].meta.type.includes(name)) {
      console.log('found match for ' + name + ' => ' + metas[0].meta.type)
      return tokens;
    } else {
      console.log(metas[0].meta.type + ' did not match ' + name)
    }
  }


}

function getMainAndAltGunCountsFromArray(array) {
  console.log('getMainAndAltGunCountsFromArray INPUT: ' + array)
  var main = 0;
  var alt = 0;
  var map = array.map( function(gun) {
    if (gun == 'M' || gun == 'm') { main++; }
    else { alt++ };
  })
  let obj = {main: main, alt: alt};
  return obj;
}


function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

// Travel speed = (Engine power - Armor)/1.6
function calculateSpeed(engineSize, armor) {
return ((engineSize - armor)/1.6);
}
// Acceleration Ratio = Armor / Engine Power
// Acceleration = (Travel speed/10) / (Acceleration Ratio + 1)
function calculateAcceleration(engineSize, armor) {
var ratio = armor / engineSize;
var speed = calculateSpeed(engineSize, armor);
var acc = (speed/10) / (ratio + 1);
return acc;
}

var ProgressComponent = (num, max, title, titleColor, dimension) => {
  console.log('ProgressComponent > PARAMS: \nNum: ' + num + '\nMAX: ' + max + '\nTITLE: ' + title)
  return (
    <div style={{width: '100%'}}>
      <Row>
        <Col xs={12} sm={4}></Col>
        <Col xs={12} sm={8}>
          <h5><span style={{color: COLORS[2]}}>{num}</span> out of <span style={{color: COLORS[3]}}>{max}</span> <span dangerouslySetInnerHTML={{ __html: dimension }} /></h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <h4 style={{marginTop: '0%', color: titleColor}}>{title}</h4>
        </Col>
        <Col xs={12} sm={8}>
          <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
            <ProgressBar now={(num/max)*100} key={1} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
          </ProgressBar>
        </Col>
      </Row>
    </div>
  );
}
function getImg(type, car) {
  var img; // Name of correct car
  if (type == 0) {
    img = cars.premium[car].img;
  } else if (type == 1) {
    img = cars.midGrade[car].img;
  } else if (type == 2) {
    img = cars.regular[car].img;
  } else { return 'ERRORR!!!' }

  return img;
}

const DEFAULT_INPUT_TEXT = "";
class MyInput extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        text: DEFAULT_INPUT_TEXT,
      };
    }

    changeText(e) {
      let text = e.target.value;

      this.setState({
        text,
      });

      /*
       * This will update the value that the confirm
       * button resolves to:
       */
      swal.setActionValue(text);
    }

    render() {
      return (
        <input
            style={{color: 'black', padding: '2px', width: '80%'}}
            value={this.state.text}
            onChange={this.changeText.bind(this)}
        />
      )
    }
}


class ViewKitsOwned extends React.Component {

  constructor(props) {
    super(props);
    let type = props.type
    let car = props.car
    let img = getImg(type, car);
    this.state = {
      type: type,
      car: car, // variant index
      cars: [],
      metas: [],
      img: img,
      goHome: false
    };

    this.transferPrompt = this.transferPrompt.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    var self = this;
    var type = this.state.type;
    var car = this.state.car;
    swal({
      title: "Loading...",
      text: "Please wait while the kits are loaded",
      icon: loadingGif,
      className: 'wr-loader',
      button: false,
      closeOnClickOutside: false
    });
    if (!variables.isWeb3Known()) {
      await variables.askForWeb3();
    }
    variables.getAccounts().then( function(accounts) {
      console.log('Found accounts: ' + accounts)
      updateContracts();
        let address = accounts[0];
        self.setState ({ currentId: address });
        getMyTokens(address, car + 1).then( function(tokens) {
          console.log('XX got tokens: ' + tokens);
          getMetasForTokens(tokens).then( function(metas) {
            if (metas && typeof metas == 'object') {
              console.log('XX got metas as object: ' + JSON.stringify(metas))
              let filteredMetas = filterMetas(type, car, metas);
              let filteredTokens = filterTokens(type, car, metas, tokens);
              self.setState ({
                cars: filteredTokens,
                metas: filteredMetas
              });
            } else if (metas && typeof metas == 'array') {
              console.log('Not supposed to happen.. check this out')
            }
            swal.close();
          }.bind(this)).catch( function(err) { console.log(err) })
        }.bind(this)).catch(function(err) { console.log(err) });
    }.bind(this)).catch(function(err) {
      console.log('NO ACCOUNTSS - componentDidMount: ' + err)
      console.log('NO ACCOUNTSS - componentDidMount: ' + err)
      swal.close();
      var elem = document.createElement("div");
      elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
      swal({
        title: "No External Wallet",
        content: elem,
        icon: "error"
      }).then(function() {
        self.setState({
          goHome: true
        })
      });
    })
  }

  getTokenAtIndex = (index) => {
    console.log('Getting token at index: ' + index)
    if (this.state.cars) {
      console.log('metas are good')
      if (this.state.cars[index]) {
        console.log('index is good, meta for index selected is: ' + JSON.stringify(this.state.cars[index]) + '\n Returning ' + this.state.cars[index])
        return this.state.cars[index].toString();
      } else {
        return 'NaN(2)';
      }
    } else {
      return 'NaN(1)';
    }
  }

  async transferPrompt(metadata) {
    let self = this;
    let wrapper = document.createElement('div');
    ReactDom.render(<MyInput />, wrapper);
    let el = wrapper.firstChild;

    let isBatch = this.state.batchNumber == -2;

    let name = metadata.name;

    let value = await swal({
        title: "Transfer " + name,
        text: "Who would you like to transfer " + name + " to:",
        icon: metadata.image,
        content: el,
        buttons: {
            confirm: {
                /*
                * We need to initialize the value of the button to
                * an empty string instead of "true":
                */
                value: DEFAULT_INPUT_TEXT,
            }
        },
    });

    if (value == null || value == "")
            return;
    
    if (value.endsWith('.eth')) {
        value = await variables.web3.eth.ens.getAddress(value);
    }
    
    if (!variables.web3.utils.isAddress(value)) {
        swal('Invalid Address', 'Please enter a valid address', 'error').then(function() {
            self.transferPrompt(metadata);
        })
    } else {
        let result = await swal({
            title: 'Please Confirm',
            text: 'Would you like to transfer ' + name + ' to ' + value + ' ?',
            icon: 'warning',
            buttons: true,
            confirmButtonText: 'Yes, transfer it'
        });

        if (result) {
            swal({
                title: "Loading...",
                text: "Please wait while " + name + " is transferred to " + value,
                icon: loadingGif,
                className: 'wr-loader',
                button: false,
                closeOnClickOutside: false
            });

            try {
              let transferResult = await variables.econtracts.carToken.transferFrom(self.state.currentId, value, metadata.meta.tokenId);
              let transferr = await transferResult.wait(1);
              let tx = transferr.transactionHash;
              if (transferr.status) {
                var elem = document.createElement("div");
                elem.innerHTML = "<p style=\"color: black\">" +  name + " has been successfully been transferred to " + value + "<br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
                swal({
                    title: "Transfer Successful",
                    content: elem,
                    icon: "success"
                }).then(function() {
                    self.fetchData(self.state.batchNumber);
                });
              } else {
                swal("Transfer Failed", "Your transfer could not be processed, we're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nTransaction Hash: " + tx, "error");
              }
            } catch (e) {
              if (e.message.includes('User denied')) {
                swal.close();
              } else {
                swal("Transfer Failed", "Your transfer could not be processed, we're sorry :( Please provide the error to the devs to help figure out what went wrong.\nError: " + e.message, "error");
              }
            }
          }
        }
      }
  render() {
    if (this.state.goHome) {
      return <Redirect to='/' />
    }

    let metas = this.state.metas;
    console.log('this.state.metas = {' + toStr(metas) + '}')
    console.log('this.state.data = {' + toStr(this.state.data) + '}')
    let type = this.state.type;
    let car = this.state.car;
    let name = getCarName(type, car);
    console.log('Typeof Metas: ' + (typeof metas))

    let self = this;

    let cond1 = (metas && typeof metas == 'object');
    let cond2 = (metas &&  typeof metas == 'array')


    console.log((cond1) ? 'Metas exist and are an object' : 'Condition 1 failed!')
    console.log((cond2) ? 'Metas exist, but are not an object' : 'Condition 2 failed!')
    var MetasComponent;
    if (cond1) {
      let children = Object.keys(metas).length;
      console.log('Metas children: ' + children)



       MetasComponent =
      (children > 1) ? (
        metas.map( function(meta, index) {

          let mainSlots = getMainAndAltGunCountsFromArray(meta.meta.gunSlots).main;
          let altSlots = getMainAndAltGunCountsFromArray(meta.meta.gunSlots).alt;
          let eq = meta.meta.equipmentSlots;
          console.log('in metas loop, here is component: ' + toStr(meta))
          console.log('Gun slots: ' + mainSlots + ' and ' + altSlots);
          console.log('Car ' + index + ' has tank size of ' + meta.meta.BZNtank)
          var svg = ( <GradientSVG
                       idCSS='sxxx'
                       rotation={90}
                     />);
          return (
            <Col sm={4} md={4} style={{padding: '10px'}}>
              <div style={{border: '4px solid white', borderRadius: '15px'}}>

                <Row>

                  <h4 style={{lineHeight: '30px'}}>{name} #{index+1}</h4>
                  <p>Token ID: <a target="_blank" href={"https://opensea.io/assets/0x5caebd3b32e210e85ce3e9d51638b9c445481567/" + self.getTokenAtIndex(index)}>{self.getTokenAtIndex(index)}</a></p>
                </Row>
                <Row>
                  <img style={{width: '50%'}} src={meta.image} ></img>
                </Row>
                <Row style={{width: '90%'}} className='centered'>
                  <div>{ProgressComponent(calculateSpeed(meta.meta.engineSize, meta.meta.armor),maxes.speed, 'Speed', COLORS[1], ' MPH')}</div>
                  <div>{ProgressComponent(roundToTwo(calculateAcceleration(meta.meta.engineSize, meta.meta.armor)),maxes.acc, 'Accel.', COLORS[2], ' MPH<sup>2</sup>')}</div>
                  <div>{ProgressComponent(meta.meta.BZNtank,maxes.bznTank, 'BZN Tank', COLORS[3], ' BZN')}</div>
                  <div>{ProgressComponent(meta.meta.armor,maxes.armor, 'Armor', COLORS[4], ' UNITS')}</div>
                </Row>
                <Row style={{width: '95%'}}  className='centered'>
                  <Col xs={4} sm={4} md={4}>
                    <div >
                      {svg}
                          <h4 style={{height: '34px'}} className='centered'>Main Guns</h4>
                          <br></br>
                          <CircularProgressbar percentage={ mainSlots / maxes.mainGunSlots*100} text={`${mainSlots}`} styles={{ width: '95%',  trail: { stroke: `#888888` }}}></CircularProgressbar>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4}>
                    <div>
                      {svg}
                      <h4 style={{height: '34px'}} className='centered'>Alt Guns</h4>
                      <br></br>
                      <CircularProgressbar percentage={ altSlots / maxes.altSlots*100} text={`${altSlots}`} styles={{ width: '95%',  trail: { stroke: `#888888` }}}></CircularProgressbar>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4}>
                    <div>
                      {svg}
                      <h4 style={{height: '34px'}} className='centered'>Equip Slots</h4>
                      <br></br>
                      <CircularProgressbar percentage={(eq)/maxes.equipmentSlots*100} text={`${eq}`} styles={{ width: '95%',  trail: { stroke: `#888888` }}}></CircularProgressbar>
                    </div>
                  </Col>

                </Row>
                <Row>
                  <Col sm={6} md={6}>
                    <Link to={urls.viewMyCar(type, car, self.getTokenAtIndex(index))}>
                    <a className="btn" type="button" style={{width: '140px', marginTop: '70px'}}>
                      <span>VIEW</span>
                    </a>
                    </Link>
                  </Col>
                  <Col sm={6} md={6}>
                      <a onClick={_ => self.transferPrompt(meta)} className="btn" type="button" style={{width: '140px', marginTop: '70px'}}>
                          <span>TRANSFER</span>
                      </a>
                  </Col>
              </Row>
                <Row><div style={{width: '100%', height: '30px'}}></div></Row>
                </div>
              </Col>
          );
        })
      ) : ((children == 1) ? (
            <Col sm={4} md={4} style={{padding: '10px'}}>
              <div style={{border: '4px solid white', borderRadius: '15px'}}>

                <Row>
                  <h2>{name} #1</h2>
                  <p>Token ID: <a target="_blank" href={"https://opensea.io/assets/0x5caebd3b32e210e85ce3e9d51638b9c445481567/" + metas[0].meta.tokenId}>{metas[0].meta.tokenId}</a></p>
                </Row>
                <Row>
                  <img style={{width: '50%'}} src={metas[0].image} ></img>
                </Row>
                <Row style={{width: '90%'}} className='centered'>
                  <div>{ProgressComponent(calculateSpeed(metas[0].meta.engineSize, metas[0].meta.armor),maxes.speed, 'Speed', COLORS[1], ' MPH')}</div>
                  <div>{ProgressComponent(roundToTwo(calculateAcceleration(metas[0].meta.engineSize, metas[0].meta.armor)),maxes.acc, 'Accel.', COLORS[2], ' MPH<sup>2</sup>')}</div>
                  <div>{ProgressComponent(metas[0].meta.BZNtank,maxes.bznTank, 'BZN Tank', COLORS[3], ' BZN')}</div>
                  <div>{ProgressComponent(metas[0].meta.armor,maxes.armor, 'Armor', COLORS[4], ' UNITS')}</div>
                </Row>
                <Row style={{width: '95%'}}  className='centered'>
                  <Col xs={4} sm={4} md={4}>
                    <div >
                      {<GradientSVG
                                   idCSS='sxxx'
                                   rotation={90}
                                 />}
                          <h4 style={{height: '34px'}} className='centered'>Main Guns</h4>
                          <br></br>
                          <CircularProgressbar percentage={ getMainAndAltGunCountsFromArray(metas[0].meta.gunSlots).main/ maxes.mainGunSlots*100} text={`${getMainAndAltGunCountsFromArray(metas[0].meta.gunSlots).main}`} styles={{ width: '95%',  trail: { stroke: `#888888` }}}></CircularProgressbar>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4}>
                    <div>
                      {<GradientSVG
                                   idCSS='sxxx'
                                   rotation={90}
                                 />}
                      <h4 style={{height: '34px'}} className='centered'>Alt Guns</h4>
                      <br></br>
                          <CircularProgressbar percentage={getMainAndAltGunCountsFromArray(metas[0].meta.gunSlots).alt / maxes.altSlots*100} text={`${getMainAndAltGunCountsFromArray(metas[0].meta.gunSlots).alt}`} styles={{width: '95%',  trail: { stroke: `#888888` }}}></CircularProgressbar>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4}>
                    <div>
                      {<GradientSVG
                                   idCSS='sxxx'
                                   rotation={90}
                                 />}
                      <h4 style={{height: '34px'}} className='centered'>Equip Slots</h4>
                      <br></br>
                          <CircularProgressbar percentage={(metas[0].meta.equipmentSlots)/maxes.equipmentSlots*100} text={`${metas[0].meta.equipmentSlots}`} styles={{ width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                    </div>
                  </Col>

                </Row>
                <Row>
                <Col sm={6} md={6}>
                    <Link to={urls.viewMyCar(type, car, self.getTokenAtIndex(0))}>
                    <a className="btn" type="button" style={{width: '140px', marginTop: '70px'}}>
                      <span>VIEW</span>
                    </a>
                    </Link>
                  </Col>
                  <Col sm={6} md={6}>
                      <a onClick={_ => self.transferPrompt(metas[0])} className="btn" type="button" style={{width: '140px', marginTop: '70px'}}>
                          <span>TRANSFER</span>
                      </a>
                  </Col>
              </Row>
                <Row><div style={{width: '100%', height: '30px'}}></div></Row>
                </div>
              </Col>
      ) : (<div><h1>Vehicle Inventory Empty</h1></div>))

    } else {
      MetasComponent = (<div><h1>Vehicle Inventory Empty</h1></div>)
      document.getElementById("kits-owned").removeAttribute("style");
    }




    return (
      <div id='VehicleSelectBuy' style={{textAlign: 'center'}}>
        <MetaTags>
            <title>Car Garage</title>
            <meta property="og:title" content="Car Garage" />
            <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:image" content={this.state.img}/>
            <meta property="og:url" content={"https://app.warriders.com/garage/" + this.state.type + '/' + this.state.car}/>
            <meta name="twitter:card" content="summary_large_image"/>

            <meta property="og:site_name" content="War Riders"/>
            <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
          </MetaTags>
          <div>
            <div><br></br><br></br><br></br><br></br><br></br><br></br></div>
            <Grid>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <h2>View {name}s</h2>
                </Col>
              </Row>
              <Row>
                <img src={this.state.img} style={{color: '#680aff', width: '60%'}}></img>
              </Row>
              <Row>

              </Row>
            </Grid>
            <div>{MetasComponent}</div>
            <div><br></br><br></br><br></br></div>
          </div>
      </div>
    )
  }

}

export default ViewKitsOwned;
