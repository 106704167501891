import React, { Component } from 'react';
import ReactDom from 'react-dom';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import imgs from './ImgFactory.js';
import { Progress } from 'react-sweet-progress';
import { Line, Circle } from 'rc-progress';
import "react-sweet-progress/lib/style.css";
import ScrollAnimation from 'react-animate-on-scroll';
import {LineChart, ComposedChart, ScatterChart, Scatter, Dot, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, AreaChart, Area, ReferenceLine, Cell, BarChart, Bar, LabelList} from 'recharts';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import {Helmet} from "react-helmet";
import NumericInput from 'react-numeric-input';
import axios from 'axios';
import Countdown from 'react-countdown-now';
import logo from './logo.svg';
import Referral from './Referral.js';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './App.css';
import moment from 'moment';
import vars from './variables.js';
import MetaTags from 'react-meta-tags';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import GradientSVG from './GradientSVG.js';
import Popup from 'react-popup';
import posed from 'react-pose';
import styled from "styled-components";
import { tween, transform } from "popmotion";
import { CSSTransitionGroup } from 'react-transition-group' // ES6
import ReactPlayer from 'react-player'
import { timeline } from 'popmotion';
import swal from 'sweetalert';
import ReactGA from 'react-ga';
import loadingGif from './images/loading.gif';
String.prototype.hashCode = function() {
    var hash = 0;
    if (this.length == 0) {
        return hash;
    }
    for (var i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

const { pipe, clamp } = transform;
var cars = vars.cars_table_data_minMax;
//let web3 = vars.web3;
let gradients = vars.gradients;
const Box = posed.div({});

let preorder_contract = vars.contracts.preorder;
let info_preorder_contract = vars.infoContracts.preorder;
let preorder_migrate_contract = vars.contracts.preorder_migrate;
let info_preorder_migrate_contract = vars.infoContracts.preorder_migrate;
let preorder_batch_contract = vars.contracts.preorder_batch;
let car_token_contract = vars.infoContracts.carToken;
let preorder_address = preorder_contract.address;

function updateContracts() {
  preorder_contract = vars.contracts.preorder;
  info_preorder_contract = vars.infoContracts.preorder;
  preorder_migrate_contract = vars.contracts.preorder_migrate;
  info_preorder_migrate_contract = vars.infoContracts.preorder_migrate;
  preorder_batch_contract = vars.contracts.preorder_batch;
  car_token_contract = vars.infoContracts.carToken;
  preorder_address = preorder_contract.address;
}
//var localhost = 'http://127.0.0.1:1234';
var localhost = 'https://api.warriders.com/';
var URLs = vars.urls;
var maxes = vars.maxes;

function log(x) {
  //console.log('(Z) ' + x);
}

let app_local_server = 'https://app.warriders.com';
let homeDir = app_local_server + '/selectType/';

const increasingFXdata = [
  { ourXvalue: 1, Price: 2, extraData: 100 },
  { ourXvalue: 2, Price: 4, extraData: 120 },
  { ourXvalue: 3, Price: 6, extraData: 150 },
  { ourXvalue: 4, Price: 10, extraData: 180 },
  { ourXvalue: 5, Price: 15, extraData: 200 },
  { ourXvalue: 6, Price: 21, extraData: 499 },
  { ourXvalue: 7, Price: 28, extraData: 50 },
  { ourXvalue: 8, Price: 36, extraData: 100 },
  { ourXvalue: 9, Price: 45, extraData: 200 },
  { ourXvalue: 10, Price: 55, extraData: 222},
  { ourXvalue: 11, Price: 66, extraData: 210 }
];

const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}

function getThreeAdvertisementCars(callback) {
  var premiumCarsSize = cars.premium.length;
//   var mgCarsSize = cars.midGrade.length;
//   var regCarsSize = cars.regular.length;
//   var max = premiumCarsSize + mgCarsSize + regCarsSize;
//   var possible = [];
//   for (var i = 0; i < max; i++) {
//     possible.push(i);
//   }
//   var mutablePossible = possible;
//   var ads = [];
//   for (var i = 0; i < 3; i++) {
//     let randIndex = getRndInteger(0,mutablePossible.length-1);
//     ads.push(possible[randIndex]);
//     mutablePossible.splice(randIndex, 1);
//   }
//
// var adCarsList = [];
//
//   for (var i = 0; i < ads.length; i++) {
//     if (ads[i] < premiumCarsSize) { // Is premium car
//       let index = i;
//       let car = cars.premium[index];
//       adCarsList.push(car);
//     } else if (ads[i] < (premiumCarsSize+mgCarsSize)) { // Mid Grade
//       let index = i - premiumCarsSize;
//       let car = cars.midGrade[index];
//       adCarsList.push(car);
//     } else {
//       let index = i - premiumCarsSize - mgCarsSize;
//       let car = cars.regular[index];
//       adCarsList.push(car);
//     }
//   }

  var possible = [];
  var max = premiumCarsSize;
  for (var i = 0; i < max; i++) {
    possible.push(i);
  }
  //console.log('possible are: ' + JSON.stringify(possible))
  var mutablePossible = possible;
    var ads = [];
    for (var i = 0; i < 3; i++) {
      let randIndex = getRndInteger(0,mutablePossible.length-1);
      ads.push(possible[randIndex]);
      mutablePossible.splice(randIndex, 1);
    }
  //console.log('ads are: ' + JSON.stringify(ads))
  var adCarsList = [];
  var indexList = [];

    for (var i = 0; i < ads.length; i++) {
        let index = ads[i];
        let car = cars.premium[index];
        adCarsList.push(car);
        indexList.push(index);
    }
  //console.log('final list is: ' + JSON.stringify(adCarsList))
  callback(adCarsList, indexList);

}

function imageForVehicleIndex(index) {
  switch (index) {
    case 0: return imgs.tanker; break;
    case 1: return imgs.lambo; break;
    case 2: return imgs.carRegular; break;
    default: return imgs.car;
  }
}

function getColorForValue(value) {

  if (value <= 25) {  return COLORS[3] }
  else if (value <= 50) {return COLORS[2]}
  else if (value <= 75) { return COLORS[1]}
  else {  return COLORS[0]}
}
// Random component
const Completionist = () => <span style={{color: '#a922ed'}}><strong>SALE HAS ENDED</strong></span>;
const conditionalRenderer = (value) => {
  if (value == 'DNE') { return <span style={{color: 'black'}}><strong>{value}</strong></span>; }
   else { return <span style={{color: '#a922ed'}}><strong>{value}</strong></span>; }
};
// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span style={{color: '#a922ed'}}>{days} : {hours} : {minutes} : {seconds}</span>;
  }
};
let indices = {
  speed: 1,
  acc: 2,
  armor: 3,
  bznTank: 4,
  engine: 5,
  mainGuns: 6,
  altGuns: 7,
  equipmentSlots: 8,
  nos: 9
};

function convertValue(index, value) {
  //console.log('Converting value ' + value + ' with index ' + index);
  // Get value to 100 (standardize)
  var returning;
  //console.log(index);
  //console.log(indices.speed);
  //console.log(maxes.speed);
  if (index == indices.speed) {
    //console.log('maxes.speed: ' + maxes.speed);
    let max = maxes.speed;
    let conversion = 100/max;
    returning = value * conversion;
  } else if (index === indices.acc) {
    let max = maxes.acc;
    let conversion = 100/max;
    returning = value * conversion;
  } else if (index === indices.armor) {
    let max = maxes.armor;
    let conversion = 100/max;
    returning = value * conversion;
  } else if (index === indices.bznTank) {
    let max = maxes.bznTank;
    let conversion = 100/max;
    returning = value * conversion;
  } else if (index === indices.engine) {
    let max = maxes.engine;
    let conversion = 100/max;
    returning = value * conversion;
  } else if (index === indices.mainGuns) {
    let max = maxes.mainGunSlots;
    let conversion = 100/max;
    returning = value * conversion;
  } else if (index === indices.altGuns) {
    let max = maxes.altSlots;
    let conversion = 100/max;
    returning = value * conversion;
  } else if (index === indices.equipmentSlots) {
    let max = maxes.equipmentSlots;
    let conversion = 100/max;
    returning = value * conversion;
  } else if (index == indices.nos) {
    if (value) { return 100; } else { return 0; }
  }
  //console.log('Returning (Converted value): ' + returning);
  return returning;
}

function roundToThree(num) {
    return +(Math.round(num + "e+3")  + "e-3");
}
function roundToFour(num) {
    return +(Math.round(num + "e+4")  + "e-4");
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

function roundToEight(num) {
    return +(Math.round(num + "e+8")  + "e-8");
}

function getCarTypeForIndex(index) {
  switch (index) {
    case 0:
      return 'Premium'.toUpperCase()
      break;
      case 1:
        return 'Mid-Grade'.toUpperCase()
        break;
        case 2:
          return 'Regular'.toUpperCase()
          break;
    default:
     return 'DNE';
  }
}
function getImageForIndex(array, index) {
  switch (array) {
    case 0:
      return cars.premium[index].img;
      break;
      case 1:
        return cars.midGrade[index].img;
        break;
        case 2:
          return cars.regular[index].img;
          break;
    default:
     return 'DNE';
  }
}
function getNameForIndex(array, index) {
  switch (array) {
    case 0:
      return cars.premium[index].name.toUpperCase();
      break;
      case 1:
        return cars.midGrade[index].name.toUpperCase();
        break;
        case 2:
          return cars.regular[index].name.toUpperCase();
          break;
    default:
     return 'DNE';
  }
}
function getDataForIndex(array, index) {
  switch (array) {
    case 0:
      return cars.premium[index].data;
      break;
      case 1:
        return cars.midGrade[index].data;
        break;
        case 2:
          return cars.regular[index].data;
          break;
    default:
     return 'DNE';
  }
}
function getCar(type, car) {
  var img; // Name of correct car
  if (type == 0) {
    img = cars.premium[car];
  } else if (type == 1) {
    img = cars.midGrade[car];
  } else if (type == 2) {
    img = cars.regular[car];
  } else { return 'ERRORR!!!' }
  //console.log('ZQ: Returning car: ' + img);
  return img;
}

function getXvalues(CURRENT) {
  //console.log('getXVals input: ' + CURRENT)
  let curr = Number(CURRENT);
    var xValues = [];
    for (var i = curr; i < curr + 1000; i+=1) {
      //console.log('getXvals appending ' + i)
      xValues.push(i);
    }
    return xValues;
}



class ImgComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
      img: props.img
    }
  }
  render() {
    const MyComponent = ({ hostRef }) => <div ref={hostRef} ><img style={{width: '50%'}} src={this.state.img}></img></div>

    const View = posed.div({
      idle: { scale: 1, opacity: 1,
        transition: { duration: 30000 }
       },
      hovered: { scale: 1.5, opacity: 0.3,
        transition: { duration: 30000 }
       }
    });

    const StyledView = styled(View)`
      width: 200px;`;

    return (

        <StyledView
          pose={this.state.hovering ? "hovered" : "idle"}
          onMouseEnter={() => this.setState({ hovering: true })}
          onMouseLeave={() => this.setState({ hovering: false })}
        >
          <h1>hi</h1>
          <MyComponent />
      </StyledView>
    );
  }
}

/*

Domain = [ 0 , max_x_value ]
20 points + nowPoint

for loop:
x value = max_x / 20 x index
y value = price => base_price ^ x_value

append ( currentQuantity, currentPrice)

*/

const DraggableCircle = posed.circle({
  draggable: 'x',
  dragBounds: { left: 0, right: 100 }
})

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function riseData(type, car, base_price, current_price, current_quantity, totalSupply) {
  let dots = 100;
  let PRICE_INCREASE = getMultipleFor(type, car);
  var data = [];
  let temp = 0;
  let currentprice = base_price;

  if (car == 3 || car == 4 || car == 5) {
    current_quantity -= 7;
  }

  if (car == 0) {
    base_price += 0.06604 - 0.0372;
  } else if (car == 1) {
    base_price += 0.113008 - 0.0691;
  } else if (car == 2) {
    base_price += 0.034;
  }

  for (var i = 0; i < dots; i++) {
    let x = totalSupply / dots * i;
    //log('base: ' + base_price + ' | pwr: ' + x);
    let y = base_price * Math.pow(PRICE_INCREASE, x);
    //log('riseData pow result: ' + y)
    let obj = {quantity: Math.round(x), Price: round(y, 6), z: 80};
    //log('riseData appending: ' + toStr(obj));
    data.push(obj);
  }
  let current_obj = {quantity: current_quantity, Price: current_price, z: 90};
  data.push(current_obj);
  return data;
}


// END USELESS

// Travel speed = (Engine power - Armor)/1.6
function calculateSpeed(engineSize, armor) {
return ((engineSize - armor)/1.6);
}
// Acceleration Ratio = Armor / Engine Power
// Acceleration = (Travel speed/10) / (Acceleration Ratio + 1)
function calculateAcceleration(engineSize, armor) {
var ratio = armor / engineSize;
var speed = calculateSpeed(engineSize, armor);
var acc = (speed/10) / (ratio + 1);
return acc;
}


var hashesConfirmed = [];

var ProgressComponent = (cmin, cmax, max, title) =>
(
  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row style={{marginTop: '-2px', marginBotton: '-2px'}}>
      <Col xs={12} sm={4}></Col>
      <Col xs={12} sm={8}>
        <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span></h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={3}>
        <h4 style={{marginTop: '0%'}}>{title}</h4>
      </Col>
      <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={2} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
      </Col>
      <Col xs={12} sm={1}></Col>
    </Row>
  </ScrollAnimation>
  </div>
)

var speedProgressComponent = (cmin, cmax, max, title) =>
(
  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row style={{marginTop: '-2px', marginBotton: '-2px'}}>
      <Col xs={12} sm={4}></Col>
      <Col xs={12} sm={8}>
        <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span> MPH</h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={3}>
        <h4 style={{marginTop: '0%'}}>{title}</h4>
      </Col>
      <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={2} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
      </Col>
      <Col xs={12} sm={1}></Col>
    </Row>
  </ScrollAnimation>
  </div>
)

var accProgressComponent = (cmin, cmax, max, title) =>
(
  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row style={{marginTop: '-2px', marginBotton: '-2px'}}>
      <Col xs={12} sm={4}></Col>
      <Col xs={12} sm={8}>
        <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span> MPH<sup>2</sup></h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={3}>
        <h4 style={{marginTop: '0%'}}>{title}</h4>
      </Col>
      <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={2} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
      </Col>
      <Col xs={12} sm={1}></Col>
    </Row>
  </ScrollAnimation>
  </div>
)

var bznProgressComponent = (cmin, cmax, max, title) =>
(
  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row style={{marginTop: '-2px', marginBotton: '-2px'}}>
      <Col xs={12} sm={4}></Col>
      <Col xs={12} sm={8}>
        <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span> BZN</h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={3}>
        <h4 style={{marginTop: '0%'}}>{title}</h4>
      </Col>
      <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={2} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
      </Col>
      <Col xs={12} sm={1}></Col>
    </Row>
  </ScrollAnimation>
  </div>
)

var armorProgressComponent = (cmin, cmax, max, title) =>
(
  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row style={{marginTop: '-2px', marginBotton: '-2px'}}>
      <Col xs={12} sm={4}></Col>
      <Col xs={12} sm={8}>
        <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span> UNITS</h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={3}>
        <h4 style={{marginTop: '0%'}}>{title}</h4>
      </Col>
      <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={2} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
      </Col>
      <Col xs={12} sm={1}></Col>
    </Row>
  </ScrollAnimation>
  </div>
)

var singleProgressComponent = (cval, max, title) =>
(

  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row style={{marginTop: '-2px', marginBotton: '-2px'}}>
      <Col xs={12} sm={4}></Col>
      <Col xs={12} sm={8}>
        <h5><span style={{color: COLORS[1]}}>{cval}</span> out of <span style={{color: COLORS[2]}}>{max}</span></h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={4}>
        <h4 style={{marginTop: '0%'}}>{title}</h4>
      </Col>
      <Col xs={12} sm={8}>
      <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
        <ProgressBar now={cval/max*100} key={1} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
      </ProgressBar>
      </Col>
    </Row>
  </ScrollAnimation>
  </div>
)

var nosProgressComponent = (hasNos, title) =>
(
  <div>
    <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50}>
    <Row>
      <Col xs={12} sm={4}></Col>
      <Col xs={12} sm={8}>
        <h5>{(hasNos) ? 'Has NOS equipment' : 'Doesn\'t have NOS!' }</h5>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={4}>
        <h4 style={{marginTop: '0%'}}>{title}</h4>
      </Col>
      <Col xs={12} sm={8}>
      <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
        <ProgressBar now={(hasNos) ? 100 : 0} key={1} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
      </ProgressBar>
      </Col>
    </Row>
  </ScrollAnimation>
  </div>
)

function randomTokenId() {
  let POSSIBLES = '0123456789';
  let ID_LENGTH = 8;
  var id = '';
  for (var i = 0; i < ID_LENGTH; i++) {
    id += POSSIBLES.charAt(Math.floor(Math.random() * POSSIBLES.length));
  }
  log('-randTKNid() - : id=' + id)
  log('-randTKNid() - : Number(id)=' + Number(id))
  return Number(id);
}

function getFinalIndex(_type, _index) {
    let type = Number(_type);
    let index = Number(_index);
    //console.log('Error1 on getFinalIndex input!! Recieved type ' + type + ' and index ' + index)
  let premCars = 5;
  let mgCars = 3;
  let regCars = 3;
  if (type == 0 ) {
    return index+1;
  } else if (type == 1) {
    return premCars + index+1;
  } else if (type == 2) {
    return premCars + mgCars + index+1;
  } else {
    console.log('Error1 on getFinalIndex input!! Recieved type ' + type + ' and index ' + index)
  }
}

class Popupp extends React.Component {
    render() {
      return (
        <Popup trigger={<button> Trigger</button>} position="right center">
          <div>Popup content here !!</div>
        </Popup>
      );
    }
};


class CustomizedDot extends React.Component {
    render() {
        const { cx, cy } = this.props;

        return (
            <circle cx={cx - 10} cy={cy - 10} r={25} stroke="red" strokeWidth={9} fill="red" />
        );
    }
  };

  class CDot extends React.Component {
      render() {
          const { cx, cy } = this.props;

          return (
              <circle cx={cx - 10} cy={cy - 10} r={25} stroke="black" strokeWidth={3} fill="red" />
          );
      }
    };

class CustomChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      currentQuantity: props.currentQuantity
    };
    var data = JSON.stringify(props.data);

    //console.log('the ever-increasing data has ' + data.length + ' pieces!')
  }
  render() {
    // X-AXIS: domain={[0, this.state.data[this.state.data.length-1].quantity]}
    if (this.state.data.length > 0) {
        //console.log('asdasdasd', this.state.data);
      return (
          <ResponsiveContainer width="95%" height={300}>
            <ScatterChart margin={{top: 30, right: 0, left: 0, bottom: 5}}>
              <XAxis type="number" dataKey={'quantity'} name='Quantity' unit=' Cars' />
              <YAxis width={100} type="number" dataKey={'Price'} name='Price' unit=' ETH' domain={[this.state.data[0].Price, this.state.data[this.state.data.length-1].Price]}/>
              <ZAxis dataKey={'z'} range={[10, 100]} name='Reference Size' unit=' units'/>
             <Tooltip/>
             <Legend />
             <Scatter name='Price Increase' data={this.state.data} >
               {
                 this.state.data.map((entry, index) => {
                   if (entry.quantity === this.state.data[this.state.data.length-1].quantity) {
                     return <Cell key={`cell-${index}-${new Date().getTime()}`} fill='#3399FF' />
                   } else {
                     return <Cell key={`cell-${index}-${new Date().getTime()}`} fill={COLORS[1]} />
                   }

                 })
               }
             </Scatter>

            </ScatterChart>
          </ResponsiveContainer>
        );
    } else {
      return <div></div>
    }

  };
}

class VertChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
    var data = JSON.stringify(props.data);
    //console.log('the Vert data is: ' + data)
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart layout="vertical" data={this.state.data}
            margin={{top: 20, right: 20, bottom: 20, left: 20}}>
          <XAxis type="number" hide={true}/>
          <YAxis dataKey="name" type="category"/>
          <Bar dataKey="value" barSize={20} fill="#8884d8">
            {
              this.state.data.map((entry, index) => {
                const color = (index < COLORS.length) ? COLORS[index] : COLORS[COLORS.length-1];
                return <Cell fill={color} />;
              })
            }
            <LabelList dataKey="value" position="right" />
         </Bar>
       </ComposedChart>
     </ResponsiveContainer>
    );
  }
}

function toStr(x) {
  return (JSON.stringify(x))
}

class CircularProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // Size of the enclosing square
    const sqSize = '80%';
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * this.props.percentage / 100;

    return (
      <svg
          width={this.props.sqSize}
          height={this.props.sqSize}
          viewBox={viewBox}>
          <circle
            className="circle-background"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`} />
          <circle
            className="circle-progress"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset
            }} />
          <text
            className="circle-text"
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle">
            {`${this.props.displayText}`}
          </text>
      </svg>
    );
  }
}

// function getFinalPrice(currentPrice, amountCars, multiplier) {
//   var total = 0;
//   var lastPrice = Number(currentPrice);
//   for (var i = 0; i < amountCars; i++) {
//     total += lastPrice
//     lastPrice = lastPrice * Math.pow(multiplier, (i+1));
//     total += 300000; lastPrice += 300000;
//   }
//   let BUFFER = 100000;
//   total += BUFFER;
//   return total;
// }

function getFinalPrice(base, numCars, mult, gasPrice) {
    //log('Get final price input: ' + base + ' ' + mult + ' ' + numCars)
    let curPrice = Number(base);
    let total = 0;
    for (var i = 0; i < numCars; i++) {
      let nextPrice = curPrice * mult;

      total += curPrice;

      curPrice = nextPrice;
    }

    let txfee = numCars * gasPrice * 400000;
    let buffer = numCars * 100000000000000;

    /*var total = 0;
    for (var i = 0; i < numCars; i++) {
        //log(Math.pow(mult, i))
        let add = (base * (Math.pow(mult, i)) + (4500000 * gasPrice) + 100000000000000)
        //log('adding ' + add +  ' to final price')
        total +=  add;
    }
    return total + 4500000 + 100000000000000;*/

    return total + txfee + buffer;
};

function getTrueMultipleFor(type, car) {
  if (type == 0) {
    if (car == 0) {
      return 0.008;
    } else if (car == 1) {
      return 0.015;
    } else if (car == 2) {
      return 0.2;
    } else if (car == 3) {
      return 0.4;
    } else if (car == 4) {
      return 1.2;
    }
  }
}

function getMultipleFor(type, car) {
  if (type == 0) {
    if (car == 0) {
      return 1.00008;
    } else if (car == 1) {
      return 1.00015;
    } else if (car == 2) {
      return 1.002;
    } else if (car == 3) {
      return 1.004;
    } else if (car == 4) {
      return 1.012;
    }
  }
}

function getDescription(type, car) {
  let carRes = getCar(type, car);
  let noDescription = 'Sorry, there is no description available for thie vehicle!'
  switch (type) {
    case 0:
      return carRes.description; break;
    case 1:
    //log('getDescr case 1')
      if (car == 0 || car == 1) {
        //log('ret descr: ' + carRes.description)
        return carRes.description;
      } else {
        //log('ret noDescr')
        return noDescription;
      }
       break;
     case 2:
       if (car == 0) {
         return carRes.description;
       } else {
         return noDescription;
       }
        break;
    default: return noDescription; break;
  }
}


let increasingDataChanged = true;
let oldIncreasingData = [];
class ViewCar extends Component {

  constructor(props) {
    super(props);
      //    let id = props.carId; // Passed to specify for HTTP Req so we can differentiate between different car types
      let typeIndex = props.typeIndex;
      let carIndex = props.carIndex;

      let image = getImageForIndex(typeIndex, carIndex);
      //console.log('Recieved TI: ' + typeIndex + '\nCI: ' + carIndex);
      this.state = {
        currentId: 'DNE',
        min: 0,
        max: (carIndex == 4 ? 2 : 1000),
        isOpen: true,
        quantityBuying: 1,
        carInfo: {},
        speedMax: '',
        accMax: '',
        armorMax: '',
        bznTankMax: '',
        mg: '',
        ag: '',
        speedMin: '',
        accMin: '',
        armorMin: '',
        bznTankMin: '',
        nos: '',
        description: '',
        price: 0,
        hold: 0,
        totalSupply: 0,
        amtLeft: 0,
        lastSold: 'DNE',
        img: '',
        videoSupported: true,
        shouldUpdate: true,
        carIndex: carIndex,
        typeIndex: typeIndex,
        adCarsNames: [cars.premium[0].name, cars.premium[1].name, cars.premium[2].name],
        adCarsImgs: [cars.premium[0].img, cars.premium[1].img, cars.premium[2].img],
        adCarIndex: [0, 1, 2],
        image: image
      };
      this.handleOnChange = this.handleOnChange.bind(this);
      this.getData = this.getData.bind(this);
      getThreeAdvertisementCars(function(carz, indexes) {
        this.state.adCarsNames[0] = carz[0].name;
        this.state.adCarsNames[1] = carz[1].name;
        this.state.adCarsNames[2] = carz[2].name;
        this.state.adCarsImgs[0] = carz[0].img;
        this.state.adCarsImgs[1] = carz[1].img;
        this.state.adCarsImgs[2] = carz[2].img;
        this.state.adCarIndex[0] = indexes[0];
        this.state.adCarIndex[1] = indexes[1];
        this.state.adCarIndex[2] = indexes[2];
        //console.log('Found adCar: ' + JSON.stringify(carz))
    //    alert(carz[0].name + ', ' + carz[1].name + ', ' + carz[2].name)
      }.bind(this));
  }

getReferrer() {
/*   var self = this;
  //console.log("XXX Request " + URLs.getReferrer + "/" + uid);
  axios.get(URLs.getReferrer + "/" + uid).then( function(response) {
    let referrer = response.data;
    self.setState({referrer: referrer});
    self.state.referrer = referrer;
    //console.log("XXX Got " + referrer); 
  })*/
  let address = window.localStorage.getItem("refaddress");
  this.setState({referrer: address});
}

handleOnChange = (valueAsNumber) => {
  this.setState({
    quantityBuying: valueAsNumber
  })
}

componentWillUnmount() {
  this.setState({
    shouldUpdate: false
  });
  this.state.shouldUpdate = false;
}

componentDidMount() {
  window.scrollTo(0, 0)
  setTimeout(this.getData, 500);
  updateContracts();
}

linkAccount(self) {
  vars.getAccounts((error, accounts) => {
    if (accounts != null) {
      if (accounts.length == 0) {
        //console.log('Error: couldn\'t find accounts');
        self.setState ({
          currentId: "NA"
        });
      } else {
        //console.log("We found accounts!");
        self.setState ({
          currentId: accounts[0]
        });
 /*        self.getReferrer(accounts[0]); */
      }
    } else {
      //console.log("Got error looking up accounts " + error);
    }
    setTimeout(function() {
      self.linkAccount(self);
    }, 500);
  })
}

componentWillMount() {
  this.getReferrer()
  this.linkAccount(this);
}

popup = () => {
     var self = this;

     var notice = "";
     let numberOfCars = this.state.quantityBuying;
     if (numberOfCars > 10) {
       notice = "\n\nPlease Note: You are buying more than 10 vehicles at once. We will batch the buying for you on our end, so you may not see the vehicles even after the order has been confirmed. For more info, please contact info@warriders.com";
     }

     if (!vars.isWeb3Known()) {
       vars.askForWeb3();
       /*var elem = document.createElement("div");
       elem.innerHTML = "<p style=\"color: black\">In order to purchase vehicles, you must use a Web3 compatible browser!<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>";
       swal({
         title: "Incompatible Browser",
         content: elem,
         icon: "error"
       })*/
       return;
    }
    if (this.state.currentId == "NA") {
       swal("External Wallet Locked", "No external wallet could be used to complete this transaction! Please unlock your external wallet and try again.\nIf you just unlocked your external wallet, try refreshing the page.", "error");
       this.linkAccount(this);
       return;
     }
     if (this.state.currentId == "DNE") {
       if (vars.isWeb3Known()) {
         swal("External Wallet Locked", "No external wallet could be used to complete this transaction! Please unlock your external wallet and try again.\nIf you just unlocked your external wallet, try refreshing the page.", "error");
         this.linkAccount(this);
       } else {
         var elem = document.createElement("div");
         elem.innerHTML = "<p style=\"color: black\">In order to purchase vehicles, you must use a Web3 compatible browser!<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3</a></p>";
         swal({
           title: "Incompatible Browser",
           content: elem,
           icon: "error"
         })
       }
       return;
     }

     if (this.state.typeIndex == 0) {
       swal("Closed", "The premium vehicle sale has ended", "warning");
       return;
     }

     if (this.state.amtLeft == 0) {
       swal("Sold Out!", "This vehicle kit has sold out!", "warning");
       return
     }

     swal({
       title: 'Buy ' + this.state.quantityBuying + ((this.state.quantityBuying > 1) ? ' vehicles' : ' vehicle'),
       text: 'Please confirm that you would like to proceed with the purchase with the ETH wallet ' + this.state.currentId + notice,
       icon: 'info',
       buttons: true
     }).then((willBuy) => {
       if (willBuy) {
         self.buyCar();
       }
     });
}



buyCar = () => {
    log('Present car popup...');
    var self = this;
    let numberOfCars = this.state.quantityBuying;
    let userAccount = this.state.currentId;
    let type = this.state.typeIndex;
    let variant = getFinalIndex(type, this.state.carIndex);
    log('variantt: ' + variant)
    let normVariant = this.state.carIndex;
    var MULTIPLIER = getMultipleFor(type, this.state.carIndex);
    let gasPrice = vars.web3.utils.toBN(vars.web3.utils.toWei('5', 'gwei'));
    let price = (getFinalPrice(this.state.price, numberOfCars, MULTIPLIER, gasPrice.toNumber()));

    if (variant == 1 || variant == 2 || variant == 3) {
      price += 5000000000000000;
    }
    //log('Gas should be ' + (gasPrice * price))
    let gas = 500000;
    log("# CARS: " + numberOfCars);
    log("UID: " + userAccount);
    log('Price: ' + price);
    log('T INDEX: ' + type);
    let po = preorder_contract;
    if (this.state.carIndex == 4) {
      po = preorder_migrate_contract;
    }

    var refId = (self.state.referrer != null && self.state.referrer != '' && self.state.referrer != 'DNE' && self.state.referrer != userAccount) ? self.state.referrer : null;

    //FIX Default a null referral to the 0x0 address
    if (refId == null) {
      refId = "0x0000000000000000000000000000000000000000";
    }

    if (numberOfCars == 1) {
        var give_refund = true;
        log('BUY Request: \n METHOD: buyPremiumCar()\n Params: \nRef ID: ' + refId + '\nVariant: ' + variant + '\nGive Refund: ' + give_refund + '\nCategory: ' + (type+1) + '\n Additional Req Details: \n From Account: ' + userAccount + '\n WEI Sent: ' + price + '\n GAS Provided: ' + gas + '\n')
        po.methods.buyCar(refId, variant, give_refund, userAccount, (type+1)).send({from: userAccount, value: price, gas: gas}).on('transactionHash', function(res) {
            log('Buy Callback Hash: ' + res);
            self.openHashUrl(res);
            swal({
              title: "Processing...",
              text: "Please wait while your transaction is confirmed",
              icon: loadingGif,
              className: 'wr-loader',
              button: false,
              closeOnClickOutside: false
            });
        }).on('confirmation', function(confirmationNumber, receipt) {
          let tx = receipt.transactionHash;
          log(hashesConfirmed);
          if (!hashesConfirmed.includes(tx)) {
            log(receipt);
            if (receipt.status) {
              var elem = document.createElement("div");
              elem.innerHTML = "<p style=\"color: black\">Your purchase was successfully processed!<br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
              swal({
                title: "Order Successful",
                content: elem,
                icon: "success"
              })
              //swal("Order Successful", "Your purchase was successfully processed!\nTransaction Hash: " + tx, "success");
              self.submitPurchase((type + 1), variant, numberOfCars, price, tx);
              ReactGA.event({
                category: 'Purchase',
                action: 'Bought a car',
                value: numberOfCars
              });

            } else {
              swal("Order Failed", "Your purchase could not be processed! We're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nYour ether has been refunded.\nTransaction Hash: " + tx, "error")
              ReactGA.event({
                category: 'Failed Purchase',
                action: 'Failed to purchase a car',
                value: numberOfCars
              });
            }

            hashesConfirmed.push(tx);
          }
        }).on('error', console.error);
    } else if (numberOfCars > 1) {
      var variants = []
      for (var i = 0; i < numberOfCars; i++) {
          variants.push(variant);
      }

      if (numberOfCars > 10) {
          //Use preorder_batch as contract
          po = preorder_batch_contract;

          gas = 1500000;
          //price *= 1.1;
          log('BUY Request: \n METHOD: reserveManyTokens()\nParams: \n Variants: ' + variants + '\n Type: ' + (type+1) + '\nAdditional Req Details: \n From Account: ' + userAccount + '\n WEI Sent: ' + price + '\n GAS Provided: ' + gas + '\n')
          po.methods.reserveManyTokens(variants, (type+1), refId).send({from: userAccount, value: price, gasPrice: 5000000000, gas: gas}, function(err, res){
              if (err) {
                if (err != "Error: Returned error: Error: MetaMask Tx Signature: User denied transaction signature.") {
                  swal('Transaction Failed', 'Sorry, we couldn\'t process your transaction!\nError: ' + err, 'error')
                }
              } else {
                log('Buy Callback Hash: ' + res);
                self.openHashUrl(res);
                swal({
                  title: "Processing...",
                  text: "Please wait while your transaction is confirmed",
                  icon: loadingGif,
                  className: 'wr-loader',
                  button: false,
                  closeOnClickOutside: false
                });
              }
          }.bind(this)).on('confirmation', function(confirmationNumber, receipt) {
            let tx = receipt.transactionHash;
            log(hashesConfirmed);
            if (!hashesConfirmed.includes(tx)) {
              log(receipt);
              if (receipt.status) {
                var elem = document.createElement("div");
                elem.innerHTML = "<p style=\"color: black\">Your purchase was successfully processed! Our Deadly Factory produces vehicles as fast as it can, so please be patient while your vehicles are being delivered to your garage.<br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
                swal({
                  title: "Order Successful",
                  content: elem,
                  icon: "success"
                })
                self.submitPurchase((type + 1), variant, numberOfCars, price, tx);
                ReactGA.event({
                  category: 'Purchase',
                  action: 'Bought a car',
                  value: numberOfCars
                });
              } else {
                swal("Order Failed", "Your purchase could not be processed! We're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nYour ether has been refunded.\nTransaction Hash: " + tx, "error")
                ReactGA.event({
                  category: 'Failed Purchase',
                  action: 'Failed to purchase a car',
                  value: numberOfCars
                });
              }

              hashesConfirmed.push(tx);
            }
          }).on('error', function(e) {
            console.log("AAAAAFFFF " + e);
          });
      } else {
        gas = 500000 * numberOfCars;

          if (type == 0) {
            log("buyBulkPremiumCar")
            po.methods.buyBulkPremiumCar(refId, variants, userAccount).send({from: userAccount, value: price, gasPrice: 5000000000, gas: gas}, function(err, res){
              if (err) {
                if (err != "Error: Returned error: Error: MetaMask Tx Signature: User denied transaction signature.") {
                  swal('Transaction Failed', 'Sorry, we couldn\'t process your transaction!\nError: ' + err, 'error')
                }
              } else {
                log('Buy Callback Hash: ' + res);
                swal({
                  title: "Processing...",
                  text: "Please wait while your transaction is confirmed",
                  icon: loadingGif,
                  className: 'wr-loader',
                  button: false,
                  closeOnClickOutside: false
                });
              }
            }.bind(this)).on('confirmation', function(confirmationNumber, receipt) {
              let tx = receipt.transactionHash;
              log(hashesConfirmed);
              if (!hashesConfirmed.includes(tx)) {
                log(receipt);
                if (receipt.status) {
                  var elem = document.createElement("div");
                  elem.innerHTML = "<p style=\"color: black\">Your purchase was successfully processed!<br><br><a href=\"https://etherscan.io/tx/" + tx + "\">View Transaction</a></p>";
                  swal({
                    title: "Order Successful",
                    content: elem,
                    icon: "success"
                  })
                  self.submitPurchase((type + 1), variant, numberOfCars, price, tx);
                  ReactGA.event({
                    category: 'Purchase',
                    action: 'Bought a car',
                    value: numberOfCars
                  });
                } else {
                  swal("Order Failed", "Your purchase could not be processed! We're sorry :( Please provide the transaction hash to the devs to help figure out what went wrong.\nYour ether has been refunded.\nTransaction Hash: " + tx, "error")
                  ReactGA.event({
                    category: 'Failed Purchase',
                    action: 'Failed to purchase a car',
                    value: numberOfCars
                  });
                }

                hashesConfirmed.push(tx);
              }
            });
          } else if (type == 1) {
            log("buyBulkMidGradeCar")
            po.methods.buyBulkMidGradeCar(refId, variants, userAccount).send({from: userAccount, value: price, gasPrice: 5000000000, gas: gas}, function(err, res){
                if (err) {
                  swal('Transaction Failed', 'Sorry, we couldn\'t process your transaction!', 'error')
                } else {
                  log('Buy Callback Hash: ' + res);
                  self.openHashUrl(res);
                }
            }.bind(this));
          } else {
            log("buyBulkRegularCar")
            po.methods.buyBulkRegularCar(refId, variants, userAccount).send({from: userAccount, value: price, gasPrice: 5000000000, gas: gas}, function(err, res){
                if (err) {
                  swal('Transaction Failed', 'Sorry, we couldn\'t process your transaction!', 'error')
                } else {
                  log('Buy Callback Hash: ' + res);
                  self.openHashUrl(res);
                }
            }.bind(this));
          }
      }


    }

}

submitPurchase(category, type, numberOfCars, weiPrice, txHash) {
  //console.log("Submitting purchase " + category + " " + type + " " + numberOfCars + " " + weiPrice + " " + txHash);

  try {
    function intToName(type) {
      switch (type) {
          case 1:
              return "SUV";
          case 2:
              return "Truck";
          case 3:
              return "Hovercraft";
          case 4:
              return "Tank";
          case 5:
              return "Lambo";
          case 6:
              return "Buggy";
          case 7:
              return "Midgrade Type 2";
          case 8:
              return "Midgrade Type 3";
          case 9:
              return "Hatchback";
          case 10:
              return "Regular Type 2";
          case 11:
              return "Regular Type 3";
          default:
              return "";
        }
    }

    function catToName(category) {
      switch (category) {
        case 1:
          return "Premium";
        case 2:
          return "Midgrade";
        case 3:
          return "Regular";
        default:
          return category;
      }
    }

    let ether = vars.web3.utils.fromWei(weiPrice.toString(), 'ether');

    ReactGA.plugin.execute(
      'ecommerce',
      'addItem',
      {
        id: txHash,
        name: intToName(type),
        sku: type.toString(),
        price: ether,
        category: catToName(category),
        quantity: numberOfCars.toString(),
      }
    );
    ReactGA.plugin.execute(
      'ecommerce',
      'addTransaction',
      {
        id: txHash,
        revenue: ether,
      }
    );
    ReactGA.plugin.execute('ecommerce', 'send');
    ReactGA.plugin.execute('ecommerce', 'clear');

    //console.log("FFF Sent the following: " + {
    //  id: txHash,
    //  name: intToName(type),
    //  sku: type.toString(),
    //  price: ether,
    //  category: catToName(category),
    //  quantity: numberOfCars.toString(),
    //});

    //console.log("FFF Also sent " + {
    //  id: txHash,
    //  revenue: ether,
    //})
  } catch (e) {
    console.log("FFF GOT ERROR " + e);
  }
}

openHashUrl(hash) {
  //let url = 'https://rinkeby.etherscan.io/tx/' + hash;
  //window.open(url, "_blank");
}

getData = () => {
    let type = this.state.typeIndex; // Start at 1 in Eth
    let car = this.state.carIndex;
    let carRes = getCar(type, car);
    //console.log('carRes: ' + JSON.stringify(carRes))
    var self = this;
    // Real
    let speedMin = roundToTwo(carRes.speedMin);
    let accMin = roundToTwo(carRes.accMin);
    let armorMin = roundToTwo(carRes.armorMin);
    let bznTankMin = roundToTwo(carRes.bznTankMin);
    let nos = carRes.nos;
    let engMin = roundToTwo(carRes.engineMin);
    let engMax = roundToTwo( carRes.engineMax);
    let speedMax = roundToTwo(carRes.speedMax);
    let accMax = roundToTwo( carRes.accMax);
    let armorMax = roundToTwo(carRes.armorMax);
    let bznTankMax = roundToTwo(carRes.bznTankMax);
    let mg = roundToTwo(carRes.mainGuns);
    let ag = roundToTwo(carRes.altGuns); // Should count As
    let eq = roundToTwo(carRes.equipmentSlots);
    let description = getDescription(type, car);
    //log('new description is: ' + description)
    this.setState({
      speedMax: speedMax,
      accMax: accMax,
      armorMax: armorMax,
      bznTankMax: bznTankMax,
      mg: mg,
      ag: ag,
      speedMin: speedMin,
      accMin: accMin,
      armorMin: armorMin,
      bznTankMin: bznTankMin,
      nos: nos,
      engineMin: engMin,
      engineMax: engMax,
      description: description,
      img: carRes.img
    });
    var svg = ( <GradientSVG
           idCSS='sxxx'
           rotation={90}
         />);

    var posedProps = {
      draggable: 'x',
      open: { x: '0%' },
      closed: { x: '-100%' }
    }


    function carInfoProgressComponents(){
      return (
              <div>
                <div>{speedProgressComponent(speedMin,speedMax,maxes.speed, 'Speed')}</div>
                <div>{accProgressComponent(accMin,accMax,maxes.acc, 'Accel.')}</div>
                <div>{bznProgressComponent(bznTankMin,bznTankMax,maxes.bznTank, 'BZN Tank')}</div>
                <div>{armorProgressComponent(armorMin,armorMax,maxes.armor, 'Armor')}</div>
                  <Row >
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                            <h5 className='centered'>Max Main Guns</h5>
                            <br></br>
                            <CircularProgressbar percentage={(mg)/maxes.mainGunSlots*100} text={`${mg}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                        <h5 className='centered'>Max Alt Guns</h5>
                        <br></br>
                            <CircularProgressbar percentage={(ag)/maxes.mainGunSlots*100} text={`${ag}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4}>
                      <div>
                        {svg}
                        <h5 className='centered'>Max Equipment</h5>
                        <br></br>
                            <CircularProgressbar percentage={(eq)/maxes.equipmentSlots*100} text={`${eq}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                      </div>
                    </Col>

                  </Row>
                  <Row><div style={{width: '100%', height: '30px'}}></div></Row>
              </div>
            );

    }
    //console.log('IMG to be:' + carRes.img);
    let img = (carRes.img != imgs.unknownVehicle) ? carRes.img : imgs.unknownVehicleSmall;
    let onVideoFailed = function() {
      self.setState({videoSupported: false});
    };

    let vurl = "";
    if (type == 0) {
      if (car == 0) {
        vurl = "https://warriders.com/wr_uploads/2018/08/suvrotate.webm";
      } else if (car == 1) {
        vurl = "https://warriders.com/wr_uploads/2018/08/wartruckrotate.webm";
      } else if (car == 2) {
        vurl = "https://warriders.com/wr_uploads/2018/08/hovercraftrotate.webm";
      } else if (car == 3) {
        vurl = "https://warriders.com/wr_uploads/2018/08/tankrotate.webm";
      } else if (car == 4) {
        vurl = "https://warriders.com/wr_uploads/2018/08/lamborotate.webm";
      }
    } else if (type == 1) {
      if (car == 0) {
        vurl = "https://warriders.com/wr_uploads/2018/08/buggyrotate.webm";
      }
    } else if (type == 2) {
      if (car == 0) {
        vurl = "https://warriders.com/wr_uploads/2018/08/hatchbackrotate.webm";
      }
    }
    let vid = <ReactPlayer  width='100%' height='100%' url={vurl} playing={true} loop={true} onError={onVideoFailed}/>
    if (vurl != "" && this.state.videoSupported && isBrowser) {
      //  alert('baggaeg claim')
    //  ReactDom.render(<div>{vid}</div>, document.getElementById('cImg'));
      this.setState({imgComp: vid});

    } else {
    //  ReactDom.render(<img src={img}></img>, document.getElementById('cImg'));
      this.setState({imgComp: <img src={img}></img>});
    }

    //ReactDom.render(<h4 style={{lineHeight: '30px'}}>{description}</h4>, document.getElementById('car_description'));
    let index = Number(getFinalIndex(type, car));
    let price = 0;
    let hold = 0;
    let totalSupply = 0;
    let saleEnds = 'October 17 2019';
    ///onsole.log("Using indexxxxx " + index + "(type: " + type + ", car: " + car + ")");
    let po = info_preorder_contract;
    if (index == 5) {
      po = info_preorder_migrate_contract;
    }
    if (type == 0) {
        po.methods.priceFor(index).call().then(function(p) {
          price = p;
          return car_token_contract.methods.carTypeSupply(index).call();
        }).then(function(s) {
          hold = s;
          return car_token_contract.methods.carTypeTotalSupply(index).call();
        }).then(function(t) {
          totalSupply = t;

          self.setState ({
            price: price,
            hold: hold,
            totalSupply: totalSupply,
            amtLeft: (totalSupply-hold),
          });

          let base_price = carRes.base_price;
          //log('Calling Rise Data with params: ' + '\nBase Price: ' + base_price + '\nCurrent Price: ' + price + '\nCurrent Q: ' + hold + '\nTotal supply: ' + totalSupply)
          let increasing = riseData(type, car, base_price, vars.web3.utils.fromWei(price, 'ether'),  hold , totalSupply);

          //let increasing = getIncrFXdata(price, (totalSupply-hold));
          if (type == 0 && increasingDataChanged) {
            ReactDom.render(<CustomChart currentQuantity={hold} data={increasing} className='CustomChart' id='CustomChart'/>, document.getElementById('CustomChart'));
            increasingDataChanged = false;
            oldIncreasingData = increasing;
          }

          if (JSON.stringify(oldIncreasingData) != JSON.stringify(increasing)) {
            increasingDataChanged = true;
          }

          ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
          ReactDom.render(<h3 style={{ textAlign: 'left' }} id='countdown-timer'>Sale ends in: {<Countdown
              date={saleEnds}
              renderer={renderer}
            />}
          </h3>, document.getElementById('countdown-timer'));
        }.bind(self)).catch(function(err) {
          console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
          //ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
         })
    } else {
      //TODO Remove later
      car_token_contract.methods.carTypeSupply(index).call().then(function(s) {
        hold = s;
        return car_token_contract.methods.carTypeTotalSupply(index).call();
      }).then(function(t) {
        totalSupply = t;
        self.setState ({
          price: "Not for sale",
          hold: hold,
          totalSupply: totalSupply,
          amtLeft: (totalSupply-hold),
        });

        //let base_price = carRes.base_price;
        //log('Calling Rise Data with params: ' + '\nBase Price: ' + base_price + '\nCurrent Price: ' + price + '\nCurrent Q: ' + hold + '\nTotal supply: ' + totalSupply)
        //let increasing = riseData(type, car, base_price, vars.web3.utils.fromWei(price, 'ether'), hold, totalSupply);
        //let increasing = getIncrFXdata(price, (totalSupply-hold));
        //if (type == 0) {
        //  ReactDom.render(<CustomChart currentQuantity={hold} data={increasing} className='CustomChart' id='CustomChart'/>, document.getElementById('CustomChart'));
        //}

        ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
        ReactDom.render(<h3 style={{ textAlign: 'left' }} id='countdown-timer'>Sale ends in: {<Countdown
            date={saleEnds}
            renderer={renderer}
          />}
        </h3>, document.getElementById('countdown-timer'));
      }.bind(self)).catch(function(err) {
        console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description)
        //ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
       })
    }

    if (this.state.shouldUpdate) {
      setTimeout(this.getData, 5000);
    }
  }

outFunc() {
  var tooltip = document.getElementById("myTooltip");
  tooltip.innerHTML = "Copy to clipboard";
}

anim() {
  document.getElementById('anim').className ='classname';
}
render() {

  //log('rerender triggered, description is ' + this.state.description)
  const MyComponent = ({ hostRef }) => <div ref={hostRef} ><img style={{width: '50%'}} src={imgs.car}></img></div>

  function customizationText(typeIndex, carType) {
    return "Customization is live";
  }
  function garageText(typeIndex, carType) {
    return "Comes with a free spot in the Central Garage";
  }

  function getSpecialTrait(carType) {
    carType = carType + 1;
    switch (carType) {
      case 5:
        return ["Bonus Trait | NOS".toUpperCase(), "Instantly gains speed | NOS equip. installed"];
      case 4:
        return ["Bonus Trait | NOS".toUpperCase(), "No critical damage | NOS equip. installed"];
      case 3:
        return ["Bonus Trait | NOS".toUpperCase(), "Perfect maneuverability | NOS equip. installed"];
      default:
        return ["Bonus Trait | NOS", "NOS equip. installed"];
    }
  }


  const SpecialTrait = ({ title, description }) => <div><h4 style={{color: '#a922ed'}}>{title}</h4><p>{description}</p></div>

  let config = {
    visible: {
      opacity: 1,
      x: 100,
      transition: { duration: 300000 }
    }, invisible: {
        opacity: 0.5,
        x: -100,
        transition: { duration: 300000 }
      }
  };

  const PosedComponent = posed(MyComponent)(config)

  const Box = posed.circle(config);

  var isOpen = true
  let type = this.state.typeIndex;
  let qa = <p>Quantity approximately <a href="https://warriders.com/bulk-buy/">(learn more)</a>:</p>
  let qq = <p>Quantity</p>
  let btnText = "PRE-ORDER"
  if (this.state.amtLeft == 0) btnText = "SOLD OUT"
  if (type == 0) btnText = "CLOSED"
    if (type == 0) {
      return (
        <div className="ViewCar">
          <MetaTags>
            <title>War Riders</title>
            <meta property="og:title" content="Buy game items now!" />
            <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            
            <meta property="og:image" content={this.state.img}/>
            <meta property="og:url" content={"https://app.warriders.com/viewCar/" + this.state.typeIndex + '/' + this.state.carIndex}/>
            <meta name="twitter:card" content="summary_large_image"/>

            <meta property="og:site_name" content="War Riders"/>
            <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
          </MetaTags>
          <Grid>
            <Row style={{height: 100}}></Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h3>ABOUT THIS VEHICLE KIT</h3>
                <br></br>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} md={6}>
                <div id='cImg'>
                  {this.state.imgComp}
                </div>
                <h1> PRICE NOW: {conditionalRenderer((this.state.price != 'DNE') ? round(vars.web3.utils.fromWei(this.state.price.toString(), 'ether'), 4) : 'DNE' )} ETH </h1>
                <p> {getTrueMultipleFor(this.state.typeIndex, this.state.carIndex)}% price increase with each vehicle sold </p>
              </Col>
              <Col xs={12} sm={6} md={6}>

                <div style={{ 'margin-bottom': '15px'}}>Vehicle stats will generate randomly within these ranges:</div>
                  <Col xs={12} sm={12} md={12}>
                    <div id='progressi'>

                    </div>
                   <Row>
                     <Col sm={2} md={2}></Col>
                     <Col sm={5} md={5}>
                       <p className='concealed'>.</p>
                       <div className='left-to-right'>
                         <a className="btn-one-that-doesnt-resize" onClick={() => this.popup()}>
                           <span className="hide-selection">{btnText}</span>
                         </a>
                       </div>
                     </Col>
                     <Col sm={3} md={3}>
                       {this.state.quantityBuying > 10 ? qa : qq}
                       <NumericInput id='reqFunds' className="form-control" min={this.state.min} max={this.state.max} value={this.state.quantityBuying} step={1} onChange={this.handleOnChange} style={{
                         input: {
                           backgroundColor: 'black',
                           color: '#a922ed',
                           fontSize: 16,
                         },
                         btn: {
                           color: 'white',
                           background: 'white'
                         },
                         'btn:hover': {
                           background: 'lightGrey'
                         }
                       }}/>
                     </Col>

                     <Col sm={2} md={2}></Col>
                   </Row>
                 </Col>

              </Col>

            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <div id='CustomChart'>
                  <CustomChart currentQuantity={(this.state.totalSupply-this.state.amtLeft)} data={[]} className='CustomChart'/>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <div style={{marginTop: '10%', textAlign: 'left' }}>
                  <br></br><br></br>
                  <h3>Amount left: {conditionalRenderer(this.state.amtLeft)}</h3>
                  <h3>Total supply: {conditionalRenderer(this.state.totalSupply)}</h3>
                  <h3 id='countdown-timer'>Sale ends in: {<Countdown
                      date={this.state.saleEnds}
                      renderer={renderer}
                    />}
                  </h3>
                  <h3>Filled with: {conditionalRenderer( '$' + this.state.bznTankMin / 2 + ' - ' + this.state.bznTankMax / 2)} worth of BZN</h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>ABOUT THIS VEHICLE KIT</h3>
                <br></br><br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <h4 style={{color: '#a922ed'}}>FULLY CUSTOMIZABLE</h4>
                <p>{customizationText(this.state.typeIndex, this.state.carIndex)}</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>FREE GARAGE SPOT</h4>
                <p>{garageText(this.state.typeIndex, this.state.carIndex)}</p>
                <br></br>
                <SpecialTrait title={getSpecialTrait(this.state.carIndex)[0]} description={getSpecialTrait(this.state.carIndex)[1]} />
                <br></br>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <h4 id='car_description' style={{lineHeight: '50px'}}>{(this.state.description) ? (this.state.description) : 'Sorry, there is no available description for this vehicle!'}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>BUILD AN ARMY</h3>
                <br></br>
                <h4 style={{lineHeight: '30px'}}>The more vehicles you own, the more powerful you are. We recommend that you buy multiple vehicles ensuring your army is all set for the deadliest battle in human history.</h4>
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <h5>{this.state.adCarsNames[0]}</h5>
                <img src={this.state.adCarsImgs[0]}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.viewCar(this.state.typeIndex, this.state.adCarIndex[0])}>
                  <span>PRE-ORDER</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5>{this.state.adCarsNames[1]}</h5>
                <img src={this.state.adCarsImgs[1]}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.viewCar(this.state.typeIndex, this.state.adCarIndex[1])}>
                  <span>PRE-ORDER</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5>{this.state.adCarsNames[2]}</h5>
                <img src={this.state.adCarsImgs[2]}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.viewCar(this.state.typeIndex, this.state.adCarIndex[2])}>
                  <span>PRE-ORDER</span>
                </a>
              </Col>
            </Row>
            <Row>
              <br></br><br></br><br></br><br></br>
              <h3>TRADE WITH OTHERS</h3>
              <br></br>
            </Row>
            <div>
              <h4 style={{lineHeight: '30px'}}>You will be able to freely trade vehicles with other players and exchange BZN for other virtual currencies. War Riders, partnered with OPSkins and WAX, is happy to announce that our in-game items and currency will be supported in their marketplaces as well. We are in the process of adding more support for other marketplaces and exchanges.</h4>
            </div>
          </Grid>

          <Referral />

        </div>
      );
  } else {
      return (
        <div className="ViewCar">
          <Grid>
            <Row style={{height: 100}}></Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h3>ABOUT THIS CAR TYPE</h3>
                <br></br>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} md={6}>
                <div id='cImg'>
                  {this.state.imgComp}
                </div>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <div style={{ 'margin-bottom': '15px'}}>Vehicle stats will generate randomly within these ranges:</div>
                  <Col xs={12} sm={12} md={12}>
                    <div id='progressi'>

                    </div>
                 </Col>

              </Col>

            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>ABOUT THIS CAR TYPE</h3>
                <br></br><br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <h4 style={{color: '#a922ed'}}>NON-FUNGIBLE STRUCTURE</h4>
                <p>Every vehicle is unique and cannot be replicated</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>FULLY CUSTOMIZABLE</h4>
                <p>{customizationText(this.state.typeIndex, this.state.carIndex)}</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>FREE GARAGE SPOT</h4>
                <p>{garageText(this.state.typeIndex, this.state.carIndex)}</p>
                <br></br>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <h4 id='car_description' style={{lineHeight: '50px'}}>{(this.state.description) ? (this.state.description) : 'Sorry, there is no available description for this vehicle!'}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>BUILD AN ARMY</h3>
                <br></br>
                <h4 style={{lineHeight: '30px'}}>The more vehicles you own, the more powerful you are. We recommend that you buy multiple vehicles ensuring your army is all set for the deadliest battle in human history.</h4>
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <h5 style={{'margin-top': '50px'}}>{this.state.adCarsNames[0]}</h5>
                <img src={this.state.adCarsImgs[0]}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.viewCar(this.state.typeIndex - 1, this.state.adCarIndex[0])}>
                  <span>PRE-ORDER</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5 style={{'margin-top': '50px'}}>{this.state.adCarsNames[1]}</h5>
                <img src={this.state.adCarsImgs[1]}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.viewCar(this.state.typeIndex - 1, this.state.adCarIndex[1])}>
                  <span>PRE-ORDER</span>
                </a>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <h5 style={{'margin-top': '50px'}}>{this.state.adCarsNames[2]}</h5>
                <img src={this.state.adCarsImgs[2]}></img>
                <br></br><br></br>
                <a className="btn" href={URLs.viewCar(this.state.typeIndex - 1, this.state.adCarIndex[2])}>
                  <span>PRE-ORDER</span>
                </a>
              </Col>
            </Row>
            <Row>
              <br></br><br></br><br></br><br></br>
              <h3>TRADE WITH OTHERS</h3>
              <br></br>
            </Row>
            <div>
              <h4 style={{lineHeight: '30px'}}>You will be able to freely trade vehicles with other players and exchange BZN for other virtual currencies. War Riders, partnered with OPSkins and WAX, is happy to announce that our in-game items and currency will be supported in their marketplaces as well. We are in the process of adding more support for other marketplaces and exchanges.</h4>
            </div>
            {/*<Row>*/}
              {/*<br></br><br></br><br></br><br></br>*/}
              {/*<h3>YOUR REFERRAL {((this.state.copied) ? 'LINK' : 'CODE' )}</h3>*/}
              {/*<br></br>*/}
              {/*<h4><strong>Note: </strong> You <strong>must</strong> register your referral code for it to work!!</h4>*/}
              {/*<br></br><br></br>*/}

              {/*<div>*/}
                {/*<input value={(this.state.copied) ? (homeDir.toString() + this.state.refCode.toString()): this.state.refCode} style={{color: 'black', paddingLeft: '15px', width: '50%'}}*/}
                       {/*onChange={({target: {value}}) => this.setState({value, copied: false})} />*/}
                {/*<br></br><br></br>*/}
                {/*<CopyToClipboard text={this.state.refCode}*/}
                                 {/*onCopy={() => this.regAndCopy()}>*/}
                  {/*<a className="btn">*/}
                    {/*<span>REGISTER & COPY</span>*/}
                  {/*</a>*/}
                {/*</CopyToClipboard>*/}
                {/*<br></br>*/}


                {/*{this.state.copied ? <span >Registered & Copied.</span> : null}*/}

              {/*</div>*/}
              {/*<br></br><br></br><br></br><br></br>*/}
            {/*</Row>*/}
            <Referral/>
          </Grid>


        </div>
      );
    }

  }

  animate = () => {
    document.getElementById('ex').classList.add('horizTranslate');
  }

}

function ImageCarousel(img) {
  return (
    <div>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}>
        <img src={img} key={img} />
      </CSSTransitionGroup>
    </div>
  );
}


export default ViewCar;
