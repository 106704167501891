import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import './App.css';
import { Redirect } from 'react-router';
import loadingGif from './images/loading.gif';
import swal from 'sweetalert';
import { isMobile } from "react-device-detect";
import img from './ImgFactory.js';
import PasswordStrength from "./passwordStrength";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'
import MetaTags from 'react-meta-tags';


export default function resetpassword(){
    const firebase = vars.firebase;
    const [email,setEmail] = useState('')
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [isCaptchaLoaded, setCaptchaLoaded] = useState(false);

    useEffect(() => {
      if (isCaptchaLoaded) {
        return;
      }

      loadReCaptcha();
      setCaptchaLoaded(true);
    }, [isCaptchaLoaded]);

    function handleReset() {
      if (!recaptchaVerified) {
        swal("ReCaptcha", "Please complete the ReCaptcha before you create your game account", "error");
        return;
      }

      if (email != "") {
        firebase.auth().sendPasswordResetEmail(email).then(function() {
          swal("Instructions Sent", "An email has been sent with instructions to reset your password, please follow the instructions in the email to reset your password", "warning");
          setCaptchaLoaded(false);
        }).catch(function(err) {
          swal("Error", "An error has occured: \"" + err.message + "\"", "error");
        })
      } else {
        swal("No Email", "Please provide the email you'd like to reset", "error");
      }
    }

    function verifyCallback(response) {
      console.log(response);
      const self = this;
      fetch('https://api.warriders.com/verifycaptcha', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          recaptchaResponse: response
        })
      }).then(function(response) {
        return response.text();
      }).then(function(data) {
        if (data == 'passed') {
          setRecaptchaVerified(true);
        } else {
          setRecaptchaVerified(false);
        }
      }).catch(function(e) {
        console.log(e);
        setRecaptchaVerified(false);
      });
    }

    function captchaLoaded() {
      setRecaptchaVerified(false);
    }

    return(
        <div className="loginPage">
        <MetaTags>
          <title>War Riders Game Account</title>
          <meta property="og:title" content="War Riders Game Account" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={img.account}/>
          <meta property="og:url" content="https://app.warriders.com/link"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <img src={img.account} />
        <h3>Please enter your email to reset your password:</h3>
        <input placeholder="Email Address" value={email} onChange={(e)=>setEmail(e.target.value)} />
        <div style={{  display: "flex", justifyContent: "center", alignItems: "center", margin: '25px auto 0px auto', width: 'fit-content' }}>
          <ReCaptcha
              size="normal"
              data-theme="dark"
              render="explicit"
              sitekey="6LfRHosUAAAAAN4nwL9re2SXahZXVPm0LuSDYHou"
              verifyCallback={verifyCallback}
              onloadCallback={captchaLoaded}
          />
        </div>
        <Grid>
          <Row style={{marginTop: '30px'}}>
            <Col>
              <a onClick={handleReset} className="resetBtn btn-bar loginBtn">
                <span>Reset Password</span>
              </a>
            </Col>
          </Row>
        </Grid>
        </div>
    )
}