import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ViewCar from './ViewCar.js';
import immutablex from './images/immutablex.png';
import GunGarage from './GunGarage.js';
import resetpassword from './resetpassword.js'

import ViewGun from './ViewGun.js';
import MyNavBar from './MyNavBar';
import './App.css';
import TypeSelect from './VehicleSelectType.js';
import CarSelect from './SelectVariant.js';
import Garage from './UserGarage.js';
import ViewMyCar from './ViewMyCar.js';
import SignIn from './SignIn.js';
import FirebaseAuth from './FirebaseAuth.js';
import ViewCarAuction from './ViewAuctionCar.js';
import ExtraLamboSelect from './ExtraLambos.js';
import ViewKitsOwned from './ViewKitsOwned.js';
import BZNMigrate from './BZNMigrate.js';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import LeaderboardDisplay from './Leaderboard.js';
import ReactTooltip from 'react-tooltip'
import Popup from 'react-popup';
import particleLeft from './images/particle-left.png'
import particleRight from './images/particle-right.png'
import Web3Provider from './Web3Provider.js';
import axios from 'axios';
import { Form, FormControl, Jumbotron, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import queryString from './query-string.js';
import {
  BrowserRouter,
  Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import variables from './variables.js';
import ReactGA from 'react-ga';
import NoWeb3 from './NoWeb3.js';
import ViewMyGun from './ViewMyGun.js';
import GunSelectType from './GunSelectType.js';
import HomePage from './Homepage.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import UserPanel from './UserSection.js';
import { ToastContainer } from 'react-toastify';
import Deposit from './Deposits.js';
import 'react-toastify/dist/ReactToastify.css';
import img from './ImgFactory.js';/* 
import BZNStaking from './BZNStaking.js';
import BZNLocking from './BZNLocking.js'; */
import AccountSharing from './AccountSharing';
import DeleteAccount from './DeleteAccount.js';
import ViewGarage from './ViewGarage.js';
import GarageSelectType from './GarageSelectType.js';
import ViewMyGarage from './ViewMyGarage.js'
import GarageList from './GarageList.js'

/* const lockingUI = true;
const Stake = lockingUI ? BZNLocking : BZNStaking; */

library.add(faCopy)
library.add(faQuestionCircle)
library.add(faTrash)

unregister();
//let web3 = variables.web3;

function log(x) {
  console.log('(Z) ' + x);
}

//start react-ga
ReactGA.initialize('UA-116692363-2', { cookieDomain: 'auto' });
ReactGA.plugin.require('ecommerce')
class Analytics extends React.Component<RouteComponentProps<any>> {
  componentDidMount() {
    this.sendPageChange(this.props.location.pathname, this.props.location.search)
  }

  componentDidUpdate(prevProps: RouteComponentProps<any>) {
    if (this.props.location.pathname !== prevProps.location.pathname
      || this.props.location.search !== prevProps.location.search) {
      this.sendPageChange(this.props.location.pathname, this.props.location.search)
    }
  }

  sendPageChange(pathname: string, search: string = "") {
    const page = pathname + search
    ReactGA.set({page});
    ReactGA.pageview(page);
  }

  render() {
    return null
  }
}

const AnalyticsTracker = () => {
  return <Route component={Analytics} />
}
//end react-ga

class ReferralTracker extends React.Component<RouteComponentProps<any>> {
  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    let ref = values.ref;

    if (ref) {
      log('Ref: ' + ref)
      let self = this;
      axios.post(variables.urls.referredToByGenIDOffline, {genId: ref}).then(function(response) {
        console.log("Referral Collection" + response.data);
        let address = response.data;
        window.localStorage.setItem("refaddress", address);
      }).catch(function(error) {
        console.log("pls " + error);
      })
      
      //gets accounts from metamask
      variables.getAccounts().then(function(accounts, err) {
        if (err) {
          log('Found acc error: '  + err)
        } else {
          log('Found accs')
          referredToByGenID(accounts, ref)
        }
      })
    }
  }

  render() {
    return <div></div>
  }
}

const RefTracker = () => {
  return <Route component={ReferralTracker} />
}

var cars = variables.cars_table_data_minMax;
function referredToByGenID(uid, genId) {
  console.log("ref sending " + uid + " " + genId);
  axios.post(variables.urls.referredToByGenID, {uid: uid, genId: genId}).then(function(response) {
    console.log("Got ref response " + response);
  })
}
const SelectTypeUI = ({ match }) => {
  return (
    <TypeSelect />
  );
}

const HomepageUI = ({ match }) => {
  return (
    <HomePage />
  );
}

const SelectGarageUI = ({ match }) => {
  return (
    <GarageSelectType />
  )
}

const SelectGunUI = ({ match }) => {
  return (
    <GunSelectType />
  )
}

const AccountSharingUI = ({ match }) => {
  return (
    <AccountSharing />
  )
}

const DeleteAccountUI = ({ match }) => {
  return (
    <DeleteAccount />
  )
}

const enableDeposits = true;

const DepositUI = (enableDeposits ? ({ match }) => {
  return (
    <Deposit />
  )
} : ({ match }) => {
  return (
    <div></div>
  )
})

const SelectTypeUIwithReferrer = ({ match }) => {
  let ref = match.params.referrer
  log('Ref: ' + ref)
  let self = this;
  axios.post(variables.urls.referredToByGenIOffline, {genId: ref}).then(function(response) {
      console.log("Referral Collection" + response.data);
      let address = response.data;
      window.localStorage.setItem("refaddress", address);
    }).catch(function(error) {
      console.log("pls " + error);
    })
  variables.getAccounts().then(function(accounts, err) {
    if (err) {
      log('Found acc error: '  + err)
    } else {
      log('Found accs')
      referredToByGenID(accounts, ref)
    }
    log('Should be returning..')
  })

  return (
    <TypeSelect />
  );
}
function toStr(x) {
  return (JSON.stringify(x))
}

const ErrorPage = () => (
  <div className='error-page'>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br>
    <img src="https://warriders.com/wr_uploads/2018/06/cat_404.png" />
    <h1>It seems the page link you were given isnt quite right!</h1>
    <Link to='/'>Go Home</Link>
    <br></br><br></br><br></br><br></br>
  </div>
);

const ExtrasUI = ({ match }) => {
  return (
    <ExtraLamboSelect typeIndex={0} />
  )
}

const SelectCarUI = ({ match }) => {
  let typeIndex = Number(match.params.typeIndex);
  console.log('(Z) Type Index: ' + typeIndex);
  if (typeExists(typeIndex)) {
    return (
      <CarSelect typeIndex={typeIndex}/>
    );
  } else {
    return (
      <ErrorPage/>
    );
  }

}

const GarageUI = ({ match }) => {
  return (
    <Garage/>
  );
}

const leaderboardURLS = [
  "https://gs.warriders.com/leaderboard",
  "https://gs2.warriders.com/leaderboard"
]
/* 
const StakingUI = ({ match }) => {
  return (
    <BZNLocking bznStakingAddress={match.params.address} />
  )
} */

const LeaderboardUI = ({ match }) => {
  let leaderboardIndex = Number(match.params.leaderboardIndex);

  if (leaderboardIndex >= leaderboardURLS.length) {
    return <Redirect to='/' />
  }

  return (
    <LeaderboardDisplay leaderboardURL={leaderboardURLS[leaderboardIndex]} />
  );
}

const DefaultLeaderboard = ({ match }) => {
  return (
    <LeaderboardDisplay leaderboardURL={leaderboardURLS[0]} />
  );
}

function typeExists(type) {
  let typeCount = Object.keys(cars).length;
  var typeIsGood = (type >= 0 && type < typeCount) ? true : false;

  console.log('(Z) Checking that type exists... ' );
  console.log('(Z) Check Type: ' + type + ' on total count: ' + typeCount);

  return typeIsGood;
}

function carExists(type, car) {

  let typeCount = Object.keys(cars).length;
  var carCount;
  switch (type) {
    case 0:
      carCount = Object.keys(cars.premium).length;
      break;
    case 1:
      carCount = Object.keys(cars.midGrade).length;
      break;
    case 2:
      carCount = Object.keys(cars.regular).length;
      break;
    default:
      carCount = 0;
      break;
  }

  console.log('(Z) Checking that car exists... ' );
  console.log('(Z) Check Type: ' + type + ' on total count: ' + typeCount);
  console.log('(Z) Check Car: ' + car + ' on total count: ' + carCount);
  var typeIsGood = (type >= 0 && type < typeCount) ? true : false;
  var carIsGood = (car >= 0 && car < carCount) ? true : false;
  console.log('(Z) Car is good: ' + carIsGood + 'Type is good: ' + typeIsGood);
  return (carIsGood && typeIsGood)
}


const ViewCarUI = ({ match }) => {
  console.log('(Z) SHOWING NONREFERRER ONE')
  let typeIndex = Number(match.params.typeIndex);
  let carIndex = Number(match.params.carIndex);
  if (carExists(typeIndex, carIndex)) {
    return (
      <ViewCar typeIndex={typeIndex} carIndex={carIndex}/>
    );
  } else {
    return (
      <ErrorPage />
    );
  }
}

const ViewGunUI = ({ match }) => {
  let typeIndex = Number(match.params.typeIndex);
  if (typeIndex == 0 || typeIndex == 1 || typeIndex == 2) {
    return (
      <ViewGun typeIndex={typeIndex} />
    );
  } else {
    return (
      <ErrorPage />
    );
  }
}

const ViewGarageUI = ({ match }) => {
  let typeIndex = Number(match.params.typeIndex);
  if (typeIndex == 0 || typeIndex == 1 || typeIndex == 2) {
    return (
      <ViewGarage typeIndex={typeIndex} />
    );
  } else {
    return (
      <ErrorPage />
    );
  }
}

const ViewCarAuctionUI = ({ match }) => {
  console.log(match);
  let carTokenId = match.params.tokenId;
  return (
    <ViewCarAuction carId={carTokenId} />
  )
}

const ViewMyKitsOwnedUI = ({ match }) => {
  console.log('UI SCHEMA: ViewMyKitsOwnedUI')
  let type= Number(match.params.type);
  let car = Number(match.params.car);
  if (carExists(type, car)) {
    return (
      <div id="kits-owned" style={{minHeight: '1920px'}}>
        <ViewKitsOwned type={type} car={car}/>
      </div>
    );
  } else {
    return (
      <ErrorPage />
    );
  }

}

const ViewMyCarUI = ({ match }) => {
  //console.log("QUERY: " + match.props.location.search)
  //console.log("QUERY2: " + this.props.location.search)
  console.log('UI SCHEMA: ViewMyCarUI. Tkn param: ' + match.params.token)
  let token = match.params.token;
  return (
    <ViewMyCar token={token}/>
  );

}

const ViewMyGunUI = ({ match }) => {
  let token = match.params.token;
  let category = match.params.category;
  let categoryId = match.params.categoryId;
  return (
    <ViewMyGun token={token} category={category} categoryId={categoryId} />
  )
}

const ViewMyGarageUI = ({ match }) => {
  let token = match.params.token;
  let category = match.params.category;
  let categoryId = match.params.categoryId;
  return (
    <ViewMyGarage token={token} category={category} categoryId={categoryId} />
  )
}

const ViewGunGarage = ({ match }) => {
  let id = match.params.id;
  return (
    <GunGarage batchNumber={id} />
  )
}

const ViewGarageList = ({ match }) => {
  return (
    <GarageList />
  )
}

const Footer = ({ match }) => {
  return (
      <footer style={{border: "1px black"}}>
        <div className="container">
              <span className="copyright-text">
                Copyright © 2020, War Riders. Made with love by <a href="https://cartified.com" target="_blank">Cartified.<span>Game on!</span></a>
              </span>
      </div>
    </footer>
  );
}


const ViewCarUIwithReferrer = ({ match }) => {
  console.log('(Z) SHOWING REFERRER ONE')
  let typeIndex = Number(match.params.typeIndex);
  let carIndex = Number(match.params.carIndex);
  let ref = match.params.referrer;
  if (carExists(typeIndex, carIndex)) {
    return (
      <ViewCar typeIndex={typeIndex} carIndex={carIndex} referrer={ref}/>
    );
  } else {
    return (
      <ErrorPage />
    );
  }
}

const SignInUI = ({ match }) => {
  return (
    <SignIn />
  )
}

const FirebaseAuthUI = ({ match }) => {
  return (
    <FirebaseAuth />
  )
}

const BZNMigrateUI = ({ match }) => {
  return (
    <BZNMigrate />
  )
}

const App = () => (
  <div>
    <ReactTooltip id='skintooltip' aria-haspopup='true' role='example'>
      <ul style={{paddingLeft: '10px'}}>
        <li>Common - usually 40% of all skins</li>
        <li>Rare - around 30% of all skins</li>
        <li>Epic - approximately 15% of all skins</li>
        <li>Legendary - around 10% of all skins</li>
        <li>Exclusive - less than 5% of all skins</li>
      </ul>
    </ReactTooltip>
    <AnalyticsTracker />
    <RefTracker />
    <Popup />
    <MyNavBar />
      <div className='header-background'><br></br></div>
      <Switch>
        <Route exact path='/' component={HomepageUI}/>
        <Route exact path='/selectGun' component={SelectGunUI}/>
        <Route exact path='/leaderboard/' component={DefaultLeaderboard}/>
        <Route exact path='/leaderboard/:leaderboardIndex' component={LeaderboardUI}/>
        <Route exact path='/resetpassword' component={resetpassword}/>
        <Route exact path="/garage/guns/:id" component={ViewGunGarage} />
        <Route exact path='/garage/garageList' component={ViewGarageList} />
        <Route exact path="/selectType" component={SelectTypeUI}/>
        <Route path="/ref/:referrer" component={SelectTypeUIwithReferrer}/>
        <Route path="/selectCar/:typeIndex" component={SelectCarUI}/>
        <Route path="/delete" component={DeleteAccountUI} />
        <Route exact path="/garage" component={GarageUI}/>
        <Route path="/guns/:category/:categoryId" component={ViewMyGunUI}/>
        <Route path="/garageTitle/:category/:categoryId" component={ViewMyGarageUI}/>
        <Route path="/garageTitle/:token" component={ViewMyGarageUI}/>
        <Route path="/guns/:token" component={ViewMyGunUI}/>
        <Route exact path="/garage/:type/:car" component={ViewMyKitsOwnedUI}/>
        <Route path="/garage/:token" render={ViewMyCarUI}/>
        <Route path="/viewCar/:typeIndex/:carIndex" component={ViewCarUI}/>
        <Route path="/viewCase/:typeIndex" component={ViewGunUI}/>
        <Route path='/deposit' component={DepositUI} />
        <Route path="/viewAuction/:tokenId" component={ViewCarAuctionUI}/>
        <Route path="/extras" component={ExtrasUI}/>
        <Route path="/link" component={SignInUI} />
        <Route path="/email" component={FirebaseAuthUI} />
        <Route path="/migrate" component={BZNMigrateUI} />
        <Route path='/garages/:typeIndex' component={ViewGarageUI} />
        <Route path='/selectGarage' component={SelectGarageUI} />
        {/* <Route path='/stake/:address' component={StakingUI} /> */}
        <Route path="/share" component={AccountSharingUI} />
        <Route path="*" component={ErrorPage} />
      </Switch>
        <div style={{textAlign: 'center'}}>
          <h3>PARTNERS/FEATURES:</h3>
          <br></br>
          <br></br>
          <div>
            <a href={'https://www.blockchaingamer.biz/news/5908/coinbase-wallet-features-war-riders-in-start-to-its-pre-order-campaign/'}><img src={'/coinbase.png'} style={{maxWidth: '250px'}}/></a>
            <a href={'https://medium.com/warriders/war-riders-is-supercharging-its-game-with-zk-rollup-da8aa997dd7d'}><img src={immutablex} style={{maxWidth: '250px'}}/></a>
            <a href={'https://medium.com/warriders/chainlink-and-war-riders-collaborate-and-launch-human-readable-usernames-for-smart-contracts-live-da74cf54fa43'}><img src={'/chainlink.png'} style={{maxWidth: '250px'}}/></a>
            <a href={'https://coinjournal.net/war-riders-announces-collaboration-with-opensea/'}><img src={'/opensea-partner.png'} style={{maxWidth: '250px'}}/></a>
            <a href={'https://medium.com/wax-io/war-riders-is-coming-to-wax-expresstrade-6459ecc1933c'}><img src={'/wax-partner.png'} style={{maxWidth: '250px'}}/></a>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      <div style={{height: '30px', width: '100%'}}><h5 style={{color: 'white', width:  '100%', textAlign: 'center', height: '20px'}}>Copyright © 2018-{new Date().getFullYear()} Cartified</h5></div>
      <div style={{textAlign: 'center'}}><a href="https://warriders.com/terms/">Terms</a> <span style={{ color: '#337ab7' }}>|</span> <a href="https://warriders.com/privacy/">Privacy</a></div>
      <br></br>
      <UserPanel />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        />
    </div>
);

ReactDOM.render((
  <BrowserRouter>
    <Switch>
      //Pages that don't have a navbar will go here
      <Route exact path="/noweb3" component={NoWeb3} />
      <Route component={App} />
    </Switch>
  </BrowserRouter>
), document.getElementById('root'))

//registerServiceWorker();
