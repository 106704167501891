import React from 'react';
import { Col, Row, Grid } from 'react-bootstrap';
import imgs from './ImgFactory.js';
import './App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import premiumGunImg from './images/premium-case.jpg';
import premiumGarageImg from './images/garage-main-page.jpg'
import bundlesImg from './images/bundle-image.jpg';
import "react-sweet-progress/lib/style.css";
import Referral from './Referral.js';
import MetaTags from 'react-meta-tags';
import _ from 'underscore';
import variables from './variables.js';


class HomePage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div id='VehicleSelectType' style={{textAlign: 'center'}}>
        <MetaTags>
          <title>War Riders</title>
          <meta property="og:title" content="WarRiders" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={bundlesImg}/>
          <meta property="og:url" content="https://app.warriders.com/"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <Grid>
          <Row style={{height: 100}}></Row>
          <Row>
            <br></br><br></br><br></br>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3 className='centered'>BUY GAME ITEMS</h3>
            </Col>
            <br></br><br></br><br></br>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Vehicles</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <div style={{height: '60%'}}><img src={imgs.lambo} style={{height: '90%'}}></img></div>
            </ScrollAnimation>
              <br></br>
              <br></br>
              <br></br>
              <Link to='/selectType'>
                <a className="btn">
                  <span>BUY NOW</span>
                </a>
              </Link>
              <br></br><br></br>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Weapons</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <div style={{height: '60%'}}><img src={premiumGunImg} style={{height: '90%'}}></img></div>
            </ScrollAnimation>
              <br></br>
              <br></br>
              <br></br>
              <Link to='/selectGun'>
                <a className="btn">
                  <span>BUY NOW</span>
                </a>
              </Link>
              <br></br><br></br>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Garages</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <div style={{height: '60%'}}><img src={premiumGarageImg} style={{height: '90%'}}></img></div>
            </ScrollAnimation>
              <br></br>
              <br></br>
              <br></br>
              {variables.hideIt ? 
                  <a className="btn" href="https://warriders.com/garage-sale-rsvp/">
                    <span>BUY NOW</span>
                  </a>
                          :
                  <Link to='/selectGarage'>
                    <a className="btn">
                      <span>BUY NOW</span>
                    </a>
                  </Link>
              }
              <br></br><br></br>
              </ScrollAnimation>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3 className='centered'>READY TO BUNDLE UP?</h3>
            </Col>
            <br></br><br></br><br></br>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={4} />
            <Col xs={12} sm={4} md={4}>
                <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
                <h4>Bundles</h4>
                <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
                <div style={{height: '60%'}}><img src={bundlesImg} style={{height: '90%'}}></img></div>
              </ScrollAnimation>
                <br></br>
                <br></br>
                <br></br>
                <a className="btn" href='https://warriders.com/bundle-sale/'>
                  <span>BUY NOW</span>
                </a>
                <br></br><br></br>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4} />
          </Row>

          <Referral text='Earn ETH by becoming a Virtual War Dealer. Copy the URL below, share it with your friends on social media and earn 5% commission on every item sold using your referral link. The top 3 war dealers will receive unique game items!'/>
        </Grid>
      </div>
    );
  }



}

export default HomePage;
