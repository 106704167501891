import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DropdownButton, MenuItem, Button, ButtonToolbar, Navbar, NavItem, NavDropdown, Nav } from 'react-bootstrap';
import ViewCar from './ViewCar.js';
import VehicleSelect from './VehicleSelectType.js';
import Garage from './UserGarage.js';
import imgs from './ImgFactory.js';
import './App.css';
import vars from './variables.js';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

let urls = vars.urls;

class MyNavBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navOpacity: 'rgba(0, 0, 0, 0)',
      useDropdown: true,
      expandNavbar: undefined,
    };

    this.checkWeb3 = this.checkWeb3.bind(this);
  }

  componentDidMount () {

    window.onscroll =()=>{
      let THRESHHOLD = 100;
      let currentY = (window.scrollY > THRESHHOLD) ? THRESHHOLD : window.scrollY;
      let opacity = (window.scrollY / THRESHHOLD)
      //   console.log(opacity)
      let formattedOpacity = 'rgba(0, 0, 0, ' + opacity + ')';

      this.setState({navOpacity: formattedOpacity})

    }
  }

  async checkWeb3(e) {
      if (!vars.isWeb3Known()) {
        //vars.askForWeb3();
        e.preventDefault();
        e.stopPropagation();
        if (e.nativeEvent != null)
          e.nativeEvent.stopImmediatePropagation();

        this.setState({
          expandNavbar: false
        });

        await vars.askForWeb3();

        this.setState({
          expandNavbar: undefined
        });

        /*var elem = document.createElement("div");
        elem.innerHTML = "<p style=\"color: black\">In order to go to the garage, you must use a Web3 compatible browser!<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>";
        swal({
          title: "Incompatible Browser",
          content: elem,
          icon: "error"
        })*/
        return;
     }
  }

  

  render() {
    const showDeposits = true;

    const depositLink = (
      showDeposits ? 
        <MenuItem href={urls.deposit} className='dropdownLink'>DEPOSIT</MenuItem> 
        : 
        <div></div>
    )


    let marketplaceButton = (
      <NavItem  className='link' style={{border: 'none'}}>
        <Link to={urls.selectType} className='link'>
          <h5 > MARKETPLACE </h5>
        </Link>
      </NavItem>
    )

    if (this.state.useDropdown) {
      marketplaceButton = (
        <NavDropdown title="MARKETPLACE" id="collasible-nav-dropdown">
          <MenuItem href={urls.selectType} className='dropdownLink'>VEHICLE MARKETPLACE</MenuItem>
          <MenuItem href={urls.selectGunType} className='dropdownLink'>GUN MARKETPLACE</MenuItem>
          <MenuItem href={vars.hideIt ? 'https://warriders.com/garage-sale-rsvp/' : urls.selectGarageType} className='dropdownLink'>GARAGE MARKETPLACE</MenuItem>
          <MenuItem href='https://warriders.com/bundle-sale/' className='dropdownLink'>BUNDLE MARKETPLACE</MenuItem> 
        </NavDropdown>
      )
    }

    return (
      <Navbar fixedTop inverse collapseOnSelect style={{backgroundColor: this.state.navOpacity, border: 'none', headerStyle: {
          borderBottomWidth: 0,
        }}} className='myNavbar' expanded={this.state.expandNavbar}>
        <Navbar.Header>
          <div style={{ height: '75px'}}>
            <a href="/">
              <span className="navbar-brand"><img src={imgs.logo} width="210" height="45px" alt="My Logo" ></img></span>
            </a>
            <div style={{ paddingTop: '12px'}}>
              <Navbar.Toggle/>
            </div>
          </div>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {marketplaceButton}

            <NavItem  className='link' style={{border: 'none'}}>
              <Link onClick={this.checkWeb3} to={urls.garage} className='link'>
                <h5 > MY GARAGE </h5>
              </Link>
            </NavItem>

            <NavItem  className='link' style={{border: 'none'}}>
              <Link onClick={this.checkWeb3} to={urls.deposit} className='link'>
                <h5 > DEPOSIT </h5>
              </Link>
            </NavItem>

            <NavDropdown title="MORE" id="collasible-nav-dropdown">
              <MenuItem href={urls.howto} className='dropdownLink'>HOW TO BUY?</MenuItem>
              <MenuItem href={urls.leaderboard} className='dropdownLink'>LEADERBOARD</MenuItem>
              <MenuItem href={urls.warpaper} className='dropdownLink'>WAR PAPER</MenuItem>
            </NavDropdown>
            <NavItem style={{border: 'none'}} href={'https://warriders.com/discord-app/'}>
              <Navbar.Form pullRight style={{
                shadowColor: 'transparent',
                color: '#fff',
                shadowRadius: 0,
                shadowOffset: {
                  height: 0,
                }}}>
                <a className="btn-bar" style={{ color: '#fff '}} href={'https://warriders.com/discord-app/'} target={'_blank'}>
                  <span>DISCORD</span>
                </a>
              </Navbar.Form>
            </NavItem>
            <NavItem style={{border: 'none'}} href={'https://warriders.com'}>
              <Navbar.Form pullRight style={{
                shadowColor: 'transparent',
                color: '#fff',
                shadowRadius: 0,
                shadowOffset: {
                  height: 0,
                }}}>
                <a className="btn-bar" style={{ color: '#fff '}} href={'https://warriders.com'} target={'_blank'}>
                  <span>HOMEPAGE</span>
                </a>
              </Navbar.Form>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }



}

export default MyNavBar;
