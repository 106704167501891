import React from 'react';
import ReactDom from 'react-dom';
import ReactTable from 'react-table';
import vars from './variables.js';
import { Col, Row, Grid, DropdownButton, Button} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'
import axios from 'axios';
import { Progress } from 'react-sweet-progress';
import { Line, Circle } from 'rc-progress';
import { Contract, Provider } from 'ethers-multicall';
import "react-sweet-progress/lib/style.css";
import gunLarge from './images/gun-largel.jpg';
import gunLarge2 from './images/batches-large.jpg';
import garageImg from './images/garage-main-page.jpg'
import bundlesImg from './images/bundle-image.jpg';
import Web3 from 'web3';
import imgs from './ImgFactory.js';
import {Motion, spring, presets} from 'react-motion';
import Popup from 'react-popup';
import Referral from './Referral.js';
import swal from 'sweetalert';
import MetaTags from 'react-meta-tags';
import bznTrunk from './images/BZN-truck.png';
import loadingGif from './images/loading.gif';
import ReactTooltip from 'react-tooltip';

let cars = vars.cars_table_data_minMax;
let urls = vars.urls;
const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

let token_ids = [];

async function getMyTokens(uid) {
  const ethcallProvider = new Provider(vars.econtracts.carToken.provider);

  await ethcallProvider.init(); // Only required when `chainId` is not provided in the `Provider` constructor

  const carContract = new Contract(vars.econtracts.carToken.address, vars.abis.car_tkn);

  var tokenCount = await vars.contracts.carToken.methods.balanceOf(uid).call();

  tokenCount = Number(tokenCount);
  console.log("RRRR " + uid + " has " + tokenCount + " tokens");

  let calls = [];
  let type_calls = [];
  for (let i = 0; i < tokenCount; i++) {
    calls.push(carContract.tokenOfOwnerByIndex(uid, i));
  }

  token_ids = await ethcallProvider.all(calls);

  for (let i = 0; i < token_ids.length; i++) { 
    type_calls.push(carContract.getCarType(token_ids[i]));
  }

  let types = await ethcallProvider.all(type_calls);

  var pData = cars.premium.map(function(car) {
    var key = car.typeIndex;
    // var obj = {};
    // obj[key] = 0;
    // return obj
    return {n: key, val: 0};
  })
  var mData = cars.midGrade.map(function(car) {
    var key = car.typeIndex;
    // var obj = {};
    // obj[key] = 0;
    // return obj
    return {n: key, val: 0};
  })
  var rData = cars.regular.map(function(car) {
    var key = car.typeIndex;
    // var obj = {};
    // obj[key] = 0;
    // return obj
    return {n: key, val: 0};
  })

  let data = pData.concat(mData).concat(rData);
  for (let i = 0; i < types.length; i++) {
    let type = Number(types[i].toString());

    for (let j = 0; j < data.length; j++) {
      if (data[j].n == type) {
        data[j].val += 1;
      }
    }
  }

  return [token_ids, data];
}

async function getPendingBznBalance() {
    const ethcallProvider = new Provider(vars.econtracts.gamePool.provider);

    await ethcallProvider.init(); // Only required when `chainId` is not provided in the `Provider` constructor

    const gamepoolContract = new Contract(vars.econtracts.gamePool.address, vars.abis.gamePool);
    const carTokenFactoryContract = new Contract(vars.econtracts.carTokenFactory.address, vars.abis.car_token_factory);
    const carContract = new Contract(vars.econtracts.carToken.address, vars.abis.car_tkn);

    let totalBZN = 0;
    console.log("FFFF " + token_ids.length);
    let claimPromises = [];
    for (let i = 0; i < token_ids.length; i++) {
      let id = token_ids[i];
      console.log("FFFF Checking " + id);
      claimPromises.push(gamepoolContract.BZNclaimed(id));
      //claimPromises.push(vars.contracts.gamePool.methods.BZNclaimed(id).call());
    }

    let giveawayCars = [];
    for (let i = 0; i < token_ids.length; i++) {
      let id = token_ids[i];
      giveawayCars.push(carTokenFactoryContract.giveawayCar(id));
      //giveawayCars.push(vars.contracts.carTokenFactory.methods.giveawayCar(id).call());
    }

    let type_calls = [];
    for (let i = 0; i < token_ids.length; i++) { 
      type_calls.push(carContract.getCarType(token_ids[i]));
    }

    let car_types = await ethcallProvider.all(type_calls);

    let isPremiumCars = [];
    for (let i = 0; i < car_types.length; i++) {
      isPremiumCars.push(carContract.isPremium(car_types[i]))
    }

    let claims = await ethcallProvider.all(claimPromises);
    let giveaways = await ethcallProvider.all(giveawayCars);
    let premiums = await ethcallProvider.all(isPremiumCars);
    let tankPromises = [];
    for (let i = 0; i < token_ids.length; i++) {
      let id = token_ids[i];
      let claimed = claims[i];
      let premium = premiums[i];
      let gaveaway = giveaways[i];
      console.log("FFFF " + id + " IS " + claimed);
      if (claimed) continue;
      
      if (!premium && !gaveaway) continue;

      tankPromises.push(carContract.tankSizes(id));
      //tankPromises.push(vars.contracts.carToken.methods.tankSizes(id).call());
    }

    let maxes = await ethcallProvider.all(tankPromises);
    for (let i = 0; i < maxes.length; i++) {
      let maxTank = maxes[i];
      maxTank = Number(maxTank);
      totalBZN += maxTank;
    }

    return totalBZN;
}
async function getBznBalance(address) {
  let totalBZN = await vars.contracts.bzn.methods.balanceOf(address).call();
  totalBZN = round(vars.web3.utils.fromWei(totalBZN.toString(), 'ether'), 2);
  return totalBZN;
}

async function getOldBznBalance(address) {
  let totalBZN = await vars.contracts.oldBzn.methods.balanceOf(address).call();
  totalBZN = round(vars.web3.utils.fromWei(totalBZN.toString(), 'ether'), 2);
  let totalBZN2 = await vars.contracts.old2Bzn.methods.balanceOf(address).call();
  totalBZN2 = round(vars.web3.utils.fromWei(totalBZN2.toString(), 'ether'), 2);
  return totalBZN + totalBZN2;
}

function log(x) {
  console.log('RCX: ' + x);
}

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function childrenCount(object) {
  return Object.keys(object).length;
}

function getFinalIndex(type, index) { // No need to add 1 on front-end
  let premCars = childrenCount(cars.premium);
  let mgCars = childrenCount(cars.midGrade);
  let regCars = childrenCount(cars.regular);
  if (type == 0 ) {
    return index;
  } else if (type == 1) {
    return premCars + index;
  } else if (type == 2) {
    return premCars + mgCars + index;
  } else {
    swal("Woah", 'Error 01, got strange car input', "error")
  }
}

var hashesConfirmed = [];
class Garage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      carIds: [],
      currentId: '',
      bznBalance: 0,
      pendingBZN: -1,
      legacyBZN: 0,
      carCount: -1,
      metas: [],
      goHome: false,
      gunCount: 0,
      batchCount: 0,
      titleCount: 0,
    };
  }

  loadBZN(address) {
    var self = this;
    getBznBalance(address).then( function(balance) {
      self.setState({
        bznBalance: balance
      })
    }).catch(function(err) { log(err) });
    getPendingBznBalance(address).then(function(balance) {
      self.setState({
        pendingBZN: balance
      })
    }).catch(function(err) { log(err) });
    getOldBznBalance(address).then( function(balance) {
      self.setState({
        legacyBZN: balance
      })
    }).catch(function(err) { log(err) });
  }

  async componentDidMount() {
    let self = this;
    window.scrollTo(0, 0)
    //updateContracts();
    log('componentDidMount...')
    swal({
      title: "Loading...",
      text: "Please wait while the garage is loaded",
      icon: loadingGif,
      className: 'wr-loader',
      button: false,
      closeOnClickOutside: false
    });
    let address;
    try {
      if (!vars.isWeb3Known()) {
        swal.close();
        await vars.askForWeb3();
        if (!vars.isWeb3Known()) {
          swal.close();
          var elem = document.createElement("div");
          elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a><br><br>Error: No Web3 Provider found</p>"
          swal({
            title: "No External Wallet",
            content: elem,
            icon: "error"
          }).then(function() {
            self.setState({
              goHome: true
            })
          });
          return;
        } else {
          swal({
            title: "Loading...",
            text: "Please wait while the garage is loaded",
            icon: loadingGif,
            className: 'wr-loader',
            button: false,
            closeOnClickOutside: false
          });
        }
      }
      let accounts = await vars.getAccounts();
      log('Found accounts: ' + accounts)
      log('Accounts passes..')
      address = accounts[0];
      log('address: ' + address)
      self.setState ({
        currentId: address,
      });
    } catch (err) {
      log('NO ACCOUNTSS - componentDidMount: ' + err)
      swal.close();
      var elem = document.createElement("div");
      elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a><br><br>Error: " + JSON.stringify(err) + "</p>"
      swal({
        title: "No External Wallet",
        content: elem,
        icon: "error"
      }).then(function() {
        self.setState({
          goHome: true
        })
      });
      return;
    }

    try {
      
      log("STEP 1")
      let gCount = await vars.contracts.gunToken.methods.balanceOf(address).call();
      log("STEP 2")
      let bCount = await vars.contracts.gunToken.methods.getBatchCount(address).call();
      log("STEP 2.5")
      let gtCount = -1;
      try {
        gtCount = await vars.contracts.garageToken.methods.balanceOf(address).call();
      } catch (e) {
      }
      log("STEP 3")
      self.setState({
        gunCount: gCount,
        batchCount: bCount,
        titleCount: gtCount,
      })

      let [tokens, data] = await getMyTokens(address);
      log('made it through getMyTokens with result: ' + tokens)
      self.loadBZN(address);
      self.setState({
        carCount: tokens.length
      })
      self.setState ({
        data: data,
      });

      swal.close();
    } catch (err) {
      console.log(err);
      swal.close();
      swal("Error", "Encountered an error: " + err, "error"); 
    }
  }

  fillBZN() {
    if (!vars.isDebug && (this.state.carCount == -1 || this.state.pendingBZN == -1)) {
      swal("Not Ready", "Looks like you are not logged in to your MetaMask or the garage is not fully loaded. The BZN Truck can only fill cars once the garage has been loaded.", "warning")
    } else if (this.state.carCount == 0) {
      swal("No Cars", "The BZN Truck couldn't find any cars on this wallet.", "error")
    } else if (this.state.pendingBZN == 0) {
      swal("No Pending BZN", "You have no pending BZN. Buy more empty cars to get more BZN.", "warning");
    } else {
      var self = this;
      /*swal({
        title: "Patience",
        text: "The BZN Truck will stop by at the end of the pre-order campaign to fill up your BZN tanks. Patience, warrior!",
        icon: bznTrunk
      });*/
      var transactionsRequired = Math.ceil(this.state.carCount / 200);
      var submitCount = 0;
      var confirmCount = 0;
      var errorCount = 0;
      var elem = document.createElement("div");
      var warning = "";
      if (this.state.pendingBZN == 0) {
        warning = "<br><b>Warning: You have no pending BZN. This transaction may do nothing and burn gas.</b><br>";
      }
      elem.innerHTML = "<p style=\"color: black\" id=\"txtext\">Please submit " + transactionsRequired + " transactions to fill all empty cars." + warning + "<br><p id='ts'>Transactions Submitted: " + submitCount + " / " + transactionsRequired + "</p><br><p id='tc'>Transactions Confirmed: " + confirmCount + " / " + transactionsRequired + "</p><br><p id='te'>Transactions Failed: " + errorCount + " / " + transactionsRequired + "</p></p>";

      var self = this;

      async function submitNextTransaction() {
        try {
          const fillTx = await vars.econtracts.gamePool._preorderFill();
          submitCount++;
          var plural = "transaction is confirmed";
          if (transactionsRequired > 1) {
            plural = "transactions are confirmed";
          }
          document.getElementById("txtext").innerHTML = "Please wait while your " + plural + "..";
          document.getElementById("ts").innerHTML = "Transactions Submitted: " + submitCount + " / " + transactionsRequired;
          swal({
            title: "Filling",
            content: elem,
            icon: loadingGif,
            className: 'wr-loader',
            button: false,
            closeOnClickOutside: false
          });

          if (submitCount + errorCount < transactionsRequired) {
            submitNextTransaction();
          }
          const preorderr = await fillTx.wait(1);
          let tx = preorderr.transactionHash;
          if (preorderr.status) {
            confirmCount++;
            document.getElementById("tc").innerHTML = "Transactions Confirmed: " + confirmCount + " / " + transactionsRequired;
            hashesConfirmed.push(tx);

            if (confirmCount + errorCount >= transactionsRequired) {
              setTimeout(function() {
                let errText = "";
                let iconType = "success";
                if (errorCount > 0) {
                  errText = ", " + errorCount + " transactions have failed"
                  iconType = "warning";
                }
                self.loadBZN(self.state.currentId);
                swal({
                  title: "Filling Complete",
                  text: "BZN Fill completed, " + confirmCount + " transactions have been confirmed" + errText,
                  icon: iconType
                })
              }, 3000)
            }
          }
        } catch (e) {
          errorCount++;
          document.getElementById("te").innerHTML = "Transactions Failed: " + errorCount + " / " + transactionsRequired;
          if (confirmCount + errorCount >= transactionsRequired) {
            setTimeout(function() {
              let errText = "";
              if (errorCount > 0) {
                errText = ", " + errorCount + " transactions have failed"
              }
              self.loadBZN(self.state.currentId);
              swal({
                title: "Filling Complete",
                text: "BZN Fill completed, " + confirmCount + " transactions have been confirmed" + errText,
                icon: "error"
              })
            }, 3000)
          } else if (submitCount + errorCount < transactionsRequired) {
            await submitNextTransaction();
          }
        }
      }

      swal({
        title: "Filling",
        content: elem,
        icon: bznTrunk,
        buttons: true
      }).then(function(willSubmit) {
        if (willSubmit) {
          submitNextTransaction();
        }
      });

    }
  }


  render() {

    if (this.state.goHome) {
      return <Redirect to='/' />
    }


    let data = this.state.data;
    log('this is the data we\'re about to show: \n' + JSON.stringify(data))
    var premiumCarsComponent = cars.premium.map(function(car, index){
      //log('Car index: ' + index)
      let carData = data[getFinalIndex(0, index)];
      if (!carData)
        return <></>

      //log('Car Data: ' + JSON.stringify(carData))
      return (
        <div>
          <Grid>
            <Row>
              <Col xs={12} sm={3} md={3}>
                <h2 style={{color: '#680aff', marginTop: '100px'}}>{car.name}</h2>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <img src={car.img} style={{color: '#680aff'}}></img>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <h3 style={{verticalAlign: 'middle', marginTop: '100px'}}>You have {carData.val} kits</h3>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Link to={vars.urls.garageWithParams(0, index)} >
                  <a className="btn" style={{width: '70%', marginTop: '100px'}}>
                    <span>VIEW</span>
                  </a>
                </Link>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }.bind(this))
    var midGradeCarsComponent = cars.midGrade.map(function(car, index){
      //log('Car index: ' + index)
      let carData = data[getFinalIndex(1, index)];
      if (!carData)
        return <></>
      //log('Car Data: ' + JSON.stringify(carData))
      if (car.name == 'Unknown Kit') {

      } else {
      return (
        <div>
          <Grid>
            <Row>
              <Col xs={12} sm={3} md={3}>
                <h2 style={{color: '#680aff', marginTop: '100px'}}>{car.name}</h2>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <img src={car.img} style={{color: '#680aff'}}></img>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <h3 style={{verticalAlign: 'middle', marginTop: '100px'}}>You have {carData.val} kits</h3>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Link to={vars.urls.garageWithParams(1, index)} >
                  <a className="btn" style={{width: '70%', marginTop: '100px'}}>
                    <span>VIEW</span>
                  </a>
                </Link>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    }.bind(this))
    var regularCarsComponent = cars.regular.map(function(car, index){
      //log('Car index: ' + index)
      let carData = data[getFinalIndex(2, index)];
      if (!carData)
        return <></>
      //log('Car Data: ' + JSON.stringify(carData))
      log('carData name is ' + carData.name)
      if (car.name == 'Unknown Kit') {

      } else {
      return (
        <div>
          <Grid>
            <Row>
              <Col xs={12} sm={3} md={3}>
                <h2 style={{color: '#680aff', marginTop: '100px'}}>{car.name}</h2>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <img src={car.img} style={{color: '#680aff'}}></img>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <h3 style={{verticalAlign: 'middle', marginTop: '100px'}}>You have {carData.val} kits</h3>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Link to={vars.urls.garageWithParams(2, index)} >
                  <a className="btn" style={{width: '70%', marginTop: '100px'}}>
                    <span>VIEW</span>

                  </a>
                </Link>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    }.bind(this))

    return (
      <div id='VehicleSelectBuy' style={{textAlign: 'center'}}>
        <MetaTags>
          <title>Garage</title>
          <meta property="og:title" content="WarRiders Garage" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={bundlesImg}/>
          <meta property="og:url" content="https://app.warriders.com/garage"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <ReactTooltip id='maintenance-info-fill' aria-haspopup='true' role='example'>
          <p>Filling is currently closed for maintenance</p>
        </ReactTooltip>
        <ReactTooltip id='maintenance-info-migrate' aria-haspopup='true' role='example'>
          <p>BZN Migration is currently closed for maintenance</p>
        </ReactTooltip>
          <div>
            <div><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br></div>
            <div className='garage-header'>
              <h2>Garage</h2>
              <br></br>
              <Grid>
                <Row>
                  <Col xs={12} sm={4} md={3} style={{paddingTop: '10px'}}>
                    <h4>BZN Balance: <span style={{color: COLORS[3]}}>{this.state.bznBalance}</span> <br></br> Pending BZN: <span style={{color: COLORS[3]}}>{(this.state.pendingBZN == -1 ? 0 : this.state.pendingBZN)}</span><br></br>Legacy BZN: <span style={{color: COLORS[3]}}>{this.state.legacyBZN}</span></h4>
                  </Col>
                  <Col xs={6} sm={2} md={3}>
                    <a className="btn full-btn" style={{marginTop: '15px', marginRight: '0px'}} onClick={this.fillBZN.bind(this)}>
                      <span>FILL</span>
                    </a>
                  </Col>
                  <Col xs={6} sm={2} md={3}>
                    <Link to={vars.urls.migratebzn} >
                      <a className="btn full-btn" style={{marginTop: '15px', marginLeft: '0px'}}>
                        <span>MIGRATE BZN</span>
                      </a>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <div id='habba' ></div>
                </Row>
              </Grid>
            </div>
            <div className='garage-cats'>
              <Grid>
                <Row>
                  <div>
                    <Grid>
                      <Row>
                        <Col xs={12} sm={3} md={3}>
                          <h2 style={{color: '#680aff', marginTop: '100px'}}>Guns</h2>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          <img src={gunLarge} style={{color: '#680aff'}}></img>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          <h3 style={{verticalAlign: 'middle', marginTop: '100px'}}>You have {this.state.gunCount} guns</h3>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          <Link to={vars.urls.viewAllGuns} >
                            <a className="btn" style={{width: '70%', marginTop: '100px'}}>
                              <span>VIEW</span>
                            </a>
                          </Link>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                </Row>
                <Row>
                  <div>
                    <Grid>
                      <Row>
                        <Col xs={12} sm={3} md={3}>
                          <h2 style={{color: '#680aff', marginTop: '100px'}}>Gun Batches</h2>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          <img src={gunLarge2} style={{color: '#680aff'}}></img>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          <h3 style={{verticalAlign: 'middle', marginTop: '100px'}}>You have {this.state.batchCount} batches</h3>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          <Link to={vars.urls.viewAllBatches} >
                            <a className="btn" style={{width: '70%', marginTop: '100px'}}>
                              <span>VIEW</span>
                            </a>
                          </Link>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                </Row>
                <Row>
                  <div>
                    <Grid>
                      <Row>
                        <Col xs={12} sm={3} md={3}>
                          <h2 style={{color: '#680aff', marginTop: '100px'}}>Garage Titles</h2>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          <img src={garageImg} style={{color: '#680aff'}}></img>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          <h3 style={{verticalAlign: 'middle', marginTop: '100px'}}>You have {this.state.titleCount == -1 ? 0 : this.state.titleCount} titles</h3>
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                          {this.state.titleCount == -1 ?
                            <a className="btn" style={{width: '70%', marginTop: '100px'}} href='https://warriders.com/garage-sale-rsvp/'>
                              <span>VIEW</span>
                            </a>
                            :
                            <Link to={vars.urls.viewAllGarageTitles} >
                              <a className="btn" style={{width: '70%', marginTop: '100px'}}>
                                <span>VIEW</span>
                              </a>
                            </Link>
                          }
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                </Row>
                <Row>{premiumCarsComponent}</Row>
                <Row id='midGradeCarsComponent'>{midGradeCarsComponent}</Row>
                <Row id='regularCarsComponent'>{regularCarsComponent}</Row>
              </Grid>
            </div>
          </div>

          <Referral />
      </div>

    );
  }




  // render() {
  //
  //   return (
  //     <div style={{textAlign: 'center'}}>
  //       <Grid>
  //         <Row style={{height: 150}}></Row>
  //         <Row>
  //            <Col xs={12} sm={12} md={12}>
  //             <h3 className='centered'>MY VEHICLES</h3>
  //            </Col>
  //           <br></br><br></br><br></br>
  //         </Row>
  //         <Row>
  //           <BootstrapTable data={this.state.data} version='4' options={ this.getOptions() } classname='columnClassNameFormat1' bordered={ false } headerStyle={{fontSize: '20px'}}
  //             >
  //             <TableHeaderColumn isKey dataField='name' headerAlign='center' dataFormat={formattedTextH3} columnClassName='columnClassNameFormat1' isKey >Car Name</TableHeaderColumn>
  //             <TableHeaderColumn dataField='img' headerAlign='center' dataFormat={imageFormatter} >Images</TableHeaderColumn>
  //             <TableHeaderColumn dataField='speed' headerAlign='center' dataFormat={formattedTextH3} columnClassName='columnClassNameFormat2'>Speed</TableHeaderColumn>
  //             <TableHeaderColumn dataField='armor' headerAlign='center' dataFormat={formattedTextH3} columnClassName='columnClassNameFormat3'>Armor Units</TableHeaderColumn>
  //             <TableHeaderColumn dataField='mainGuns' headerAlign='center' dataFormat={formattedTextH3} columnClassName='columnClassNameFormat4'>Main Guns</TableHeaderColumn>
  //             <TableHeaderColumn dataField='altGuns' headerAlign='center' dataFormat={formattedTextH3} columnClassName='columnClassNameFormat5' >Alt Guns</TableHeaderColumn>
  //
  //           </BootstrapTable>
  //
  //           <br></br><br></br><br></br>
  //         </Row>
  //        <Row style={{height: 150}}></Row>
  //      </Grid>
  //     </div>
  //   );
  // }

}

function imageFormatter(cell, row){
  return (<div style={{height: '100px'}}><img style={{width:'80%', marginLeft: '10%', alignContent: 'center',
  textAlign: 'center'}} src={cell}/></div>)
}

function formattedTextH2(cell, row) {
  return (<h2 className='centeredTextInTable'>{cell}</h2>)
}
function formattedTextH3(cell, row) {
  return (<h3 className='centeredTextInTable'>{cell}</h3>)
}

export default Garage;
