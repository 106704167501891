import axios from 'axios';
import React, { Component } from 'react';
import ReactDom from 'react-dom';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import './App.css';

let homeDir = 'https://app.warriders.com/ref/';
//let web3 = vars.web3;
let URLs = vars.urls;

let defaultText = 'Earn ETH by becoming a Virtual Vehicle Dealer. Copy the URL below, share it with your friends on social media and earn 5% commission on every vehicle sold using your referral link. The top 3 car dealers will receive War Lambos!';

class Referral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referrer: '',
      copied: false,
      text: props.text == null ? defaultText : props.text
    };
    this.requestReferral = this.requestReferral.bind(this);
  }

componentWillMount() {
  var self = this;
    vars.getAccounts((error, accounts) => {
      if (accounts != null ){
        if (accounts.length == 0) {
            // there is no active accounts in MetaMask
            console.log('Error: couldn\'t find metamask accounts');
        }
        else {
          console.log('We found accounts from metamask');
          self.setState ({
            currentId: accounts[0]
          });
          self.state.currentId = accounts[0];
          self.requestReferral(accounts[0]);
        }
      }
        else {
          console.log('accounts not found')
        }


    })
}

requestReferral(userAccount) {
  var self = this;
  axios.get(URLs.requestReferral + "/" + userAccount).then( function(response) {
    let code = response.data;
    self.setState({refCode: code, got: true});
  })
}




  render() {
    return (
      <div>
        <br></br><br></br>
        <h3>YOUR REFERRAL {(this.state.copied) ? 'LINK' : 'CODE'}</h3>
        <br></br>
        <Col xs={12} sm={12} md={12}>
          <h5 style={{lineHeight: '30px', marginBottom: '20px'}} className='centered'>{this.state.text}</h5>
        </Col>

        <br></br><br></br>

        <div>
          <input value={(this.state.got) ? (homeDir.toString() + this.state.refCode.toString()): "Please log into Metamask to View"} style={{color: 'black', paddingLeft: '15px', width: '50%'}} disabled={true}
            onChange={({target: {value}}) => this.setState({value, copied: false})} />
            <br></br><br></br>
          <CopyToClipboard text={(this.state.got) ? (homeDir.toString() + this.state.refCode.toString()): ""}>
            <a className="btn" style={{zIndex: '0'}}>
              <span>COPY</span>
            </a>
          </CopyToClipboard>

        </div>
        <br></br><br></br><br></br><br></br>
      </div>
    )
  }
}

export default Referral;
