import firebase from "firebase";

let TESTING = window.location.hostname == "localhost" || window.location.hostname == "test.warriders.com";

const config = {
    apiKey: "AIzaSyBnEh5OjQz0YvozenRxedEs1456Ag64bwM",
    authDomain: "war-riders-account-system.firebaseapp.com",
    databaseURL: TESTING ? "https://war-riders-account-system-testing.firebaseio.com" : "https://war-riders-account-system.firebaseio.com",
    projectId: "war-riders-account-system",
    storageBucket: "war-riders-account-system.appspot.com",
    messagingSenderId: "650550117373"
};


let app = firebase.initializeApp(config);

export default app;
