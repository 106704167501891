import React from 'react';
import { ProgressBar, Col, Row, Grid } from 'react-bootstrap';
import swal from 'sweetalert';
import loadingGif from './images/loading.gif';
import variables from './variables.js';
import GradientSVG from './GradientSVG.js';
import CircularProgressbar from 'react-circular-progressbar';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip'
import { Redirect } from 'react-router';
import ReactDom from 'react-dom';
import VisibilitySensor from './VisibilitySensor.js';

let gun_token_contract = variables.infoContracts.gunToken;
let gun_token_contract_real = variables.contracts.gunToken;
let urls = variables.urls;
//let web3 = variables.web3;
let gradients = variables.gradients;
const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

async function getMetaForBatch(batches) {
    for (let i = 0; i < batches.length; i++) {
        //pick random id
        let start = Number(batches[i].startTokenId);
        let end = start + Number(batches[i].size);

        let randomId = Math.floor(Math.random() * (end - start) + start);

        let metaURL = await gun_token_contract_real.methods.tokenURI(randomId).call();

        let data = await getFromURL(metaURL);

        let _batchId = Math.floor(start / 4000);

        let img = data.image;

        batches[i] = Object.assign({}, batches[i], {image: img, id: _batchId});
    }

    return batches;
}

async function getFromURL(url, additionalData = {}) {
    return new Promise(function(resolve, reject) {
      return fetch(url)
       .then((response) => response.json())
       .then((responseJson) => {
         return resolve(Object.assign({}, responseJson, additionalData));
       })
       .catch((error) => {
         reject(error);
       });
    })
}

async function getMetaURLFromToken(token) {
    try {
      let metaURL = await gun_token_contract_real.methods.tokenURI(token).call();

      return metaURL;
    } catch (err) {
      console.log("Got error fetching URL: " + err);
      return null;
    }
}

async function getMetasForTokens(tokens) {
    return new Promise( async function(resolve, reject) {
        let metaURLs = [];
        for (let i = 0; i < tokens.length; i++) {
          let url = await getMetaURLFromToken(tokens[i]);
          metaURLs.push(getFromURL(url, {tokenId: tokens[i]}));
        }
        Promise.all(metaURLs).then(function(allCars) {
          resolve(allCars);
        }).catch(function(err) {
          reject(err);
        });
    })
}

function categoryToString(category) {
    if (category == 1) {
        return "Premium";
    } else if (category == 2) {
        return "Midgrade";
    } else if (category == 3) {
        return "Regular"
    } else {
        return "Unknown"
    }
}

var ProgressStats = (metadata) => {
    var svg = ( <GradientSVG
        idCSS='sxxx'
        rotation={90}
      />);
    return (
        <Row style={{width: '95%'}}  className='centered'>
            <Col xs={4} sm={4} md={4}>
                <div>
                    {svg}
                    <h4 style={{height: '34px'}} className='centered'>Damage Level</h4>
                    <br></br>
                    <CircularProgressbar percentage={metadata.meta.damageLevel / 10*100} text={`${metadata.meta.damageLevel}`} styles={{ width: '95%',  trail: { stroke: `#888888` }}}></CircularProgressbar>
                </div>
            </Col>
            <Col xs={4} sm={4} md={4}>
                <div>
                    {svg}
                    <h4 style={{height: '34px'}} className='centered'>Stability Level</h4>
                    <br></br>
                    <CircularProgressbar percentage={metadata.meta.stabilityLevel / 10*100} text={`${metadata.meta.stabilityLevel}`} styles={{ width: '95%',  trail: { stroke: `#888888` }}}></CircularProgressbar>
                </div>
            </Col>
            <Col xs={4} sm={4} md={4}>
                <div>
                    {svg}
                    <h4 style={{height: '34px'}} className='centered'>Rarity Index</h4>
                    <br></br>
                    <CircularProgressbar percentage={(metadata.meta.gunRarityIndex)/10*100} text={`${metadata.meta.gunRarityIndex}`} styles={{ width: '95%',  trail: { stroke: `#888888` }}}></CircularProgressbar>
                </div>
            </Col>
        </Row>
    )
}

var GunDisplay = (isBatch, id, metadata, callback) => {
    var title = (isBatch ? <h4><span>Batch {id + 1}</span><span style={{marginRight: '10%', marginLeft: '10%'}}>{metadata.size} {metadata.size == 1 ? "Gun" : "Guns"}</span><span>{categoryToString(metadata.category)}</span></h4> : <h4 style={{lineHeight: '30px'}}>{metadata.name}</h4>)

    var progressstats = (isBatch ? <div></div> : ProgressStats(metadata));
    var itemName = (isBatch ? "Batch ID: " : "Token ID: ")
    var url = (isBatch ? "" : "https://opensea.io/assets/" + gun_token_contract_real.options.address + "/" + id)

    var linkURL = (isBatch ? urls.viewBatch(id) : urls.viewMyGun(id));

    let dataTip = ''
    if (!isBatch) {
        if (metadata.meta.skinRarity != "") {
            dataTip = 'skintooltip'
        }
    }

    let skinRarity = "";
    if (!isBatch) {
        if (metadata.meta.skinRarity == "") {
            skinRarity = <br></br>
        } else {
            skinRarity = metadata.meta.skinRarity;
        }
    }

    function tocall() {
        callback(metadata, id);
    }

    return (
        <Col sm={4} md={4} style={{padding: '30px'}}>
            <div style={{border: '4px solid white', borderRadius: '40px'}}>
                <Row>
                    {title}
                    <p>{itemName} <a target="_blank" href={url}>{id}</a></p>
                </Row>
                <Row style={{width: '90%'}} className='centered'>
                    <img src={metadata.image} />
                </Row>
                {progressstats}
                <Row style={(isBatch ? {display: "none"} : {})}>
                    <span data-tip data-for={dataTip}><h4 style={{marginTop: '25px'}}>{(isBatch ? "" : skinRarity)}</h4></span>
                </Row>
                <Row>
                    <Col sm={6} md={6}>
                        <Link to={linkURL} target="_blank">
                            <a target="_blank" className="btn" type="button" style={{width: '140px', marginTop: '10px'}}>
                                <span>VIEW</span>
                            </a>
                        </Link>
                    </Col>
                    <Col sm={6} md={6}>
                        <a onClick={tocall} className="btn" type="button" style={{width: '140px', marginTop: '10px'}}>
                            <span>TRANSFER</span>
                        </a>
                    </Col>
                </Row>
                <Row><div style={{width: '100%', height: '30px'}}></div></Row>
            </div>
        </Col>
    )
}

class GunRow extends React.Component {
    constructor(props) {
        super(props);
        let tokens = props.tokens; //-1 is all tokens, -2 is all batches, n>=0 is batch n
        let callback = props.callback;
        let isBatch = props.isBatch;
        let startIndex = props.startIndex;
        this.state = {
            callback: callback,
            tokens: tokens,
            isBatch: isBatch,
            metas: [],
            loaded: false,
            loading: false,
            startIndex: startIndex
        };

        this.fetchData = this.fetchData.bind(this);
      }

      async componentDidMount() {
          await this.fetchData();
      }

      async fetchData() {
        try {
            let tokens = this.state.tokens;

            let metas;
            if (!this.state.isBatch) {
                metas = await getMetasForTokens(tokens);
            } else {
                metas = await getMetaForBatch(tokens);
            }

            this.setState({
                metas: metas,
                tokens: tokens
            })

            this.setState({
                loaded: true
            });
        } catch (e) {
            console.log("ERROR: " + e);
        }
    }

    render() {
        if (this.state.loaded) {
            let items = [];

            for (let i = 0; i < this.state.metas.length; i++) {
                items.push(GunDisplay(this.state.isBatch, (this.state.isBatch ? (i + this.state.startIndex) : this.state.tokens[i]), this.state.metas[i], this.state.callback));
            }

            return (
                <div>
                    <ReactTooltip id='skintooltip' aria-haspopup='true' role='example'>
                        <ul style={{paddingLeft: '10px', textAlign: 'initial'}}>
                            <li>Common - usually 40% of all skins</li>
                            <li>Rare - around 30% of all skins</li>
                            <li>Epic - approximately 15% of all skins</li>
                            <li>Legendary - around 10% of all skins</li>
                            <li>Exclusive - less than 5% of all skins</li>
                        </ul>
                    </ReactTooltip>
                    <Row id="grow">
                        {items}
                    </Row>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

export default GunRow;
