import React from 'react';
import ReactDom from 'react-dom';
import { Form, FormControl, FormGroup, Checkbox, ProgressBar, ControlLabel, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well } from 'react-bootstrap';
import imgs from './ImgFactory.js';
import ViewCar from './ViewCar.js';
import './App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import SelectVariant from './SelectVariant.js';
import {LineChart, ComposedChart, Sector, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, AreaChart, Area, ReferenceLine, Cell, BarChart, Bar, LabelList} from 'recharts';
import { Link } from 'react-router-dom';
import variables from './variables.js';
import { Progress } from 'react-sweet-progress';
import { Line, Circle } from 'rc-progress';
import "react-sweet-progress/lib/style.css";
import {Motion, spring, presets} from 'react-motion';
import Popup from 'react-popup';
import Referral from './Referral.js';
import _ from 'underscore';
import STLViewer from 'stl-viewer';
import regularDayImg from './images/70-day.png';
import midgradeDayImg from './images/50-day.png';
import MetaTags from 'react-meta-tags';
import premiumDayImg from './images/20-day.png';
import ReactTooltip from 'react-tooltip'
import premiumGarageImg from './images/garage-premium.jpg'
import midgradeGarageImg from './images/garage-mid-grade.jpg'
import regularGarageImg from './images/garage-regular.jpg'
let maxes = variables.maxes;
//let web3 = variables.web3;
let gradients = variables.gradients;

let referralText = 'Earn ETH and BZN by becoming a Virtual Garage Dealer. Copy the URL below, share it with your friends on social media and earn a 5% commission on every case opened using your referral link. The top 3 gun dealers will receive unique weapons!';

let urls = variables.urls;
let ranged_data = variables.cars_table_data_minDiff;
var carSampleData = [{name: 'Speed', value: 95},
      {name: 'Armor Units', value: 93},
      {name: 'Benzene Tank', value: 93},
      {name: 'Main Gun Slots', value: 94}]
var carSampleData02 = [{name: 'Speed', value: 80},
      {name: 'Armor Units', value: 76},
      {name: 'Benzene Tank', value: 73},
      {name: 'Main Gun Slots', value: 76}]
var carSampleData03 = [{name: 'Speed', value: 55},
      {name: 'Armor Units', value: 64},
      {name: 'Benzene Tank', value: 65},
      {name: 'Main Gun Slots', value: 60}]


      var carDatta = [{name: 'Speed', premiumMin: getRndInteger(170,200), midGradeMin: getRndInteger(120,180), regularMin: getRndInteger(100,120)},
            {name: 'Armor Units', premiumMin: getRndInteger(170,200), midGradeMin: getRndInteger(120,180), regularMin: getRndInteger(100,120)},
            {name: 'Benzene Tank', premiumMin: getRndInteger(170,200), midGradeMin: getRndInteger(120,180), regularMin: getRndInteger(100,120)},
            {name: 'Main Gun Slots', premiumMin: getRndInteger(170,200), midGradeMin: getRndInteger(120,180), regularMin: getRndInteger(100,120)}]

const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

const carPopDistributionData = [{name: 'Regular', value: 1000000}, {name: 'Mid-Grade', value: 150000},
    {name: 'Premium', value: 30000}];

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }

    const renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';

      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Total supply: ${value}`}</text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };

    class AdvPieChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        activeIndex: 0,
        isDesktop: false
      };
      this.updatePredicate = this.updatePredicate.bind(this);
      this.onPieEnter = this.onPieEnter.bind(this);
     }
     componentDidMount() {
       this.updatePredicate();
       window.addEventListener("resize", this.updatePredicate);
     }

     componentWillUnmount() {
       window.removeEventListener("resize", this.updatePredicate);
     }

     updatePredicate() {
       this.setState({ isDesktop: window.innerWidth > 1000 });
     }

    onPieEnter(data, index) {
      this.setState({
        activeIndex: index,
      });
    }
    render () {
      if (this.state.isDesktop) {
        return (
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                stroke="none"
                fill="red"
                data={carPopDistributionData}
                className='pie2'
                innerRadius='50%'
                outerRadius='80%'
                fill="#8884d8"
                paddingAngle={0}
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                onMouseEnter={this.onPieEnter}
              >
                {
                  carPopDistributionData.map((entry, index) => <Cell fill={COLORS[COLORS.length-2-index]}/>)
                }
              </Pie>
             </PieChart>
         </ResponsiveContainer>
        );
      } else {

        return (
          <img style={{width: '100%', height: '100%'}} src={imgs.supplyOverview}></img>
        );
      }

    }
  }

      class CustomizedLabel extends React.Component {
        constructor(props) {
          super(props);
          console.log('x: ' + props.x);
          console.log('value: ' + props.value);
          let perc = '' + props.value*0.9 + '%';
          this.state = {
            x: props.x,
            y: props.y,
            fill: props.fill,
            value: props.value,
            perc: perc
          };
        }
        render () {
         	return (
            <text
                     x={this.state.x}
                     y={this.state.y}
                     dx={-4}
                     fontSize='16'
                     fontFamily='sans-serif'
                     fill={this.state.fill}
                     textAnchor="middle">{this.state.value}%</text>
            );

        }
      }
      // class CustomizedLabel extends React.Component {
      //   constructor(props) {
      //     super(props);
      //     this.state = {
      //       x: this.props.x,
      //       y: this.props.y,
      //       fill: this.props.fill,
      //       value: this.props.value
      //     };
      //   }
      //
      //   render () {
      //    	return (
      //       <text
      //          x={this.state.x}
      //          y={this.state.y}
      //          dy={-4}
      //          fontSize='16'
      //          fontFamily='sans-serif'
      //          fill={this.state.fill}
      //          textAnchor="middle">{this.state.value}%</text>
      //     )
      //   }
      // }

class VertChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      realData: props.realData,
      visualData: props.visualData
    };
    var data = JSON.stringify(props.data);
    console.log('the Vert data is: ' + data)
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart layout="vertical" data={this.state.realData}
            margin={{top: 20, right: 20, bottom: 20, left: 20}}>

          <XAxis type="number" hide={true} domain={[0, 100]} />
          <YAxis dataKey="name" type="category" />
          <Bar dataKey="value" stackId="a" barSize={20} fill="#8884d8" >
            {
              this.state.visualData.map((entry, index) => {
                const color = COLORS[COLORS.length-1-index];
                return <Cell fill={color}/>;
              })
            }
            <LabelList dataKey="value" position="right" />
         </Bar>
         <Bar dataKey="" barSize={20} stackId="a" fill="#8884d8" >
           {
             this.state.visualData.map((entry, index) => {
               const color = COLORS[COLORS.length-1-index];
               return <Cell fill={color}/>;
             })
           }
           <LabelList dataKey="value" position="right" />
        </Bar>
       </ComposedChart>
     </ResponsiveContainer>
    );
  }
}

class VertChart2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
    var data = JSON.stringify(props.data);
    console.log('the Vert data is: ' + data)
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart layout="vertical" data={this.state.data}
            margin={{top: 20, right: 20, bottom: 20, left: 20}}>

          <XAxis type="number" hide={true} />
          <YAxis dataKey="name" type="category" />
          <Bar dataKey="min" stackId="a" barSize={20} >
            {
              this.state.data.map((entry, index) => {
                const color = COLORS[COLORS.length-1-index];
                return <Cell fill={color}/>;
              })
            }
            <LabelList dataKey="min" position="top" />
         </Bar>
         <Bar dataKey="max" barSize={20} stackId="a">
           {
             this.state.data.map((entry, index) => {
               const color = COLORS[index+1];
               return <Cell fill={color}/>;
             })
           }
           <LabelList dataKey="title" position="right" />
        </Bar>
       </ComposedChart>
     </ResponsiveContainer>
    );
  }
}

var hComp = (text, color) => {
  if (color) {
    return (
      <h5 style={{color: {color}}}>{text}</h5>
    );
  } else {
    return (
      <h5>{text}</h5>
    );
  }
}

var mmComponent = (type, cat, max, color) => {
  let cmin = variables.getRanges(type)[cat].min
  let cmax = variables.getRanges(type)[cat].max
  return (
      <div>{hComp(cmin, color)} - {hComp(cmax, color)} out of {hComp(max)}</div>
  );
}


var ProgressComponent = (type, cat, max, title, titleColor, dimension) => {
  let cmin = variables.getRanges(type)[cat].min;
  let cmax = variables.getRanges(type)[cat].max;


  return (
    <div style={{width: '99%'}}>
      <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
      <Row>
        <Col xs={12} sm={4}></Col>
        <Col xs={12} sm={8}>
          <h5><span style={{color: COLORS[1]}}>{cmin}</span> - <span style={{color: COLORS[2]}}>{cmax}</span> out of <span style={{color: COLORS[3]}}>{max}</span> <span dangerouslySetInnerHTML={{ __html: dimension }} /></h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <h4 style={{marginTop: '0%', color: titleColor}}>{title}</h4>
        </Col>
        <Col xs={12} sm={8}>

        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={cmin/max*100} key={1} style={{backgroundImage: gradients.minBar, backgroundColor: 'none'}}/>
          <ProgressBar now={(cmax-cmin)/max*100} key={1} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>

        </Col>
      </Row>
      </ScrollAnimation>
    </div>
  );
}




class GarageSelectType extends React.Component {
  constructor(props) {
    super(props);
    
  //  this.animate = this.animate.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div id='VehicleSelectType' style={{textAlign: 'center'}}>
        <MetaTags>
          <title>Gun Supply Overview</title>
          <meta property="og:title" content="Gun Supply Overview" />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={premiumGarageImg}/>
          <meta property="og:url" content="https://app.warriders.com/selectType"/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        <ReactTooltip id='discount-info' aria-haspopup='true' role='example'>
          <p>Max discount and luck index</p>
          <p>will define the ability to earn</p>
          <p>a significant renewal discount.</p>
        </ReactTooltip>
        <Grid>
          <Row style={{height: 100}}></Row>
          <Row>
            <br></br><br></br><br></br>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3 className='centered'>GARAGE PROPERTY TITLE SUPPLY OVERVIEW</h3>
            </Col>
            <br></br><br></br><br></br>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Premium Case</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
                <div style={{height: '60%'}}><img src={premiumGarageImg} style={{height: '90%'}}></img></div>
              </ScrollAnimation>
              <br></br>
              <h5><span style={{color: COLORS[3], fontSize: '16px'}}><strong data-tip data-for='discount-info'>TOP DISCOUNT AND LUCK INDEX</strong></span></h5>
              <br></br>
              <Link to={urls.selectGarage(0)}>
                <a className="btn">
                  <span>OPEN NOW</span>
                </a>
              </Link>
              <br></br><br></br>
              <div>{ProgressComponent(6,0,maxes.tokensPerNFT, 'Tokens per NFT', COLORS[1], ' tokens')}</div>
              <div>{ProgressComponent(6,1,maxes.renewTerm, 'Renew Term', COLORS[2], ' days')}</div>
              <div>{ProgressComponent(6,2,maxes.entranceFee, 'Entrance Fee', COLORS[3], ' %')}</div>
              <div>{ProgressComponent(6,3,maxes.entrances, 'Entrances', COLORS[4], ' doors')}</div>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Mid-Grade Case</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
                <div style={{height: '60%'}}><img src={midgradeGarageImg} style={{height: '90%'}}></img></div>
              </ScrollAnimation>
              <br></br>
              <h5><span style={{color: COLORS[3], fontSize: '16px'}}><strong data-tip data-for='discount-info'>AVERAGE DISCOUNT AND LUCK INDEX</strong></span></h5>
              <br></br>
              <Link to={urls.selectGarage(1)}>
                <a className="btn">
                  <span>OPEN NOW</span>
                </a>
              </Link>
              <br></br><br></br>
              <div>{ProgressComponent(7,0,maxes.tokensPerNFT, 'Tokens per NFT', COLORS[1], ' tokens')}</div>
              <div>{ProgressComponent(7,1,maxes.renewTerm, 'Renew Term', COLORS[2], ' days')}</div>
              <div>{ProgressComponent(7,2,maxes.entranceFee, 'Entrance Fee', COLORS[3], ' %')}</div>
              <div>{ProgressComponent(7,3,maxes.entrances, 'Entrances', COLORS[4], ' doors')}</div>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Regular Case</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
                <div style={{height: '60%'}}><img src={regularGarageImg} style={{height: '90%'}}></img></div>
              </ScrollAnimation>
              <br></br>
              <h5><span style={{color: COLORS[3], fontSize: '16px'}}><strong data-tip data-for='discount-info'>REGULAR DISCOUNT AND LUCK INDEX</strong></span></h5>
              <br></br>
              <Link to={urls.selectGarage(2)}>
                <a className="btn">
                  <span>OPEN NOW</span>
                </a>
              </Link>
              <br></br><br></br>
              <div>{ProgressComponent(8,0,maxes.tokensPerNFT, 'Tokens per NFT', COLORS[1], ' tokens')}</div>
              <div>{ProgressComponent(8,1,maxes.renewTerm, 'Renew Term', COLORS[2], ' days')}</div>
              <div>{ProgressComponent(8,2,maxes.entranceFee, 'Entrance Fee', COLORS[3], ' %')}</div>
              <div>{ProgressComponent(8,3,maxes.entrances, 'Entrances', COLORS[4], ' doors')}</div>
              </ScrollAnimation>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <br></br><br></br>
              <h3>LIMITED SUPPLY</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Premium Case</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
                <div style={{height: '60%'}}><img src={premiumDayImg} style={{width: '80%'}}></img></div>
              </ScrollAnimation>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Mid-Grade Case</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
                <div style={{height: '60%'}}><img src={midgradeDayImg} style={{width: '80%'}}></img></div>
              </ScrollAnimation>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
              <h4>Regular Case</h4>
              <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut" offset={50} duration={0.4}>
                <div style={{height: '60%'}}><img src={regularDayImg} style={{width: '80%'}}></img></div>
              </ScrollAnimation>
              </ScrollAnimation>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3>PRICING:</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h4 style={{lineHeight: '30px'}}>The prices for garage cases traditionally start low and increases with every case that's openeded.
              All purchases require BZN, but ETH will still be required to cover the transaction, minting and other fees.</h4>
            </Col>
          </Row>

          <Referral text={referralText} />
        </Grid>
      </div>
    );
  }



}


// class MotionSequence extends Component {
//   constructor(props: Object) {
//     super(props);
//
//     let sequenceId = 0;
//
//     console.log(this.props.styles[0]);
//     let defaultStyle = this.props.defaultStyle;
//     if (!defaultStyle) {
//       defaultStyle = {};
//
//       _.each(this.props.styles[0], (style: any, prop: string) => {
//         defaultStyle[prop] = _.isObject(style) ? style.val : style;
//       });
//
//       sequenceId = 1;
//     }
//
//     this.state = {
//       sequenceId,
//       defaultStyle
//     };
//   }
//
//   animationEnded() {
//     setTimeout(() => {
//       if (this.state.sequenceId < this.props.styles.length - 1) {
//         this.setState({
//           sequenceId: this.state.sequenceId + 1
//         });
//       }
//     }, 0);
//   }
//
//   render() {
//     const currentStyle = this.props.styles[this.state.sequenceId];
//
//     return (
//       <Motion
//         defaultStyle={this.state.defaultStyle}
//         style={currentStyle}
//         onRest={::this.animationEnded}
//       >
//         {this.props.children}
//       </Motion>
//     );
//   }
// }

export default GarageSelectType;
