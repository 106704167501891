import React, { Component } from 'react';
import ReactDom from 'react-dom';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import imgs from './ImgFactory.js';
import { Progress } from 'react-sweet-progress';
import { Line, Circle } from 'rc-progress';
import "react-sweet-progress/lib/style.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import {LineChart, ComposedChart, ScatterChart, Scatter, Dot, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, AreaChart, Area, ReferenceLine, Cell, BarChart, Bar, LabelList} from 'recharts';
import {Helmet} from "react-helmet";
import NumericInput from 'react-numeric-input';
import axios from 'axios';
import Referral from './Referral.js';
import Countdown from 'react-countdown-now';
import logo from './logo.svg';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './App.css';
import moment from 'moment';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import GradientSVG from './GradientSVG.js';
import Popup from 'react-popup';
import posed from 'react-pose';
import ReactPlayer from 'react-player';
import MetaTags from 'react-meta-tags';
import styled from "styled-components";
import { tween, transform } from "popmotion";
import { CSSTransitionGroup } from 'react-transition-group' // ES6
import { timeline } from 'popmotion';
import swal from 'sweetalert';
const { pipe, clamp } = transform;
var cars = vars.cars_table_data_minMax;
//let web3 = vars.web3;
let gradients = vars.gradients;
let urls = vars.urls;
const Box = posed.div({});

let preorder_contract = vars.contracts.preorder;
let preorder_address = preorder_contract.address;
let gamepool_contract = vars.infoContracts.gamePool;
let gun_token_contract = vars.infoContracts.gunToken;
let car_factory_contract = vars.infoContracts.carTokenFactory;
let car_token_contract = vars.infoContracts.carToken;

var URLs = vars.urls;
var maxes = vars.maxes;

function log(x) {
  console.log('(Z) ' + x);
}

const COLORS = ['#CA3CFC', '#B641F9', '#A146F6', '#8D4AF3', '#784FF0', '#6454ED', '#4F58EA'];

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}

function getThreeAdvertisementCars(callback) {
  var premiumCarsSize = cars.premium.length;
//   var mgCarsSize = cars.midGrade.length;
//   var regCarsSize = cars.regular.length;
//   var max = premiumCarsSize + mgCarsSize + regCarsSize;
//   var possible = [];
//   for (var i = 0; i < max; i++) {
//     possible.push(i);
//   }
//   var mutablePossible = possible;
//   var ads = [];
//   for (var i = 0; i < 3; i++) {
//     let randIndex = getRndInteger(0,mutablePossible.length-1);
//     ads.push(possible[randIndex]);
//     mutablePossible.splice(randIndex, 1);
//   }
//
// var adCarsList = [];
//
//   for (var i = 0; i < ads.length; i++) {
//     if (ads[i] < premiumCarsSize) { // Is premium car
//       let index = i;
//       let car = cars.premium[index];
//       adCarsList.push(car);
//     } else if (ads[i] < (premiumCarsSize+mgCarsSize)) { // Mid Grade
//       let index = i - premiumCarsSize;
//       let car = cars.midGrade[index];
//       adCarsList.push(car);
//     } else {
//       let index = i - premiumCarsSize - mgCarsSize;
//       let car = cars.regular[index];
//       adCarsList.push(car);
//     }
//   }

  var possible = [];
  var max = premiumCarsSize;
  for (var i = 0; i < max; i++) {
    possible.push(i);
  }
  console.log('possible are: ' + JSON.stringify(possible))
  var mutablePossible = possible;
    var ads = [];
    for (var i = 0; i < 3; i++) {
      let randIndex = getRndInteger(0,mutablePossible.length-1);
      ads.push(possible[randIndex]);
      mutablePossible.splice(randIndex, 1);
    }
  console.log('ads are: ' + JSON.stringify(ads))
  var adCarsList = [];
  var idList = [];

    for (var i = 0; i < ads.length; i++) {
        let index = ads[i];
        let car = cars.premium[index];
        idList.push(index);
        adCarsList.push(car);
    }
  console.log('final list is: ' + JSON.stringify(adCarsList))
  callback(adCarsList, idList);

}

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

function getCar(type, car) {
  var img; // Name of correct car
  if (type == 0) {
    img = cars.premium[car];
  } else if (type == 1) {
    img = cars.midGrade[car];
  } else if (type == 2) {
    img = cars.regular[car];
  } else { return 'ERRORR!!!' }
  console.log('ZQ: Returning car: ' + img);
  return img;
}


// END USELESS

function getMainAndAltGunCountsFromArray(array) {
  log('getMainAndAltGunCountsFromArray INPUT: ' + array)
  var main = 0;
  var alt = 0;
  var map = array.map( function(gun) {
    if (gun == 'M' || gun == 'm') { main++; }
    else { alt++ };
  })
  let obj = {main: main, alt: alt};
  return obj;
}

// Travel speed = (Engine power - Armor)/1.6
function calculateSpeed(engineSize, armor) {
return ((engineSize - armor)/1.6);
}
// Acceleration Ratio = Armor / Engine Power
// Acceleration = (Travel speed/10) / (Acceleration Ratio + 1)
function calculateAcceleration(engineSize, armor) {
var ratio = armor / engineSize;
var speed = calculateSpeed(engineSize, armor);
var acc = (speed/10) / (ratio + 1);
return acc;
}

var ProgressComponent = (num, max, title, titleColor, dimension) => {
  return (
    <div style={{width: '100%'}}>
      <Row>
        <Col xs={12} sm={4}></Col>
        <Col xs={12} sm={8}>
          <h5><span style={{color: COLORS[2]}}>{num}</span> out of <span style={{color: COLORS[3]}}>{max}</span><span dangerouslySetInnerHTML={{ __html: dimension }} /></h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <h4 style={{marginTop: '0%', color: titleColor}}>{title}</h4>
        </Col>
        <Col xs={12} sm={8}>
        <ProgressBar style={{backgroundImage: 'none', backgroundColor: 'darkGrey'}}>
          <ProgressBar now={(num/max)*100} key={1} style={{backgroundImage: gradients.maxBar, backgroundColor: 'none'}}/>
        </ProgressBar>
        </Col>
      </Row>
    </div>
  );
}

function getRefCode() {
  var randomStr = '';
  var secRandStr = '';
  for (var i = 0; i < 5; i++) {
    randomStr += (String.fromCharCode(65 + Math.floor(Math.random() * 26)));
    secRandStr += (String.fromCharCode(65 + Math.floor(Math.random() * 26)));
  }
  var genId = randomStr + Date.now() + secRandStr;
  return genId;
}

async function getFromURL(url) {
  return new Promise(function(resolve, reject) {
    return fetch(url)
     .then((response) => response.json())
     .then((responseJson) => {
       return resolve(responseJson);
     })
     .catch((error) => {
       reject(error);
     });
  })
}

async function getMetasForTokens(token) {
  return new Promise( async function(resolve, reject) {
      let metaURLs = [];
      for (let i = 0; i < token.length; i++) {
        let url = "https://vault.warriders.com/" + token[i] + ".json";
        metaURLs.push(getFromURL(url));
      }
      Promise.all(metaURLs).then(function(allCars) {
        resolve(allCars);
      }).catch(function(err) {
        reject(err);
      });
  })
}

class ViewMyCar extends Component {

  constructor(props) {
    super(props);
      //    let id = props.carId; // Passed to specify for HTTP Req so we can differentiate between different car types
      //let typeIndex = props.type;
      //let carIndex = props.car;
      let token = props.token;
      this.state = {
        currentId: 'DNE',
        refCode: getRefCode(),
        token: token,
        carInfo: {},
        speedMax: '',
        accMax: '',
        armorMax: '',
        bznTankMax: '',
        mg: '',
        ag: '',
        speedMin: '',
        accMin: '',
        armorMin: '',
        bznTankMin: '',
        nos: '',
        description: '',
        price: 0,
        hold: 0,
        fillMsg: 'loading..',
        totalSupply: 0,
        amtLeft: 0,
        lastSold: 'DNE',
        carIndex: 0,
        typeIndex: 0,
        videoSupported: true,
        adCarsNames: [cars.premium[0].name, cars.premium[1].name, cars.premium[2].name],
        adCarsImgs: [cars.premium[0].img, cars.premium[1].img, cars.premium[2].img],
        adIdList: [0,0,0],
        image: ''
      };
      this.getData = this.getData.bind(this);
      getThreeAdvertisementCars(function(carz, idz) {
        this.state.adCarsNames[0] = carz[0].name;
        this.state.adCarsNames[1] = carz[1].name;
        this.state.adCarsNames[2] = carz[2].name;
        this.state.adCarsImgs[0] = carz[0].img;
        this.state.adCarsImgs[1] = carz[1].img;
        this.state.adCarsImgs[2] = carz[2].img;
        this.state.adIdList[0] = idz[0];
        this.state.adIdList[1] = idz[1];
        this.state.adIdList[2] = idz[2];
        console.log('Found adCar: ' + JSON.stringify(carz))
    //    alert(carz[0].name + ', ' + carz[1].name + ', ' + carz[2].name)
      }.bind(this));
      this.getData();
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillMount() {

      vars.getAccounts((error, accounts) => {
        if (accounts != null ){
          if (accounts.length == 0) {
              // there is no active accounts in MetaMask
              console.log('Error: couldn\'t find metamask accounts');
          }
          else {
            console.log('We found accounts from metamask');
            this.setState ({
              currentId: accounts[0]
            });
          }
        }
          else {
            console.log('accounts not found')
          }


      })


   }

   popup = () => {
     var self = this;
     Popup.create({
   title: 'Buy ' + this.state.quantityBuying + ((this.state.quantityBuying > 1) ? ' cars' : ' car'),
   content: 'Please confirm that you would like to proceed with the purchase.',
   buttons: {
       left: [{
           text: 'Cancel',
           className: 'danger',
           action: function () {
               Popup.close();
           }
       }],
       right: [{
         text: 'Confirm',
         className: 'success',
         action: function () {
             Popup.close();
             // Proceed with purchase
             self.buyCar();
         }
       }]
   }
});
   }

  getData = () => {
    function typeToInt(type) {
      switch (type) {
          case "SUV":
              return 1;
          case "Truck":
              return 2;
          case "Hovercraft":
              return 3;
          case "Tank":
              return 4;
          case "Lambo":
              return 5;
          case "Buggy":
              return 6;
          case "Midgrade Type 2":
              return 7;
          case "Midgrade Type 3":
              return 8;
          case "Hatchback":
              return 9;
          case "Regular Type 2":
              return 10;
          case "Regular Type 3":
              return 11;
          default:
              return -1;
      }
    }
    function typeToCategory(type) {
        switch (type) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                return 1;
            case 6:
            case 7:
            case 8:
                return 2;
            case 9:
            case 10:
            case 11:
                return 3;
            default:
                return -1;
        }
    }
      //let type = this.state.typeIndex; // Start at 1 in Eth
      //let car = this.state.carIndex;
      let token = this.state.token;
      let self = this;

      let premium = false;
      let claimed = false;
      let giveaway = false;
      let fillMsg = "Loading..";

      self.setState({
        fillMsg: fillMsg
      });

      car_token_contract.methods.getCarType(token).call().then(function(carType) {
        return car_token_contract.methods.isPremium(carType).call();
      }).then(function(isPremium) {
        premium = isPremium;

        return car_factory_contract.methods.giveawayCar(token).call();
      }).then(function(isGiveaway) {
        giveaway = isGiveaway;

        return gamepool_contract.methods.BZNclaimed(token).call();
      }).then(function(claimed) {
          if (claimed) {
            fillMsg = "previously filled, no longer eligible"
          } else if (!premium && !giveaway) {
            fillMsg = "not eligible for initial fill"
          } else {
            fillMsg = "eligible for the initial fill" 
          }

          self.setState({
            fillMsg: fillMsg
          });
      }).catch(function(e) {
        swal.close();
        swal("Failed Eligibility Fetch", "Encountered an error: " + e, "error");
      });

      getMetasForTokens([token]).then(function(response) {
        let meta = response[0].meta;
        let guns = getMainAndAltGunCountsFromArray(meta.gunSlots);
        let carIndex = typeToInt(meta.type);
        let typeIndex = typeToCategory(carIndex);
        let image = meta.image;
        console.log('Recieved TI: ' + typeIndex + '\nCI: ' + carIndex);
        let carRes = getCar(typeIndex, carIndex);

        self.setState({
          carIndex: carIndex,
          typeIndex: typeIndex,
          image: image,
          badgeType: meta.badge
        });
        self.state.carIndex = carIndex;
        self.state.typeIndex = typeIndex;
        self.state.image = image;

        let name = response[0].name;
        self.setState ({
          nameString: name
        });

      let mg = guns.main;
      let ag = guns.alt; // Should count As
      console.log("XXXX " + name);
      this.setState({description: response[0].description, image: response[0].image});
      //this.state.description = description;
      var svg = ( <GradientSVG
             idCSS='sxxx'
             rotation={90}
           />);


      function carInfoProgressComponents(){
        return (
                <div>
                  <div>{ProgressComponent(calculateSpeed(meta.engineSize, meta.armor),maxes.speed, 'Speed', COLORS[1], ' MPH')}</div>
                  <div>{ProgressComponent(roundToTwo(calculateAcceleration(meta.engineSize, meta.armor)),maxes.acc, 'Accel.', COLORS[2], ' MPH<sup>2</sup>')}</div>
                  <div>{ProgressComponent(meta.BZNtank,maxes.bznTank, 'BZN Tank', COLORS[3], ' BZN')}</div>
                  <div>{ProgressComponent(meta.armor,maxes.armor, 'Armor', COLORS[4], ' UNITS')}</div>
                    <Row >
                      <Col xs={4} sm={4} md={4}>
                        <div>
                          {svg}
                              <h5 className='centered' style={{height: '16px'}}>Main Guns</h5>
                              <br></br>
                              <CircularProgressbar percentage={(mg)/maxes.mainGunSlots*100} text={`${mg}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                        </div>
                      </Col>
                      <Col xs={4} sm={4} md={4}>
                        <div>
                          {svg}
                          <h5 className='centered' style={{height: '16px'}}>Alt Guns</h5>
                          <br></br>
                              <CircularProgressbar percentage={(ag)/maxes.mainGunSlots*100} text={`${ag}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                        </div>
                      </Col>
                      <Col xs={4} sm={4} md={4}>
                        <div>
                          {svg}
                          <h5 className='centered' style={{height: '16px'}}>Equipment Slots</h5>
                          <br></br>
                              <CircularProgressbar percentage={(meta.equipmentSlots)/maxes.equipmentSlots*100} text={`${meta.equipmentSlots}`} styles={{  width: '95%', trail: { stroke: `#888888` }}}></CircularProgressbar>
                        </div>
                      </Col>

                    </Row>
                    <Row><div style={{width: '100%', height: '30px'}}></div></Row>
                </div>
              );

      }
      let onVideoFailed = function() {
        self.setState({videoSupported: false});
        ReactDom.render(<img src={self.state.image}></img>, document.getElementById('car-img'));
      };

      let vurl = response[0].webm;
      if (vurl != null && vurl != "" && this.state.videoSupported && isBrowser) {
        ReactDom.render(<ReactPlayer  width='100%' height='100%' url={vurl} playing={true} loop={true} onError={onVideoFailed}/>, document.getElementById('car-img'));
      } else {
        ReactDom.render(<img src={this.state.image}></img>, document.getElementById('car-img'));
      }

      ReactDom.render(<div>{carInfoProgressComponents()}</div>, document.getElementById('progressi'));
    }.bind(this)).catch(function(err) { console.log('We found an Error: ' + err + '\n' + err.message + '\n' + err.description) })
  }

render() {
  function customizationText(typeIndex, carType) {
    return "Customization is live";
  }
  function garageText(typeIndex, carType) {
    return "Comes with a free spot in the Central Garage";
  }

  function getSpecialTrait(carType) {
    carType = carType;
    switch (carType) {
      case 5:
        return ["Bonus Trait | NOS".toUpperCase(), "Instantly gains speed | NOS equip. installed"];
      case 4:
        return ["Bonus Trait | NOS".toUpperCase(), "No critical damage | NOS equip. installed"];
      case 3:
        return ["Bonus Trait | NOS".toUpperCase(), "Perfect maneuverability | NOS equip. installed"];
      default:
        return ["Bonus Trait | NOS", "NOS equip. installed"];
    }
  }
  const SpecialTrait = ({ title, description }) => <div><h4 style={{color: '#a922ed'}}>{title}</h4><p>{description}</p></div>

  let type = this.state.typeIndex;
      return (
        <div className="ViewCar">
          <MetaTags>
            <title>{this.state.nameString}</title>
            <meta name="description" content={this.state.description} />
            <meta property="og:title" content={this.state.nameString + ' - War Riders'} />
            <meta property="og:image" content={this.state.image} />

            <meta property="og:description" content={this.state.description} />
            <meta property="og:url" content={"https://app.warriders.com/garage/" + this.state.token}/>
            <meta name="twitter:card" content="summary_large_image"/>

            <meta property="og:site_name" content="War Riders"/>
            <meta name="twitter:image:alt" content={this.state.description}/>
          </MetaTags>
          <Grid>
            <Row style={{height: 100}}></Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h3>{this.state.nameString}</h3>
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <div id='car-img'>
                  <img src={this.state.image}></img>

                </div>
              </Col>
              <Col xs={12} sm={6} md={6}>
                  <Col xs={12} sm={12} md={12}>
                    <div id='progressi'>

                    </div>
                 </Col>

              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h4 id='car_description'>BZN tank status: <span style={{color: '#a922ed'}}>{this.state.fillMsg}</span></h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <br></br>
                <h3>ABOUT THIS VEHICLE KIT</h3>
                <br></br><br></br>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <h4 style={{color: '#a922ed'}}>FULLY CUSTOMIZABLE</h4>
                <p>{customizationText(this.state.typeIndex, this.state.carIndex)}</p>
                <br></br>
                <h4 style={{color: '#a922ed'}}>FREE GARAGE SPOT</h4>
                <p>{garageText(this.state.typeIndex, this.state.carIndex)}</p>
                <br></br>
                <SpecialTrait title={getSpecialTrait(this.state.carIndex)[0]} description={getSpecialTrait(this.state.carIndex)[1]} />
                <br></br>
                {this.state.badgeType != null &&
                  <div>
                  <h4 style={{color: '#a922ed'}}>BADGE:</h4>
                  <p>{this.state.badgeType}</p>
                  <br></br>
                  </div>
                }
              </Col>
              <Col xs={12} sm={6} md={6}>
                <h4 id='car_description' style={{lineHeight: '50px'}}>{(this.state.description) ? (this.state.description) : 'Sorry, there is no available description for this vehicle!'}</h4>
              </Col>
            </Row>

            <Row>
             <Col xs={12} sm={12} md={12}>
               <br></br>
               <h3>BUILD AN ARMY</h3>
               <br></br>
               <h4 style={{lineHeight: '30px'}}>The more vehicles you own, the more powerful you are. We recommend that you buy multiple vehicles ensuring your army is all set for the deadliest battle in human history.</h4>
               <br></br>
             </Col>
           </Row>
           <Row>
             <Col xs={12} sm={4} md={4}>
               <div style={{'margin-bottom': '70px'}}>
                 <h5>{this.state.adCarsNames[0]}</h5>
                 <img src={this.state.adCarsImgs[0]}></img>
                 <br></br><br></br>
                 <a className="btn" href={URLs.viewCar(this.state.typeIndex - 1, this.state.adIdList[0])}>
                   <span>PRE-ORDER</span>
                 </a>
               </div>
             </Col>
             <Col xs={12} sm={4} md={4} >
               <div style={{'margin-bottom': '70px'}}>
                 <h5>{this.state.adCarsNames[1]}</h5>
                 <img src={this.state.adCarsImgs[1]}></img>
                 <br></br><br></br>
                 <a className="btn" href={URLs.viewCar(this.state.typeIndex - 1, this.state.adIdList[1])}>
                   <span>PRE-ORDER</span>
                 </a>
               </div>
             </Col>
             <Col xs={12} sm={4} md={4}>
               <div style={{'margin-bottom': '70px'}}>
                 <h5>{this.state.adCarsNames[2]}</h5>
                 <img src={this.state.adCarsImgs[2]}></img>
                 <br></br><br></br>
                 <a className="btn" href={URLs.viewCar(this.state.typeIndex - 1, this.state.adIdList[2])}>
                   <span>PRE-ORDER</span>
                 </a>
               </div>
             </Col>
           </Row>
           <Row>
             <br></br><br></br><br></br><br></br>
             <h3>TRADE WITH OTHERS</h3>
             <br></br>
           </Row>
           <div>
             <h4 style={{lineHeight: '30px'}}>You will be able to freely trade vehicles with other players and exchange BZN for other virtual currencies. War Riders, partnered with OPSkins and WAX, is happy to announce that our in-game items and currency will be supported in their marketplaces as well. We are in the process of adding more support for other marketplaces and exchanges.</h4>
           </div>

           <Referral />


          </Grid>


        </div>
      );
    }
}

export default ViewMyCar;
