import React from 'react';
import ReactDom from 'react-dom';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, Col, ProgressBar, Row, Grid, DropdownButton, Jumbotron, MenuItem, Button, ButtonToolbar, Well, Navbar, NavItem, NavDropdown, Nav } from 'react-bootstrap';
import imgs from './ImgFactory.js';
import ViewCar from './ViewCar.js';
import './App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import variables from './variables.js';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {LineChart, ComposedChart, Sector, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, ResponsiveContainer, AreaChart, Area, ReferenceLine, Cell, BarChart, Bar, LabelList} from 'recharts';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Link } from 'react-router-dom';
import Referral from './Referral.js';
import MetaTags from 'react-meta-tags';
var urls = variables.urls;
var cars = variables.cars;
let cars_table_data = variables.cars; // MUST GET RID OF ( SUB MIN/MAX)
var PIE_COLORS = ['#4029DC', '#601BDF', '#800DE2', '#A100E5', '#7200ff'];
var PIE_COLORS_MORE = ['#680aff', '#a922ed', '#c823ed', '#5c42f4', '#3b00ff', '#7200ff'];


function getPopDistributionData(typeIndex) {
  let carss = variables.cars_table_data_minMax;
  switch (typeIndex) {
    case 0: // Prem
    var data = [];
    carss.premium.map(function(car){
      data.push({name: car.name, value: car.totalSupply})
    })
    return data;
    break;
    case 1: // Mid-Grade
    var data = [];
    carss.midGrade.map(function(car){
      data.push({name: car.name, value: car.totalSupply})
    })
    return data;
    break;
    case 2: // Reg
    var data = [];
    carss.regular.map(function(car){
      data.push({name: car.name, value: car.totalSupply})
    })
    return data;
    break;
    default:
      return 'DNE';
  }
}
const carPopDistributionData = [{name: 'Regular', value: 1000000}, {name: 'Mid-Grade', value: 150000},
    {name: 'Premium', value: 30000}];

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }

    const renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';

      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Total supply: ${value}`}</text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };

    function convertValue(index, value) {
      // Get value to 100 (standardize)
      if (index == 0) { // avg 400
        let avg = 400;
        let conversion = 100/avg;
        return 0.5* value * conversion; // change to 1X when we get MAX; use MAX not avg
      } else if (index == 1) { // avg 125
        let avg = 125;
        let conversion = 100/avg;
        return 0.5*value * conversion;
      } else if (index == 2) { // avg 125
        let avg = 125;
        let conversion = 100/avg;
        return 0.5*value * conversion;
      } else if (index == 3) { // avg 4
        let avg = 4;
        let conversion = 100/avg;
        return 0.5*value * conversion;
      } else if (index == 4) { // avg 1.5
        let avg = 1.5;
        let conversion = 100/avg;
        return 0.5*value * conversion;
      } else if (index == 5) { // avg 1.5
        let avg = 1.5;
        let conversion = 100/avg;
        return 0.5*value * conversion;
      } else if (index == 6) { // Y/N
        if (value) { return 100; } else { return 30; }
      }
    }
    const COLORS = ['#680aff', '#a922ed', '#c823ed', '#c200e0'];
    function getColorForValue(value) {
  //    console.log('Getting color for ' + value)

      if (value <= 25) { console.log('Returning color index 3'); return COLORS[3] }
      else if (value <= 50) { console.log('Returning color index 2'); return COLORS[2]}
      else if (value <= 75) { console.log('Returning color index 1'); return COLORS[1]}
      else { return COLORS[0]}
    }
    class AdvPieChart extends React.Component {
        constructor(props) {
          super(props);
          this.state = {
            activeIndex: 0,
            isDesktop: false,
            typeIndex: props.typeIndex
          };
          this.updatePredicate = this.updatePredicate.bind(this);
          this.onPieEnter = this.onPieEnter.bind(this);
         }
         componentDidMount() {
           //log('comppp mounted')
           this.updatePredicate();
           window.addEventListener("resize", this.updatePredicate);
         }

         componentWillUnmount() {
           window.removeEventListener("resize", this.updatePredicate);
         }

         updatePredicate() {
           //log('screen size change')
           this.setState({ isDesktop: window.innerWidth > 1000 });
         }

        onPieEnter(data, index) {
          this.setState({
            activeIndex: index,
          });
        }
      	render () {
          if (this.state.isDesktop) {
            return (
              <ResponsiveContainer width="100%" height={400}>
              	<PieChart>
                  <Pie stroke="none" fill="red"
                    data={getPopDistributionData(this.state.typeIndex)}
                    className='pie2'
                    innerRadius='50%'
                    outerRadius='80%'
                    fill="#8884d8"
                    paddingAngle={0}
                    activeIndex={this.state.activeIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={this.onPieEnter}
                  >
                  	{
                    	carPopDistributionData.map((entry, index) => <Cell fill={PIE_COLORS[index]}/>)
                    }
                  </Pie>
                 </PieChart>
             </ResponsiveContainer>
            );
          } else {
            var srcComponent;
            if (this.state.typeIndex ==  0) { srcComponent = <img style={{width: '100%', height: '100%'}} src={imgs.premiumSupply}></img> }
            else if (this.state.typeIndex ==  1) { srcComponent = <img style={{width: '100%', height: '100%'}} src={imgs.midgradeSupply}></img> }
            else if (this.state.typeIndex ==  2) { srcComponent = <img style={{width: '100%', height: '100%'}} src={imgs.regularSupply}></img> }
            return (
              <div>{srcComponent}</div>
            );
          }

        }
      }

      class CustomizedLabel extends React.Component {
        constructor(props) {
          super(props);
          console.log('x: ' + props.x);
          this.state = {
            x: props.x,
            y: props.y,
            fill: props.fill,
            value: props.value
          };
        }
        render () {
         	return (
          <text
             x={this.state.x}
             y={this.state.y}
             dy={3}
             dx={4}
             fontSize='16'
             fontFamily='sans-serif'
             fill={this.state.fill}
             fontColor='white'
             color='white'
             textAnchor="middle">{this.state.value}%
           </text>
            );

        }
      }

function converted(data) {
  let speed = convertValue(0, data[0].value);
  let armorUnits = convertValue(1, data[1].value);
  let bznTank = convertValue(2, data[2].value);
  let mainGunSlots = convertValue(3, data[3].value);
  let returnData = [{name: 'Speed', value: speed},
          {name: 'Armor Units', value: armorUnits},
          {name: 'Benzene Tank', value: bznTank},
          {name: 'Main Gun Slots', value: mainGunSlots}];
  return returnData;
}

class VertChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      realData: props.realData,
      visualData: props.visualData
    };
    var data = JSON.stringify(props.realData);
    console.log('the Vert data is: ' + data)
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart layout="vertical" data={converted(this.state.realData)}
            margin={{top: 20, right: 20, bottom: 20, left: 20}}>
          <XAxis type="number" hide={true} domain={[0, 100]} />
          <YAxis dataKey="name" type="category"/>
          <Bar dataKey="value" barSize={20} fill="#8884d8"  >
            {
              this.state.visualData.map((entry, index) => {
                const color = getColorForValue(convertValue(index, entry.value));
                return <Cell fill={color}/>;
              })
            }
            <LabelList dataKey="value" position="right" />
         </Bar>
       </ComposedChart>
     </ResponsiveContainer>
    );
  }
}

function getCarTypeForIndex(index) {
  switch (index) {
    case 0:
      return 'Premium'.toUpperCase()
      break;
      case 1:
        return 'Mid-Grade'.toUpperCase()
        break;
        case 2:
          return 'Regular'.toUpperCase()
          break;
    default:
     return 'DNE';
  }
}
function getImageForIndex(array, index) {
  switch (array) {
    case 0:
      return cars.premium[index].img;
      break;
      case 1:
        return cars.midGrade[index].img;
        break;
        case 2:
          return cars.regular[index].img;
          break;
    default:
     return 'DNE';
  }
}
function getNameForIndex(array, index) {
  switch (array) {
    case 0:
      return cars.premium[index].name.toUpperCase();
      break;
      case 1:
        return cars.midGrade[index].name.toUpperCase();
        break;
        case 2:
          return cars.regular[index].name.toUpperCase();
          break;
    default:
     return 'DNE';
  }
}

function getDescriptionForType(index) {
  switch (index) {
    case 0:
      return 'The prices for premium vehicles start low and increase with every vehicle sold. Every premium vehicle will be filled with Benzene (BZN) at the end of pre-orders. All in-game items including BZN will be tradable in a wide range of marketplaces, including OPSkins, WAX, OpenSea and more.';
      break;
      case 1:
        return 'Mid-grade vehicles are designed for experienced players looking for a good balance of price and power. There are 150,000 mid-grade​ vehicles​ ever to be released.';
        break;
        case 2:
          return 'Regular-Grade Vehicles are the entry-level war machines for all kinds of players. They can be used for general purposes, Benzene mining, Wasteland patrol, and vehicular combat. The regular-grade vehicles are going to be offered for sale in 2019. There are only 1,000,000 Regular-Grade​ Vehicles​ that will ever be released.';
          break;
    default:
     return 'DNE';
  }
}

class NewSelect extends React.Component {

  constructor(props) {
    super(props);
    let type = props.typeIndex;
    let minMax = variables.cars_table_data_minMax;
    var data;
    if (type == 0) {
      data = minMax.premium.slice(0);
      /*
      data.unshift(
        {
          name: 'Bundles',
          img: imgs.bundleIcon,
          engineMin: 900,
          engineMax: 1000,
          armorMin: 650,
          armorMax: 700,
          bznTankMin: 200,
          bznTankMax: 250,
          speedMin: 12.5,
          speedMax: 218.75,
          accMin: 7.03,
          accMax: 13.26,
          mainGuns: 1,
          altGuns: 2,
          totalGuns: '1-8',
          totalSpeed: '12.5-218.75',
          totalBznTank: '150-450',
          equipmentSlots: 5,
          description: 'The War Lambo is a speedy beast on customized sand-adapted suspension. War Lambos are the fastest and most stylish vehicles in the Wasteland, and each has the special ability of instantly gaining speed. There are only 100 Lambos​ to ever be released.',
          nos: true,
          base_price: 0.5000,
          totalSupply: 100
        }
      )
      data.unshift(
        {
          name: 'Auctions',
          img: imgs.allAuctions,
          engineMin: 900,
          engineMax: 1000,
          armorMin: 650,
          armorMax: 700,
          bznTankMin: 200,
          bznTankMax: 250,
          speedMin: 53.18,
          speedMax: 218.75,
          accMin: 7.03,
          accMax: 13.26,
          mainGuns: 1,
          altGuns: 2,
          totalGuns: '3-8',
          totalSpeed: '53.18-218.75',
          totalBznTank: '200-250',
          equipmentSlots: 5,
          description: 'The War Lambo is a speedy beast on customized sand-adapted suspension. War Lambos are the fastest and most stylish vehicles in the Wasteland, and each has the special ability of instantly gaining speed. There are only 100 Lambos​ to ever be released.',
          nos: true,
          base_price: 0.5000,
          totalSupply: 100
        }
      )
      */
    }
    else if (type == 1) { data = minMax.midGrade }
    else if (type == 2) { data = minMax.regular }
    console.log('ZZZ_data: ' + data);
    this.state = {
      typeIndex: type,
      data: data,
      isDesktop: false,
    };
    this.getIdForName = this.getIdForName.bind(this);
    this.aZero = this.aZero.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
   }
   componentDidMount() {
     window.scrollTo(0, 0)
     this.updatePredicate();
     window.addEventListener("resize", this.updatePredicate);
   }

   componentWillUnmount() {
     window.removeEventListener("resize", this.updatePredicate);
   }

   updatePredicate() {
     this.setState({ isDesktop: window.innerWidth > 1100 });
   }

   getIdForName(name) {
     let data = this.state.data;
    for(var i = 0;i<data.length;i++) {
      if (data[i].name == name) {
        return i;
      }
    }
    return 'IDK MAN!!';
  }

 rowClassNameFormat(row, rowIdx) {
  return 'border: none';
}

headerStyle() {
  this.state.ind += 1;
  return this.state.ind > 2 ? {fontSize: '28px'} : {fontSize: 'red'}
}


renderCustomHeader(visible, text) {
  if (visible) {
    return(
      <p style={{fontSize: '18px', marginBotton: '0px'}}>{text}</p>
    );
  } else {
    return(
      <p style={{fontSize: '18px', opacity: 0}}>{text}</p>
    );
  }
  }

   aZero(cell, row) {
    let data = this.state.data
    let type = this.state.typeIndex;
    let index = data.map(function(car) { return car.name; }).indexOf(cell);
    return (<a className="btn" style={{width: '140px', marginTop: '70px'}}>
      <span>{(type == 0) ? 'PRE-ORDER' : 'PREVIEW'}</span>
    </a>)
  }

  cellButton = (cell, row, enumObject, rowIndex) => {
    let type = this.state.typeIndex;
      return (
        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" offset={80} duration={1.2}>
        <div>
          <Link to={urls.viewCar(this.state.typeIndex, (rowIndex))}>
          <a className="btn" type="button" style={{width: '140px', marginTop: '70px'}}>
            <span>{(type == 0) ? 'VIEW' : 'PREVIEW'}</span>
          </a>
        </Link>
        </div>
      </ScrollAnimation>
      )
 }




  render = () => {
    console.log('ZZ_State: ' + toStr(this.state));
    console.log('ZZ_IND: ' + this.state.isDesktop);
    console.log('ZZ_Data: ' + this.state.data);
    const isDesktop = this.state.isDesktop;

    var namesList = this.state.data.map(function(car, index){
      console.log('Car index: ' + index)
                        return (
                          <div>
                            <Grid>
                              <Row>
                                <h2 style={{color: '#680aff'}}>{car.name}</h2>
                              </Row>
                              <Row>
                                <img src={car.img} style={{color: '#680aff'}}></img>
                              </Row>
                              <Row>
                                <h2 style={{color: '#680aff'}}>{car.totalSpeed} MPH </h2>
                              </Row>
                              <Row>
                                <Col xs={6} sm={6} md={6}> <h3 style={{color: '#680aff'}}>{car.totalBznTank} BZN </h3></Col>
                                <Col xs={6} sm={6} md={6}> <h3 style={{color: '#680aff'}}>{car.totalGuns} Guns </h3></Col>
                              </Row>
                              <Row>
                                <br></br>
                                  <a href={urls.viewCar(this.state.typeIndex, index)} className="btn" style={{width: '70%', marginTop: '33px'}}>
                                    <span>{(this.state.typeIndex == 0 ? "VIEW" : "PREVIEW")}</span>
                                  </a>
                                <br></br><br></br><br></br><br></br>
                              </Row>
                            </Grid>
                          </div>
                        );
      }.bind(this))
    return (
      <div id='VehicleSelectBuy' style={{textAlign: 'center'}}>
        <MetaTags>
          <title>{getCarTypeForIndex(this.state.typeIndex)} Vehicle Kits</title>
          <meta property="og:title" content={getCarTypeForIndex(this.state.typeIndex) + 'Vehicle Kits'} />
          <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
          <meta property="og:image" content={this.state.data[0].img}/>
          <meta property="og:url" content={"https://app.warriders.com/selectCar/" + this.state.typeIndex}/>
          <meta name="twitter:card" content="summary_large_image"/>

          <meta property="og:site_name" content="War Riders"/>
          <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
        </MetaTags>
        {isDesktop ? (
          <Grid>
            <Row style={{height: 150}}></Row>
            <Row>
               <Col xs={12} sm={12} md={12}>
                <h3 className='centered'>VIEW {getCarTypeForIndex(this.state.typeIndex)} VEHICLE KITS</h3>
               </Col>
              <br></br><br></br><br></br>
            </Row>
            <Row>
              <ScrollAnimation animateIn="bounceInUp" offset={120} duration={1.2}>
              <BootstrapTable data={this.state.data} version='4' ref="table" bordered={ false }selectRow={ selectRowProp }
                >
                <TableHeaderColumn isKey dataField='name' ref="name" headerAlign='center' dataFormat={formattedTextH3} columnClassName='columnClassNameFormat1' isKey >{this.renderCustomHeader(false, 'Car Name') }</TableHeaderColumn>
                <TableHeaderColumn dataField='img' headerAlign='center' width='280px' dataFormat={imageFormatter} >{this.renderCustomHeader(false, 'Images') }</TableHeaderColumn>
                <TableHeaderColumn dataField='totalSpeed' headerAlign='center' dataFormat={formattedSpeed} columnClassName='columnClassNameFormat2'>{this.renderCustomHeader(true, 'Speed') }</TableHeaderColumn>
                <TableHeaderColumn dataField='totalBznTank' headerAlign='center'  dataFormat={formattedBznTank} columnClassName='columnClassNameFormat3'>{this.renderCustomHeader(true, 'BZN Tank') }</TableHeaderColumn>
                <TableHeaderColumn dataField='totalGuns' headerAlign='center' dataFormat={formattedGuns} columnClassName='columnClassNameFormat4'>{this.renderCustomHeader(true, 'Guns') }</TableHeaderColumn>
                <TableHeaderColumn dataField='name' headerAlign='center' dataFormat={this.cellButton} columnClassName='columnClassNameFormat4'>{this.renderCustomHeader(false, 'Guns') }</TableHeaderColumn>
              </BootstrapTable>
            </ScrollAnimation>
              <br></br><br></br><br></br>
            </Row>
            <Row>

              <Col xs={12} sm={12} md={12}>
                <h3>TOTAL SUPPLY OF {getCarTypeForIndex(this.state.typeIndex)} VEHICLES:</h3>
              </Col>
            </Row>
           <Row>
             <Col xs={12} sm={12} md={12}>
               <AdvPieChart typeIndex={this.state.typeIndex}/>
             </Col>
           </Row>
           <Row>
             <Col xs={12} sm={12} md={12}>
               <br></br><br></br>
               <h4 style={{lineHeight: '30px'}}>{getDescriptionForType(this.state.typeIndex)}</h4>
             </Col>
           </Row>
           <Referral />
         </Grid>
        ) : (
          <div>
            <div><br></br><br></br><br></br><br></br><br></br><br></br></div>
            <h3 className='centered'>VIEW {getCarTypeForIndex(this.state.typeIndex)} VEHICLES</h3>
            <div>{namesList}</div>

            <div>
              <Col xs={12} sm={12} md={12}>
                <h3>TOTAL SUPPLY OF {getCarTypeForIndex(this.state.typeIndex)} VEHICLES:</h3>
              </Col>
            </div>
            <div>
              <Col xs={12} sm={12} md={12}>
                <AdvPieChart typeIndex={this.state.typeIndex}/>
              </Col>
            </div>
            <div>
              <Col xs={12} sm={12} md={12}>
                <br></br><br></br>
                <h4 style={{lineHeight: '30px'}}>{getDescriptionForType(this.state.typeIndex)}</h4>
                <br></br><br></br><br></br>
              </Col>
            </div>
            <Referral />
          </div>
        )}
      </div>

    );
  }

}


function toStr(x) {
  return (JSON.stringify(x))
}

function rowStyleFormat(row, rowIdx) {
  return { backgroundColor: rowIdx % 2 === 0 ? '#3366FF' : '#6633CC' };
}

function imageFormatter(cell, row){
  console.log('RowImg: ' + toStr(row))
  return (<ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" offset={80} duration={1.2}><div style={{height: '180px'}}><img style={{marginTop: '0px', width:'90%', marginLeft: '10%', alignContent: 'center',
  textAlign: 'center'}} src={cell}/></div></ScrollAnimation>)
}

function formattedTextH2(cell, row) {
  console.log('RowH2: ' + toStr(row))
  return (<ScrollAnimation animateIn="fadeIn" animateOut="fadeOut"  offset={80} duration={1.2}><div style={{marginTop: '70px', fontSize: '16px'}} className='centeredTextInTable'>{cell} </div></ScrollAnimation>)
}

const selectRowProp = {
 clickToSelect: false,
 unselectable: [ 0, 1, 2, 3 , 4] // give rowkeys for unselectable row
};

function formattedTextH3(cell, row) {
  console.log('RowH3: ' + toStr(row))
  console.log('ZZ_: ' + cell);
  if (row.name == "Exclusive Auctions")
    return (<ScrollAnimation animateIn="fadeIn" animateOut="fadeOut"  offset={80} duration={1.2}><div style={{marginTop: '70px', fontSize: '18px'}} className='centeredTextInTable'>{cell}</div></ScrollAnimation>)
  else
    return (<ScrollAnimation animateIn="fadeIn" animateOut="fadeOut"  offset={80} duration={1.2}><div style={{marginTop: '70px'}} className='centeredTextInTable'>{cell}</div></ScrollAnimation>)
}

function formattedSpeed(cell, row) {
  return (<ScrollAnimation animateIn="fadeIn" animateOut="fadeOut"  offset={80} duration={1.2}><div style={{marginTop: '70px'}} className='centeredTextInTable'><div><span style={{display:'inline-block'}}>{cell}</span><span style={{display:'block'}}>MPH</span></div></div></ScrollAnimation>)
}

function formattedBznTank(cell, row) {
  return (<ScrollAnimation animateIn="fadeIn" animateOut="fadeOut"  offset={80} duration={1.2}><div style={{marginTop: '70px'}} className='centeredTextInTable'><div><span style={{display:'inline-block'}}>{cell}</span><span style={{display:'block'}}>BZN</span></div></div></ScrollAnimation>)
}

function formattedGuns(cell, row) {
  return (<ScrollAnimation animateIn="fadeIn" animateOut="fadeOut"  offset={80} duration={1.2}><div style={{marginTop: '70px'}} className='centeredTextInTable'><div><span style={{display:'inline-block'}}>{cell}</span><span style={{display:'block'}}>GUNS</span></div></div></ScrollAnimation>)
}

// class SelectVariant extends React.Component {
//
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       type: getCarTypeForIndex(props.carTypeIndex),
//       typeIndex: props.carTypeIndex,
//     };
//   }
//
//
//
//   render() {
//     return (
//       <div id='VehicleSelectBuy' style={{textAlign: 'center'}}>
//         <Grid>
//           <Row style={{height: 100}}></Row>
//           <Row>
//             <Col xs={12} sm={12} md={12}>
//               <h3 className='centered'>BUY {this.state.type}-GRADE VEHICLES</h3>
//             </Col>
//             <br></br><br></br><br></br>
//           </Row>
//           <Row>
//             <Col xs={12} sm={4} md={4}>
//               <h4>{getNameForIndex(this.state.typeIndex, 0)}</h4>
//               <div  style={{height: '60%'}}><img src={getImageForIndex(this.state.typeIndex, 0)} style={{height: '90%'}}></img></div>
//               <br></br>
//               <a className="btn" onClick={() => this.viewSelect(this.state.typeIndex, 0)}>
//                 <span>PRE-ORDER</span>
//               </a>
//               <VertChart visualData={getDataForIndex(this.state.typeIndex, 0)} realData={getDataForIndex(this.state.typeIndex, 0)}/>
//             </Col>
//             <Col xs={12} sm={4} md={4}>
//               <h4>{getNameForIndex(this.state.typeIndex, 1)}</h4>
//               <div  style={{height: '60%'}}><img src={getImageForIndex(this.state.typeIndex, 1)} style={{height: '90%'}}></img></div>
//               <br></br>
//               <a className="btn" onClick={() => this.viewSelect(this.state.typeIndex, 1)}>
//                 <span>PRE-ORDER</span>
//               </a>
//               <VertChart visualData={getDataForIndex(this.state.typeIndex, 1)} realData={getDataForIndex(this.state.typeIndex, 1)}/>
//             </Col>
//             <Col xs={12} sm={4} md={4}>
//               <h4>{getNameForIndex(this.state.typeIndex, 2)}</h4>
//               <div style={{height: '60%'}}><img src={getImageForIndex(this.state.typeIndex, 2)} style={{height: '90%'}}></img></div>
//               <br></br>
//               <a className="btn" onClick={() => this.viewSelect(this.state.typeIndex, 2)}>
//                 <span>PRE-ORDER</span>
//               </a>
//               <VertChart visualData={getDataForIndex(this.state.typeIndex, 2)} realData={getDataForIndex(this.state.typeIndex, 2)}/>
//             </Col>
//           </Row>
//           <Row>
//             <Col xs={12} sm={12} md={12}>
//               <h3>TOTAL SUPPLY OF VEHICLES:</h3>
//             </Col>
//           </Row>
//           <Row>
//             <Col xs={12} sm={12} md={12}>
//               <AdvPieChart typeIndex={this.state.typeIndex}/>
//             </Col>
//           </Row>
//           <Row>
//             <Col xs={12} sm={12} md={12}>
//               <h3>PRICING:</h3>
//             </Col>
//           </Row>
//           <Row>
//             <Col xs={12} sm={12} md={12}>
//               <h4>The pricing for the SUVs and tankers starts low and increases by 0.015% with every vehicle sold. The special vehicles have a decaying auction mechanism where the starting price is determined b averaging the prices of the last 3 special vehicles sold.</h4>
//             </Col>
//           </Row>
//           <Row style={{height: 100}}></Row>
//         </Grid>
//
//       </div>
//     );
//   }
// }



export default NewSelect;
