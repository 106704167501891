import React, { Component } from 'react';
import vars from './variables.js';
import { Col, Row, Grid } from 'react-bootstrap';
import './App.css';
import loadingGif from './images/loading.gif';
import swal from 'sweetalert';
import img from './ImgFactory.js';
import MetaTags from 'react-meta-tags';
import ReactTooltip from 'react-tooltip'
import { Redirect } from 'react-router';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'
import { isMobile } from "react-device-detect";
import variables from './variables.js';

var hashesConfirmed = [];
//const web3 = vars.web3;
const firebase = vars.firebase;

class DeleteAccount extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        goHome: false,
        userProfile: null,
        currentUsername: "",
        loginAccount: true,
        email: "",
        password: "",
        recaptchaVerified: false,
        recaptchaResponseData: null,
      };

      this.verifyCallback = this.verifyCallback.bind(this);
      this.doCancel = this.doCancel.bind(this);
      this.confirmDelete = this.confirmDelete.bind(this);
      this.doDelete = this.doDelete.bind(this);
      this.handleUnlink = this.handleUnlink.bind(this);
      this._unlink = this._unlink.bind(this);
      this.loginRender = this.loginRender.bind(this);
      this.mobileLoginRender = this.mobileLoginRender.bind(this);
      this.deleteAccountRender = this.deleteAccountRender.bind(this);
      this.handleLogin = this.handleLogin.bind(this);
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handlePassChange = this.handlePassChange.bind(this);
      this.captchaLoaded = this.captchaLoaded.bind(this);
    }

    verifyCallback(response) {
      this.setState({
        recaptchaResponseData: response
      })

      /*console.log(response);
      const self = this;
      fetch('https://api.warriders.com/verifycaptcha', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          recaptchaResponse: response
        })
      }).then(function(response) {
        return response.text();
      }).then(function(data) {
        if (data == 'passed') {
          self.setState({ recaptchaVerified: true });
        } else {
          self.setState({ recaptchaVerified: false });
        }
      }).catch(function(e) {
        console.log(e);
        self.setState({ recaptchaVerified: false });
      });*/
    }

    captchaLoaded() {
      this.setState({ recaptchaVerified: false, recaptchaResponseData: null });
    }

    componentDidMount() {
        let self = this;

        window.scrollTo(0, 0);

        loadReCaptcha();
        
        firebase.auth().signOut();

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
          self.setState({
            userProfile: user
          });
          vars.username().then(function(cu) {
            self.setState({
              currentUsername: cu
            });
          }).catch(function(e) {
            console.log("Could not lookup username");
            console.log(e);
          });
        });

        let userCheck = firebase.auth().currentUser;
        if (userCheck != null) {
          self.setState({
            userProfile: userCheck
          });
          vars.username().then(function(cu) {
            self.setState({
              currentUsername: cu
            });
          }).catch(function(e) {
            console.log("Could not lookup username");
            console.log(e);
          });
        }
        
        /*vars.getAccounts().then(function(accounts) {
            if (accounts != null) {
              if (accounts.length == 0) {
                var elem = document.createElement("div");
                elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
                swal({
                  title: "No External Wallet",
                  content: elem,
                  icon: "error"
                }).then(function() {
                  self.setState({
                    goHome: true
                  })
                });
              }
              else {
                console.log('We found external wallets from metamask');
                self.setState ({
                  address: accounts[0]
                });
      
              }
            }
            else {
              var elem = document.createElement("div");
              elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
              swal({
                title: "No External Wallet",
                content: elem,
                icon: "error"
              }).then(function() {
                self.setState({
                    goHome: true
                })
              });
            }
        }).catch(function(error) {
            var elem = document.createElement("div");
            elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
            swal({
              title: "No External Wallet",
              content: elem,
              icon: "error"
            }).then(function() {
              self.setState({
                goHome: true
              })
            });
        });*/
    }

    confirmDelete() {
      if (!this.state.recaptchaResponseData) {
        swal("ReCaptcha", "Please complete the ReCaptcha before you delete your game account", "error");
        return;
      }

      let self = this;
      if (self.state.userProfile == null) {
        swal("Login Failed", "No account logged in!", "error");
        self.setState({
          goHome: true
        });
        return;
      }
      
      self.doDelete();
    }

    async doDelete() {
      let self = this;
      try {
        const result = await this.handleUnlink();

        if (!result) {
          return;
        }

        const willdelete = await swal({
          title: "Are You Sure?",
          text: "Please confirm that you would like to delete the account " + this.state.currentUsername + " permanently.  You will receive a one-time sign in link to complete the request.",
          icon: 'warning',
          buttons: true
        });

        if (!willdelete) {
          return;
        }

        swal({
          title: "Processing...",
          text: "Please wait while delete request is created...",
          icon: loadingGif,
          className: 'wr-loader',
          button: false,
          closeOnClickOutside: false
        });

        const email = this.state.userProfile.email;

        console.log("Email to use: " + email);

        const userToken = await this.state.userProfile.getIdToken();
        
        let response = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/account/requestDelete', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + userToken
          },
          body: JSON.stringify({
            recaptchaResponse: this.state.recaptchaResponseData
          })
        });

        let result2 = await response.json();

        if (result2.error) {
          swal("Delete Request Failed", result2.message, "error");
          return;
        }

        window.localStorage.setItem('emailForSignIn', email);

        swal("Check your Email", "Please check your email to confirm your account deletion. Check your spam folder if you don't see the message.", "info");

        firebase.auth().signOut();
    
        self.setState({
          goHome: true
        });

      } catch (error) {
        swal("Deletion Failed", "Could not delete your account. Sorry about that :(\n Please give this error to the devs.\nError: " + error, "error");
      }
    }

    async handleUnlink() {
      let self = this;

      swal({
        title: "Processing...",
        text: "Please wait while user data is verified.",
        icon: loadingGif,
        className: 'wr-loader',
        button: false,
        closeOnClickOutside: false
      });

      try {
        await variables.accountLinked();
      } catch (e) {
        return true; //No address to unlink
      }

      const willUnlink = await swal({
        title: "Wallet Unlink Required",
        text: "You must first unlink your wallet to be able to delete your account. Your wallet will be unlinked, but your assets will remain in your wallet. Would you like to unlink your wallet?",
        icon: 'warning',
        buttons: true
      });

      if (!willUnlink) {
        return false; //They dont want to unlink
      }

      swal({
        title: "Processing...",
        text: "Please wait while your external wallet is unlinked. You may need to sign a message to verify you are the owner of this wallet. Check your wallet if you don’t see a Signature Request pop-up.",
        icon: loadingGif,
        className: 'wr-loader',
        button: false,
        closeOnClickOutside: false
      });

      await self.state.userProfile.reload();
      if (!self.state.userProfile.emailVerified) {
        return true; //If they never verified their email, nothing to unlink
      }

      return await self._unlink();
    }

    async _unlink() {
      if (this.state.userProfile.displayName == null || this.state.userProfile.displayName == "") {
        return false; //No username? odd
      }
  
      var self = this;

      // First, grab all the accounts
      let address = null;
      try {
        await vars.askForWeb3();

        const accounts = await vars.getAccounts();

        if (accounts != null) {
          if (accounts.length == 0) {
            var elem = document.createElement("div");
            elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
            swal({
              title: "No External Wallet",
              content: elem,
              icon: "error"
            });

            return false; // No external wallet connected
          }
          else {
            console.log('We found external wallets from metamask');
            address = accounts[0]
          }
        }
        else {
          var elem = document.createElement("div");
          elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
          swal({
            title: "No External Wallet",
            content: elem,
            icon: "error"
          });

          return false; // No external wallet linked
        }
      } catch (error) {
        var elem = document.createElement("div");
        elem.innerHTML = "<p style=\"color: black\">No external wallet is logged in. Please make sure you are using a Web3 compatible browser and that it is unlocked.<br><br><a href=\"https://app.warriders.com/noweb3\">What's Web3?</a></p>"
        swal({
          title: "No External Wallet",
          content: elem,
          icon: "error"
        }).then(function() {
          self.setState({
            goHome: true
          })
        });

        return false; // No external wallet connected
      }
  
      const msg = new Buffer('I confirm I am the owner of this account');
      var hash = '0x' + msg.toString('hex');
      
      try {
        const signature = await vars.web3.eth.personal.sign(hash, address);
        const token = await self.state.userProfile.getIdToken();

        const response = await fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/unlink', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            message: signature
          })
        });

        const obj = await response.json();
        if (!obj.error) {
          return true;
        } else {
          if (obj.message == "No link") {
            swal("Incorrect Wallet Address", "The wallet signing the unlink message does not match the linked address. Please check your connected wallet and try again.", "error");
          } else {
            swal("Got Error", "Error: " + obj.message, "error");
          }
        }
      } catch (error) {
        swal("Got Error", "Error: " + error.message, "error");
      }

      return false;
    }

    handleLogin() {
      var self = this;
  
      let email = this.state.email;
      let password = this.state.password;
  
      if (email == "" || password == "") {
        swal("Incomplete", "Please fill in all fields", "error");
        return;
      }
  
      firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
  
        swal("Error", "An error has occured: \"" + errorMessage + "\"", "error");
      }).then(function(data) {
        if (data == null) {
          swal("Login Failed", "Invalid email or password!", "error");
          return;
        }
  
        self.setState({
          loginAccount: false
        });
      });
    }

    handleEmailChange(event) {
      this.setState({email: event.target.value});
    }

    handlePassChange(event) {
      this.setState({password: event.target.value});
    }

    doCancel() {
      this.setState({
        goHome: true
      });
    }

    mobileLoginRender() {
      return (
        <div className="loginPage">
          <MetaTags>
            <title>War Riders Game Account</title>
            <meta property="og:title" content="War Riders Game Account" />
            <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:image" content={img.account}/>
            <meta property="og:url" content="https://app.warriders.com/link"/>
            <meta name="twitter:card" content="summary_large_image"/>
  
            <meta property="og:site_name" content="War Riders"/>
            <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
          </MetaTags>
          <img src={img.account} />
          <h3>Please log in to your game account to continue to account deletion:</h3>
          <input placeholder="Email Address" value={this.state.email} onChange={this.handleEmailChange} />
          <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePassChange} />
          <a onClick={this.handleLogin} className="btn-bar loginBtn">
            <span>Log In</span>
          </a>
        </div>
      )
    }
  
    loginRender() {
      return (
        <div className="loginPage">
          <MetaTags>
            <title>War Riders Game Account</title>
            <meta property="og:title" content="War Riders Game Account" />
            <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
            <meta property="og:image" content={img.account}/>
            <meta property="og:url" content="https://app.warriders.com/link"/>
            <meta name="twitter:card" content="summary_large_image"/>
  
            <meta property="og:site_name" content="War Riders"/>
            <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
          </MetaTags>
          <img src={img.account} />
          <h3>Please log in to your game account to continue to account deletion:</h3>
          <input placeholder="Email Address" value={this.state.email} onChange={this.handleEmailChange} />
          <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePassChange} />
          <Grid>
            <Row style={{marginTop: '30px'}}>
              <Col>
                <a onClick={this.handleLogin} className="btn-bar loginBtn">
                  <span>Log In</span>
                </a>
              </Col>
            </Row>
          </Grid>
        </div>
      )
    }

    deleteAccountRender() {
        if (this.state.goHome) {
            return <Redirect to='/' />
        }
    
        return (
          <div className="loginPage" style={{paddingLeft: '40px', paddingRight: '40px'}}>
            <MetaTags>
              <title>War Riders Delete Game Account</title>
              <meta property="og:title" content="War Riders Delete Game Account" />
              <meta name="description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
              <meta property="og:description" content="War Riders is the first MMO game of earning cryptocurrency and blowing up cars." />
              <meta property="og:image" content={img.account}/>
              <meta property="og:url" content="https://app.warriders.com/link"/>
              <meta name="twitter:card" content="summary_large_image"/>
    
              <meta property="og:site_name" content="War Riders"/>
              <meta name="twitter:image:alt" content="Buy game items now at app.warriders.com"/>
            </MetaTags>
            <br></br>
            <br></br>
            <br></br>
            <h3 style={{textAlign: 'center'}}>DELETE GAME ACCOUNT?</h3>
            <br></br>
            <h4 style={{textAlign: 'center', lineHeight: '30px'}}>Are you sure you want to delete your game account and all associated data? Deleting your game account will also unlink your wallet.</h4>
            <Grid>
              <Row>
                <Col md={4} sm={12}>
                </Col>
                <Col md={2} sm={12}>
                  <a onClick={this.confirmDelete} className="btn-bar" style={{ color: '#fff', width: '140px', margin: "0 auto", marginTop: '45px'}}>
                    <span>Delete Account</span>
                  </a>
                </Col>
                <Col md={2} sm={12}>
                  <a onClick={this.doCancel} className="btn-bar" style={{ color: '#fff', width: '120px', margin: "0 auto", marginTop: '45px'}}>
                    <span>Cancel</span>
                  </a>
                </Col>
                <Col md={4} sm={12}>
                </Col>
              </Row>
            </Grid>
            <div style={{  display: "flex", justifyContent: "center", alignItems: "center", margin: '25px auto 0px auto', width: 'fit-content' }}>
              <ReCaptcha
                  ref={(el) => {this.createCaptcha = el;}}
                  size="normal"
                  data-theme="dark"
                  render="explicit"
                  sitekey="6LfRHosUAAAAAN4nwL9re2SXahZXVPm0LuSDYHou"
                  verifyCallback={this.verifyCallback}
                  onloadCallback={this.captchaLoaded}
              />
            </div>
          </div>
        )
    }

    render() {
      if (this.state.loginAccount) {
        if (isMobile) {
          return this.mobileLoginRender();
        }
        return this.loginRender();
      }
      return this.deleteAccountRender();
    }
}

export default DeleteAccount;