import logo from './images/mahLogo.png';
import lambo from './images/lambo-large.jpg';
import hovercraft from './images/hover-large.jpg';
import tanker from './images/tank-large.jpg';
import regular from './images/regular-large.jpg';
import midGrade from './images/mid-grade-large.jpg';
import buggy from './images/buggy.jpg';
import prius from './images/prius.jpg';
import suv from './images/suv-large.jpg';
import war_truck from './images/war-truck-large.jpg';
import unknownVehicle from './images/mystery-vehicle-small.jpg';
import unknownVehicleSmall from './images/mystery-vehicle.jpg';
import bznTruck from './images/BZN-truck.jpg';
import supplyOverview from './images/supply-overview.jpg';
import midgradeSupply from './images/mid-grade-supply.jpg';
import regularSupply from './images/mid-grade-supply.jpg';
import premiumSupply from './images/premium-supply.jpg';
import allAuctions from './images/all-auctions-icon.jpg';
import foundersLambo from './images/founders-lambo.png';
import foundersTank from './images/founders-tank.png';
import goldLambo from './images/gold-lambo.png';
import goldTank from './images/gold-tank.png';
import platinumLambo from './images/platinum-lambo.png';
import platinumTank from './images/platinum-tank.png';
import bundleIcon from './images/bundle-icon.jpg';
import etherHover from './images/eth-hover.png';
import glassTank from './images/glass-car-tank.png';
import glassHover from './images/glass-hover.png';
import glassLambo from './images/glass-war-lambo.png';
import palladiumHover from './images/palladium-hover.png';
import bitcoinSUV from './images/suv-btc.png';
import etherSUV from './images/suv-eth.png';
import palladiumSUV from './images/suv-palladium.png';
import bitcoinTruck from './images/truck-btc.png';
import etherTruck from './images/truck-eth.png';
import palladiumTruck from './images/truck-palladium.png';
import bitcoinHover from './images/btc-hover.png';
import link from './images/link.png';
import account from './images/account-wr.png';
import trophyTruck from './images/trophy_truck_xr.png';

var ImgFactory = {
  link: link,
  account: account,
  glassTank: glassTank,
  glassHover: glassHover,
  glassLambo: glassLambo,
  etherHover: etherHover,
  palladiumHover: palladiumHover,
  bitcoinSUV: bitcoinSUV,
  etherSUV: etherSUV,
  palladiumSUV: palladiumSUV,
  bitcoinTruck: bitcoinTruck,
  etherTruck: etherTruck,
  palladiumTruck: palladiumTruck,
  bitcoinHover: bitcoinHover,
  logo: logo,
  lambo: lambo,
  hovercraft: hovercraft,
  tanker: tanker,
  regular: regular,
  midGrade: midGrade,
  buggy: buggy,
  prius: prius,
  suv: suv,
  war_truck: war_truck,
  unknownVehicleSmall: unknownVehicleSmall,
  unknownVehicle: unknownVehicle,
  bznTruck: bznTruck,
  supplyOverview: supplyOverview,
  midgradeSupply: midgradeSupply,
  regularSupply: regularSupply,
  premiumSupply: premiumSupply,
  allAuctions: allAuctions,
  foundersTank: foundersTank,
  foundersLambo: foundersLambo,
  goldLambo: goldLambo,
  goldTank, goldTank,
  platinumLambo: platinumLambo,
  platinumTank: platinumTank,
  bundleIcon: bundleIcon,
  trophyTruck: trophyTruck
};

export default ImgFactory;
