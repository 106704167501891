import Blockies from 'react-blockies';
import axios from 'axios';
import React, { Component } from 'react';
import ReactDom from 'react-dom';
import vars from './variables.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Form, FormControl, FormGroup, Checkbox, ControlLabel, ProgressBar, Col, Row, Grid, DropdownButton, MenuItem, Button, ButtonToolbar, Well} from 'react-bootstrap';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import loadingGif from './images/loading.gif';
import MetaTags from 'react-meta-tags';
import { isMobile } from "react-device-detect";

let homeDir = 'https://app.warriders.com/ref/';
//let web3 = vars.web3;
let URLs = vars.urls;
const firebase = vars.firebase;

class UserOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      copied: false,
      isSignedIn: false,
      userProfile: null,
      alreadyLinked: false,
      isPending: false,
      linkedAddress: '',
      redirectURL: "",
      windowWidth: window.innerWidth,
      username: '',
    };

    this.didCopy = this.didCopy.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleUnlinkRelink = this.handleUnlinkRelink.bind(this);
    this.askDisconnect = this.askDisconnect.bind(this);
    this.connectWeb3 = this.connectWeb3.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.getUsername = this.getUsername.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  askDisconnect() {
    const self = this;

    swal({
      title: "Are you sure?",
      text: "Are you sure you want to log out of your wallet?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No, nevermind", "Yes, logout"]
    }).then(function(shouldDisconnect) {
      if (shouldDisconnect) {
        vars.web3Logout();
        self.props.onDisconnect();
        self.setState({
          alreadyLinked: false,
          address: '',
        });
      }
    });
  }

  async getUsername(user) {
    const snapshot = await firebase.database().ref("users/" + user.uid + "/username").once("value");
    let username = snapshot.val() || null;

    return username;
  }

  componentWillMount() {
    var self = this;

    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      let temp = !!user;

      if (temp) {
        vars.accountPendingLink().then(function(pending) {
          self.setState({
            isPending: pending
          });
        });
        var username;
        //User is now signed in, lets see if they're linked
        self.getUsername(user).then(function(un) {
          username = un;
          return vars.accountLinked();
        }).then(function(address) {
          self.setState({ alreadyLinked: true, linkedAddress: address, isSignedIn: temp, userProfile: user, username: username });
        }).catch(function() {
          self.setState({ alreadyLinked: false, linkedAddress: "", isSignedIn: temp, userProfile: user });
        })
      } else {
        self.setState({ alreadyLinked: false, linkedAddress: "", isSignedIn: temp, userProfile: user });
      }
    });

    let userCheck = firebase.auth().currentUser;
    if (userCheck != null) {
      self.getUsername(userCheck).then(function(un) {
        self.setState({
          username: un
        });
      });

      vars.accountPendingLink().then(function(pending) {
        self.setState({
          isPending: pending
        });
      });

      //User is already signed in, lets see if they're linked
      this.setState({ isSignedIn: true, userProfile: userCheck });

      vars.accountLinked().then(function(address) {
        self.setState({ alreadyLinked: true, linkedAddress: address });
      }).catch(function() {
        self.setState({ alreadyLinked: false, linkedAddress: "" });
      })
    }

      vars.getAccounts((error, accounts) => {
        if (accounts != null ){
          if (accounts.length == 0) {
              // there is no active accounts in MetaMask
          }
          else {
            self.setState ({
              address: accounts[0]
            });

          }
        }
      })
    }

    componentDidMount() {
      window.addEventListener("resize", this.handleResize);
    }
  
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    didCopy() {
      this.setState({copied: true});

      toast.success('Copied!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    handleResize(e) {
      if (this.state.windowWidth == window.innerWidth) {
        return; //Nothing to do
      }
      this.setState({
        windowWidth: window.innerWidth
      });
    }

    async connectWeb3() {
      this.props.onClose();
      await vars.askForWeb3();
    }

    handleLink() {
      this.props.onClose();
    }

    notVerifiedError() {
      const user = this.state.userProfile;
      swal("Not Verified", "Your account has not been verified. Please check your email for instructions to verify your email and link your wallet.\nIf you just verified your account, try logging out and logging back in.\nIf you lost the email, you can resend it using the button below", "error", {
        buttons: {
          resend: "Resend Verification Email", 
          close: "Close"
        }
      }).then(function(val) {
        if (val == "resend") {
          user.sendEmailVerification();
        }
      });
    }

    handleLogout() {
      firebase.auth().signOut();
      this.setState({ alreadyLinked: false, linkedAddress: "", isSignedIn: false, userProfile: null });
    }

    handleUnlinkRelink() {
      let self = this;

      if (this.state.address.toLowerCase() != this.state.linkedAddress.toLowerCase()) {
        swal({
          title: "Are you sure?",
          text:  "Are you sure you want to link a new wallet to this account? Only one wallet may be linked at a time.",
          icon: "warning",
          buttons: ["No, nevermind", "Yes, link this wallet"]
        }).then(function(shouldRelink) {
          if (shouldRelink) {
            self.setState({ redirectURL: vars.urls.linkAccount});
          }
        });
      } else {
        swal({
          title: "Are you sure?",
          text:  "Are you sure you want to unlink this wallet?",
          icon: "warning",
          buttons: ["No, nevermind", "Yes, unlink this wallet"]
        }).then(function(shouldUnlink) {
          if (shouldUnlink) {
            swal({
              title: "Processing...",
              text: "Please wait while your account is unlinked",
              icon: loadingGif,
              className: 'wr-loader',
              button: false,
              closeOnClickOutside: false
            });

            const msg = new Buffer('I confirm I am the owner of this account');
            var hash = '0x' + msg.toString('hex')

            vars.web3.eth.personal.sign(hash, self.state.address).then(function(signature) {
              self.state.userProfile.getIdToken().then(function(token) {
                fetch('https://us-central1-war-riders-account-system.cloudfunctions.net/app/unlink', {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                  },
                  body: JSON.stringify({
                    message: signature
                  })
                }).then(function(response) {
                  return response.json();
                }).then(function(obj) {
                  if (!obj.error) {
                    swal({
                      title: "Success",
                      text: "Your wallet has successfully been unlinked.",
                      icon: "success"
                    }).then(function() {
                      self.setState({ alreadyLinked: false, linkedAddress: "" });
                    })
                  } else {
                    swal("Got Error", "Error: " + obj.message, "error");
                  }
                }).catch(function(error) {
                  swal("Got Error", "Error: " + error, "error");
                })
              });
            });
          }
        });
      }
    }

    handleShare() {
      this.setState({
        redirectURL: '/share'
      })
    }

    handleDelete() {
      this.setState({
        redirectURL: '/delete'
      })
    }

    render() {
      const usingMobile = isMobile || this.state.windowWidth <= 1620;

      const padding = usingMobile ? '15px' : '40px'

      if (this.state.redirectURL != "") {
        this.setState({ redirectURL: "" });
        this.props.onClose();
        return <Redirect to={this.state.redirectURL} />
      }

      const address = this.state.address != '' ? this.state.address.substring(0, 10) + "..." + this.state.address.substring(this.state.address.length - 10) : 'NOT LOGGED IN';
      const linkedAddress = this.state.linkedAddress != '' ? this.state.linkedAddress.substring(0, 10) + "..." + this.state.linkedAddress.substring(this.state.linkedAddress.length - 10) : 'NOT LINKED';
      const fixedLinkedAddress = this.state.linkedAddress != '' ? vars.infoWeb3.utils.toChecksumAddress(this.state.linkedAddress) : 'NOT LINKED';
      //#0b0c20
      return (
        <div className={this.props.className} id="ignore-click">
          <div>
            <h2 style={{textAlign: 'center'}}>External Wallet</h2>
            <div className='usr-address-section'>
              <Blockies
                  seed={this.state.address}
                  size={10}
                  scale={3}
                  className="usr-identicon identicon" />
              <input className="usr-address" type="text" value={address} readonly />
              <CopyToClipboard text={this.state.address} onCopy={() => this.didCopy() }>
                <Button bsStyle="primary usr-disconnect-btn" style={{margin: '0'}}>
                  <FontAwesomeIcon icon="copy" />
                </Button>
              </CopyToClipboard>
            </div>
            <a onClick={this.state.address != '' ? this.askDisconnect : this.connectWeb3} className="btn-bar" style={{ color: '#fff', width: '110px', margin: '0 auto', marginTop: '15px'}}>
              {this.state.address != '' ?
                <span>LOG OUT</span> :
                <span>LOG IN</span>
              }
            </a>
          </div>
          <h2 style={{textAlign: 'center'}}>Game Account</h2>
          {this.state.isSignedIn ? 
            <div>
              <p style={{ overflow: "hidden", textAlign: "center"}}>Username: {this.state.username}</p>
              <p style={{ overflow: "hidden", textAlign: "center"}}>Email: {this.state.userProfile.email}</p>
            </div> :
            <p style={{ overflow: "hidden", textAlign: "center"}}>Not logged in</p>
          }
          <div>
            <div className='usr-address-section'>
              <Blockies
                  seed={fixedLinkedAddress}
                  size={10}
                  scale={3}
                  className="usr-identicon identicon" />
              <input className="usr-address" type="text" value={linkedAddress} readonly />
              <CopyToClipboard text={this.state.linkedAddress} onCopy={() => this.didCopy() }>
                <Button bsStyle="primary usr-disconnect-btn" style={{margin: '0'}}>
                  <FontAwesomeIcon icon="copy" />
                </Button>
              </CopyToClipboard>
            </div>
          </div>
          <div className='usr-button-box'>
            <div>
              {(this.state.isPending ? 
                <a onClick={this.notVerifiedError} className="btn-bar" style={{ color: '#fff', width: '110px', margin: '0 auto', marginTop: '15px'}}>
                  <span>{"LINK"} </span>
                </a>
                :
                !this.state.alreadyLinked ?
                  (this.state.address != '' || !this.state.isSignedIn ?
                    <Link to={vars.urls.linkAccount}>
                      <a onClick={this.handleLink} className="btn-bar" style={{ color: '#fff', width: '110px', margin: '0 auto', marginTop: '15px'}}>
                        <span>{(this.state.userProfile == null) ? "Login & Link" : "LINK"} </span>
                      </a>
                    </Link>
                    :
                    <></>
                  )
                :
                <a onClick={this.handleUnlinkRelink} className="btn-bar" style={{ color: '#fff', width: '110px', margin: '0 auto', marginTop: '15px'}}>
                    <span>{(this.state.address.toLowerCase() != this.state.linkedAddress.toLowerCase()) ? "RELINK" : "UNLINK"}</span>
                </a>
              )}
            </div>
            {(this.state.isSignedIn ?
            <div>
              <a onClick={this.handleLogout} className="btn-bar" style={{ color: '#fff', width: '110px', margin: '0 auto', marginTop: '15px'}}>
                <span>Log Out</span>
              </a>
            </div> : '')}
          </div>
          <div className='usr-button-box'>
            {(this.state.isSignedIn ?
            <div>
              <a onClick={this.handleShare} className="btn-bar" style={{ color: '#fff', width: '75px', margin: '0px 0px 0px 0px'}}>
                <span>Share</span>
              </a>
            </div> : '')}
            {(this.state.isSignedIn ?
            <div>
              <a onClick={this.handleDelete} className="btn-bar" style={{ color: '#fff', width: '75px', margin: '0 auto', marginTop: '0px'}}>
                <span>Delete</span>
              </a>
            </div> : '')}
          </div>
        </div>
      )
    }
}

function shouldIgnoreClicks(e) {
  let current = e.target;
  while (current != null) {
    if (current.id == "ignore-click") {
      return true;
    }
    current = current.parentElement;
  }

  return false;
}

class UserPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      opened: false,
      userClass: "usr",
      windowWidth: window.innerWidth
    };

    this.avatarClicked = this.avatarClicked.bind(this);
    this.setupAccounts = this.setupAccounts.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.connectWeb3 = this.connectWeb3.bind(this);
    this.mobileAccountRender = this.mobileAccountRender.bind(this);
    this.desktopRender = this.desktopRender.bind(this);
    this.mobileRender = this.mobileRender.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
  }

  avatarClicked(e) {
    if (shouldIgnoreClicks(e)) {
      return; //Ignore clicks
    }

    var shouldOpen = !this.state.opened;
    this.setState({
      opened: shouldOpen
    })

    if (shouldOpen) {
      this.setState({
        userClass: "usr usr-opened"
      });
    } else {
      this.setState({
        userClass: "usr usr-closed"
      });
    }
  }

  handleResize(e) {
    if (this.state.windowWidth == window.innerWidth) {
      return; //Nothing to do
    }
    this.setState({
      windowWidth: window.innerWidth
    });
  }

  componentWillMount() {
    this.setupAccounts();

    let self = this;
    vars.addWeb3DisconnectListener(function() {
      self.setState({
        enabled: false
      });

      vars.waitForWeb3().then(self.setupAccounts);
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setupAccounts() {
    var self = this;
    if (window.ethereum) {
      // Listen for account changes
      window.ethereum.on('accountsChanged', function(accounts) {
        if (accounts != null ){
          if (accounts.length == 0) {
            self.setState({
              address: 'DNE',
            });
            // there is no active accounts in MetaMask
          }
          else {
            self.setState({
              address: accounts[0],
              enabled: true
            });
          }
        }
        else {
          self.setState({
            address: 'DNE',
          });
        }
      })

      // And get current accounts
      vars.getAccounts((error, accounts) => {
        if (accounts != null ){
          if (accounts.length == 0) {
            self.setState({
              address: 'DNE',
            });
            // there is no active accounts in MetaMask
          }
          else {
            self.setState({
              address: accounts[0],
              enabled: true
            });
          }
        }
        else {
          self.setState({
            address: 'DNE',
          });
        }
      })
    } else {
      vars.getAccounts((error, accounts) => {
        if (accounts != null ){
          if (accounts.length == 0) {
            self.setState({
              address: 'DNE',
            });
            // there is no active accounts in MetaMask
          }
          else {
            self.setState({
              address: accounts[0],
              enabled: true
            });
          }
        }
        else {
          self.setState({
            address: 'DNE',
          });
        }

        setTimeout(self.setupAccounts, 100);
      })
    }
  }

  handleClose() {
    this.setState({
      opened: false
    })
  }

  handleDisconnect() {
    this.setState({
      address: 'DNE',
    });
  }

  async connectWeb3() {
    await vars.askForWeb3();

    this.setState({
      showConnect: !vars.isWeb3Known()
    })
  }

  accountRender() {
    const addressToUse = this.state.address != 'DNE' && this.state.address != '' ? this.state.address : '0x528A8058E04456aA6a7fFc921Ea313346cad81d8'

    return (
          <div>
            {(this.state.opened) ? <UserOptions className='usr-option option-small-desktop' onClose={this.handleClose} onDisconnect={this.handleDisconnect} /> : <div></div>}
            <Blockies
              seed={addressToUse}
              size={10}
              scale={3}
              className="identicon" /> 
          </div>
    )
  }

  mobileAccountRender(className, customStyle) {
    const usingMobile = isMobile || this.state.windowWidth < 770;
    const addressToUse = this.state.address != 'DNE' && this.state.address != '' ? this.state.address : '0x528A8058E04456aA6a7fFc921Ea313346cad81d8'

    return (
          <div style={customStyle}>
            {(this.state.opened) ? <UserOptions className={className} onClose={this.handleClose} onDisconnect={this.handleDisconnect} /> : <div></div>}
            <a onClick={this.avatarClicked}>
              <Blockies
                seed={addressToUse}
                size={10}
                scale={3}
                className="identicon"
                style={{marginTop: '20px'}} />
            </a>
          </div>
    )
  }

  connectRender() {
    const padding = isMobile ? '15px' : '40px'

    return (
      <div>
        <a className="btn-bar" style={{ color: '#fff', marginBottom: padding, marginLeft: padding}} onClick={this.connectWeb3}>
            <span>CONNECT</span>
        </a>
      </div>
    )
  }

  mobileRender() {
    return (
      <div className='usr-desktop usr-mobile'>
        <div onClick={this.avatarClicked} className='usr-inner'>
            <p style={{margin: 'auto'}}>My Account</p>
            {this.mobileAccountRender('usr-option option-small-desktop', {marginRight: '10px'})}  
        </div>
      </div>
    )
  }

  desktopRender() {
    const smallDesktop = this.state.windowWidth <= 1620;
    return (
      <div className={'usr-desktop' + (smallDesktop ? ' small-desktop' : '')}>
        <div onClick={this.avatarClicked} className='usr-inner'>
            <h5>My Account</h5>
            {smallDesktop ? this.mobileAccountRender('usr-option option-small-desktop', {}) : this.accountRender()}  
        </div>
      </div>
    )
  }

  render() {
    const usingMobile = isMobile || this.state.windowWidth < 770;
    return usingMobile ? this.mobileRender() : this.desktopRender()
  }
}

export default UserPanel;
